import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, DatePicker } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { put, get } from "../../../services/authService";
import ProjectIcon from "../../../Assets/svg/iconMenu1.svg";

const { Option } = Select;
const { TextArea } = Input;

const EditProject = ({ visible, onClose, project, onSubmit, setMessage }) => {
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if(project){
      const contractId = localStorage.getItem("currentContractId");
    const apiCountries = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;
    get(apiCountries)
      .then((res) => setAllCountries(res.data))
      .catch((error) => console.log(error));

    
    const apiContracts = `/api/Contract?id=${contractId}`;

    get(apiContracts)
      .then((res) => setClients(res.data.clients))
      .catch((error) => console.log(error));

    const countryId = project?.projectDetails?.country?.id
      get(`/api/GeoData/cities?countryId=${countryId}`)
      .then((res) => setCities(res.data))
      .catch((error) => console.log(error));
    }

  }, [project]);


  const [form] = Form.useForm();
  const id = localStorage.getItem("currentContractId");


  const handleCountryChange = (countryId) => {
    
    if (!countryId){
      form.setFieldsValue({ cityId: [] });
      setCities([]);
    }
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => setCities(res.data))
        .catch((error) => console.log(error));
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const requestBody = {
          id: project?.id,
          projectName: values.projectName,
          projectNumber: values.projectNumber,
          description: values.projectDescription || "",
          projectDetails: {
            countryId: values.countryId,
            contractId: id,
            cityIds: values.cityId,
            clientFocalPoint: values.clientFocalPoint,
            clientFocalPointEmail: values.clientFocalPointEmail,
            referenceDocuments: values.referenceDocuments || "",
            linkToSharedDrive: values.linkToSharedDrive,
          },
          clients: values.clients.map((clientId) => {
            const client = clients.find((c) => c.id === clientId);
            return { id: client.id, name: client.name };
          }),
        };

        console.log(requestBody);

        setLoading(true);
        put("/api/projects", requestBody)
          .then((response) => {
            form.resetFields();
            onSubmit();
            const message = response.message || "This project was correctly updated to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: ProjectIcon,
              title: "Project updated successfully!",
              text: message,
            });
            onClose();
          })
          .catch((error) => {
            console.error("There was an error updating the project!", error);
            const errorMessage = error.response?.data?.errors[0]?.message || "We had a problem updating this project, please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              icon: ProjectIcon,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        const error = errorInfo.response?.data?.errors[0]?.message || "Please fill all required fields correctly.";
        setMessage({
          trigger: true,
          type: "danger",
          icon: ProjectIcon,
          title: "Oops! A problem has occurred!",
          text: error,
        });
      });
    setMessage({
      trigger: false,
      type: "",
      icon: "",
      title: "",
      text: "",
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit project round</h2>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          projectName: project?.projectName,
          projectNumber: project?.projectNumber,
          countryId: project?.projectDetails?.country?.id,
          cityId: project?.projectDetails?.locations?.map(city => city.id),
          clients: project?.clients?.map((client) => client.id),
          clientFocalPoint: project?.projectDetails?.clientFocalPoint,
          clientFocalPointEmail: project?.projectDetails?.clientFocalPointEmail,
          projectDescription: project?.description,
          referenceDocuments: project?.projectDetails?.referenceDocuments,
          linkToSharedDrive: project?.projectDetails?.linkToSharedDrive,
        }}
      >
        <p className="modalsHeader1">Project Details</p>
        <Form.Item
          name="projectName"
          label="Project Name"
          rules={[{ required: true, message: "Please enter project name" }]}
        >
          <Input placeholder="Enter Project Name" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="projectNumber"
          label="Project number"
          rules={[{ required: true, message: "Please enter project number" }]}
        >
          <Input placeholder="Enter Project number" autoComplete="off" />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="countryId"
            label="Country"

            rules={[{ required: true, message: "Please select country" }]}
            style={{ width: "48%" }}
          >
            <Select
              onChange={handleCountryChange}
              placeholder="Select Country"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allCountries &&
                allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="cityId"
            label="Governorate/State"
            rules={[{ required: true, message: "Please select governorate or state" }]}
            style={{ width: "48%" }}
          >
            <Select
              placeholder={cities?.length < 1 ? "Select a country first!" :"Select governorate or state"}
              showSearch
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="clients"
            label="Clients"
            rules={[{ required: true, message: "Please select a client" }]}
            style={{ width: "48%" }}
          >
            <Select
              placeholder="Select Clients"
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {clients &&
                clients.map((client) => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          name="clientFocalPoint"
          label="Client Focal Point"
          rules={[
            { required: true, message: "Please enter a client focal point" },
          ]}
        >
          <Input placeholder="example" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="clientFocalPointEmail"
          label="Client Focal Point Email"
          rules={[
            {
              required: true,
              message: "Please enter a client focal point email",
            },
          ]}
        >
          <Input placeholder="example" autoComplete="off" />
        </Form.Item>
        <Form.Item name="projectDescription" label="Project Description">
          <TextArea placeholder="Project Description" rows={4} />
        </Form.Item>
        <Form.Item
          name="referenceDocuments"
          label="Links to reference documents (communications doc link, etc.)"
          rules={[
            { required: true, message: "Please enter a links to reference documents" },
          ]}
        >
          <TextArea placeholder="Links to reference documents" rows={4} />
        </Form.Item>
        <Form.Item
          name="linkToSharedDrive"
          label="Link to Shared drive"
          rules={[
            { required: true, message: "Please enter a Link to Shared drive" },
          ]}
        >
          <Input placeholder="example" autoComplete="off" />
        </Form.Item>

        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
            disabled={loading}
          >
            Edit project round
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditProject;
