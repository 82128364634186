import { Button, DatePicker, Input, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "../../../../Assets/svg/searchIcon.svg";
import { get } from "../../../../services/authService";

import { taskTypes } from "./taskTypes";
import { DataContext } from "./DataContext";

const { Option } = Select;

// months and years data

const Filters = ({setPagination}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [users, setUsers] = useState([]);

  const [allCountries, setAllCountries] = useState();

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    get(apiCountries)
      .then((res) => setAllCountries(res.data))
      .catch((error) => console.log(error));

    const apiUsers = "/api/User/getAllUsers?IgnorePagination=true";

    get(apiUsers)
      .then((res) => setUsers(res.data))
      .catch((error) => console.log(error));
  }, []);

  const context = useContext(DataContext);
  if (!context) {
    return <></>;
  }
  const { filters, setFilters} = context;
  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      Search: "",
      Type: "",
      Status: "",
      Country: "",
      UserId: "",
    });
  };

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    setFilters((prevFilters) => ({ ...prevFilters, Search: search }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleLocation = (location) => {
    setFilters((prevFilters) => ({ ...prevFilters, Country: location }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleType = (type) => {
    setFilters((prevFilters) => ({ ...prevFilters, Type: type }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleStatus = (status) => {
    setFilters((prevFilters) => ({ ...prevFilters, Status: status }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleUser = (user) => {
    setFilters((prevFilters) => ({ ...prevFilters, UserId: user }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  // Get data from API for filters


  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        <Input
          placeholder="Search by task name..."
          onChange={handleSearch}
          value={filters.Search || ""}
          suffix={<img src={SearchIcon} alt="Search" />}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Task type</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a type"
              allowClear
              showSearch
              value={filters.Type || undefined}
              onChange={handleType}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {taskTypes && taskTypes.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Status</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a status"
              allowClear
              showSearch
              value={filters.Status || undefined}
              onChange={handleStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={1}>Assigned</Option>
              <Option value={2}>Accepted</Option>
              <Option value={3}>Completed</Option>
              <Option value={4}>On hold</Option>
              <Option value={5}>Archived</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Location</p>
            <Select
              onChange={handleLocation}
              value={filters.Country || undefined}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries &&
                allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country?.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Assigned to</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a user"
              allowClear
              showSearch
              value={filters.UserId || undefined}
              onChange={handleUser}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {users &&
                users.map((user) => (
                  <Option key={user?.id} value={user?.id}>
                    {user?.userName}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
