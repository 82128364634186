import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Divider,
  Space,
  Button,
  Spin,
} from "antd";
import MessagePopUp from "../../../../../components/Messages/MessagePopUp";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { get, post } from "../../../../../../services/authService";

const { Option } = Select;
const customSpinner = (
  <LoadingOutlined style={{ fontSize: 20, color: "var(--2, #AA1A5F)" }} spin />
);

const implementingPartner = [
  "6FE4F20C-6AF6-4A09-92F7-14C41ACFFC56",
  "DA2C6BD6-F477-448E-BD67-190C2BF1315F",
  "695A9074-8230-4870-83FC-6D6F6DEFD26E",
  "F146B89D-E36D-4F85-87F9-954F0A54BA34",
  "CD11E9C8-4347-424D-BF8A-B638105524E8",
  "1261D65F-EA55-48D4-9402-E00856E7B60B",
  "12CF1789-00B0-4138-A9EF-F25334BEF497",
  "0A3D28AE-DAD4-4C11-82C0-F51BF8BC17F9",
];

const GeneralDetails = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [clusters, setClusters] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);

  const [newCluster, setNewCluster] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");

  const [addingCluster, setAddingCluster] = useState(false);
  const [addingSector, setAddingSector] = useState(false);
  const [addingSubSector, setAddingSubSector] = useState(false);
  const [selectedSectorId, setSelectedSectorId] = useState();
  const inputRef = useRef(null);

  const addCluster = async (e) => {
    e.preventDefault();
    if (newCluster) {
      try {
        setLoading(true);
        await post("/api/Site/createCluster", { name: newCluster });

        fetchClusterData();
        setNewCluster("");
        setAddingCluster(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Cluster added successfully!`,
          text: `A new cluster has been created and added to your database!`,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem adding this cluster to your database, please try again.`,
        });
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const addSector = async (e) => {
    e.preventDefault();
    if (newSector) {
      try {
        setLoading(true);
        await post("/api/Site/createSector", { name: newSector });

        fetchSectorData();
        setNewSector("");
        setAddingSector(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Sector added successfully!`,
          text: `A new sector has been created and added to your database!`,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem adding this sector to your database, please try again.`,
        });
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const addSubSector = async (e) => {
    e.preventDefault();
    if (newSubSector && selectedSectorId) {
      try {
        setLoading(true);
        await post("/api/Site/createSubSector", {
          name: newSubSector,
          sectorId: selectedSectorId,
        });
        fetchSubSectorData();
        setNewSubSector("");
        setAddingSubSector(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Subsector added successfully!`,
          text: `A new subsector has been created and added to your database!`,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem adding this subsector to your database, please try again.`,
        });
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClusterSelectChange = (value) => {
    setAddingCluster(false);
  };

  const handleSectorSelectChange = (id) => {
    setSelectedSectorId(id);
    setAddingCluster(false);
  };

  const handleSubSectorSelectChange = (value) => {
    setAddingCluster(false);
  };

  // Effect to fetch data for clusters, sectors, and subsectors

  const fetchClusterData = async () => {
    try {
      setLoading(true);
      const clustersResponse = await get("/api/Site/getAllClusters");
      setClusters(clustersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const fetchSectorData = async () => {
    try {
      setLoading(true);
      const sectorsResponse = await get("/api/Site/getAllSectors");
      setSectors(sectorsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClusterData();
    fetchSectorData();
  }, []);

  const fetchSubSectorData = async () => {
    try {
      setLoading(true);
      const subSectorsResponse = await get("/api/Site/getAllSubsectors").then(
        (res) => res.data.filter((item) => item.sectorId === selectedSectorId)
      );
      setSubSectors(subSectorsResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSubSectorData();
  }, [selectedSectorId]);


  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />

      
      <h3>General details</h3>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Implementing partner" name="partner" required>
              <Select placeholder="Select an implementing partner">
                {implementingPartner &&
                  implementingPartner.map((implementingPartner, index) => (
                    <Option key={index} value={implementingPartner}>
                      Partner {index + 1}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Select IP planning card" name="ipPlanningCard">
              <Select disabled={true} placeholder="Select IP planning card">
                <Option value="card1">IP Card 1</Option>
                <Option value="card2">IP Card 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>

          <Spin spinning={loading} indicator={customSpinner}>
            <Form.Item label="Cluster" name="cluster" required>
                <Select
                  allowClear
                  placeholder="Select an item"
                  // disabled={loading}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      {addingCluster ? (
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={newCluster}
                            onChange={(e) => setNewCluster(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            type="text"
                            loading={loading}
                            icon={<PlusOutlined />}
                            onClick={addCluster}
                          >
                            Add item
                          </Button>
                        </Space>
                      ) : (
                        <Space
                          style={{
                            padding: "0 8px 4px",
                            cursor: "pointer",
                            color: "var(--2, #AA1A5F)",
                          }}
                          onClick={() => setAddingCluster(true)}
                        >
                          <PlusOutlined /> Other
                        </Space>
                      )}
                    </>
                  )}
                  onChange={handleClusterSelectChange}
                  onDropdownVisibleChange={(open) => {
                    !open && setAddingCluster(false);
                    setNewCluster();
                  }}
                  options={clusters.map((cluster) => ({
                    label: cluster.name,
                    value: cluster.id,
                  }))}
                />
            </Form.Item>
            </Spin>
          </Col>
          <Col span={8}>
          <Spin spinning={loading} indicator={customSpinner}>
            <Form.Item label="Sector" name="sector" required>
                <Select
                  allowClear
                  placeholder="Select an item"
                  // disabled={loading}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      {addingSector ? (
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={newSector}
                            onChange={(e) => setNewSector(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            type="text"
                            loading={loading}
                            icon={<PlusOutlined />}
                            onClick={addSector}
                          >
                            Add item
                          </Button>
                        </Space>
                      ) : (
                        <Space
                          style={{
                            padding: "0 8px 4px",
                            cursor: "pointer",
                            color: "var(--2, #AA1A5F)",
                          }}
                          onClick={() => setAddingSector(true)}
                        >
                          <PlusOutlined /> Other
                        </Space>
                      )}
                    </>
                  )}
                  onChange={handleSectorSelectChange}
                  onDropdownVisibleChange={(open) => {
                    !open && setAddingSector(false);
                    setNewSector();
                  }}
                  options={sectors.map((sector) => ({
                    label: sector.name,
                    value: sector.id,
                  }))}
                />
            </Form.Item>
            </Spin>
          </Col>
          <Col span={8}>
          <Spin spinning={loading} indicator={customSpinner}>
            <Form.Item label="Subsectors (optional)" name="subSector">
                <Select
                  placeholder="Select an item"
                  disabled={!selectedSectorId}
                  allowClear
                  value={selectedSectorId ? undefined : null}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      {addingSubSector ? (
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={newSubSector}
                            onChange={(e) => setNewSubSector(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            loading={loading}
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addSubSector}
                          >
                            Add item
                          </Button>
                        </Space>
                      ) : (
                        <Space
                          style={{
                            padding: "0 8px 4px",
                            cursor: "pointer",
                            color: "var(--2, #AA1A5F)",
                          }}
                          onClick={() => setAddingSubSector(true)}
                        >
                          <PlusOutlined /> Other
                        </Space>
                      )}
                    </>
                  )}
                  onChange={handleSubSectorSelectChange}
                  onDropdownVisibleChange={(open) => {
                    !open && setAddingSubSector(false);
                    setNewSubSector();
                  }}
                  options={subSectors.map((subSector) => ({
                    label: subSector.name,
                    value: subSector.id,
                  }))}
                />
            </Form.Item>
            </Spin>
          </Col>
        </Row>
    </>
  );
};

export default GeneralDetails;
