import React from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../TableView/Filters";
import { Pagination } from "antd";

const KanbanViewReporting = ({tasks,columns ,changeStatus ,setFilters,handleFilterChange,onPageChange, pagination, setPagination}) => {
 
  return (
    <>
      <Filters onChange={handleFilterChange} setFilters={setFilters} />
      <KanbanBoardComponent tasks={tasks} columns={columns} changeStatus={changeStatus} />
      <div className="d-flex justify-content-end my-5">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
              />
          </div>
    </>
  );
};

export default KanbanViewReporting;
