import React from "react";
import SubscriptionPlanBoxes from "./SubscriptionPlanBoxes";

const CurrentSubscription = React.memo(
   ({ planName, subscriptionStatus, subscriptionId, price, isMonthly }) => {
      return (
         <div className="currentSubscription">
            <SubscriptionPlanBoxes
               planName={planName}
               subscriptionStatus={subscriptionStatus}
               subscriptionId={subscriptionId}
               price={price}
               isMonthly={isMonthly}
            ></SubscriptionPlanBoxes>
         </div>
      );
   }
);

export default CurrentSubscription;

