import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
} from "antd";
import React, { useState } from "react";

const VisitDetails = () => {
  const [isPostponed, setIsPostponed] = useState(false);
  return (
    <div>
      <Row gutter={16}>
        {/* <Col span={12}>
          <Form.Item
            name="visitStartTime"
            label="Visit start time"
            rules={[
              {
                required: true,
                message: "Please select the visit start time!",
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Form.Item
            name="dateOfPlannedVisit"
            label="Date of planned visit"
            rules={[
              {
                required: true,
                message: "Please select the date of the planned visit!",
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Form.List
        name="siteContacts"
        rules={[
          {
            validator: async (_, contacts) => {
              if (!contacts || contacts.length < 1) {
                return Promise.reject(
                  new Error("At least one contact is required")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <> */}
      {/* <div style={{ marginBottom: 8 }}>
              <span style={{ fontWeight: "700" }}>
                Site contacts (add up to 3)
              </span>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={16} align="bottom">
                  <div className="d-flex justify-content-center">
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input contact name",
                          },
                        ]}
                        label="Contact name"
                      >
                        <Input placeholder="Enter name" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, "email"]}
                        rules={[
                          { required: true, message: "Please input email" },
                        ]}
                        label="Email"
                      >
                        <Input placeholder="Enter email" />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        {...field}
                        name={[field.name, "role"]}
                        rules={[
                          { required: true, message: "Please input role" },
                        ]}
                        label="Role"
                      >
                        <Input placeholder="Enter role" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, "organization"]}
                        label="Organization"
                      >
                        <Input placeholder="Enter organization" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, "phone"]}
                        label="Phone"
                      >
                        <Input placeholder="Enter phone" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, "skype"]}
                        label="Skype"
                      >
                        <Input placeholder="Enter Skype" />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Col>
                  </div>
                </Row>
              ))}
            </div> */}

<div style={{ marginBottom: 8, paddingTop: "20px" }}>
        <span style={{ fontWeight: "700" }}>IP point of contact</span>
        <Form.List
          name="pointContacts"
          initialValue={[{}]} // Start with one empty object to render the first contact form
          rules={[
            {
              validator: async (_, pointContacts) => {
                if (!pointContacts || pointContacts.length < 1) {
                  return Promise.reject(
                    new Error("Please add at least one contact")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <div className="siteContactsWrapper">
                <div className="siteContactForm">
                  {fields.map((field, index) => (
                    <React.Fragment key={field.key}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "contactName"]}
                            fieldKey={[field.fieldKey, "contactName"]}
                            label="Contact name"
                            rules={[
                              {
                                required: true,
                                message: "Contact name required",
                              },
                            ]}
                          >
                            <Input placeholder="Enter text here" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "email"]}
                            fieldKey={[field.fieldKey, "email"]}
                            label="Email"
                            rules={[
                              { required: true, message: "Email required" },
                            ]}
                          >
                            <Input placeholder="Enter text here" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "role"]}
                            fieldKey={[field.fieldKey, "role"]}
                            label="Role"
                            rules={[
                              { required: true, message: "Role required" },
                            ]}
                          >
                            <Input placeholder="Enter text here" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "organization"]}
                            fieldKey={[field.fieldKey, "organization"]}
                            label="Organization"
                            rules={[
                              {
                                required: true,
                                message: "Organization required",
                              },
                            ]}
                          >
                            <Input placeholder="Enter text here" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "phone"]}
                            fieldKey={[field.fieldKey, "phone"]}
                            label="Phone"
                          >
                            <Input placeholder="Enter text here" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, "skype"]}
                            fieldKey={[field.fieldKey, "skype"]}
                            label="Skype"
                          >
                            <Input placeholder="Enter text here" />
                          </Form.Item>
                        </Col>
                        <Col span={8} style={{ textAlign: "center" }}>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              style={{ margin: "32px", color: "red" }}
                              onClick={() => remove(field.name)}
                            />
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}

                  <Form.ErrorList errors={errors} />
                </div>
              </div>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </div>
      {/* <Form.ErrorList errors={errors} /> */}
      <div style={{ marginBottom: 8 }}>
        <span style={{ fontWeight: "700" }}>Site contacts (optional)</span>
        <Form.List
          name="siteContacts"
          initialValue={[]} // Start with one empty object to render the first contact form
          // rules={[
          //   {
          //     validator: async (_, siteContacts) => {
          //       if (!siteContacts || siteContacts.length < 1) {
          //         return Promise.reject(
          //           new Error("Please add at least one contact")
          //         );
          //       }
          //     },
          //   },
          // ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <div className="siteContactsWrapper">
                <div className="siteContactForm">
                  {fields.map((field, index) => (
                    <React.Fragment key={field.key}>
                      <Form.Item name="id" className="hidden">
                        <Input type="hidden" />
                      </Form.Item>
                      <Row gutter={24}>
                          <Col span={22}>
                          <Row gutter={16}>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                name={[field.name, "contactName"]}
                                fieldKey={[field.fieldKey, "contactName"]}
                                label="Contact name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Contact name required",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter text here" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                name={[field.name, "email"]}
                                fieldKey={[field.fieldKey, "email"]}
                                label="Email"
                                rules={[
                                  { required: true, message: "Email required" },
                                ]}
                              >
                                <Input placeholder="Enter text here" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                name={[field.name, "role"]}
                                fieldKey={[field.fieldKey, "role"]}
                                label="Role"
                                rules={[
                                  { required: true, message: "Role required" },
                                ]}
                              >
                                <Input placeholder="Enter text here" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                name={[field.name, "organization"]}
                                fieldKey={[field.fieldKey, "organization"]}
                                label="Organization"
                                rules={[
                                  {
                                    required: true,
                                    message: "Organization required",
                                  },
                                ]}
                              >
                                <Input placeholder="Enter text here" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                name={[field.name, "phone"]}
                                fieldKey={[field.fieldKey, "phone"]}
                                label="Phone"
                              >
                                <Input placeholder="Enter text here" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                name={[field.name, "skype"]}
                                fieldKey={[field.fieldKey, "skype"]}
                                label="Skype"
                              >
                                <Input placeholder="Enter text here" />
                              </Form.Item>
                            </Col>
                            
                          </Row>
                          </Col>
                          <Col span={2} style={{padding: '30px 12px 25px 0px'}}>
                            <div className="delete-button" onClick={() => remove(field.name)}>
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                style={{ margin: "32px"}}
                              />
                            </div>
                          </Col>
                      </Row>
                      
                    </React.Fragment>
                  ))}

                  <Form.ErrorList errors={errors} />
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                    className="addOtherContactBtn"
                  >
                    Add contact
                  </Button>
                </div>
              </div>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </div>

      <div style={{ marginBottom: 8, paddingTop: "25px" }}>
        <span style={{ fontWeight: "700" }}>
          KII contacts (optional)
        </span>
        <Form.List
          name="KIIcontacts"
          initialValue={[]} // Start with one empty object to render the first contact form
          // rules={[
          //   {
          //     validator: async (_, KIIcontacts) => {
          //       if (!KIIcontacts || KIIcontacts.length < 1) {
          //         return Promise.reject(
          //           new Error("Please add at least one contact")
          //         );
          //       }
          //     },
          //   },
          // ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <div className="siteContactsWrapper">
                <div className="siteContactForm">
                  {fields.map((field, index) => (
                    <React.Fragment key={field.key}>
                      <Row
                        gutter={16}
                        style={
                          index < fields.length - 1
                            ? {
                                padding: "15px 0",
                                borderBottom: "1px solid #FADCEA",
                              }
                            : { paddingTop: "15px" }
                        }
                      >
                        <Col span={24}>
                          <Row gutter={24}>
                            <Col span={22}>
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "contactName"]}
                                  fieldKey={[field.fieldKey, "contactName"]}
                                  label="Contact name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Contact name required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "email"]}
                                  fieldKey={[field.fieldKey, "email"]}
                                  label="Email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Email required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "role"]}
                                  fieldKey={[field.fieldKey, "role"]}
                                  label="Role"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Role required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "organization"]}
                                  fieldKey={[field.fieldKey, "organization"]}
                                  label="Organization"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Organization required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "phone"]}
                                  fieldKey={[field.fieldKey, "phone"]}
                                  label="Phone"
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "skype"]}
                                  fieldKey={[field.fieldKey, "skype"]}
                                  label="Skype"
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                            </Row>
                            </Col>
                            <Col span={2} style={{padding: '30px 12px 25px 0px'}}>
                              <div className="delete-button" onClick={() => remove(field.name)}>
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={{ margin: "32px"}}
                                  />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}

                  <Form.ErrorList errors={errors} />
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                      className="addOtherContactBtn"
                    >
                      Add another contact
                    </Button>
                </div>
              </div>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </div>
      {/* </>
        )}
      </Form.List> */}
      <Row>
        <Col span={24}>
          <Form.Item
            label="Specifics of on-site operation"
            style={{ paddingTop: "20px" }}
            name="specificsOfOnSiteOperation"
          >
            <Input placeholder="Enter instructions" />
          </Form.Item>
          {/* <Form.Item name="observationList" valuePropName="checked">
            <div className="d-flex  align-items-center" style={{ gap: "10px" }}>
              <Switch />
              <span>Observation List </span>
            </div>
          </Form.Item> */}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="beneficiariesCount"
            label="Beneficiaries"
            required
            tooltip="Number of beneficiaries to be interviewed"
          >
            <InputNumber min={1} max={10} defaultValue={3} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="kills"
            label="Klls"
            required
            tooltip="Number of kills to be observed"
          >
            <InputNumber min={1} max={10} defaultValue={3} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="observationChecklistCount"
            label="Observation checklist"
            required
            tooltip="Items to be checked during observation"
          >
            <InputNumber min={1} max={10} defaultValue={3} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="mediaLimit"
            label="Media (Video/Photos)"
            required
            tooltip="Number of media items needed"
          >
            <InputNumber min={1} max={10} defaultValue={3} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="samplingInstructions"
        label="Sampling instructions (beneficiaries, sample, profile, selection method, etc)"
      >
        <Input.TextArea rows={3} placeholder="Enter sampling instructions" />
      </Form.Item>
      <Form.Item name="clientInstructions" label="Instructions for any Kills">
        <Input.TextArea rows={3} placeholder="Enter instructions" />
      </Form.Item>
      <Form.Item name="otherInstructions" label="Any other instructions">
        <Input.TextArea rows={3} placeholder="Enter instructions" />
      </Form.Item>
      <Form.Item name="visitPlanApproval" label="Visit plan approver">
        <Input.TextArea rows={3} placeholder="Enter visit plan approver" />
      </Form.Item>
    </div>
  );
};
export default VisitDetails;
