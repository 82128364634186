import React, { createContext, useState, useEffect } from "react";
import { get, put } from "../../../../services/authService";
import { useTaskStore } from "./store/taskStore";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { fetchTaskss, fetchAllTasks } = useTaskStore();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({
    Search: "",
    Type: "",
    Status: "",
    Country: "",
    UserId: "",
  });

  const changeTaskStatus = (taskId, newStatus) => {
    const params = {
      id: taskId,
      status: newStatus,
    };

    put(`/api/Tasks/updateTaskStatus`, params)
      .then((response) => {
        console.log("Response:", response);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    console.log("FETCHED", fetchTaskss);
    if (fetchTaskss) {
      fetchData(pagination.current, pagination.pageSize);
      fetchAllTasks(false);
    }
  }, [fetchTaskss]);

  const fetchData = async (current, pageSize) => {
    try {
      setLoading(true);
      const projectId = localStorage.getItem("currentProjectID");
      const params = {
        ProjectId: projectId,
        Search: filters.Search,
        Type: filters.Type,
        Status: filters.Status,
        Country: filters.Country,
        UserId: filters.UserId,
        pageSize: pageSize,
        pageNumber: current,
      };

      const response = await get("/api/Tasks/getAllTasks", { ...params });
      const result = response.data;
      setData(result);

      if (response.metaData) {
        const meta = response.metaData;
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: meta.totalItemCount,
          current: meta.pageNumber,
          pageSize: meta.pageSize,
        }));
      } else {
        console.warn("No metaData found in response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, filters]);

  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        fetchData,
        changeTaskStatus,
        filters,
        setFilters,
        pagination,
        setPagination,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
