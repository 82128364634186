import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Divider,
  Space,
  Button,
  Spin,
} from "antd";
import { get, post } from "../../../../../services/authService";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const customSpinner = (
  <LoadingOutlined style={{ fontSize: 20, color: "var(--2, #AA1A5F)" }} spin />
);

const implementingPartner = ["be4a4fb9-e3be-4f38-c7ef-08dc99a52727"];

const GeneralDetails = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [clusters, setClusters] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  // const [allImplementingPartners, setAllImplementingPartners] = useState([]);
  const [allIPPlans, setAllIPPlans] = useState([]);
  const [ipLoader, setIpLoader] = useState(false);

  const [newCluster, setNewCluster] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");

  const [addingCluster, setAddingCluster] = useState(false);
  const [addingSector, setAddingSector] = useState(false);
  const [addingSubSector, setAddingSubSector] = useState(false);
  const [selectedSectorId, setSelectedSectorId] = useState();
  const [selectedSubSectorId, setSelectedSubSectorId] = useState();
  const contractID = localStorage.getItem("currentProjectID");

  const inputRef = useRef(null);

  const addCluster = async (e) => {
    e.preventDefault();
    if (newCluster) {
      try {
        setLoading(true);
        await post("/api/Site/createCluster", { name: newCluster });

        fetchClusterData();
        setNewCluster("");
        setAddingCluster(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Cluster added successfully!`,
          text: `A new cluster has been created and added to your database!`,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem adding this cluster to your database, please try again.`,
        });
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const addSector = async (e) => {
    e.preventDefault();
    if (newSector) {
      try {
        setLoading(true);
        await post("/api/Site/createSector", { name: newSector });

        fetchSectorData();
        setNewSector("");
        setAddingSector(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Sector added successfully!`,
          text: `A new sector has been created and added to your database!`,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem adding this sector to your database, please try again.`,
        });
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const addSubSector = async (e) => {
    e.preventDefault();
    if (newSubSector && selectedSectorId) {
      try {
        setLoading(true);
        await post("/api/Site/createSubSector", {
          name: newSubSector,
          sectorId: selectedSectorId,
        });
        fetchSubSectorData();
        setNewSubSector("");
        setAddingSubSector(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Subsector added successfully!`,
          text: `A new subsector has been created and added to your database!`,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem adding this subsector to your database, please try again.`,
        });
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClusterSelectChange = (value) => {
    setAddingCluster(false);
  };

  const handleSectorSelectChange = (id) => {
    setSelectedSubSectorId(id);
    setSelectedSectorId(id);
    setAddingCluster(false);
  };

  const handleSubSectorSelectChange = (value) => {
    setAddingCluster(false);
  };

  // Effect to fetch data for clusters, sectors, and subsectors

  const fetchClusterData = async () => {
    try {
      setLoading(true);
      const clustersResponse = await get("/api/Site/getAllClusters");
      setClusters(clustersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const fetchSectorData = async () => {
    try {
      setLoading(true);
      const sectorsResponse = await get("/api/Site/getAllSectors");
      setSectors(sectorsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // const fetchImplementingPartners = async () => {
  //   try {
  //     const response = await get(`/api/ImplementingPartner/getAllImplementingPartners?contractId=${contractID}`);
  //     setAllImplementingPartners(response?.data);
  //   } catch (err) {
  //     console.log('error fetching implementing partners', err);
  //   }
  // }

  useEffect(() => {
    fetchClusterData();
    fetchSectorData();
    fetchIPPlaningCards();
  }, []);

  const fetchSubSectorData = async () => {
    setSelectedSubSectorId(null);
    setNewSubSector(null);
    try {
      setLoading(true);
      const subSectorsResponse = await get("/api/Site/getAllSubsectors").then(
        (res) => res.data.filter((item) => item.sectorId === selectedSectorId)
      );
      setSubSectors(subSectorsResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const fetchIPPlaningCards = async () => {
    setIpLoader(true);
    try {
      const response = await get(
        `/api/IPPlan/getAllIPPlans?ProjectId=${contractID}`
      );
      setAllIPPlans(response?.data);
      setIpLoader(false);
    } catch (error) {
      console.error("Error fetching IPPlans: ", error);
      setIpLoader(false);
    }
  };

  useEffect(() => {
    fetchSubSectorData();
  }, [selectedSectorId]);

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />

      <h3>General details</h3>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Select IP planning card" name="IPlaningCard">
            <Select placeholder="IP plannig card" loading={ipLoader}>
              {allIPPlans &&
                allIPPlans.map((IPPlan, index) => (
                  <Option key={index} value={IPPlan?.id}>
                    {IPPlan?.identifier}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Cluster" name="cluster" required>
            <Select
              allowClear
              placeholder="Select an item"
              // disabled={loading}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  {addingCluster ? (
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Please enter item"
                        ref={inputRef}
                        value={newCluster}
                        onChange={(e) => setNewCluster(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        loading={loading}
                        icon={<PlusOutlined />}
                        onClick={addCluster}
                      >
                        Add item
                      </Button>
                    </Space>
                  ) : (
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        cursor: "pointer",
                        color: "var(--2, #AA1A5F)",
                      }}
                      onClick={() => setAddingCluster(true)}
                    >
                      <PlusOutlined /> Other
                    </Space>
                  )}
                </>
              )}
              onChange={handleClusterSelectChange}
              onDropdownVisibleChange={(open) => {
                !open && setAddingCluster(false);
                setNewCluster();
              }}
              options={clusters.map((cluster) => ({
                label: cluster.name,
                value: cluster.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Sector" name="sector" required>
            <Select
              allowClear
              placeholder="Select an item"
              // disabled={loading}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  {addingSector ? (
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Please enter item"
                        ref={inputRef}
                        value={newSector}
                        onChange={(e) => setNewSector(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        loading={loading}
                        icon={<PlusOutlined />}
                        onClick={addSector}
                      >
                        Add item
                      </Button>
                    </Space>
                  ) : (
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        cursor: "pointer",
                        color: "var(--2, #AA1A5F)",
                      }}
                      onClick={() => setAddingSector(true)}
                    >
                      <PlusOutlined /> Other
                    </Space>
                  )}
                </>
              )}
              onChange={handleSectorSelectChange}
              onDropdownVisibleChange={(open) => {
                !open && setAddingSector(false);
                setNewSector();
              }}
              options={sectors.map((sector) => ({
                label: sector.name,
                value: sector.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Spin spinning={loading} indicator={customSpinner}>
            <Form.Item label="Subsectors (optional)" name="subSector">
              <Select
                placeholder="Select an item"
                disabled={!selectedSectorId}
                allowClear
                // value={selectedSubSectorId}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    {addingSubSector ? (
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={newSubSector}
                          onChange={(e) => setNewSubSector(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          loading={loading}
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addSubSector}
                        >
                          Add item
                        </Button>
                      </Space>
                    ) : (
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          cursor: "pointer",
                          color: "var(--2, #AA1A5F)",
                        }}
                        onClick={() => setAddingSubSector(true)}
                      >
                        <PlusOutlined /> Other
                      </Space>
                    )}
                  </>
                )}
                onChange={handleSubSectorSelectChange}
                onDropdownVisibleChange={(open) => {
                  !open && setAddingSubSector(false);
                  setNewSubSector();
                }}
                options={subSectors.map((subSector) => ({
                  label: subSector.name,
                  value: subSector.id,
                }))}
              />
            </Form.Item>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default GeneralDetails;
