import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Space,
  Divider,
  Spin,
} from "antd";
import "./UserManagement.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import minusButtonIcon from "../../../Assets/Images/minusButtonIcon.png";
import { get, post } from "../../../services/authService";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddMember = ({ visible, onClose, onSubmit, setMessage }) => {
  const [form] = Form.useForm();
  const [rolePermissions, setRolePermissions] = useState([{ id: 1 }]);
  const idCounter = useRef(1);
  const [allCountries, setAllContries] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [roles, setRoles] = useState([]);
  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);
  const [newOffice, setNewOffice] = useState("");
  const [addingOffice, setAddingOffice] = useState(false);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedCityIds, setSelectedCityIds] = useState([]);

  const fetchOfficeData = (cityIds) => {
    const params = new URLSearchParams();
    cityIds.forEach(id => params.append('CityIds', id));
    
    get(`/api/Office/getAllByCity?${params}`)
      .then((res) => setOffices(res.data))
      .catch((error) => console.log(error));
  };

  const addOffice = async (e) => {
    e.preventDefault();
    if (newOffice) {
      try {
        setLoading(true);
        console.log(selectedCityIds);
  
        const requestBody = {
          cityIds: selectedCityIds,
          name: newOffice,
        };
  
        const response = await post("/api/Office", requestBody);
        const message = response.message || 'Office added successfully!';
        fetchOfficeData(selectedCityIds);
        setNewOffice("");
        setAddingOffice(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Office added successfully!`,
          text: message,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item:`, error);
        const errorMessage = error.response?.data?.errors[0]?.message
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      } finally {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }
    }
  };
  

  const handleOfficeSelectChange = () => {
    setAddingOffice(false);
  };

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));

    get("/api/Account/getAllRoles")
      .then((res) => setRoles(res.data))
      .catch((error) => console.log(error));

    get("/api/contract/getAll?pageSize=10000&pageNumber=1")
      .then((res) => setWorkspaces(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleCountryChange = (countryId) => {
    form.resetFields(["city", "office"]);
    setCities([]);
    setSelectedCityIds([])
    setOffices([]);
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => setCities(res.data))
        .catch((error) => console.log(error));
    }
  };

  
  const handleCityChange = (cityIds) => {
    // console.log(cityIds)
    form.resetFields(["office"]);
    setOffices([]);
    setSelectedCityIds(cityIds);
    if (cityIds && cityIds?.length > 0) {
      fetchOfficeData(cityIds);
    }
  };



  const handleAddRolePermissions = () => {
    const newId = idCounter.current + 1;
    idCounter.current = newId;
    const newRolePermissions = [...rolePermissions, { id: newId }];
    setRolePermissions(newRolePermissions);
  };

  const handleRemoveRolePermissions = (idToRemove) => {
    const updatedPermissions = rolePermissions.filter(
      (role) => role.id !== idToRemove
    );
    form.resetFields([`role${idToRemove}`, `workspace${idToRemove}`]);
    setRolePermissions(updatedPermissions);
  };

  const handleOk = () => {
    setLoading(true);
  
    form
      .validateFields()
      .then((values) => {
        const { email, position, country, city, office, ...rest } = values;
  
        // Ensure officeLocations is an array of strings
        const officeLocations = Array.isArray(office) ? office : office ? [office] : [];
  
        // Map rolePermissions to contractRoles array
        const contractRoles = rolePermissions.map((role) => ({
          contractId: rest[`contract${role.id}`],
          roleId: rest[`role${role.id}`],
        }));
  
        // Construct the request body
        const requestBody = {
          email,
          position,
          officeLocations, // Assign officeLocations directly
          contractRoles,
        };
  
        // Log the request body for debugging
        console.log("Request body:", requestBody);
  
        // Send POST request to the API endpoint
        return post("/api/user/addmember", requestBody);
      })
      .then((response) => {
        console.log("API response:", response);
        const message = response.message || 'A new member has been created and added to your database!';
  
        // Reset form fields on successful submission
        form.resetFields();
        
        // Set success message for user feedback
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Member added successfully!",
          text: message,
        });
  
        // Perform any additional actions upon successful submission
        onSubmit();
        setSelectedCityIds([])
        setCities([])
        onClose();
      })
      .catch((error) => {
        console.error("Error adding member:", error);
  
        // Extract error message from API response or handle general error
        const errorMessage = error.response?.data?.errors[0]?.message || 'We had a problem adding this member to your database, please try again.';
        console.error("Error message:", errorMessage);
  
        // Set error message for user feedback
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      })
      .finally(() => {
        setLoading(false);
  
        // Clear message after 3 seconds
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      });
  };
  
  

  const handleCancel = () => {
    form.resetFields();
    setSelectedCityIds([])
    setCities([])
    onClose();
  };

  const getAvailableWorkspaces = (index) => {
    const selectedWorkspaceIds = rolePermissions
      .filter((_, idx) => idx !== index) // Exclude the current index
      .map((role) => form.getFieldValue(`workspace${role.id}`))
      .filter(Boolean); // Filter out undefined values
    return workspaces.filter(
      (workspace) => !selectedWorkspaceIds.includes(workspace.id)
    );
  };

  const validateDuplicateWorkspace = (_, value) => {
    const workspaceValues = rolePermissions.map((role) =>
      form.getFieldValue(`workspace${role.id}`)
    );
    const duplicate =
      workspaceValues.filter((workspace) => workspace === value).length > 1;
    return duplicate
      ? Promise.reject(new Error("You already selected this workspace"))
      : Promise.resolve();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Add New Member</h2>
      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Personal Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Email" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="position"
            label="Position"
            rules={[{ required: true, message: "Please select position" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Position" autoComplete="off" />
          </Form.Item>
        </div>
        <p className="modalsHeader1">Location Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Please select country" }]}
            style={{ width: "48%" }}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select Country"
              onChange={handleCountryChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allCountries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="city"
            label="Governorate/State"
            rules={[{ required: true, message: "Please select a governorate or state" }]}
            style={{ width: "48%" }}
          >
            <Select
            showSearch
            loading={!cities}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={cities?.length < 1 ? 'Select a country first!' : "Select governorate or state"}
              onChange={handleCityChange}
              disabled={cities?.length < 1}
              mode="multiple"
            >
              {!cities ? <Option><Spin></Spin></Option> : cities.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          style={{ width: "48%" }}
          label="Office"
          name="office"
          required
        >
          <Select
            allowClear
            mode="multiple"
            disabled={selectedCityIds?.length < 1 || selectedCityIds === undefined}
            placeholder={selectedCityIds?.length < 1 || selectedCityIds === undefined ? 'Select governorate or state first!' : "Select an office"}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                {addingOffice ? (
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter office name"
                      ref={inputRef}
                      value={newOffice}
                      onChange={(e) => setNewOffice(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      loading={loading}
                      icon={<PlusOutlined />}
                      onClick={addOffice}
                    >
                      Add office
                    </Button>
                  </Space>
                ) : (
                  selectedCityIds && (
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        cursor: "pointer",
                        color: "var(--2, #AA1A5F)",
                      }}
                      onClick={() => setAddingOffice(true)}
                    >
                      <PlusOutlined /> Create a new office
                    </Space>
                  )
                )}
              </>
            )}
            onChange={handleOfficeSelectChange}
            onDropdownVisibleChange={(open) => {
              !open && setAddingOffice(false);
              setNewOffice();
            }}
            options={ offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
        </Form.Item>
        <p className="modalsHeader1">Roles and Permissions</p>
        {rolePermissions.map((role, index) => (
          <div key={role.id} className="rolesAndPremissions">
            <Form.Item
              name={`role${role.id}`}
              label="Role"
              rules={[{ required: true, message: "Please select role" }]}
              style={{ width: "48%" }}
            >
              <Select placeholder="Select Role">
                {roles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.roleName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={`contract${role.id}`}
              label="Contract"
              rules={[
                { required: true, message: "Please select contract" },
                { validator: validateDuplicateWorkspace },
              ]}
              style={{ width: "48%" }}
            >
              <Select placeholder="Select Contract">
                {getAvailableWorkspaces(index).map((workspace) => (
                  <Option key={workspace.id} value={workspace.id}>
                    {workspace.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {rolePermissions.length > 1 && (
              <div onClick={() => handleRemoveRolePermissions(role.id)}>
                <img
                  src={minusButtonIcon}
                  style={{ cursor: "pointer" }}
                  alt="Remove role permission"
                />
              </div>
            )}
          </div>
        ))}
        <div
          className="addRolesAndPremissins"
          onClick={handleAddRolePermissions}
        >
          Enroll to another workspace
        </div>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Add Member
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMember;
