import React, { useEffect, useState } from "react";
import { Select, Input, Button, DatePicker, Spin } from "antd";
import SearchIcon from "../../../../Assets/svg/searchIcon.svg";
import { get } from "../../../../services/authService";
import dayjs from 'dayjs';

const { MonthPicker, YearPicker } = DatePicker;
const { Option } = Select;

const Filters = ({ onChange, filters, setFilters, pagination, setPagination,fetchSiteVisitCards }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [implementingPartnerId, setImplementingPartnerId] = useState(null);
  const [clusterId, setCluserId] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [search, setSearch] = useState("");
  const [siteVisitStatus, setSiteVisitStatus] = useState(null);
  const projectID = localStorage.getItem("currentProjectID");


  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setPagination({ ...pagination, current: 1 });
    onChange(key, value);
  };

  const handleClearAll = () => {
    setSearch("");
    setSiteVisitStatus(null);
    setImplementingPartnerId(null);
    setCluserId(null);
    setSelectedCountry(null);
    setMonth(null);
    setYear(null);
    setFilters({});
    setPagination({ ...pagination, current: 1 });
    fetchSiteVisitCards({ projectID, pageNumber: 1, pageSize: 10 })
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleFilterChange("search", e.target.value);
  };

  const handleIp = (value,option) => {
    const implementingPartnerId = option ? option.key : null;
    setImplementingPartnerId(value);
    handleFilterChange("implementingPartnerId", implementingPartnerId);
  };

  const handleProgressStatus = (value) => {
    const siteVisitStatus = value ? parseInt(value) : null;
    setSiteVisitStatus(siteVisitStatus);
    handleFilterChange("siteVisitStatus", siteVisitStatus);
  };

  const handleLocation = (value, option) => {
    const countryId = option ? option.value : null;
    setSelectedCountry(value);
    handleFilterChange("country", countryId);
  };

  const handleCluster = (value, option) => {
    const clusterId = option ? option.key : null;
    setCluserId(clusterId);
    handleFilterChange("clusterId", clusterId);
  };

  const handleMonth = (date) => {
    const monthValue = date ? dayjs(date).format("MM") : null;
    setMonth(monthValue);
    handleFilterChange("month", monthValue);
  };

  const handleYear = (date) => {
    const yearValue = date ? dayjs(date).format("YYYY") : null;
    setYear(yearValue);
    handleFilterChange("year", yearValue);
  };

  useEffect(() => {
    setLoading(true);
    get("/api/GeoData/countries")
      .then((res) => {
        setAllCountries(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    get("/api/Site/getAllClusters")
      .then((res) => setClusters(res.data))
      .catch((error) => console.log(error));

    const workspaceId = localStorage.getItem("currentContractId");
    get(`/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`)
      .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
      <Input
          placeholder="Type something here"
          value={search}
          onChange={handleSearchChange}
          suffix={<img src={SearchIcon} alt="search icon" />}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              showSearch
              value={implementingPartnerId}
              onChange={handleIp}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              suffixIcon={loading ? <Spin size="small" /> : null}
            >
              {implementingPartners.map((partner) => (
                <Option key={partner.id} value={partner.id}>
                  {partner.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Location</p>
            <Select
              onChange={handleLocation}
              value={selectedCountry}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries.map((country) => (
                <Option key={country.id} value={country.stateName}>
                  {country.stateName}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Progress Status</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              showSearch
              value={siteVisitStatus}
              onChange={handleProgressStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={1}>Data collection</Option>
              <Option value={2}>Post-data Collection</Option>
              <Option value={3}>Finalization</Option>
              <Option value={4}>Delivered</Option>
              <Option value={5}>IP Feedback</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Cluster</p>
            <Select
              onChange={handleCluster}
              value={clusterId}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a cluster"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {clusters.map((cluster) => (
                <Option key={cluster.id} value={cluster.id}>
                  {cluster.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Month</p>
            <MonthPicker
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select month"
              allowClear
              value={month ? dayjs(month, "MM") : null}
              onChange={handleMonth}
              format="MM"
            />
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <YearPicker
              onChange={handleYear}
              allowClear
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select year"
              value={year ? dayjs(year, "YYYY") : null}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
