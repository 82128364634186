import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import "./UserManagement.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const InviteMember = ({ visible, onClose}) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={600}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Invite Members</h2>
      <p className="inviteText">
        Grant access to your project by sharing this link with new members. Once
        they click the link, they can create an account and start collaborating
        in the selected project.{" "}
      </p>{" "}
      <p className="inviteText" style={{ color: "#AA1A5F" }}>
        Note: All invited members will have the role of “user” by default.
      </p>
      <Form layout="vertical">
        <Form.Item
          name="project"
          label={
            <span style={{ fontWeight: "bold" }}>
              Select the project you want to invite members to
            </span>
          }
          rules={[{ required: true, message: "Please select role" }]}
        >
          <Select placeholder="Select Project">
            <Option value="admin">P1</Option>
            <Option value="user">P2</Option>
            {/* Add more role options as needed */}
          </Select>
        </Form.Item>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button className="addButtonTPM" key="submit" type="primary">
            Copy Link
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default InviteMember;
