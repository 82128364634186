import React from 'react';
import PropTypes from 'prop-types';
import './Banner.css'; // Import the CSS file
import banner from "../../../Assets/Images/Banner.png"
const Banner = ({ text }) => {
  return (
    <div className="banner-container">
      <div className="banner-text">{text}</div>
      <div className='banner-photo'>
      <img src={banner} /></div>
    </div>
  );
};

Banner.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Banner;
