import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { get } from '../../../services/authService';


const { Option } = Select;

const Filters = ({ filters, setFilters, setPagination}) => {


  const [showFilters, setShowFilters] = useState(false);


  //get data from API for filters
  const [countries, setContries] = useState();
  const [languages, setLanguages] = useState();
  const [contracts, setContracts] = useState();
  const [roles, setRoles] = useState();

  useEffect(() => {
    const apiContries = "/api/GeoData/countries";

    get(apiContries)
      .then((res) => setContries(res.data))
      .catch((error) => console.log(error));

    const apiLanguages = "/api/GeoData/languages";
    get(apiLanguages)
      .then((res) => setLanguages(res.data))
      .catch((error) => console.log(error));

    const apiContracts = "/api/Contract/getAll?pageSize=10000&pageNumber=1";
    get(apiContracts)
      .then((res) => setContracts(res.data))
      .catch((error) => console.log(error));

    const roles = "/api/Account/getAllRoles"

    get(roles)
      .then((res) => setRoles(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      Search: "",
      RoleIds: [],
      CountryIds: [],
      LanguageIds: [],
      ContractIds: [],
      Gender: [],
    });
  };

  const handleSearchChange = (e) => {
    const Search = (e.target.value);
    setFilters((prevFilters) => ({ ...prevFilters, Search: Search }));
    setPagination((prevPagination => ({ ...prevPagination, current: 1})))
  };

  const handleGender = (gender) => {
    setFilters((prevFilters) => ({ ...prevFilters, Gender: gender }));
    setPagination((prevPagination => ({ ...prevPagination, current: 1})))
  };

  const handleRole = (role) => {
    setFilters((prevFilters) => ({ ...prevFilters, RoleIds: role }));
    setPagination((prevPagination => ({ ...prevPagination, current: 1})))
  };

  const handleLanguage = (language) => {
    setFilters((prevFilters) => ({ ...prevFilters, LanguageIds: language }));
    setPagination((prevPagination => ({ ...prevPagination, current: 1})))
  };

  const handleCountry = (country) => {
    setFilters((prevFilters) => ({ ...prevFilters, CountryIds: country }));
    setPagination((prevPagination => ({ ...prevPagination, current: 1})))
  };

  const handleWorkspace = (contracts) => {

    setFilters((prevFilters) => ({ ...prevFilters, ContractIds: contracts }));
    setPagination((prevPagination => ({ ...prevPagination, current: 1})))
  };

  return (
    <div className="filtersWrapper">
    <div className="containerFilters">
      {" "}
      <Input
        placeholder="Search by name or email..."
        value={filters.Search}
        onChange={handleSearchChange}
      />
      <Button className="clearButton" onClick={handleClearAll}>
        Clear All
      </Button>
      <Button onClick={handleToggleFilters} className="toggleButton">
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
    </div>
    {showFilters && (
      <div className="filtersContainer">
        <div>
          <p className="filterText">Language</p>
          <Select
            onChange={handleLanguage}
            value={filters.LanguageIds}
            allowClear
            mode="multiple"
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select Language"
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {languages &&
              languages.map((language) => (
                <Option key={language.id} value={language.id}>
                  {language.name}
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <p className="filterText">Country</p>
          <Select
            onChange={handleCountry}
            value={filters.CountryIds}
            allowClear
            mode="multiple"
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select Country"
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {countries &&
              countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.stateName}
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <p className="filterText">Gender</p>
          <Select
            onChange={handleGender}
            value={filters.Gender ? filters.Gender: null}
            allowClear
            mode='multiple'
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select Gender"
            
          >
            <Option value="1">Male</Option>
            <Option value="2">Female</Option>
            <Option value="3">Other</Option>
            {/* Add more gender options as needed */}
          </Select>
        </div>
        <div>
          <p className="filterText">Contract</p>
          <Select
            onChange={handleWorkspace}
            allowClear
            value={
              filters.ContractIds !== null && filters.ContractIds
            }
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
            mode="multiple"
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select Workspace"
          >
            {
              contracts && contracts.map(contract => (
                <Option key={contract.id} value={contract.id}>{contract.name}</Option>
              ))
            }
          </Select>
        </div>
        <div>
          <p className="filterText">Role</p>
          <Select
            allowClear
            onChange={handleRole}
            mode="multiple"
            value={filters.RoleIds === "" ? null : filters.RoleIds}
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select Role"
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {
              roles && roles.map(role=>(
                <Option key={role.id} value={role.id}>{role.roleName}</Option>
              ))
            }
            {/* Add more role options as needed */}
          </Select>
        </div>
      </div>
    )}
  </div>
  )
}

export default Filters