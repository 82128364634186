import React, { useState, useEffect } from "react";
import { Select, Radio, Spin, Progress } from "antd";
import { Pie } from "@ant-design/plots";
import "./TpmSummary.scss";
import iconCal from "../../../../Assets/Images/iconCalendar.png";
import iconCol1 from "../../../../Assets/Images/iconCollection1.png";
import iconCol2 from "../../../../Assets/Images/iconCollection2.png";
import snapshot1 from "../../../../Assets/svg/snapshot1.svg";
import snapshot2 from "../../../../Assets/svg/snapshot2.svg";
import snapshot3 from "../../../../Assets/svg/snapshot3.svg";
import snapshot4 from "../../../../Assets/svg/snapshot4.svg";
import snapshot5 from "../../../../Assets/svg/snapshot5.svg";
import snapshot6 from "../../../../Assets/svg/snapshot6.svg";
import location1 from "../../../../Assets/svg/location1.svg";
import location2 from "../../../../Assets/svg/location2.svg";
import location3 from "../../../../Assets/svg/location3.svg";
import location4 from "../../../../Assets/svg/location4.svg";
import location5 from "../../../../Assets/svg/location5.svg";
import location6 from "../../../../Assets/svg/location6.svg";
import WorldMap from "./WorldMap";
import { httpService } from "../../../../services/httpService.service";

const { Option } = Select;

function TpmSummary() {
  const [periodType, setPeriodType] = useState("Month");
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGraphs, setShowGraphs] = useState(true);
  const [selectedYear, setSelectedYear] = useState("2024");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = months[new Date().getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  useEffect(() => {
    const handleResize = () => {
      setShowGraphs(window.innerWidth >= 500);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);
      try {
        const projectID = localStorage.getItem("currentProjectID");
        if (projectID) {
          const apiProject = `/api/Projects/getTPMsummary?projectId=${projectID}&year=${selectedYear}&month=${selectedMonth}`;
          httpService.get(
            apiProject,
            (data) => {
              setProjectData(data);
              setLoading(false);
            },
            (error) => {
              console.error("Error fetching project data:", error);
              setLoading(false);
            }
          );
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [selectedYear, selectedMonth]);

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handlePeriodTypeChange = (e) => {
    setPeriodType(e.target.value);
  };

  // if (loading) {
  //   return <div className="w-100 p-5 justify-content-center"><Spin /></div>;
  // }

  const createPieConfig = (data, chartName) => {
    const noData = data.every((item) => item.value === 0);
    const displayData = noData
      ? [{ type: "No data available", value: 1 }]
      : data;

    return {
      appendPadding: 10,
      data: displayData,
      angleField: "value",
      colorField: "type",
      radius: 1,
      innerRadius: 0.6,
      label: {
        offset: "-50%",
        content: noData ? "" : "{name}",
        style: {
          fill: "#fff",
          fontSize: 12,
          textAlign: "center",
        },
      },
      interactions: [{ type: "element-active" }],
      legend: {
        position: "right",
      },
    };
  };

  const siteVisitsData = [
    {
      type: "Data Collection",
      value: projectData?.data?.siteVisitsProgress?.dataCollection,
    },
    {
      type: "Delivered",
      value: projectData?.data?.siteVisitsProgress?.delivered,
    },
    {
      type: "finalization",
      value: projectData?.data?.siteVisitsProgress?.finalization,
    },
    {
      type: "ipFeedback",
      value: projectData?.data?.siteVisitsProgress?.ipFeedback,
    },
    {
      type: "postDataCollection",
      value: projectData?.data?.siteVisitsProgress?.postDataCollection,
    },
  ];

  const redFlagsData = [
    {
      type: "archived",
      value: projectData?.data?.redFlagsProgress?.archived,
    },
    {
      type: "assigned",
      value: projectData?.data?.redFlagsProgress?.assigned,
    },
    {
      type: "inprogress",
      value: projectData?.data?.redFlagsProgress?.inprogress,
    },
    {
      type: "onhold",
      value: projectData?.data?.redFlagsProgress?.onhold,
    },
    {
      type: "resolved",
      value: projectData?.data?.redFlagsProgress?.resolved,
    },
  ];

  const tasksData = [
    {
      type: "acceptedTasks",
      value: projectData?.data?.tasksOverviewResponse?.acceptedTasks,
    },
    {
      type: "archivedTasks",
      value: projectData?.data?.tasksOverviewResponse?.archivedTasks,
    },
    {
      type: "assignedTasks",
      value: projectData?.data?.tasksOverviewResponse?.assignedTasks,
    },
    {
      type: "completedTasks",
      value: projectData?.data?.tasksOverviewResponse?.completedTasks,
    },
    {
      type: "On Hold",
      value: projectData?.data?.tasksOverviewResponse?.onHoldTasks,
    },
  ];

  const years = [];
  for (let year = 2000; year <= 2030; year++) {
    years.push(
      <Option key={year} value={year}>
        {year}
      </Option>
    );
  }

  const monthOptions = months.map((month, index) => (
    <Option key={index} value={month}>
      {month}
    </Option>
  ));

  return (
    <Spin spinning={loading}>
      <div className="tpmSummaryDiv">
        <div className="tpmSummaryHeader">
          <Select
            defaultValue="2024"
            style={{ width: 120 }}
            onChange={handleYearChange}
          >
            {years}
          </Select>
          <Select
            defaultValue={currentMonth}
            style={{ width: 120 }}
            onChange={handleMonthChange}
          >
            {monthOptions}
          </Select>
          <Radio.Group
            onChange={handlePeriodTypeChange}
            value={periodType}
            className="custom-radio-group"
          >
            <Radio.Button value="Month" className="custom-radio-button">
              Month
            </Radio.Button>
            <Radio.Button value="Quarter" className="custom-radio-button">
              Quarter
            </Radio.Button>
          </Radio.Group>
        </div>
        {showGraphs && (
          <div className="tpmSummaryProject">
            <div className="tpmSummaryProjectName">
              <img src={iconCal} alt="img" />
              Month, year
            </div>
            <div className="tpmSummaryProjectInfo">
              <div className="tpmSummaryCollectionGoal">
                <div className="collectionGoalHeader">
                  <img src={iconCol1} alt="img" /> Site visits progress
                </div>
                <div className="collectionGoalProgress">
                  <center>
                    {loading ? (
                      <div>Loading...</div> // Replace this with your preferred loading indicator
                    ) : (
                      <Pie {...createPieConfig(siteVisitsData)} width={300}  />
                    )}
                  </center>
                  {/* <div className="progressInfo">
                <span className="itemsCompleted">
                  250/1000 <br />
                  <span style={{ color: "#999", fontSize: "12px" }}>
                    completed
                  </span>
                </span>
              </div> */}
                </div>
              </div>
              <div className="tpmSummaryCollectionGoal">
                <div className="collectionGoalHeader">
                  <img src={iconCol2} alt="img" /> Red flags progress
                </div>
                <div className="collectionGoalProgress">
                  <center>
                    {loading ? (
                      <div>Loading...</div> // Replace this with your preferred loading indicator
                    ) : (
                      <Pie {...createPieConfig(redFlagsData)} width={300} />
                    )}
                  </center>
                  {/* <div className="progressInfo">
                <span className="itemsCompleted">
                  2/10 <br />
                  <span style={{ color: "#999", fontSize: "12px" }}>
                    completed
                  </span>
                </span>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">Activity Snapshot</h2>
          <div className="activitySnapshotResultCard">
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeIpPlanningCards}
                </h2>
                <p className="activitySnapshotText">Active IP planning cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot1} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeRedFlagCards}
                </h2>
                <p className="activitySnapshotText">Active Red flag cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot2} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.projectMembers}
                </h2>
                <p className="activitySnapshotText">Project members</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot3} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activitySnapshot
                      ?.activeEnumeratorTeamCards
                  }
                </h2>
                <p className="activitySnapshotText">
                  Active enumerator team cards
                  <br />
                </p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot4} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeSiteVisitCards}
                </h2>
                <p className="activitySnapshotText">Active site visit cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot5} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeTripCards}
                </h2>
                <p className="activitySnapshotText">Active trip cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot6} alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">Activity by Location</h2>
          <div className="activitySnapshotResultCard">
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activityByLocation?.plannedVisits}
                </h2>
                <p className="activitySnapshotText">Planned visits</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location1} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activityByLocation?.postponedCards}
                </h2>
                <p className="activitySnapshotText">Postponed cards</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location2} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activityByLocation?.completedVisits}
                </h2>
                <p className="activitySnapshotText">Completed visits</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location3} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activityByLocation
                      ?.completedBeneficiaryInterviews
                  }{" "}
                  <span style={{ fontSize: " 20px" }}>completed</span>
                </h2>
                <p className="activitySnapshotText">Beneficiary interviews</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location4} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activityByLocation
                      ?.completedClientsInterviews
                  }
                  <span style={{ fontSize: " 20px" }}>completed</span>
                </h2>
                <p className="activitySnapshotText">clients interviews</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location5} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activityByLocation
                      ?.completedObservationInterviews
                  }
                  <span style={{ fontSize: " 20px" }}>completed</span>
                </h2>
                <p className="activitySnapshotText">Observation checklist</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location6} alt="img"></img>
              </div>
            </div>
          </div>
          <WorldMap></WorldMap>
        </div>
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">
            IP/Donor Reporting Status Snapshot
          </h2>
          <div className="donorsDiv">
            <div className="donorsLeft">
              <div className="donorsCardsContainer">
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {" "}
                      {
                        projectData?.data?.ipDonorReporting
                          ?.completedFieldTeamReporting
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      Field team reports-Completed
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location1} alt="img"></img>
                  </div>
                </div>{" "}
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {
                        projectData?.data?.ipDonorReporting
                          ?.awaitingFieldReportings
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      IP/Donor reports - Awaiting Field
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location2} alt="img"></img>
                  </div>
                </div>{" "}
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {
                        projectData?.data?.ipDonorReporting
                          ?.otherStatusesFieldTeamReporting
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      Field team reports-Other statuses
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location5} alt="img"></img>
                  </div>
                </div>{" "}
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {
                        projectData?.data?.ipDonorReporting
                          ?.completedIPDonorReports
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      IP/Donor reports - Completed
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location6} alt="img"></img>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="donorsRight">
              {" "}
              <div className="donorListLeft">
                <h1 className="donorListHeaderText">IP/Donor reports</h1>
              </div>
              <div className="donorListRight">
                <h2 className="donorListHeader2Text">Last 3 uploaded</h2>
              </div>
              <ul>
                {" "}
                {/* Assuming this is a list of reports */}
                {projectData?.data?.ipDonorReporting?.ipDonorReports.map(
                  (report, index) => (
                    <li key={index} className="donorListItem">
                      IP/Donor Report - {report.ipName} - {report.location} -
                      Report {report.reportNumber}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">Tasks overview</h2>
          <div className="donorsDiv">
            {" "}
            {showGraphs && (
              <div className="taskLeft">
                <div className="collectionGoalHeader">
                  <img src={iconCol1}></img> Tasks progress
                </div>
                <div className="taskCircleProgress">
                  <div className="circleContainer">
                    <center>
                      {loading ? (
                        <div>Loading...</div> // Replace this with your preferred loading indicator
                      ) : (
                        <Pie {...createPieConfig(tasksData)} width={300} />
                      )}
                    </center>
                  </div>
                  {/* <div className="statusText">
                <div className="acceptedText">Accepted</div>
                <div className="completedText">Completed</div>
              </div> */}
                </div>
              </div>
            )}
            <div className="taskRight">
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.assignedTasks}
                  </h2>
                  <p className="activitySnapshotText">Assigned tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot1} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.acceptedTasks}
                  </h2>
                  <p className="activitySnapshotText">Accepted tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot1} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.onHoldTasks}
                  </h2>
                  <p className="activitySnapshotText">On hold tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot1} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.archivedTasks}
                  </h2>
                  <p className="activitySnapshotText">Archived tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot3} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.completedTasks}
                  </h2>
                  <p className="activitySnapshotText">Completed tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot5} alt="img"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default TpmSummary;
