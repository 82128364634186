import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import "../../../pages/UserManagement/UserManagement.scss";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditIpModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log("Form values:", values);
      form.resetFields();
      onClose();
      message.success('Driver added successfully!');
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit implementing partner</h2>
      <Form form={form} layout="vertical">
        {" "}
        <Form.Item
            name="fullName"
            label="Full name"
            rules={[{ required: true, message: "Please enter IP full name" }]}
            style={{ width: "100%" }}
          >
            <Input placeholder="IP full name" autoComplete="off" />
          </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="organization"
            label="Organization"
            rules={[{ required: true, message: "Please enter organization name" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Organization name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please enter role" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Manager" autoComplete="off" />
          </Form.Item>
        </div>
        <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please enter email" }]}
            style={{ width: "100%" }}
          >
            <Input placeholder="ipemail@gmail.com" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true, message: "Please enter phone number" }]}
            style={{ width: "100%" }}
          >
            <Input placeholder="1234 567 890" autoComplete="off" />
          </Form.Item>        <Form.Item
            name="skypeId"
            label="Skype id"
            rules={[{ required: true, message: "Please enter skype id" }]}
            style={{ width: "100%" }}
          >
            <Input placeholder="Skype id" autoComplete="off" />
          </Form.Item>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Save changes
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditIpModal;
