import { Modal, Table, notification, Button } from "antd";
import { useState, useEffect } from "react";
import { get, del } from "../../../../../../../services/authService";
import deleteIcon from "../../../../../../../Assets/svg/table-action-delete.svg";
import { httpService } from "../../../../../../../services/httpService.service";
import { RevertButton } from "../../../../../../../Assets/svg";
import MessagePopUp from "../../../../../../components/Messages/MessagePopUp";

const DeletedModal = ({ visible, onOk, onCancel, fetchTripLogistics }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (pagination) => {
    setLoading(true);
    try {
      const projectId = localStorage.getItem("currentProjectID");
      if (!projectId) {
        throw new Error("Project ID is not available");
      }

      const url = `/api/TripLogistic/getAllTripLogistics?ProjectId=${projectId}&GetDeletedOnly=true&page=${pagination.current}&pageSize=${pagination.pageSize}`;
      const result = await get(url);
      if (!result || !result.data || !result.metaData) {
        throw new Error("API response is missing expected fields.");
      }
      setData(result.data);
      setPagination({
        ...pagination,
        total: result.metaData.totalItemCount,
      });
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchData(pagination);
    }
  }, [visible]);

  const deleteTask = async (taskId) => {
    try {
      const url = `/api/TripLogistic/deleteTripLogistics?includeIsDeleted=true`;
      const data = JSON.stringify([{ id: taskId, isDeleted: true }]);
      await del(url, data);
      fetchData(pagination);
    } catch (error) {
      console.error("Failed to delete task:", error);
    }
  };

  const recoverData = async (id) => {
    
      httpService.put(`/api/Site/revertDelete`, {
        entityType: 13,
        ids: [id],
      },(response)=>{

        const message =
        response.message ||
        "Trip logistic successfully reverted from soft deletion";
      setMessage({
        trigger: true,
        type: "success",
        icon: "",
        title: "Trip logistic reverted successfully!",
        text: message,
      });
      
      fetchData(pagination);
      fetchTripLogistics();
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
      },(error)=>{
        const errorMessage =
        error.response?.data?.errors[0]?.message ||
        "We had a problem reverting this trip logistic card to your database, please try again.";
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: errorMessage,
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
      });
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination);
  };

  const columns = [
    {
      title: <span className="styledTitle">Trip status</span>,
      dataIndex: "tripStatus",
      key: "tripStatus",
      render: (tripStatus, text) => {
        const statusMap = {
          1: "Planned",
          2: "In Progress",
          3: "Completed",
          4: "Cancelled",
        };
        const statusText = statusMap[tripStatus] || "Unknown Status";
        let className;
        let fill;

        if (statusText === "Planned") {
          className = "archived";
          fill = "#555555";
        } else if (statusText === "In Progress") {
          className = "completed";
          fill = "#0E9349";
        } else if (statusText === "Completed") {
          className = "onHold";
          fill = "#D46600";
        } else if (statusText === "Cancelled") {
          className = "accepted";
          fill = "#3B81FE";
        }
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{statusText}</span>
          </div>
        );
      },
    },

    {
      title: <span className="styledTitle">Date for trip</span>,
      dataIndex: ["enumeratorInformation", "tripDate"],
      key: "tripDate",
      render: (tripDate) => (
        <span className="styledDataIndex3">
          {new Date(tripDate).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Site visit card id name</span>,
      dataIndex: "siteVisitId",
      key: "distanceToSite",
      render: (siteVisitId) => (
        <span className="styledDataIndex3">{siteVisitId}</span>
      ),
    },
    {
      title: <span className="styledTitle">Driver Name</span>,
      dataIndex: "driverName",
      key: "driverName",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Team lead name</span>,
      dataIndex: "teamLeadName",
      key: "teamLeadName",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Pickup Time</span>,
      dataIndex: ["enumeratorInformation", "pickupTime"],
      key: "pickupTime",
      render: (pickupTime) => (
        <span className="styledDataIndex3">{pickupTime}</span>
      ),
    },
    {
      title: <span className="styledTitle">Pickup Place</span>,
      dataIndex: ["enumeratorInformation", "pickupPlace"],
      key: "pickupPlace",
      render: (pickupPlace) => (
        <span className="styledDataIndex3">{pickupPlace}</span>
      ),
    },
    {
      title: <span className="styledTitle">Meeting Time</span>,
      dataIndex: ["enumeratorInformation", "meetingTime"],
      key: "meetingTime",
      render: (meetingTime) => (
        <span className="styledDataIndex3">{meetingTime}</span>
      ),
    },
    {
      title: <span className="styledTitle">Location 2</span>,
      dataIndex: ["enumeratorInformation", "meetingPlace"],
      key: "meetingPlace",
      render: (meetingPlace) => (
        <span className="styledDataIndex3">{meetingPlace}</span>
      ),
    },
    {
      title: <span className="styledTitle">Overnight</span>,
      dataIndex: ["enumeratorInformation", "isOverNight"],
      key: "isOverNight",
      render: (isOverNight) => (
        <span className="styledDataIndex3">{isOverNight ? "Yes" : "No"}</span>
      ),
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            onClick={() => deleteTask(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={deleteIcon} alt="delete" />
          </Button>
          <Button
            onClick={() => recoverData(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={RevertButton} alt="RevertButton" />
          </Button>
        </span>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Modal
        title="Deleted trip logistics"
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={[]}
        width={"70%"}
      >
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey="key"
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </div>
  );
};

export default DeletedModal;
