import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { get } from "../../../services/authService";
import { useForm } from "antd/es/form/Form";

const { Option } = Select;

const TableFilters = ({ filters, setFilters, setPagination }) => {
  const [countries, setCountries] = useState();
  const [cities, setCities] = useState([]);
  const [form] = Form.useForm();

  const handleCountry = (CountryId) => {
    setFilters((prevFilters) => ({ ...prevFilters, CountryId }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }))

    if (!CountryId) {
      form.setFieldsValue({ ["city"]: undefined });
      setCities([]);
      setFilters((prevFilters) => ({ ...prevFilters, CityId: null }))
    }
    get(`/api/GeoData/cities?countryId=${CountryId}`)
      .then((res) => {
        if (res?.data < 1) {
          form.setFieldsValue({ ["city"]: undefined });
          setCities([]);
        } else {
          setCities(res.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    get(apiCountries)
      .then((res) => setCountries(res.data))
      .catch((error) => console.log(error));
  }, []);

  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      search: "",
      CityId: "",
      CountryId: "",
    });
    form.setFieldsValue({ ["city"]: undefined });
    form.setFieldsValue({ ["country"]: undefined });
    setCities([])
  };

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }))
  };

  const handleCityChange = (CityId) => {
    setFilters((prevFilters) => ({ ...prevFilters, CityId }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }))
  };

  return (
    <div className="filtersWrapper mb-3">
      <Form form={form} className="w-100">
        <div className="containerFilters w-100">
          <div className="d-flex align-items-center justify-content-between w-100 gap-2">
            <Input
              value={filters.search}
              placeholder="Type something here"
              onChange={handleSearchChange}
              style={{ width: "100%" }} // Ensure the input takes full width
            />
            <Button className="me-2 clearButton" onClick={handleClearAll}>
              Clear All
            </Button>
            <Button onClick={handleToggleFilters} className="toggleButton">
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Button>
          </div>
        </div>
        {showFilters && (
          <div className="filtersContainer mt-4">
            <div>
              <p className="filterText">Country</p>
              <Form.Item name="country">
                <Select
                  value={filters.CountryId}
                  allowClear
                  showSearch
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a country"
                  onChange={handleCountry}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {countries &&
                    countries.map((country) => (
                      <Option key={country.id} value={country.name}>
                        {country.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <p className="filterText">Governorate/State</p>

              <Form.Item name="city">
                <Select
                  value={filters.CityId}
                  showSearch
                  allowClear
                  onChange={handleCityChange}
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder={cities?.length < 1 ? "Select country first!" : "Select governorate or state"}
                  disabled={cities?.length < 1 ? true : false}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {cities &&
                    cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default TableFilters;
