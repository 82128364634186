import React, { useEffect, useState } from "react";
import "./Flags.scss";
import { Button, Tabs, Tooltip } from "antd";
import AddButton from "../../../Assets/svg/addButton.svg";
import DeleteButton from "../../../Assets/svg/table-action-delete.svg";
import CalendarView from "./CalendarView/CalendarView";
import TableView from "./TableView/TableView";
import PostVisitCardModal from "./Modals/PostVisitCardModal";
import AddRedFlagCardModal from "./Modals/AddRedFlagCardModal";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import KanbanView from "./KanbanView/KanbanVIew";
import { get } from "../../../services/authService";
import axios from "axios";
import DeletedModal from "./Modals/DeletedModal";

function Flags() {
  // Handle show/hide Modal for "RedFlagCardModal" button
  const [showRedFlagModal, setshowRedFlagModal] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redFlags, setRedFlags] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    Search: "",
    State: "",
    ImplementingPartnerId: "",
    Month: "",
    Year: "",
  });


  const handleShowDeletedModal = () => {
    setIsDeletedModalVisible(true);
}

  const handleShowRedFlagModal = () => {
    setshowRedFlagModal(!showRedFlagModal);
  };

  const handleCloseRedFlagModal = () => {
    setshowRedFlagModal(false);
  };

  const [showVisitCardModal, setshowVisitCardModal] = useState(false);

  const handleShowVisitCard = () => {
    setshowVisitCardModal(!showVisitCardModal);
  };

  const handleCloseVisitCardModal = () => {
    setshowVisitCardModal(false);
  };

  const onChange = (key) => {
    // if(key===1){
    //   const projectID = localStorage.getItem("currentProjectID");
    //   fetchRedFlags(
    //     projectID,
    //     pagination.pageSize,
    //     pagination.current
    //   );
    // }else{
    //   const projectID = localStorage.getItem("currentProjectID");
    //   fetchRedFlags(
    //     projectID,
    //   );
    // }
  };

  const fetchRedFlags = async (
  ) => {
    try {
      setLoading(true);
      const response = await get(`/api/RedFlag/getAllRedFlags?ProjectId=${projectID}&pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`, {
        Search: filters.search,
        State: filters.country,
        ImplementingPartnerId: filters.implementingPartnerId,
        Month: filters.month,
        Year: filters.year,
      });
  
      const redFlagsData = response?.data;
  
      if (redFlagsData.length > 0) {
        setRedFlags(redFlagsData);
      } else {
        setRedFlags([]);
      }
      setPagination({
        ...pagination,
        total: response.metaData.totalItemCount
      });
    } catch (error) {
      console.error("Error fetching red flags:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };
  
  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  // This will return which page is going to be rendered
  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <TableView
          redFlags={redFlags}
          setRedFlags={setRedFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
    {
      key: "2",
      label: "Kanban view",
      children: (
        <KanbanView
          redFlags={redFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarView
          redFlags={redFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
  ];

  return (
    <div className="flagsPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="banner-container">
        <div className="banner-text">Flags</div>
        <div className="banner-photo"></div>
      </div>
      <div className="tableViewcontainer">
        <div className="tableHeader">
          <span className="tableViewHeaderText">Red flags board</span>
          <div className="tableViewHeaderButtons">
            <Button className="inviteButtonTPM" onClick={handleShowDeletedModal}>
              <img src={DeleteButton} alt="DeleteButton"/>
              See deleted cards
            </Button>
            <Tooltip title="Stay tuned! This exciting feature will be available in the upcoming version.">
            <Button
              className="exportButtonTPM addButtonTPM text-white"
              onClick={handleShowVisitCard}
              disabled
            >
              <img src={AddButton} alt="AddButton"/>
              Add new post-visit card
            </Button>
            </Tooltip>
            <Button className="addButtonTPM" onClick={handleShowRedFlagModal}>
              <img src={AddButton} alt="addButtonTPM"/>
              Add new red flag
            </Button>
            <PostVisitCardModal
              visible={showVisitCardModal}
              onClose={handleCloseVisitCardModal}
            />
            <AddRedFlagCardModal
              visible={showRedFlagModal}
              onClose={handleCloseRedFlagModal}
              setMessage={setMessage}
              fetchRedFlags={fetchRedFlags}
            />
            <DeletedModal
              visible={isDeletedModalVisible}
              onCancel={() => { setIsDeletedModalVisible(false) }}
              onOk={handleShowDeletedModal}
              fetchRedFlags={fetchRedFlags}
            />
          </div>
        </div>
        <div className="tabsHeader">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="customTabs"
          />
        </div>
      </div>
    </div>
  );
}

export default Flags;
