import React, { useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../Filters";
import { get, put } from "../../../../../services/authService";
import { Pagination, Spin } from "antd";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import Preview from "../TableView/modals/Preview";

const KanbanView = ({siteVisitCards, fetchSiteVisitCards, loading, setLoading, onPageChange, pagination,setPagination }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [clickedTaskId, setClickedTaskId] = useState();
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const projectID = localStorage.getItem("currentProjectID");
  
  useEffect(() => {
    
    fetchSiteVisitCards(
      projectID,
      filters?.pageSize,
      filters?.current,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year
    );
  }, [filters]);


  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };


  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal)
  }


  const changeSiteVisitCardStatus = (draggedTask) => {
    const trackingId = draggedTask.id
    const siteVisitStatus = Number(draggedTask.column)

    setLoading(true);

    put(`/api/Site/changeSiteVisitStatus?TrackingId=${trackingId}&SiteVisitStatus=${siteVisitStatus}`)
      .then((response) => {
        console.log("Response:", response);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Status updated successfully!",
          text: "Red flag status was correctly updated to your database.",
        });
        fetchSiteVisitCards(
          projectID,
          filters.pageSize,
          filters.current,
          filters.Search,
          filters.ImplementingPartnerId,
          filters.State,
          filters.SiteVisitStatus,
          filters.ClusterId,
          filters.Month,
          filters.Year
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "We had a problem updating red flag status, please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    setTimeout(() => {
      setMessage({
        trigger: false,
        type: "",
        icon: "",
        title: "",
        text: "",
      });
    }, 3000);
  };

  const columns = [
    { id: 6, title: "No Status", color: "red" },
    { id: 1, title: "Data collection", color: "#3B81FE" },
    { id: 2, title: "Post-data collection", color: "#325899" },
    { id: 3, title: "Finalization", color: "#045B46" },
    { id: 4, title: "Delivered", color: "#0F7507" },
    { id: 5, title: "IP Feedback", color: "#9670B8" },
  ];

  // format data for Kanban view
    const formatData = (siteVisitCard) => {
      return {
        id: siteVisitCard?.id,
        content: {
          title: siteVisitCard?.identifier,
          statusUpdated: siteVisitCard?.createdDate,
          ip: siteVisitCard?.implementingPartner?.name,
          state: siteVisitCard?.locationDetails?.country,
          municipality: siteVisitCard?.locationDetails?.municipality,
        },
        column: siteVisitCard?.tracking?.siteVisitStatus || 6,
      };
    };
    
    const formattedData = siteVisitCards.map(formatData);

    const handleFiltersChange = (newFilters) => {
      setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
      setFilters({ ...filters, current: 1 });
    };

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />
      <Spin spinning={loading}>
        <Filters onFiltersChange={handleFiltersChange} filters={filters} setFilters={setFilters} pagination={pagination} setPagination={setPagination}/>
        <div id="excludedStrictMode">
          <KanbanBoardComponent
            tasks={formattedData}
            columns={columns}
            changeStatus={changeSiteVisitCardStatus}
            setClickedTaskId={handleTaskClick}
          />
        </div>
        <div className="d-flex justify-content-end my-5">
            
            <Spin spinning={loading}>
           <Pagination
          
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
              />
              </Spin>
           </div>
      </Spin>
    </>
  );
};

export default KanbanView;
