import React, { useState } from "react";
import "./IpSummary.scss";
import arrow1 from "../../../../Assets/Images/arrowRight.png";


const getPosponedStatus = (value) => (value ? "Yes" : "No");


const formatDate = (dateString) => dateString.split("T")[0];

function SiteVisitCards({ projectData }) {
  const [selectedCardKeyInfo, setSelectedCardKeyInfo] = useState(null);

  const handleCardClick = (keyInfo) => {
    setSelectedCardKeyInfo(keyInfo);
  };

  const cardsData = Array.isArray(projectData?.data?.ipSummarySiteVisitResponse)
    ? projectData.data.ipSummarySiteVisitResponse
    : [];

  return (
    <div style={{ width: "100%" }}>
      <div className="ipCardDiv">
        <h2 className="ipCardName">Site visit cards</h2>
        <div className="ipCards">
          {cardsData.map((card, index) => (
            <React.Fragment key={card?.svIdentifier}>
              <div className="ipCardCarousel">
                <div
                  className="cardIp"
                  onClick={() => handleCardClick(card.siteVisitKeyInfo)}
                >
                  <h6 className="ipCardHeadingName">{card?.svIdentifier}</h6>
                  <span className="ipCardTextName">{`Address: ${card?.address}`}</span>
                  <span className="ipCardTextName">{`Location: ${card?.location2}`}</span>
                  <span className="ipCardTextName">{`IP name: ${card?.ipName}`}</span>
                </div>
                {index === 1 && <img src={arrow1} alt="arrow1" />}
              </div>
            </React.Fragment>
          ))}

          <div className="ipCardList">
            {selectedCardKeyInfo ? (
              <>
            
                <span className="ipCardTextName">
                  {`Planned Visit: `}{" "}
                  <span>{formatDate(selectedCardKeyInfo.plannedVisit)}</span>
                </span>
                <span className="ipCardTextName">
                  {`Postponed visit: `}{" "}
                  <span>
                    {getPosponedStatus(selectedCardKeyInfo?.posponedVisit)}
                  </span>
                </span>
            
              </>
            ) : (
              <span className="ipCardTextName">
                Select a card to see key info
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteVisitCards;
