import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Switch, Select } from "antd";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import { AimOutlined, SearchOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";

const LocationMarker = ({ position }) => {
  const map = useMap();

  React.useEffect(() => {
    if (position) {
      map.setView(position, 13);
    }
  }, [position, map]);

  return position === null ? null : <Marker position={position} />;
};

const LocationDetails = ({form}) => {
  const [isPostponed, setIsPostponed] = useState(false);
  // const [form] = Form.useForm();
  const [position, setPosition] = React.useState([0, 0]);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, accuracy } = position.coords;
          form.setFieldsValue({
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
            accuracy: accuracy.toFixed(2),
          });
          setPosition([latitude, longitude]);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div>
      {/* <Form form={form} layout="vertical"> */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Latitude (x°)" name="latitude">
              <Input type="number" placeholder="Enter latitude value" />
            </Form.Item>
          </Col>
          {/* <Row gutter={24}>

        <Col span={20}>
          <Input.Search placeholder="Search something here" />
        </Col>
        <Col span={4}>
        <Button onClick={handleGetCurrentLocation} style={{ marginTop: 16 }}>

          </Button>
        </Col>
        </Row>
      </Row> */}
          <Col span={10}>
            <Form.Item label="Search" name="search">
              <Input.Search placeholder="Search something here" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item>
              <Button
                onClick={handleGetCurrentLocation}
                style={{ marginTop: 30 }}
                className="d-flex justify-content-center align-items-center"
              >
                <AimOutlined style={{ color: "#999" }} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Longitude (y°)" name="longitude">
              <Input type="number" placeholder="Enter longitude value" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Altitude (m)" name="altitude">
              <Input type="number" placeholder="Enter altitude value" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Accuracy (m)" name="accuracy">
              <Input type="number" placeholder="Enter accuracy value" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Button
              className="clearFieldsBtn"
              onClick={() => form.resetFields()}
              style={{ marginBottom: 16 }}
            >
              Clear all fields
            </Button>
            <Form.Item name="isVirtual">
              <div
                className="d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <Switch />

                <span>This visit will be virtual</span>
              </div>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginTop: "-268px" }}>
            <div style={{ height: "400px", paddingTop: "-270px !important" }}>
              <MapContainer
                center={position}
                zoom={13}
                style={{ height: "100%", width: "100%" }}
                whenCreated={(mapInstance) => {
                  setTimeout(() => {
                    mapInstance.invalidateSize();
                  }, 0);
                }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  errorTileUrl="https://png.pngtree.com/png-vector/20190419/ourmid/pngtree-vector-location-icon-png-image_956422/{z}/{x}/{y}.jpg"
                />
                <LocationMarker position={position} />
              </MapContainer>
            </div>
          </Col>
        </Row>
      {/* </Form> */}
    </div>
  );
};

export default LocationDetails;
