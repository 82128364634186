import React, { useState,useEffect } from "react";
import "../Implementation.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import DeleteButton from "../../../../Assets/svg/table-action-delete.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import TableView from "./TableView/TableView";
import AddSiteVisitCardModal from "./VisitBoardModals/AddSiteVisitCardModal";
import TrackingModal from "../../planning/VisitBoard/Modals/AddTrackingCardModal";
import DeletedModal from "./TableView/modals/DeletedModal";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import { get } from "../../../../services/authService";

function VisitBoard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [siteVisitCardsUntracked, setSiteVisitCardsUntracked] = useState([]);
  const [siteVisitCardsForExcel, setSiteVisitCardsForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [paginationUntracked, setPaginationUntracked] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });

  const [filtersUntracked, setFiltersUntracked] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });

  const handleOk = () => {
    setIsModalVisible(false);
    fetchSiteVisitCards();
    fetchSiteVisitCardsUntracked();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeletedModal = () => {
    setIsDeletedModalVisible(true)
  }
  
  const onChange = (key) => {
    console.log(key);
  };

  const fetchSiteVisitCards = async () => {
    try {
      setLoading(true)
      const response = await get(`/api/Site/getAllSiteVisits?ProjectId=${projectID}&includeOnlyWithTracking=true&pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`,
        {
          Search: filters.search,
          ImplementingPartnerId: filters.implementingPartnerId,
          State: filters.country,
          SiteVisitStatus: filters.siteVisitStatus,
          ClusterId: filters.clsuterId,
          Month: filters.month,
          Year: filters.year,
        }
      );
  
      const sitevisitcardsData = response?.data
      setPagination({
        ...pagination,
        total: response.metaData.totalItemCount
      });
      if (sitevisitcardsData.length > 0) {
        setSiteVisitCards(sitevisitcardsData);
      } else {
        setSiteVisitCards([]); 
      }
  
    } catch (error) {
      console.error('Error fetching site visit cards:', error);
    }
    setLoading(false)
  };  

  const fetchSiteVisitCardsUntracked = async () => {
    try {
      setLoading(true)
      const response = await get(`/api/Site/getAllSiteVisits?ProjectId=${projectID}&includeOnlyWithTracking=false&pageNumber=${paginationUntracked.current}&pageSize=${paginationUntracked.pageSize}`,
        {
          Search: filtersUntracked.search,
          ImplementingPartnerId: filtersUntracked.implementingPartnerId,
          State: filtersUntracked.country,
          SiteVisitStatus: filtersUntracked.siteVisitStatus,
          ClusterId: filtersUntracked.clsuterId,
          Month: filtersUntracked.month,
          Year: filtersUntracked.year,
        }
      );
  
      const sitevisitcardsData = response?.data
      setPaginationUntracked({
        ...paginationUntracked,
        total: response.metaData.totalItemCount
      });
      if (sitevisitcardsData.length > 0) {
        setSiteVisitCardsUntracked(sitevisitcardsData);
      } else {
        setSiteVisitCardsUntracked([]); 
      }
  
    } catch (error) {
      console.error('Error fetching site visit cards:', error);
    }
    setLoading(false)
  };  

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const onPageChangeUntracked = (pageNumber, pageSize) => {
    setPaginationUntracked({ ...paginationUntracked, current: pageNumber, pageSize });
  };
  
  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };
  
  const handleFilterChangeUntracked = (key, value) => {
    setFiltersUntracked({
      ...filtersUntracked,
      [key]: value,
    });
    setPaginationUntracked({ ...paginationUntracked, current: 1 });
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: 
      <>
        <TableView onOk={() => handleOk()} tracked={false} siteVisitCards={siteVisitCardsUntracked} fetchSiteVisitCards={fetchSiteVisitCardsUntracked} loading={loading} setLoading={setLoading} setSiteVisitCardsForExcel={setSiteVisitCardsForExcel} onPageChange={onPageChangeUntracked} pagination={paginationUntracked} setPagination={setPaginationUntracked} handleFilterChange={handleFilterChangeUntracked} filters={filtersUntracked} setFilters={setFiltersUntracked}/>,
        <TableView onOk={() => handleOk()} tracked={true} siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards} loading={loading} setLoading={setLoading} setSiteVisitCardsForExcel={setSiteVisitCardsForExcel} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination} handleFilterChange={handleFilterChange} filters={filters} setFilters={setFilters}/>
      </>
    },
    {
      key: "2",
      label: "Kanban view",
      children: <KanbanView siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards} loading={loading} setLoading={setLoading} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination} handleFilterChange={handleFilterChange} filters={filters} setFilters={setFilters}/>,
    },
    {
      key: "3",
      label: "Calendar view",
      children: <CalendarView  siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards}  loading={loading} setLoading={setLoading} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination} handleFilterChange={handleFilterChange} filters={filters} setFilters={setFilters}/>,
    },
  ];
 
  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Visits board</span>
        <div className="tableViewHeaderButtons">
          <Button
            className="inviteButtonTPM"
            onClick={showDeletedModal}
          >
            <img src={DeleteButton} alt="DeleteButton"/>
            See deleted cards
          </Button>
          <Button
            className="exportButtonTPM addButtonTPM"
            onClick={() => {
              if (siteVisitCardsForExcel.length !== 0) {
                exportToExcel(siteVisitCardsForExcel, "SiteVisitCards")
              }
            }}
          >
            <img src={ExportButton} alt="ExportButton"/>
            Export selected visits
          </Button>
          {/* <Button
            className="addButtonTPM" onClick={showModal}
          >
            <img src={AddButton} alt="AddButton"/>
            Add new site visit card
          </Button> */}
          <TrackingModal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          />
          <DeletedModal
            visible={isDeletedModalVisible}
            onOk={showDeletedModal}
            onCancel={() => { setIsDeletedModalVisible(false) }}
            fetchSiteVisitCards={fetchSiteVisitCards}
          />
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default VisitBoard;
