import React, { useState } from "react";
import "./IpSummary.scss";
import arrow1 from "../../../../Assets/Images/arrowRight.png";

// Utility function to get IP Status description
const getIpStatusDescription = (enumValue) => {
  const descriptions = {
    1: "Assigned",
    2: "In progress",
    3: "On hold",
    4: "Resolved",
    5: "Archived",
  };
  return descriptions[enumValue] || "Unknown";
};

// Utility function to format the date
const formatDate = (dateString) => {
  if (!dateString) return "Unknown";
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

function RedFlagCards({ projectData }) {
  const [selectedCardKeyInfo, setSelectedCardKeyInfo] = useState(null);

  const handleCardClick = (keyInfo) => {
    setSelectedCardKeyInfo(keyInfo);
  };

  const cardsData = Array.isArray(
    projectData?.data?.ipSummaryRedFlagCardsResponse
  )
    ? projectData.data.ipSummaryRedFlagCardsResponse
    : [];

  return (
    <div style={{ width: "100%" }}>
      <div className="ipCardDiv">
        <h2 className="ipCardName">Red Flag Cards</h2>
        <div className="ipCards">
          {cardsData.map((card, index) => (
            <React.Fragment key={card.redFlagIdentifier}>
              <div className="ipCardCarousel">
                <div
                  className="cardIp"
                  onClick={() => handleCardClick(card.redFlagKeyInfo)}
                >
                  <h6 className="ipCardHeadingName">
                    {card?.redFlagIdentifier}
                  </h6>
                  <span className="ipCardTextName">{`IP name: ${card?.ipName}`}</span>
                  <span className="ipCardTextName">{`Assign to: ${card?.assignedTo}`}</span>
                  <span className="ipCardTextName">{`Date of visit: ${formatDate(
                    card?.dateOfVisit
                  )}`}</span>
                </div>
                {index === 1 && <img src={arrow1} alt="arrow1" />}
              </div>
            </React.Fragment>
          ))}

          <div className="ipCardList">
            {selectedCardKeyInfo ? (
              <>
                <span className="ipCardTextName">
                  {`Assign To: `}{" "}
                  <span>
                    <span>{selectedCardKeyInfo.assignedTo}</span>
                  </span>
                </span>
                <span className="ipCardTextName">
                  {`Due Date: `}{" "}
                  <span>{formatDate(selectedCardKeyInfo.dueDate)}</span>
                </span>
                <span className="ipCardTextName">
                  {`Follow up notes: `}{" "}
                  <span>{selectedCardKeyInfo.followUpNotes}</span>
                </span>
                <span className="ipCardTextName">
                  {`Last update: `}{" "}
                  <span>{formatDate(selectedCardKeyInfo.lastUpdate)}</span>
                </span>
                <span className="ipCardTextName">
                  {`Red Flag status: `}{" "}
                  <span>
                    {getIpStatusDescription(selectedCardKeyInfo.redFlagStatus)}
                  </span>
                </span>
              </>
            ) : (
              <span className="ipCardTextName">
                Select a card to see key info
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedFlagCards;
