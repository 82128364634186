import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message, Table } from "antd";
import * as XLSX from "xlsx";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import minusButtonIcon from "../../../Assets/Images/minusButtonIcon.png";
import "./IpManagement.scss";
import { get, post } from "../../../services/authService";

const { Option } = Select;

const AddNewIp = ({ visible, onClose, onSubmit, setMessage }) => {
  const [form] = Form.useForm();
  const [ipGroups, setIpGroups] = useState([{ id: Date.now() }]);
  const [tableData, setTableData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [allCountries, setAllContries] = useState([]);
  const [cities, setCities] = useState({});
  const [selectedCountries, setSelectedCountries] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const workspaceId = localStorage.getItem("currentContractId");

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleCountryChange = (groupId, countryId) => {
    const updatedCountries = { ...selectedCountries, [groupId]: countryId };
    setSelectedCountries(updatedCountries);
    form.resetFields([`city${groupId}`, `office${groupId}`]);
    setCities((prev) => ({ ...prev, [groupId]: [] }));
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => setCities((prev) => ({ ...prev, [groupId]: res.data })))
        .catch((error) => console.log(error));
    }
  };

  const fetchOfficeData = (cityIds) => {
    const cityIdsParams = cityIds.map(id => `CityIds=${id}`).join('&');
    get(`/api/Office/getAllByCity?${cityIdsParams}`)
      .catch((error) => console.log(error));
  };

  const handleCityChange = (groupId, cityIds) => {
    form.resetFields([`office${groupId}`]);
    if (cityIds && cityIds.length > 0) {
      fetchOfficeData(cityIds);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const ipData = ipGroups.map((group) => ({
          name: values[`ipName${group.id}`],
          cityIds: values[`city${group.id}`],
          contractId: workspaceId,
        }));

        setLoading(true); // Start loading
        post("/api/ImplementingPartner/createImplementingPartner", ipData)
          .then(() => {
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: `IP(s) added successfully!`,
              text: `New ip(s) has been created and added to your database!`,
            });
            message.success("IP(s) added successfully!");
            onSubmit();
            form.resetFields();
            onClose();
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setMessage({
                trigger: true,
                type: "danger",
                icon: "",
                title: "Oops! A problem has occurred!",
                text: `We had a problem adding this ip(S) to your database, please try again.`,
              });
            } else {
              setMessage({
                trigger: true,
                type: "danger",
                icon: "",
                title: "Oops! A problem has occurred!",
                text: `We had a problem adding this ip(S) to your database, please try again.`,
              });
            }
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: ``,
              });
            }, 3000); 
          });
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
        const errorFields = errorInfo.errorFields;
        const firstErrorField =
          errorFields.length > 0 ? errorFields[0].name[0] : "";
        form.scrollToField(firstErrorField);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const handleAddIpGroup = () => {
    setIpGroups([...ipGroups, { id: Date.now() }]);
  };

  const handleRemoveIpGroup = (id) => {
    setIpGroups(ipGroups.filter((group) => group.id !== id));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(
          workbook.Sheets[firstSheetName],
          { header: 1 }
        );

        const columns = worksheet[0].map((col) => ({
          title: col,
          dataIndex: col.toLowerCase().replace(/\s/g, ""),
          key: col.toLowerCase().replace(/\s/g, ""),
        }));

        const dataSource = worksheet.slice(1).map((row, index) => {
          const rowData = {};
          worksheet[0].forEach((col, colIndex) => {
            rowData[col.toLowerCase().replace(/\s/g, "")] = row[colIndex];
          });
          return { ...rowData, key: index };
        });

        setTableData(dataSource);
        setIsTableVisible(true);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setTableData([]);
    setIsTableVisible(false);
  };

  const columns = [
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Add new IP(s)</h2>
      <div className="uploadIpList">
        {isTableVisible ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ marginRight: "20px" }}>{file && file.name}</span>
            <Button
              className="exportButtonTPM addButtonTPM"
              onClick={handleRemoveFile}
            >
              Remove file
            </Button>
          </div>
        ) : (
          <>
            You can upload a list of IPs or enter them manually in the fields
            below.
            <Button
              className="exportButtonTPM addButtonTPM"
              onClick={() => document.getElementById("fileInput").click()} // Click the hidden file input
              disabled
            >
              Coming soon
            </Button>
            <input
              id="fileInput"
              type="file"
              accept=".xlsx, .xls"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </>
        )}
      </div>
      {isTableVisible ? (
        <div>
          <Table dataSource={tableData} columns={columns} pagination={false} />
          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              loading={loading} // Loading state for the button
              onClick={handleOk} // Handle the form submission
            >
              Add new IP(s)
            </Button>
          </div>
        </div>
      ) : (
        <Form form={form} layout="vertical">
          <div className="divIpDesign">
            {ipGroups.map((group, index) => (
              <div key={group.id}>
                <div className="ipGroup">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="modalsHeader1">IP {index + 1}</p>
                    {ipGroups.length > 1 && (
                      <img
                        src={minusButtonIcon}
                        alt="Remove"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveIpGroup(group.id)}
                      />
                    )}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name={`ipName${group.id}`}
                      label="IP name"
                      rules={[
                        { required: true, message: "Please enter IP name" },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input placeholder="Enter IP name" autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name={`country${group.id}`}
                      label="Country"
                      rules={[
                        { required: true, message: "Please select country" },
                      ]}
                      style={{ width: "48%" }}
                    >
                      <Select
                        placeholder="Select Country"
                        onChange={(value) =>
                          handleCountryChange(group.id, value)
                        }
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                      >
                        {allCountries.map((country) => (
                          <Option key={country.id} value={country.id}>
                            {country.stateName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={`city${group.id}`}
                      label="Governorate/State"
                      rules={[
                        { required: true, message: "Please select a governorate or state" },
                      ]}
                      style={{ width: "48%" }}
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        placeholder="Select a governorate or state"
                        onChange={(value) => handleCityChange(group.id, value)}
                        disabled={!selectedCountries[group.id]}
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                      >
                        {(cities[group.id] || []).map((city) => (
                          <Option key={city.id} value={city.id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {index < ipGroups.length - 1 && (
                  <hr
                    style={{ border: "0.5px solid #F5B0D2", margin: "20px 0" }}
                  />
                )}
              </div>
            ))}
            <div
              className="addRolesAndPremissins"
              style={{
                background: "#FADCEA",
                border: "1px dashed var(--2, #AA1A5F)",
                cursor: "pointer",
                textAlign: "center",
                padding: "10px",
              }}
              onClick={handleAddIpGroup}
            >
              Add another IP
            </div>
          </div>
          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              loading={loading} // Loading state for the button
              onClick={handleOk} // Handle the form submission
            >
              Add new IP(s)
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default AddNewIp;
