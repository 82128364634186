import React, { useContext, useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import PreviewTaskModal from "../Modals/PreviewTaskModal";
import Filters from "../Filters";
import { DataContext } from "../DataContext";
import { Pagination, Spin } from "antd";

const columns = [
  { id: 1, title: "Assigned", color: "#FC9403" },
  { id: 2, title: "Accepted", color: "#3B81FE" },
  { id: 3, title: "Completed", color: "#0E9349" },
  { id: 4, title: "On hold", color: "#D46600" },
  { id: 5, title: "Archived", color: "#555555" },
];

const KanbanView = ({dataUpdated, setDataUpdated}) => {
  const context = useContext(DataContext);

  const [clickedTaskId, setClickedTaskId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [filters, setFilters] = useState({
    Search: "",
    Type: "",
    Status: "",
    Country: "",
    UserId: "",
  });



  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    console.log(clickedTaskId);
    setShowPreviewModal(true);
  };

  const changeSiteVisitCardStatus = (draggedTask) => {
    changeTaskStatus(draggedTask.id, draggedTask.column);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const formattedDate = date.toLocaleDateString("en-CA");
    const formattedTime = date.toLocaleTimeString("en-GB", { hour12: false });

    return `${formattedDate}/Time: ${formattedTime}`;
  };



  if (!context) {
    return <></>;
  }
  const { data, loading, fetchData, changeTaskStatus, pagination, setPagination } = context;


  if(dataUpdated){
    fetchData()
    setDataUpdated(false)
  }

  const onPageChange = (current, pageSize) => {
    setPagination({ ...pagination, current, pageSize });
  };

  const formattedData = data.map((task) => ({
    id: task?.id,
    content: {
      title: task?.name,
      statusUpdated: formatDate(task?.updatedDate) || "Never updated",
      ip: task?.implementingPartner?.name,
      state: task?.taskLocation?.country?.stateName,
      municipality: task?.taskLocation?.municipality,
    },
    column: task?.status,
  }));

  
  return (
    <div>
      {clickedTaskId && (
        <PreviewTaskModal
          isVisible={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
          taskId={clickedTaskId} // Make sure to pass taskId instead of taskData
        />
      )}
        <div className="mb-4 mt-5">
        <Filters filters={filters} setFilters={setFilters} />
        </div>
        <Spin spinning={loading}>
        <KanbanBoardComponent
          tasks={formattedData}
          columns={columns}
          changeStatus={changeSiteVisitCardStatus}
          setClickedTaskId={handleTaskClick}
        />
        </Spin>
        <div className="d-flex justify-content-end my-5">
            
            <Spin spinning={loading}>
           <Pagination
          
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
              />
              </Spin>
           </div>
    </div>
  );
};

export default KanbanView;
