import React, { useState } from "react";
import { Button, Col, Form, Input, Row, message, Modal, Spin } from "antd";
import "./LoginInformation.scss";
import { post, get } from "../../../../services/authService";

const LoginInformation = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [confLoaded, setConfLoaded] = useState(false);

  const userEmail = localStorage.getItem("userEmail");

  const onFinish = async (values) => {
    try {
      const response = await post("/api/account/changepassword", {
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmPassword,
      });

      if (response.success) {
        console.log("Password changed successfully", response);
        message.success("Password changed successfully");
      } else {
      }
    } catch (error) {
      console.error("Password change failed", error);

      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors[0].message;
        message.error(errorMessage);

        if (errorMessage.includes("Code not confirmed yet!")) {
          setIsModalVisible(true);
        }
      } else {
        message.error("Failed to change password. Please try again.");
      }
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const response = await post("/api/Account/confirmCode", {
        email: userEmail,
        code,
        changeOrForgotPasswordConfirmation: true,

      });

      setLoading(false);
      message.success("Code verified!");
      setIsModalVisible(false);
    } catch (error) {
      setLoading(false);
      message.error("Invalid code!");
    }
  };

  const handleResendCode = async () => {
    setConfLoaded(true);
    try {
      await get("/api/Account/resendCode", {
        email: userEmail,
      });
      setConfLoaded(false);
      message.success("Verification code resent!");
    } catch (error) {
      setConfLoaded(false);
      message.error("Failed to resend code.");
    }
  };

  return (
    <>
      <div className="loginInformation">
        <div className="accountSettings">
          <p className="title">Log in information</p>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="mt-8 mb-5">
              <h4>Username and password</h4>
            </div>
            <Row gutter={24}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={<span className="label-text">Email</span>}
                  name="email"
                >
                  <span>{userEmail}</span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={<span className="label-text">Old password</span>}
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your old password!",
                    },
                  ]}
                  autoComplete="off"
                >
                  <Input.Password
                    autoComplete="off"
                    placeholder="Type your old password"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={<span className="label-text">New password</span>}
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password!",
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete="off"
                    placeholder="Type your new password"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={
                    <span className="label-text">Confirm new Password</span>
                  }
                  name="confirmPassword"
                  dependencies={["newPassword"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    autoComplete="off"
                    placeholder="Confirm your new password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <span className="password-list-text">
              Your new password must contain at least:
            </span>
            <ul>
              <li className="password-list-text">One uppercase character</li>
              <li className="password-list-text">One lowercase character</li>
              <li className="password-list-text">One number</li>
              <br />
            </ul>
            <Row gutter={16} className="mt-8" align="middle">
              <Col>
                <Button htmlType="submit" className="addButtonTPM">
                  Save changes
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={false}
      >
        <h2 className="verificationCodeHeader">Verification Code</h2>
        <p className="verificationCodeText">
          Please enter the verification code that we sent to {userEmail}
        </p>
        <form>
          <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter code"
            className="code-input"
          />

          <div className="buttonsModal">
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleVerify}
              loading={loading}
              disabled={loading}
            >
              Verify code
            </Button>
          </div>
        </form>
        <p className="verificationCodeText" style={{marginTop:"20px"}}>
              If you didn’t receive a verification code,{" "}
              <span
                style={{ color: "#E164A0", cursor: "pointer" }}
                onClick={handleResendCode}
              >
                click here to resend it
              </span>{" "}
              or send us an email at info@kaptechgroup.com
            </p>
              {
                loading ? 
                <Spin style={{width: '100%'}} /> :
                <></>
              }
      </Modal>
    </>
  );
};

export default LoginInformation;
