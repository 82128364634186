import React,{useState,useEffect} from "react";
import { Modal, Input, Select, Button, DatePicker,TimePicker,InputNumber,Switch } from "antd";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { Formik, Field, Form,ErrorMessage } from "formik";
import { get } from "../../../../../../../services/authService";
import * as Yup from "yup";
import DownArrow from "../../../../../../../Assets/svg/downArrow.svg";
import UpArrow from "../../../../../../../Assets/svg/upArrow.svg";
import AddMemberIcon from "../../../../../../../Assets/svg/addMemberIcon.svg"
import dayjs from "dayjs"
import { httpService } from "../../../../../../../services/httpService.service";

const { Option } = Select;

const Edit = ({ visible, onClose,fetchTripLogistics,siteVisits,record,setMessage }) => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [overNight, setOverNight] = useState(true);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const token = localStorage.getItem("token");
  const projectId = localStorage.getItem("currentProjectID");


  const [initialValues,setInitialValues] = useState({
    SiteVisitCard: "",
    dateForTrip: null,
    pickUpTime: "",
    pickUpPlace: "",
    meetingTime: "",
    meetingPlace: "",
    mainRoadsToTravel: "",
    distanceToSite: "",
    timeToSite: "",
    numberOfDays: "",
    enumeratorTeamDistance: "",
    numberOfPeople: "",
    numberOfNights: "",
    lodging: "",
    otherComments: "",
    logCoordinatorName: "",
    tripStatus: "",
    isOverNight: true,
  });

  const validationSchema = Yup.object({
    SiteVisitCard: Yup.string().required("Please select site visit card"),
    dateForTrip: Yup.date().required("Please select date"),
    pickUpTime: Yup.string().required("Please pick up time"),
    pickUpPlace: Yup.string().required("Enter pick up place"),
    meetingTime: Yup.string().required("Please pick up time"),
    meetingPlace: Yup.string().required("Enter meeting place"),
    enumeratorTeamDistance: Yup.string().required("Select enumerator team distance"),
    numberOfDays: Yup.number()
      .required("Please write number of days")
      .typeError("Please enter a valid number"),
    isOverNight: Yup.boolean().required("Overnight is required").oneOf([true], 'Overnight is required'),
    numberOfPeople: Yup.number()
      .required("Please enter number of people")
      .typeError("Please enter a valid number"),
    numberOfNights: Yup.number()
      .required("Please enter number of nights")
      .typeError("Please enter a valid number"),
    lodging: Yup.string().required("Please enter lodging (e.g. Hotel Name)"),
    logCoordinatorName: Yup.string().required(
      "Please write logistics coordinator name(s) & contact information"
    ),
    tripStatus: Yup.string().required("Enter select trip status"),
  });

  const handleSiteVisitChange = (value) => {
    const visit = siteVisits.find((visit) => visit.identifier === value);
    setSelectedSiteVisit(visit);
    console.log(visit,"visit")
  };


  useEffect(() => {
    if (visible) {
      fetchTripLogisticData(record.id);
    }
  }, [visible]);

  const fetchTripLogisticData = async (id) => {
    try {
      const response = await get(`/api/TripLogistic/getTripLogistic?id=${id}`);
      const data = response.data;
      setInitialValues({
        SiteVisitCard: data.siteVisitIdentifier,
        dateForTrip: dayjs(data.enumeratorInformation.tripDate).format("YYYY-MM-DD"),
        pickUpTime: data.enumeratorInformation.pickupTime,
        pickUpPlace: data.enumeratorInformation.pickupPlace,
        meetingTime: data.enumeratorInformation.meetingTime,
        meetingPlace: data.enumeratorInformation.meetingPlace,
        mainRoadsToTravel: data.enumeratorInformation.mainRoadsToTravel,
        distanceToSite: data.enumeratorInformation.distanceToSite,
        timeToSite: data.enumeratorInformation.timeToSite,
        numberOfDays: data.enumeratorInformation.numberOfDays,
        enumeratorTeamDistance: data.enumeratorInformation.enumeratorTeamDistance,
        isOverNight: data.enumeratorInformation.isOverNight,
        numberOfPeople: data.enumeratorInformation.overNightNoOfPeople,
        numberOfNights: data.enumeratorInformation.overNightNoOfNights,
        lodging: data.enumeratorInformation.overNightLodging,
        otherComments: data.otherComments,
        logCoordinatorName: data.logiCoordNameAndOtherInfo,
        tripStatus: data.tripStatus,
      });
      const visit = siteVisits.find((visit) => visit.id === data.siteVisitId);
      setSelectedSiteVisit(visit);
    } catch (error) {
      console.error("Error fetching trip logistic data:", error);
    }
  };


  const handleSubmit = async (values, { setSubmitting }) => {
    const selectedVisit = siteVisits.find(visit => visit.identifier === values.SiteVisitCard);

    const payload = {
      id: record.id,
      projectId: projectId,
      siteVisitId: selectedVisit ? selectedVisit.id : null,
      enumeratorInformation: {
        tripDate: values.dateForTrip,
        pickupTime: values.pickUpTime,
        pickupPlace: values.pickUpPlace,
        meetingTime: values.meetingTime,
        meetingPlace: values.meetingPlace,
        mainRoadsToTravel: values.mainRoadsToTravel,
        distanceToSite: values.distanceToSite,
        timeToSite: values.timeToSite,
        numberOfDays: values.numberOfDays,
        enumeratorTeamDistance: values.enumeratorTeamDistance,
        isOverNight: values.isOverNight,
        overNightNoOfPeople: values.numberOfPeople,
        overNightNoOfNights: values.numberOfNights,
        overNightLodging: values.lodging,
      },
      otherComments: values.otherComments,
      logiCoordNameAndOtherInfo: values.logCoordinatorName,
      tripStatus: values.tripStatus,
    };

    try {
      await httpService.put("/api/TripLogistic", payload);
      onClose();
      fetchTripLogistics(projectId);
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: "Trip Logistic updated successfully!",
        text: "This Trip Logistic has been updated to your database!",
      });
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000); 
    } catch (error) {
      setMessage({
        trigger: true,
        type: "error",
        icon: AddMemberIcon,
        title: "Failed to delete this trip logistic",
        text: error.message,
      });
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000); 
    } finally {
      setSubmitting(false);
    }
  };


  const CustomTimePicker = ({ field, form, ...props }) => {
    const value = field.value ? dayjs(field.value, "HH:mm:ss") : null;
    return (
      <TimePicker
        {...props}
        value={value}
        onChange={(time, timeString) => form.setFieldValue(field.name, timeString)}
        format="HH:mm:ss"
      />
    );
  };
  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={800}
      className="tripLogs"
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <span>Last update: {selectedSiteVisit?.visit?.updatedDate }</span>
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="cancelX"
        />
      </div>
      <h2 className="modalHeader">Trip logistics card</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue,values,handleChange }) => (
          <Form className="formik-form">
            <div className="fullWidthInput">
            <div style={{ display:"flex", flexDirection:"column",gap:"5px" }}>
              <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Select site visit card</label>
                <Select
                  value={values.SiteVisitCard}
                name="SiteVisitCard"
                 placeholder="Select site visit card"
                onChange={(value) => {
                  setFieldValue("SiteVisitCard", value);
                  handleSiteVisitChange(value);
                }}
              >
                {siteVisits && siteVisits?.map((visit) => (
                  <Option key={visit.id} value={visit.identifier}>
                    {visit.name}
                  </Option>
                ))}
                </Select>
              </div>
              <ErrorMessage
                name="SiteVisitCard"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </div>
            <div className="tripCardDetails">
              <div className="tripCardDetailsHeader">
                <p className="modalsHeader1">Enumerator information</p>
                <a type="button">Change site visit card</a>
              </div>
              {selectedSiteVisit && (
                <ul className={moreInfo ? "list" : "list showAllList"}>
                  <li>
                    <p>
                      Location 1: <span>{selectedSiteVisit?.locationDetails?.city}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Location 2: <span>{selectedSiteVisit?.locationDetails?.road}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Location 3: <span>{selectedSiteVisit?.locationDetails?.district}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Site name: <span>{selectedSiteVisit?.identifier}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Exact address: <span>{selectedSiteVisit?.locationDetails?.address}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Start time: <span>{dayjs(selectedSiteVisit?.visit?.startTime).format('HH:mm:ss')}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Duration: <span>{/* Calculate duration if needed */}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      IP Activity Description: <span>{selectedSiteVisit?.specificsOfOnSiteOperation}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Cluster: <span>{selectedSiteVisit?.cluster?.name}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      TPM coordinator name: <span>{selectedSiteVisit?.logistic?.tpmCoordinator?.firstName} {selectedSiteVisit?.logistic?.tpmCoordinator?.lastName}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Enumerator Team card info: <span></span>
                    </p>
                  </li>
                </ul>
              )}
              <div className="seeMoreWrapper">
                <a type="button" onClick={() => setMoreInfo(!moreInfo)}>
                  {moreInfo ? "See Less" : "See More"}{" "}
                  <img src={moreInfo ? UpArrow : DownArrow} alt="arrow" />
                </a>
              </div>
            </div>
            <p className="modalsHeader1">Enumerator information</p>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom:"10px" }}>
              <div>
                <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Date for trip</label>
                <DatePicker
                    name="dateForTrip"
                    value={values.dateForTrip ? dayjs(values.dateForTrip) : null}
                    onChange={(date) => 
                    {
                      if (date) {
                        const formattedDate = dayjs(date).format("YYYY-MM-DD");
                        setFieldValue("dateForTrip", formattedDate);
                      } else {
                        setFieldValue("dateForTrip", null); 
                      }
                    }
                  }
                  />
                  </div>
                <ErrorMessage
                  name="dateForTrip"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
              <div>
              <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Pick up time</label>
                <Field name="pickUpTime" component={CustomTimePicker} />
              </div>
                  <ErrorMessage
                  name="pickUpTime"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom:"10px" }}>
              <div>
              <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Pick up place</label>
                  <Input name="pickUpPlace" placeholder="Enter the pick up place" value={ values.pickUpPlace} onChange={handleChange}/>
                  </div>
                <ErrorMessage
                  name="pickUpPlace"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
              <div>
              <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Meeting time</label>
                  <Field name="meetingTime" component={CustomTimePicker} />
                  </div>
                <ErrorMessage
                  name="meetingTime"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom:"10px" }}>
              <div style={{ width: "48%" }}>
              <div style={{ display:"flex", flexDirection:"column",gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Meeting place</label>
                  <Input name="meetingPlace" placeholder="Enter meeting place" value={ values.meetingPlace} onChange={handleChange}/>
                  </div>
                <ErrorMessage
                  name="meetingPlace"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
              <div style={{ width: "48%" }}>
              <div style={{ display:"flex", flexDirection:"column",gap:"5px" }}>
                <label>Main roads to travel</label>
                <Input name="mainRoadsToTravel" placeholder="Enter the main roads to travel" value={ values.mainRoadsToTravel} onChange={handleChange} />
                </div>
                  <ErrorMessage
                  name="mainRoadsToTravel"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom:"10px" }}>
              <div style={{ display:"flex", flexDirection:"column" }}>
              <div style={{ display:"flex", flexDirection:"column",gap:"5px" }}>
                <label>Distance to site</label>
                  
                  <InputNumber name="distanceToSite"
                    onChange={(values) => setFieldValue("distanceToSite", values)}
                    placeholder="Number" value={values.distanceToSite} />
                </div>
                  <ErrorMessage
                  name="distanceToSite"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
              <div>
              <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                <label>Time to site</label>
                <Field name="timeToSite" component={CustomTimePicker} />
                </div>
                  <ErrorMessage
                  name="timeToSite"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom:"10px" }}>
              <div style={{ display:"flex",flexDirection:"column" }}>
              <div style={{ display:"flex", flexDirection:"column",gap:"5px" }}>
                <label><span style={{ color: "red", paddingRight: "3px" }}>*</span>Number of days</label>
                  <InputNumber name="numberOfDays" placeholder="Number" value={values.numberOfDays}
                  onChange={(values) => setFieldValue("numberOfDays", values)}
                  />
               </div>
               <ErrorMessage
                  name="numberOfDays"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                />
              </div>
              <div style={{ width: "48%" }}>
              <div style={{ display:"flex", flexDirection:"column",gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Enumerator team distance</label>
                <Select
                allowClear
                showSearch
                value={values.enumeratorTeamDistance}
                name="enumeratorTeamDistance"
                placeholder="Select enumerator team distance"
                onChange={(value) => setFieldValue("enumeratorTeamDistance", value)}
              >
                <Option value="SameCity">Same city</Option>
                <Option value="FromOtherStates">From other states</Option>
                <Option value="AnotherStatesCity">Another state’s city</Option>
                <Option value="Other">Other</Option>
                  </Select>
                  <ErrorMessage
              name="enumeratorTeamDistance"
              component="div"
              className="error"
              style={{ color:"red" }}
              />
                  </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                gap:"10px"
              }}
            >
              <Switch
                style={{ marginLeft: "10px" }}
                checked={values.isOverNight}
                onChange={(checked) => {
                  setOverNight(checked);
                  setFieldValue("isOverNight", checked);
                }}
              />
              <span><span style={{ color:"red", paddingRight:"3px" }}>*</span>Overnight</span>
            </div>
              <ErrorMessage
                      name="isOverNight"
                      component="div"
                      className="error"
                      style={{ color:"red" }}
                    />
            {initialValues && (
              <div style={{ background: "var(--14, #F6F6F6)",  padding: "24px",
                borderRadius: "8px", marginBottom:"10px" }}>
                   <p className="modalsHeader1">Overnight details</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between", paddingBottom:"10px" }}
                >
                  <div style={{ width: "48%" }}>
                    <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                    <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Number of people</label>
                      <InputNumber name="numberOfPeople" placeholder="Number"
                     onChange={(values) => setFieldValue("numberOfPeople", values)}
                        value={values.numberOfPeople} />
                    </div>
                      <ErrorMessage
                      name="numberOfPeople"
                      component="div"
                      className="error"
                      style={{ color:"red" }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                  <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                    <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Number of nights</label>
                      <InputNumber name="numberOfNights" placeholder="Number" type="number"
                     onChange={(values) => setFieldValue("numberOfNights", values)}
                        value={values.numberOfNights}  />
                   </div>
                      <ErrorMessage
                      name="numberOfNights"
                      component="div"
                      className="error"
                      style={{ color:"red" }}
                    />
                  </div>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
                <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Lodging (e.g. Hotel Name)</label>
                <Input name="lodging" placeholder="Enter the lodging here" value={ values.lodging} onChange={handleChange} />
                </div>
                    <ErrorMessage
                  name="lodging"
                  component="div"
                  className="error"
                  style={{ color:"red" }}
                  />
                  </div>
              </div>
            )}
            <div style={{ paddingBottom: "10px" }}>
            <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
            <label>Other comments</label>
                <Input.TextArea name="otherComments" rows={4} value={ values.otherComments} placeholder="Other comments" onChange={handleChange}/>
                </div>
            </div>
            <div style={{ paddingBottom: "10px" }}>
            <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
            <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Logistics coordinator name(s) & contact information</label>
                <Input name="logCoordinatorName" value={values.logCoordinatorName} placeholder="Enter the log coordinator name" onChange={handleChange}/>
                </div>
            <ErrorMessage
              name="logCoordinatorName"
              component="div"
              className="error"
              style={{ color:"red" }}
              />
            </div>
            <div style={{ paddingBottom: "10px" }}>
            <div style={{ display:"flex", flexDirection:"column", gap:"5px" }}>
            <label><span style={{ color:"red", paddingRight:"3px" }}>*</span>Select trip status</label>
            <Select
                  name="tripStatus"
                  value={values.tripStatus}
              placeholder="Select the trip status"
              onChange={value => setFieldValue("tripStatus", value)}
            >
              <Option value={1}>Planned</Option>
              <Option value={2}>In progress</Option>
              <Option value={3}>Completed</Option>
              <Option value={4}>Cancelled</Option>
            </Select>
                </div>
            <ErrorMessage
              name="tripStatus"
              component="div"
              className="error"
              style={{ color:"red" }}
              />
                </div>
            <div className="footer-buttons">
                      <Button
                        className="cancel-workspace-button"
                        key="cancel"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="add-workspace-button"
                        key="success"
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default Edit;
