import { create } from "zustand";

export const useSiteVisitStore = create((set) => ({
  siteVisitList: null,
  otherDocuments: null,
  siteVideos: null,

  setSiteVisitList: (data) => {
    set({
      siteVisitList: data,
    });
  },

  setOtherDocuments: (data) => {
    set({
      otherDocuments: data,
    });
  },

  setSiteVideos: (data) => {
    set({
      siteVideos: data,
    });
  },

  handleDeleteSiteVisit: () => {
    set({
      siteVisitList: null,
    });
  },
  handleDeleteOtherDocuments: () => {
    set({
      otherDocuments: null,
    });
  },
  handleDeleteSiteVideos: () => {
    set({
      siteVideos: null,
    });
  },
}));
