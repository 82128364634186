import React from "react";
import { Badge, Calendar } from "antd";
import PropTypes from 'prop-types';
import "./CalendarViewComponent.scss";

const CalendarViewComponent = ({ getListData, setClickedEventId }) => {


  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData?.map((item, index) => (
          <li key={index}>
            <Badge 
            status={item.type}
            text={item.content}
            onClick={() => setClickedEventId(item.id)}
            style={{cursor: 'pointer'}}
            />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };

  return (
    <div className="calendar-wrapper">
      <Calendar cellRender={cellRender} />
    </div>
  );
};

CalendarViewComponent.propTypes = {
  getListData: PropTypes.func.isRequired,
};

export default CalendarViewComponent;
