import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "antd";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import { get } from "../../../../../../../services/authService";

const Preview = ({ visible, onClose, record }) => {
  const [teamData, setTeamData] = useState("");

  useEffect(() => {
    if (record?.id) {
      fetchTeamById(record.id);
    }
  }, [record?.id]);

  const fetchTeamById = (teamId) => {
    const apiEndpoint = `/api/User/getTeamById?teamId=${teamId}`;
    get(apiEndpoint)
      .then((res) => {
        const team = res.data;
        setTeamData(team);
      })
      .catch((error) => {
        console.error("Error fetching team details:", error);
      });
  };

  const membersUsernames = teamData?.members?.map(member => member.userName).join(", ") || "";

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt=""
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Form layout="vertical">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="name"
            label={<span style={{ fontWeight: "bold" }}>Team Name</span>}
          >
            <p className="previewText">{teamData.name}</p>
          </Form.Item>
          <Form.Item
            name="members"
            label={<span style={{ fontWeight: "bold" }}>Members</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{membersUsernames}</p>
          </Form.Item>
        </div>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Preview;
