import React,{useState} from "react";
import { Button, Tabs } from "antd";
import DeleteButton from "../../../../../Assets/svg/table-action-delete.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanVIew";
import TableView from "./TableView/TableView";
import DeletedModal from "./TableView/modals/DeletedModal";

function TripLogistics(
  { showAllTripData,
    fetchTripLogistics,
    siteVisits,
    page,
    pagination,
    onPageChange,
    setPagination,
    loadingTripLogistic,
    handleFilterChange,
    filters,
    setFilters
  }) {
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  
  const handleShowDeletedModal = () => {
  setIsDeletedModalVisible(true)
}

  const onChange = (key) => {
    console.log(key,"key");
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: <TableView showAllTripData={showAllTripData} fetchTripLogistics={fetchTripLogistics} siteVisits={siteVisits} pagination={pagination} onPageChange={onPageChange} setPagination={setPagination} loadingTripLogistic={loadingTripLogistic} handleFilterChange={handleFilterChange} filters={filters} setFilters={setFilters}/>,
    },
    {
      key: "2",
      label: "Kanban view",
      children: <KanbanView showAllTripData={showAllTripData} fetchTripLogistics={fetchTripLogistics} page={page} pagination={pagination} onPageChange={onPageChange} setPagination={setPagination} filters={filters} setFilters={setFilters}/>,
    },
    {
      key: "3",
      label: "Calendar view",
      children: <CalendarView showAllTripData={showAllTripData} fetchTripLogistics={fetchTripLogistics} pagination={pagination} onPageChange={onPageChange} setPagination={setPagination} filters={filters} setFilters={setFilters}/>,
    },
  ];

  return (
    

    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Trip Logistics</span>
        <div className="tableViewHeaderButtons">
          <Button
            className="inviteButtonTPM"
            onClick={handleShowDeletedModal}
          >
            <img src={DeleteButton} alt="DeleteButton"/>
            See deleted cards
          </Button>
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
      <DeletedModal
        visible={isDeletedModalVisible}
        onCancel={() => { setIsDeletedModalVisible(false) }}
        onOk={handleShowDeletedModal}
        fetchTripLogistics={fetchTripLogistics}
      />
    </div>
  );
}

export default TripLogistics;
