import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Space, Divider, Spin } from "antd";
import "./UserManagement.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import minusButtonIcon from "../../../Assets/Images/minusButtonIcon.png";
import { get, post, put } from "../../../services/authService";
import { PlusOutlined } from "@ant-design/icons";
import editMemberIcon from "../../../Assets/svg/editMemberIcon.svg";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";


const { Option } = Select;

const EditMember = ({ visible, onClose, onSubmit, record, setMessage }) => {
  const [form] = Form.useForm();
  const [rolePermissions, setRolePermissions] = useState([{ id: 1 }]);
  const idCounter = useRef(1);
  const [allCountries, setAllContries] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);
  const [newOffice, setNewOffice] = useState("");
  const [addingOffice, setAddingOffice] = useState(false);
  const inputRef = useRef(null);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedCityIds, setSelectedCityIds] = useState([]);


  const addOffice = async (e) => {
    e.preventDefault();
    if (newOffice) {
      try {
        setLoading(true);
        console.log(selectedCityIds);
  
        const requestBody = {
          cityIds: selectedCityIds,
          name: newOffice,
        };
  
        const response = await post("/api/Office", requestBody);
        const message = response.message || 'Office added successfully!';
        fetchOfficeData(selectedCityIds);
        setNewOffice("");
        setAddingOffice(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Office added successfully!`,
          text: message,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item:`, error);
        const errorMessage = error.response?.data?.errors[0]?.message
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      } finally {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }
    }
  };

  const handleOfficeSelectChange = () => {
    setAddingOffice(false);
  };

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));

    get("/api/Account/getAllRoles")
      .then((res) => setRoles(res.data))
      .catch((error) => console.log(error));

    get("/api/contract/getAll?pageSize=1000&pageNumber=1")
      .then((res) => setContracts(res.data))
      .catch((error) => console.log(error));

    if (user?.offices?.[0]?.cities) {
      fetchCityData(user?.offices?.[0]?.cities[0].country?.id);
    }
    if (user?.offices?.[0]?.cities) {
      const cityIds = user?.offices[0]?.cities.map(city => city?.id)
      fetchOfficeData(cityIds);
    }
  }, [user]);

  const fetchCityData = (countryId) => {
    get(`/api/GeoData/cities?countryId=${countryId}`)
      .then((res) => setCities(res.data))
      .catch((error) => console.log(error));
  };

  const fetchOfficeData = (cityId) => {
    get(`/api/Office/getAllByCity?CityIds=${cityId}`)
      .then((res) => setOffices(res.data))
      .catch((error) => console.log(error));
  };

  const handleCountryChange = (countryId) => {
    form.resetFields(["city", "office"]);
    setCities([]);
    setSelectedCityIds([]);
    setOffices([]);
    if (countryId) {
      fetchCityData(countryId);
    }
  };

  const handleCityChange = (cityIds) => {
    // console.log(cityIds)
    form.resetFields(["office"]);
    setOffices([]);
    setSelectedCityIds(cityIds);
    if (cityIds && cityIds.length > 0) {
      fetchOfficeData(cityIds);
    }
  };

  const handleAddRolePermissions = () => {
    const newId = idCounter.current + 1;
    idCounter.current = newId;
    const newRolePermissions = [...rolePermissions, { id: newId }];
    setRolePermissions(newRolePermissions);
  };

  const handleRemoveRolePermissions = (idToRemove) => {
    const updatedPermissions = rolePermissions.filter(
      (role) => role.id !== idToRemove
    );
    form.resetFields([`role${idToRemove}`, `contract${idToRemove}`]);
    setRolePermissions(updatedPermissions);
  };

  const handleOk = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const { email, position, country, city, office, ...rest } = values;

        const officeLocations = Array.isArray(office)
          ? office
          : office
          ? [office]
          : [];
        const contractRoles = rolePermissions.map((role) => ({
          contractId: rest[`contract${role.id}`] || null,
          roleId: rest[`role${role.id}`] || null,
        }));

        // Remove empty or undefined fields from requestBody
        const requestBody = {
          id: user?.id,
          // email: email || null,
          position: position || null,
          country: country || null,
          city: city || null,
          officeLocations,
          contractRoles: contractRoles.filter(
            (role) => role.contractId && role.roleId
          ),
        };

        put("/api/user/updatemember", requestBody)
          .then((response) => {
            console.log("API response:", response);
            form.resetFields();
            onClose();
            const message =
              response.message ||
              "A new member has been edited and saved to your database!";
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: "Member edited successfully!",
              text: message,
            });

            onSubmit();
            setSelectedCityIds([])
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.errors[0]?.message ||
              "We had a problem editing this member in your database, please try again.";
            console.error("Error updating member:", error);
            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
          })
          .finally(() => {
            setLoading(false);
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
          });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (record) {
      const memberId = record.id;
      get(`/api/User?userId=${memberId}`)
        .then((res) => setUser(res.data))
        .catch((error) => console.log(error));
    }
  }, [record]);

  useEffect(() => {
    setPageLoading(true);
    if (user) {
      const countryId = user?.offices[0]?.cities[0]?.country?.id;
      const cityId = user?.offices[0]?.cities?.map(city => city?.id);
      const officeIds = user?.offices.map((office) => office.id);

      setSelectedCityIds(cityId);

      form.setFieldsValue({
        email: user?.email,
        position: user?.userDetails?.position,
        country: countryId,
        city: cityId,
        office: officeIds,
      });
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 1000);

    if (user && visible) {
      const userRoles = user.userContractRoles.map((item, index) => ({
        id: index + 1,
        roleId: item?.role?.id,
        contractId: item?.contract?.id,
      }));
      setRolePermissions(userRoles);
      const initialValues = {};
      userRoles.forEach((role) => {
        initialValues[`role${role.id}`] = role.roleId;
        initialValues[`contract${role.id}`] = role.contractId;
      });
      form.setFieldsValue(initialValues);
    }
  }, [user, form]);

  const handleCancel = () => {
    form.resetFields();
    setSelectedCityIds([])
    onClose();
  };

  const getAvailableContracts = (index) => {
    const selectedContractIds = rolePermissions
      .filter((_, idx) => idx !== index) // Exclude the current index
      .map((role) => form.getFieldValue(`contract${role.id}`))
      .filter(Boolean); // Filter out undefined values
    return contracts.filter(
      (contract) => !selectedContractIds.includes(contract.id)
    );
  };

  const validateDuplicateContract = (_, value) => {
    const contractValues = rolePermissions.map((role) =>
      form.getFieldValue(`contract${role.id}`)
    );
    const duplicate =
      contractValues.filter((contract) => contract === value).length > 1;
    return duplicate
      ? Promise.reject(new Error("You already selected this contract"))
      : Promise.resolve();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Edit Member</h2>
      <Spin spinning={pageLoading}>
        <Form form={form} layout="vertical">
          <p className="modalsHeader1">Personal Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
              style={{ width: "48%" }}
            >
              <Input disabled placeholder="Enter Email" autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="position"
              label="Position"
              rules={[{ required: true, message: "Please select position" }]}
              style={{ width: "48%" }}
            >
              <Input placeholder="Enter Position" autoComplete="off" />
            </Form.Item>
          </div>
          <p className="modalsHeader1">Location Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Please select country" }]}
              style={{ width: "48%" }}
            >
              <Select
                placeholder="Select Country"
                onChange={handleCountryChange}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="city"
              label="Governorate/State"
              rules={[{ required: true, message: "Please select governorate or state" }]}
              style={{ width: "48%" }}
              
            >
              <Select
                allowClear
                mode="multiple"
                placeholder={
                  cities.length
                    ? "Select governorate or state" 
                    : "Select a country first!"
                }
                disabled={cities?.length < 1}
                onChange={handleCityChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            style={{ width: "48%" }}
            label="Office"
            name="office"
            required
          >
            <Select
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={selectedCityIds?.length < 1 || selectedCityIds === undefined}
              showSearch
              placeholder={
                selectedCityIds?.length < 1 || selectedCityIds === undefined
                  ? "Select governorate or state first!"
                  : "Select an office"
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  {addingOffice ? (
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Please enter office name"
                        ref={inputRef}
                        value={newOffice}
                        onChange={(e) => setNewOffice(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        loading={loading}
                        icon={<PlusOutlined />}
                        onClick={addOffice}
                      >
                        Add office
                      </Button>
                    </Space>
                  ) : (
                    selectedCityIds && (
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          cursor: "pointer",
                          color: "var(--2, #AA1A5F)",
                        }}
                        onClick={() => setAddingOffice(true)}
                      >
                        <PlusOutlined /> Other
                      </Space>
                    )
                  )}
                </>
              )}
              onChange={handleOfficeSelectChange}
              onDropdownVisibleChange={(open) => {
                !open && setAddingOffice(false);
                setNewOffice();
              }}
              options={offices.map((office) => ({
                label: office.name,
                value: office.id,
              }))}
            />
          </Form.Item>
          <p className="modalsHeader1">Roles and Permissions</p>
          {rolePermissions.map((role, index) => (
            <div key={role.id} className="rolesAndPremissions">
              <Form.Item
                name={`role${role.id}`}
                label="Role"
                rules={[{ required: true, message: "Please select role" }]}
                style={{ width: "48%" }}
              >
                <Select placeholder="Select Role">
                  {roles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.roleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={`contract${role.id}`}
                label="Contract"
                rules={[
                  { required: true, message: "Please select contract" },
                  { validator: validateDuplicateContract },
                ]}
                style={{ width: "48%" }}
              >
                <Select placeholder="Select Contract">
                  {getAvailableContracts(index).map((contract) => (
                    <Option key={contract.id} value={contract.id}>
                      {contract.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {rolePermissions.length > 1 && (
                <div onClick={() => handleRemoveRolePermissions(role.id)}>
                  <img
                    src={minusButtonIcon}
                    style={{ cursor: "pointer" }}
                    alt="Remove role permission"
                  />
                </div>
              )}
            </div>
          ))}
          <div
            className="addRolesAndPremissins"
            onClick={handleAddRolePermissions}
          >
            Enroll to another contract
          </div>
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
            >
              Edit Member
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditMember;
