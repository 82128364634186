import { Select, Table, Input, Button, List } from "antd";
import React, { useState } from "react";
// import "../Reporting.scss";
import previewList from "../../../../Assets/svg/previewList.svg"
import downloadList from "../../../../Assets/svg/downloadList.svg"

const data = Array.from({ length: 50 }, (_, index) => ({
    ipName: `Name ${index + 1}`,
    location: `Location ${1 + index}`,
    date:'01/01/2024'
}));

function DatasetList() {
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const { Option } = Select;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const [tableData, setTableData] = useState(data)

  const paginatedData = tableData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = (e) =>{
    const search = e.target.value
    const filteredData = data.filter(item => item.location.toLowerCase().includes(search.toLowerCase()))
    setTableData(filteredData)
  }

  return (
    <div className="reportingListDiv">
      <span className="tableViewHeaderText" >Dataset list</span>
      <div className="filtersWrapper">
        <div className="containerFilters">
          <Input placeholder="Type something here" onKeyUp={handleSearch}/>
          <Button className="clearButton">Clear All</Button>
          <Button onClick={handleToggleFilters} className="toggleButton">
            {showFilters ? "Hide Filters" : "Show Filters"}
          </Button>
        </div>
        {showFilters && (
          <div className="filtersContainer">
            <div className="filter">
              <p className="filterText">Implementing Partner</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Language"
              >
                <Option value="english">English</Option>
                <Option value="spanish">Spanish</Option>
                <Option value="french">French</Option>
                {/* Add more language options as needed */}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Location</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
              >
                <Option value="usa">USA</Option>
                <Option value="uk">UK</Option>
                <Option value="canada">Canada</Option>
                {/* Add more country options as needed */}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Current IP status</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Gender"
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
                {/* Add more gender options as needed */}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">IP/donor reporting status</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Workspace"
              >
                <Option value="workspace1">Workspace 1</Option>
                <Option value="workspace2">Workspace 2</Option>
                <Option value="workspace3">Workspace 3</Option>
                {/* Add more workspace options as needed */}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Month </p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Role"
              >
                <Option value="role1">Role 1</Option>
                <Option value="role2">Role 2</Option>
                <Option value="role3">Role 3</Option>
                {/* Add more role options as needed */}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Year</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Role"
              >
                <Option value="role1">Role 1</Option>
                <Option value="role2">Role 2</Option>
                <Option value="role3">Role 3</Option>
                {/* Add more role options as needed */}
              </Select>
            </div>
          </div>
        )}
      </div>
      <div className="listDesign">
      <List
        itemLayout="horizontal"
        dataSource={paginatedData}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button className="buttonsList"><img src={previewList}></img></Button>,
              <Button className="buttonsList"><img src={downloadList}></img></Button>,
            ]}
            style={{ width: "100%" }}
          >
            <List.Item.Meta
                 title={<span className="titleText">{`${item.ipName} ${item.location} ${item.date}`}</span>}
            />
          </List.Item>
        )}
        pagination={{
          current: currentPage,
          pageSize: itemsPerPage,
          total: data.length,
          onChange: handlePageChange,
        }}
        style={{ width: "100%" }}
      /></div>
    </div>
  );
}

export default DatasetList;
