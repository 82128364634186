import { Form, message, Upload, Button } from "antd";
import Dragger from "antd/es/upload/Dragger";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import { useState } from "react";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import { useSiteVisitStore } from "./store/siteVisitStore";

const fileProps = {
  name: "file",
  multiple: true,
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      console.log(info);
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  beforeUpload(file) {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("File must smaller than 5MB!");
    }
    return isLt5M;
  },
};

const ResourcesDetails = ({ form }) => {
  const {
    siteVisitList,
    otherDocuments,
    siteVideos,
    setSiteVisitList,
    setOtherDocuments,
    setSiteVideos,
  } = useSiteVisitStore();

  const handleSiteVisitUpload = (file) => {
    setSiteVisitList(file);
    form.setFieldsValue({
      report_documents: file,
    });
  };

  const handleOtherDocumentsUpload = (file) => {
    setOtherDocuments(file);
    form.setFieldsValue({
      other_files: file,
    });
  };

  const handleSiteVideosUpload = (file) => {
    setSiteVideos(file);
    form.setFieldsValue({
      video_files: file,
    });
  };

  const handleDeleteSiteVisit = () => {
    setSiteVisitList(null);
  };
  const handleDeleteOtherDocuments = () => {
    setOtherDocuments(null);
  };
  const handleDeleteSiteVideos = () => {
    setSiteVideos(null);
  };

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <Form.Item
        name="report_documents"
        label="Upload site visit report document(s)"
        style={{ fontWeight: "500" }}
      >
        <Upload.Dragger
          listType="text"
          name="report_documents"
          // accept=".png,.jpeg,.pdf"
          file={siteVisitList}
          onChange={(e) => handleSiteVisitUpload(e.file)}
          beforeUpload={() => false}
        >
          <div className="d-flex justify-content-center pb-3">
            <img src={uploadIcon} alt="" />
          </div>
          <Button className="uploadButton">
            Drag and drop site visit report document(s) here, or click to select
            files
          </Button>
          <div className="d-flex justify-content-center bottomText ">
            <span className="span3">(file formats that are allowed)</span>
            <span className="span4">Max file size: 5MB</span>
          </div>
        </Upload.Dragger>
        <div className="uploadsContainer">
          {
            siteVisitList && (
              <div className="uploadContent">
                <img
                  className="uploadIcon"
                  src={uploadIcon}
                  alt={siteVisitList?.name}
                />
                {siteVisitList?.fileName ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h1 style={{ margin: "0" }}>{siteVisitList?.fileName}</h1>
                  </div>
                ) : (
                  <div>
                    <h1>{siteVisitList?.name}</h1>
                    <p>
                      {(parseInt(siteVisitList?.size) / (1024 * 1024)).toFixed(
                        1
                      )}{" "}
                      MB
                    </p>
                  </div>
                )}
                <img
                  onClick={() => handleDeleteSiteVisit(siteVisitList)}
                  src={RemoveIcon}
                />
              </div>
            )
            // siteVisitList?.map((file) => (
            //   <div className="uploadContent">
            //     <img
            //       className="uploadIcon"
            //       src={uploadIcon}
            //       alt={file?.name}
            //     />
            //     <div>
            //       <h1>{file?.name}</h1>
            //       <p>
            //         {(parseInt(file?.size) / (1024 * 1024)).toFixed(1)}{" "}
            //         MB
            //       </p>
            //     </div>
            //     <img
            //       onClick={() => handleDeleteSiteVisit(file)}
            //       src={RemoveIcon}
            //     />
            //   </div>
            // ))
          }
        </div>
      </Form.Item>

      <Form.Item
        name="other_files"
        label="Upload other document(s)"
        style={{ fontWeight: "500" }}
      >
        <Upload.Dragger
          listType="text"
          // accept=".png,.jpeg,.pdf"
          file={otherDocuments}
          onChange={(e) => handleOtherDocumentsUpload(e.file)}
          beforeUpload={() => false}
        >
          <div className="d-flex justify-content-center pb-3">
            <img src={uploadIcon} alt="" />
          </div>
          <Button className="uploadButton">
            Drag and drop other document(s) here, or click to select files
          </Button>
          <div className="d-flex justify-content-center bottomText ">
            <span className="span3">(file formats that are allowed)</span>
            <span className="span4">Max file size: 5MB</span>
          </div>
        </Upload.Dragger>
        <div className="uploadsContainer">
          {otherDocuments && (
            <div className="uploadContent">
              <img
                className="uploadIcon"
                src={uploadIcon}
                alt={otherDocuments?.name}
              />
              {otherDocuments?.fileName ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h1 style={{ margin: "0" }}>{otherDocuments?.fileName}</h1>
                </div>
              ) : (
                <div>
                  <h1>{otherDocuments?.name}</h1>
                  <p>
                    {(parseInt(otherDocuments?.size) / (1024 * 1024)).toFixed(
                      1
                    )}{" "}
                    MB
                  </p>
                </div>
              )}
              <img
                onClick={() => handleDeleteOtherDocuments(otherDocuments)}
                src={RemoveIcon}
              />
            </div>
          )}
        </div>
      </Form.Item>

      <Form.Item
        name="video_files"
        label="Upload site video file(s)"
        style={{ fontWeight: "500" }}
      >
        <Upload.Dragger
          listType="text"
          accept=".mp4"
          file={siteVideos}
          onChange={(e) => handleSiteVideosUpload(e.file)}
          beforeUpload={() => false}
        >
          <div className="d-flex justify-content-center pb-3">
            <img src={uploadIcon} alt="" />
          </div>
          <Button className="uploadButton">
            Drag and drop other document(s) here, or click to select files
          </Button>
          <div className="d-flex justify-content-center bottomText ">
            <span className="span3">(file formats that are allowed)</span>
            <span className="span4">Max file size: 5MB</span>
          </div>
        </Upload.Dragger>
        <div className="uploadsContainer">
          {siteVideos && (
            <div className="uploadContent">
              <img
                className="uploadIcon"
                src={uploadIcon}
                alt={siteVideos?.name}
              />
              {siteVideos?.fileName ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h1 style={{ margin: "0" }}>{siteVideos?.fileName}</h1>
                </div>
              ) : (
                <div>
                  <h1>{siteVideos?.name}</h1>
                  <p>
                    {(parseInt(siteVideos?.size) / (1024 * 1024)).toFixed(1)} MB
                  </p>
                </div>
              )}
              <img
                onClick={() => handleDeleteSiteVideos(siteVideos)}
                src={RemoveIcon}
              />
            </div>
          )}
        </div>
      </Form.Item>
    </div>
  );
};
export default ResourcesDetails;
