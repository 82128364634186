import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Tooltip,
  Space,
  Divider,
  Spin,
} from "antd";
import "./style.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import FormItem from "antd/es/form/FormItem";
import PlanningIcon from "../../../../Assets/svg/iconMenu2.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import { put, post, get } from "../../../../services/authService";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import { PlusOutlined } from "@ant-design/icons";
import AddMemberIcon from "../../../../Assets/svg/addMemberIcon.svg";
import moment from "moment";
import dayjs from "dayjs";

const { Option } = Select;
const EditModal = ({ visible, onClose, setMessage, onEdit, record, onOk }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [files, setFiles] = useState({
    securityPlan: [],
    ipOrDonorReport: [],
    resource: [],
  });
  const projectID = localStorage.getItem("currentProjectID");
  const [isPostponed, setIsPostponed] = useState(false);
  const [allCountries, setAllContries] = useState([]);
  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);
  const [newOffice, setNewOffice] = useState("");
  const [addingOffice, setAddingOffice] = useState(false);
  const inputRef = useRef(null);
  const [clusters, setClusters] = useState([]);
  const [newCluster, setNewCluster] = useState("");
  const [addingCluster, setAddingCluster] = useState(false);
  const [selectedCityIds, setSelectedCityIds] = useState();
  const [users, setUsers] = useState();
  const [implementingPartners, setImplementingPartners] = useState();
  const [roles, setRoles] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const currentYear = new Date().getFullYear();
  const [ipPlanFiles, setIpPlanFiles] = useState([]);
  const years = Array.from({ length: 11 }, (_, i) => currentYear + i);

  const [fileList, setFileList] = useState([]);

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  useEffect(() => {
    if (record && visible) {
      get(`/api/IPplan/getIpPlan?id=${record}`)
        .then((res) => {
          const ipPlan = res.data;

          if (ipPlan?.office?.cities) {
            fetchCityData(ipPlan?.office?.cities[0]?.country?.id);
          }
          if (ipPlan?.office?.cities) {
            const cityIds = ipPlan?.office?.cities.map((city) => city?.id);
            fetchOfficeData(cityIds);
          }
          const initialSelectedCities = ipPlan?.cities.map((city) => city.id);
          setSelectedCityIds(initialSelectedCities);
          const initialData = {
            id: ipPlan?.id,
            name: ipPlan?.name || "",
            ImplementingPartnerId: ipPlan?.implementingPartner?.id || "",
            description: ipPlan?.description || "",
            programDescription: ipPlan?.planningRound?.programDescription || "",
            country: ipPlan?.office?.cities[0]?.country?.id || "",
            city: initialSelectedCities,
            OfficeId: ipPlan?.office?.id || "",
            AssignedTo: ipPlan?.assignee?.id || "",
            cluster: ipPlan?.clusters?.map((cluster) => cluster.id) || "",
            generalInfo: ipPlan?.generalInfo || "",
            contactSkype: ipPlan?.pointOfContact?.skype || "",
            contactEmail: ipPlan?.pointOfContact?.email || "",
            contactName: ipPlan?.pointOfContact?.name || "",
            contactOrganization: ipPlan?.pointOfContact?.organization || "",
            contactRole: ipPlan?.pointOfContact?.role || "",
            contactPhone: ipPlan?.pointOfContact?.phone || "",
            isPostponed: ipPlan?.isPostponed || false,
            postponeReason: ipPlan?.reasonOfPostponed || "",
            Month: ipPlan?.planningRound?.month || "",
            Year: ipPlan?.planningRound?.year || "",
            currentIpStatus: ipPlan?.currentIPStatus || "",
            ipDonorReportingStatus: ipPlan?.ipDonorReportingEnum || "",
            InitialMeeting: ipPlan?.initialMeeting
              ? dayjs(ipPlan.initialMeeting)
              : null,
          };
          const filesUploaded = ipPlan?.ipPlanFiles?.map((file) => file);
          setIpPlanFiles(filesUploaded);
          setInitialValues(initialData);
          form.setFieldsValue(initialData); // Set the fetched values into the form
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [record, form, visible]);

  const addOffice = async (e) => {
    e.preventDefault();
    if (newOffice) {
      try {
        setLoading(true);
        console.log(selectedCityIds);

        const requestBody = {
          cityIds: selectedCityIds,
          name: newOffice,
        };

        const response = await post("/api/Office", requestBody);
        const message = response.message || "Office added successfully!";
        fetchOfficeData(selectedCityIds);
        setNewOffice("");
        setAddingOffice(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Office added successfully!`,
          text: message,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item:`, error);
        const errorMessage =
          error.response?.data?.errors[0]?.message ||
          "We had a problem adding this office to the database. Please try again later!";
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      } finally {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }
    }
  };

  const handleOfficeSelectChange = () => {
    setAddingOffice(false);
  };

  useEffect(() => {
    fetchClusterData();
  }, []);

  const fetchClusterData = async () => {
    try {
      setLoading(true);
      const clustersResponse = await get("/api/Site/getAllClusters");
      setClusters(clustersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const addCluster = async (e) => {
    e.preventDefault();
    if (newCluster) {
      try {
        setLoading(true);
        const response = await post("/api/Site/createCluster", {
          name: newCluster,
        });
        const message =
          response.message ||
          `A new cluster has been created and added to your database!`;
        fetchClusterData();
        setNewCluster("");
        setAddingCluster(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Cluster added successfully!`,
          text: message,
        });
      } catch (error) {
        const errorMessage =
          error.response?.data?.errors[0]?.message ||
          `We had a problem adding this cluster to your database, please try again.`;
        console.error(`Error adding new item to:`, error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      } finally {
        setLoading(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClusterSelectChange = () => {
    setAddingCluster(false);
  };

  useEffect(() => {
    const workspaceId = localStorage.getItem("currentContractId");
    get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&RoleIds=615a8dc6-ca07-4f82-8d0d-7d82dd4d3ef8&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`
    )
      .then((res) => setUsers(res.data))
      .catch((error) => console.log(error));

    get("/api/Account/getAllRoles?")
      .then((res) => setRoles(res.data))
      .catch((error) => console.log(error));

    get("/api/GeoData/countries")
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));

    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}&IgnorePagination=true`;

    get(apiIps)
      .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleCountryChange = (countryId) => {
    form.setFieldsValue({
      city: undefined,
      OfficeId: undefined,
    });
    
    if (countryId) {
      fetchCityData(countryId);
    }else{
      setCities([]);
      setOffices([]);
      setSelectedCityIds([])
    }
  };

  const fetchCityData = (countryId) => {
    get(`/api/GeoData/cities?countryId=${countryId}`)
      .then((res) => setCities(res.data))
      .catch((error) => console.log(error));
  };

  const fetchOfficeData = (cityIds) => {
    const cityIdsParams = cityIds.map((id) => `CityIds=${id}`).join("&");
    get(`/api/Office/getAllByCity?${cityIdsParams}`)
      .then((res) => setOffices(res.data))
      .catch((error) => console.log(error));
  };

  const handleCityChange = (cityIds) => {
    form.resetFields(["office"]);
    setOffices([]);
    setSelectedCityIds(cityIds);
    if (cityIds && cityIds?.length > 0) {
      fetchOfficeData(cityIds);
    }
  };
  // const handleSwitchChange = (checked) => {
  //   setIsPostponed(checked);
  // };

  // const handleSecurityPlanUpload = (files) => {
  //   const uploads = files.fileList.map((file) => ({
  //     fileName: file.name,
  //     fileSize: file.size,
  //     originFileObj: file.originFileObj,
  //   }));
  //   setFiles((prevFiles) => ({ ...prevFiles, securityPlan: uploads }));
  // };
  // const handleSecPlanRemove = (fileToRemove) => {
  //   setFiles((prevFiles) => ({
  //     ...prevFiles,
  //     securityPlan: prevFiles.securityPlan.filter(
  //       (file) => file !== fileToRemove
  //     ),
  //   }));
  // };

  // const handleipOrDonorReportUpload = (files) => {
  //   const uploads = files.fileList.map((file) => ({
  //     fileName: file.name,
  //     fileSize: file.size,
  //   }));
  //   setFiles((prevFiles) => ({ ...prevFiles, ipOrDonorReport: uploads }));
  // };

  // const handleIpDonorRemove = (fileToRemove) => {
  //   setFiles((prevFiles) => ({
  //     ...prevFiles,
  //     ipOrDonorReport: prevFiles.ipOrDonorReport.filter(
  //       (file) => file !== fileToRemove
  //     ),
  //   }));
  // };

  // const handleResourceUpload = (files) => {
  //   const uploads = files.fileList.map((file) => ({
  //     fileName: file.name,
  //     fileSize: file.size,
  //   }));
  //   setFiles((prevFiles) => ({ ...prevFiles, resource: uploads }));
  // };

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList?.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const formData = new FormData();
        formData.append("Id", record);
        formData.append("ImplementingPartnerId", values.ImplementingPartnerId);
        formData.append("OfficeId", values.OfficeId);
        selectedCityIds.forEach((cityId) => {
          formData.append("CityIds", cityId);
        });
        formData.append("ProjectId", projectID);
        formData.append("AssignedTo", values.AssignedTo);
        values.cluster.forEach((cluster, index) =>
          formData.append(`Clusters[${index}]`, cluster)
        );
        formData.append(
          "PlanningRound.ProgramDescription",
          values.programDescription
        );
        formData.append("generalInfo", values.generalInfo || "");
        formData.append(
          "InitialMeeting",
          values.InitialMeeting
            ? values.InitialMeeting.format("YYYY-MM-DD")
            : null
        );

        if (values.Month) formData.append("PlanningRound.Month", values.Month);
        if (values.Year) formData.append("PlanningRound.Year", values.Year);
        if (values.contactName)
          formData.append("PointOfContact.Name", values.contactName);
        if (values.contactEmail)
          formData.append("PointOfContact.Email", values.contactEmail);
        if (values.contactRole)
          formData.append("PointOfContact.Role", values.contactRole);
        if (values.contactPhone)
          formData.append("PointOfContact.Phone", values.contactPhone);
        if (values.contactSkype)
          formData.append("PointOfContact.Skype", values.contactSkype);
        if (values.contactOrganization)
          formData.append(
            "PointOfContact.Organization",
            values.contactOrganization
          );

        formData.append("PointOfContact.ContactType", 2);
        formData.append("IsPostponed", isPostponed);
        formData.append("reasonOfPostponed", values.postponeReason || "");

        formData.append("CurrentIPStatus", values.currentIpStatus || "");
        formData.append(
          "IPDonorReportingEnum",
          values.ipDonorReportingStatus || ""
        );
        if (fileList?.length < 1) {
          formData.append(`Files[0].FileStatus`, null);
          formData.append(`Files[0].File`, "");
        } else {
          fileList.forEach((file, index) => {
            formData.append(`Files[${index}].FileStatus`, 1);
            formData.append(`Files[${index}].File`, file.originFileObj);
          });
        }

        // Send the FormData object using the post function
        put("/api/IPPlan/updateIPPlan", formData)
          .then((values) => {
            const message =
              values.message ||
              "A new project has been created and added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: PlanningIcon,
              title: "Project added successfully!",
              text: message,
            });
            onEdit();
            onClose();
            setInitialValues(null);
            form.resetFields();
            onOk();
          })
          .catch((error) => {
            console.error("There was an error adding the project!", error);
            const errorMessage =
              error.response?.data?.errors[0]?.message ||
              "We had a problem adding this project from your database, please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              icon: PlanningIcon,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: PlanningIcon,
          title: "Oops! A problem has occurred!",
          text: "Please fill all required fields correctly.",
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
    setInitialValues(null);
  };

  const handleResourceIpPlan = (fileToRemove) => {
    setIpPlanFiles((prevFiles) =>
      prevFiles.filter((file) => file.fileName !== fileToRemove.fileName)
    );
  };

  return (
    <Modal
    destroyOnClose
      open={visible}
      closable={false}
      width={800}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} className="inviteButtonTPM">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={loading}
        >
          Save changes
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit IP Plan Card</h2>
      <Spin spinning={!initialValues}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <h3>General details</h3>
          <Form.Item
            name="ImplementingPartnerId"
            label="Implementing partner"
            rules={[{ required: true, message: "Select an IP" }]}
          >
            <Select placeholder="Select an IP" showSearch allowClear>
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div className="firstSection">
            <div className="row">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="country"
                  label="Country"
                  placeholder="Select a country"
                  rules={[{ required: true, message: "Please select country" }]}
                  style={{ width: "48%" }}
                >
                  <Select
                    placeholder="Select Country"
                    onChange={handleCountryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                    showSearch
                    allowClear
                  >
                    {allCountries.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.stateName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="Governorate/State"
                  rules={[
                    {
                      required: true,
                      message: "Please select a governorate or state",
                    },
                  ]}
                  style={{ width: "48%" }}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    disabled={cities?.length < 1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                    placeholder="Select a state or governorate"
                    onChange={handleCityChange}
                    // value={}
                  >
                    {cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  style={{ width: "48%" }}
                  label="Office"
                  name="OfficeId"
                  showSearch
                  rules={[
                    {
                      required: true,
                      message: "Please select an office",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    disabled={selectedCityIds?.length < 1}
                    placeholder="Select an office"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        {addingOffice ? (
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="Please enter office name"
                              ref={inputRef}
                              value={newOffice}
                              onChange={(e) => setNewOffice(e.target.value)}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              loading={loading}
                              icon={<PlusOutlined />}
                              onClick={addOffice}
                            >
                              Add office
                            </Button>
                          </Space>
                        ) : (
                          selectedCityIds && (
                            <Space
                              style={{
                                padding: "0 8px 4px",
                                cursor: "pointer",
                                color: "var(--2, #AA1A5F)",
                              }}
                              onClick={() => setAddingOffice(true)}
                            >
                              <PlusOutlined /> Other
                            </Space>
                          )
                        )}
                      </>
                    )}
                    onChange={handleOfficeSelectChange}
                    onDropdownVisibleChange={(open) => {
                      !open && setAddingOffice(false);
                      setNewOffice();
                    }}
                    options={offices.map((office) => ({
                      label: office.name,
                      value: office.id,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  name="AssignedTo"
                  label="Assigned To"
                  rules={[
                    {
                      required: true,
                      message: "Select a person to assign the plan",
                    },
                  ]}
                  style={{ width: "48%" }}
                >
                  <Select
                    placeholder="Select a person to assign the plan"
                    allowClear
                    showSearch
                  >
                    {users &&
                      users.map((user) => (
                        <Option key={user.id} value={user.id}>
                          {user.userName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                label="Clusters"
                name="cluster"
                rules={[
                  {
                    required: true,
                    message: "Please select a cluster",
                  },
                ]}
              >
                <Select
                  width={100}
                  allowClear
                  showSearch
                  placeholder="Select an item"
                  mode="multiple"
                  // disabled={loading}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      {addingCluster ? (
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Enter new cluster name..."
                            ref={inputRef}
                            value={newCluster}
                            onChange={(e) => setNewCluster(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            type="text"
                            loading={loading}
                            icon={<PlusOutlined />}
                            onClick={addCluster}
                          >
                            Add cluster
                          </Button>
                        </Space>
                      ) : (
                        <Space
                          style={{
                            padding: "0 8px 4px",
                            cursor: "pointer",
                            color: "var(--2, #AA1A5F)",
                          }}
                          onClick={() => setAddingCluster(true)}
                        >
                          <PlusOutlined /> Add new cluster
                        </Space>
                      )}
                    </>
                  )}
                  onChange={handleClusterSelectChange}
                  onDropdownVisibleChange={(open) => {
                    !open && setAddingCluster(false);
                    setNewCluster();
                  }}
                  options={clusters.map((cluster) => ({
                    label: cluster.name,
                    value: cluster.id,
                  }))}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="generalInfo"
            label="General information about on-site operations"
            rules={[
              { required: true, message: "General information is required" },
            ]}
          >
            <Input.TextArea placeholder="Enter general information about operations" />
          </Form.Item>
          <Form.Item
            name="programDescription"
            label="IP Program Activities Description"
          >
            <Input.TextArea placeholder="Enter a description of the program" />
          </Form.Item>

          <div className="secondSection">
            <div className="row">
              {/* <div className="column">
                <Form.Item label="Sites requested">
                  <DatePicker disabled style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item label="Sites provided">
                  <DatePicker disabled style={{ width: "100%" }} />
                </Form.Item>
              </div> */}
              <div className="column">
                {/* <Form.Item label="Sites proposed">
                  <DatePicker disabled style={{ width: "100%" }} />
                </Form.Item> */}
                <Form.Item
                  name="InitialMeeting"
                  label="Initial meeting"
                  rules={[
                    { required: true, message: "Initial meeting is required" },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="assignedToRoundLabel py-3 pb-2">
            <span>Assigned to round</span>
            <Tooltip title="Information about assignment to rounds">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
          <div className="row">
            <div className="column">
              <Form.Item
                name="Month"
                label="Month"
                rules={[{ required: true, message: "Month is required" }]}
              >
                <Select
                  placeholder="Select month"
                  style={{ width: "100%" }}
                  allowClear
                >
                  <Option value={1}>January</Option>
                  <Option value={2}>February</Option>
                  <Option value={3}>March</Option>
                  <Option value={4}>April</Option>
                  <Option value={5}>May</Option>
                  <Option value={6}>June</Option>
                  <Option value={7}>July</Option>
                  <Option value={8}>August</Option>
                  <Option value={9}>September</Option>
                  <Option value={10}>October</Option>
                  <Option value={11}>November</Option>
                  <Option value={12}>December</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item
                name="Year"
                label="Year"
                rules={[{ required: true, message: "Year is required" }]}
              >
                <Select
                  showSearch
                  placeholder="Select year"
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  allowClear
                >
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="assignedToRoundLabel">
            <span>Point of contact</span>
            <Tooltip title="Information about assignment to rounds">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
          <div className="contact-info">
            <div className="row">
              <div className="column">
                <Form.Item
                  name="contactName"
                  label="Contact name"
                  rules={[
                    { required: true, message: "Contact name is required" },
                  ]}
                >
                  <Input placeholder="Enter your name" />
                </Form.Item>
                <Form.Item
                  name="contactEmail"
                  label="Email"
                  rules={[
                    { required: true, message: "Email is required" },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
              </div>

              <div className="column">
                <Form.Item
                  name="contactRole"
                  label="Role"
                  rules={[{ required: true, message: "Please enter role" }]}
                >
                  <Input placeholder="Enter role" />
                </Form.Item>
                <Form.Item
                  name="contactOrganization"
                  label="Organization"
                  rules={[
                    { required: true, message: "Organization is required" },
                  ]}
                >
                  <Input placeholder="Enter your organization" />
                </Form.Item>
              </div>
              <div className="column">
                <Form.Item
                  name="contactPhone"
                  label="Phone"
                  rules={[
                    { required: true, message: "Phone number is required" },
                  ]}
                >
                  <Input placeholder="Enter your phone number" />
                </Form.Item>
              </div>
              <div className="column">
                <Form.Item
                  name="contactSkype"
                  label="Skype"
                  rules={[{ required: true, message: "Skype is required" }]}
                >
                  <Input placeholder="Enter your skype username" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="column">
              <FormItem
                name="currentIpStatus"
                label="Current IP status"
                rules={[{ required: true, message: "Field is required" }]}
              >
                <Select
                  placeholder="Select an IP status"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Field is required" }]}
                  allowClear
                >
                  <Option value={1}>Discussion</Option>
                  <Option value={2}>Document Review</Option>
                  <Option value={3}>Pre Phase</Option>
                  <Option value={4}>Data Collection</Option>
                  <Option value={5}>Closed</Option>
                </Select>
              </FormItem>
            </div>

            {/* <div className="column">
                <Form.Item
                  name="ipDonorReportingStatus"
                  label="IP/Donor Reporting Status"
                  disabled
                >
                  <Select
                    placeholder="Select a status"
                    style={{ width: "100%" }}
                    disabled
                  >
                    <Option value={1}>Awaiting Field</Option>
                    <Option value={2}>Data cleaning</Option>
                    <Option value={3}>Data analysis</Option>
                    <Option value={4}>Data translation</Option>
                    <Option value={5}>IP Report Development</Option>
                    <Option value={6}>Donor Report</Option>
                    <Option value={7}>Completed</Option>
                  </Select>
                </Form.Item>
              </div> */}
          </div>

          <div className="assignedToRoundLabel py-3">
            <span>Security plan</span>
            <Tooltip title="Information about assignment to rounds"></Tooltip>
          </div>
          <div className="row">
            <div className="column">
              <Form.Item name="files" label="Upload files">
                <Upload
                  multiple
                  listType="text"
                  accept=".png,.jpeg,.pdf"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={() => false}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">
                      (file formats that are allowed)
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload>

                <div className="uploadsContainer">
                  {fileList &&
                    fileList.map((file) => (
                      <div className="uploadContent" key={file.uid}>
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.name}
                        />
                        <div>
                          <h1>{file.name}</h1>
                          {file.size && (
                            <p>
                              {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                              MB
                            </p>
                          )}
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                </div>
                <div className="uploadsContainer">
                  {ipPlanFiles.map((file, index) => (
                    <div className="uploadContent" key={index}>
                      <img
                        className="uploadIcon"
                        src={uploadIcon}
                        alt={file.fileName}
                      />
                      <div>
                        <h1>{file.fileName}</h1>
                      </div>
                      <img
                        onClick={() => handleResourceIpPlan(file)}
                        src={RemoveIcon}
                        alt="RemoveIcon"
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
            </div>
          </div>
          {/* 
          <div className="postponeElement">
            <Form.Item
              name="IsPostponed"
              label=""
              className="d-flexr align-items-center"
            >
              <Switch checked={isPostponed} onChange={handleSwitchChange} />
              <span className="px-2">Postponed</span>
            </Form.Item>
          </div>
          {isPostponed && (
            <Form.Item
              name="postponeReason"
              label="Reason for postponement"
              rules={[{ required: true, message: "Reason is required" }]}
            >
              <Input.TextArea placeholder="Enter the reason for the postponement" />
            </Form.Item>
          )} */}

          {/* <div className="resourcesElement">
          <Form.Item label="Resources" className="resourcesLabel">
            <div className="resourcesUploadElement">
              <FormItem
                label="Add an IP or Donor Report"
                className="uploadLabels"
                name="ipOrDonorReport"
                disabled
              >
                <Upload
                  className="uploadBasket"
                  fileList={files.ipOrDonorReport}
                  onChange={handleipOrDonorReportUpload}
                  beforeUpload={() => false} // Prevents automatic upload
                  multiple
                  disabled
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton" disabled>
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">
                      (file formats that are allowed)
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload>
              </FormItem>
              <div className="uploadsContainer">
                {files.securityPlan &&
                  files.ipOrDonorReport.map((file) => (
                    <div className="uploadContent">
                      <img
                        className="uploadIcon"
                        src={uploadIcon}
                        alt={file.fileName}
                      />
                      <div>
                        <h1>{file.fileName}</h1>
                        <p>
                          {(parseInt(file.fileSize) / (1024 * 1024)).toFixed(1)}{" "}
                          MB
                        </p>
                      </div>
                      <img
                        onClick={() => handleIpDonorRemove(file)}
                        src={RemoveIcon}
                      />
                    </div>
                  ))}
              </div>

              <FormItem
                label="Attach a resource here"
                className="uploadLabels"
                name="resource"
                disabled
              >
                <Upload
                  className="uploadBasket"
                  fileList={files.resource}
                  onChange={handleResourceUpload}
                  beforeUpload={() => false} // Prevents automatic upload
                  multiple
                  disabled
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton" disabled>
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">
                      (file formats that are allowed)
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload>
              </FormItem>
              <div className="uploadsContainer">
                {files.securityPlan &&
                  files.resource.map((file) => (
                    <div className="uploadContent">
                      <img
                        className="uploadIcon"
                        src={uploadIcon}
                        alt={file.fileName}
                      />
                      <div>
                        <h1>{file.fileName}</h1>
                        <p>
                          {(parseInt(file.fileSize) / (1024 * 1024)).toFixed(1)}{" "}
                          MB
                        </p>
                      </div>
                      <img
                        onClick={() => handleResourceRemove(file)}
                        src={RemoveIcon}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </Form.Item>
        </div> */}
          <div className="trackingElement">
            <Form.Item label="" className="trackingLabel"></Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditModal;
