import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Input, message, Spin } from "antd";
import { post, get } from "../../../services/authService";
import "./styles.scss";

const ConfirmationCode = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [confLoader, setConfLoader] = useState(false);
  const [roles, setRoles] = useState([]); // State for roles
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || localStorage.getItem("email");

  // Save email to local storage on initial render or when email is available
  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email, navigate]);

  const handleVerify = async () => {
    setLoading(true);
    try {
      const response = await post("/api/Account/confirmCode", {
        email,
        code,
      });
  
      const { token, roles, lastLoggedInDate, fullName } = response.data;
  
      localStorage.setItem("token", token);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("username", fullName); 
      localStorage.setItem("role", roles);
  
      setRoles(roles);
  
      setLoading(false);
      message.success("Code verified!");
  
      if (lastLoggedInDate === null) {
        navigate("/first-time");
      } else {
        navigate("/dashboard/contracts");
      }
    } catch (error) {
      setLoading(false);
      message.error("Invalid code!");
      localStorage.removeItem("token");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("username");
      localStorage.removeItem("role");
    }
  };

  const handleResendCode = async () => {
    setConfLoader(true);
    try {
      await get("/api/Account/resendCode", { email });
      setConfLoader(false);
      message.success("Verification code resent!");
    } catch (error) {
      setConfLoader(false);
      message.error("Failed to resend code.");
    }
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>

        <div className="col-md-6 right-column">
          <div className="form-wrapper1">
            <h2 className="verificationCodeHeader">Verification Code</h2>
            <p className="verificationCodeText">
              Please enter the verification code that we sent to {email}
            </p>
            <form>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter code"
                className="code-input"
              />

              <div className="buttonsModal">
                <Button
                  className="addButtonTPM"
                  key="submit"
                  type="primary"
                  onClick={handleVerify}
                  loading={loading}
                  disabled={loading}
                >
                  Verify code
                </Button>
              </div>
            </form>
            {/* Resend code link */}
            <p className="verificationCodeText">
              If you didn’t receive a verification code,{" "}
              <span
                style={{ color: "#E164A0", cursor: "pointer" }}
                onClick={handleResendCode}
              >
                click here to resend it
              </span>{" "}
              or send us an email at info@kaptechgroup.com
            </p>
            {confLoader ? <Spin style={{ width: "100%" }} /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCode;
