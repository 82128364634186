import { Select, Input, Button, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { get } from "../../../../services/authService";
import dayjs from "dayjs";

const { Option } = Select;
const { MonthPicker, YearPicker } = DatePicker;


const Filters = ({onChange,filters, setFilters,pagination,setPagination,fetchRedFlags}) => {
  
  const [states, setStates] = useState();
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [implementingPartnerId, setImplementingPartnerId] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [search, setSearch] = useState("");
  const projectID = localStorage.getItem("currentProjectID");


  const [showFilters, setShowFilters] = useState(false);
  
  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setPagination({ ...pagination, current: 1 });
    onChange(key, value);
  };

  useEffect(() => {
    const apiContries = "/api/GeoData/countries";

    get(apiContries)
      .then((res) => setStates(res.data))
      .catch((error) => console.log(error));
      
    const workspaceId = localStorage.getItem("currentContractId")
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;
    
    get(apiIps)
      .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
  }, []);


  const handleClearAll = () => {
    setSearch("");
    setImplementingPartnerId(null);
    setSelectedCountry(null);
    setMonth(null);
    setYear(null);
    setFilters({});
    setPagination({ ...pagination, current: 1 });
    fetchRedFlags({ projectID, pageNumber: 1, pageSize: 10 })
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleFilterChange("search", e.target.value);
  };

  const handleIp = (value,option) => {
    const implementingPartnerId = option ? option.key : null;
    setImplementingPartnerId(value);
    handleFilterChange("implementingPartnerId", implementingPartnerId);
  };
  
  const handleLocation = (value, option) => {
    const countryId = option ? option.value : null;
    setSelectedCountry(value);
    handleFilterChange("country", countryId);
  };

  const handleMonth = (date) => {
    const monthValue = date ? dayjs(date).format("MM") : null;
    setMonth(monthValue);
    handleFilterChange("month", monthValue);
  };

  const handleYear = (date) => {
    const yearValue = date ? dayjs(date).format("YYYY") : null;
    setYear(yearValue);
    handleFilterChange("year", yearValue);
  };

  
  return (
    <div className="filtersWrapper">
    <div className="containerFilters">
      {" "}
      <Input placeholder="Type something here" value={search} onChange={handleSearchChange}/>
      <Button className="clearButton" onClick={handleClearAll}>Clear All</Button>
      <Button onClick={handleToggleFilters} className="toggleButton">
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
    </div>
    {showFilters && (
      <div className="filtersContainer">
        <div className="filter">
          <p className="filterText">Implementing Partner</p>
          <Select
            value={implementingPartnerId}
            onChange={handleIp}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select the IP"
          >
            {
              implementingPartners && implementingPartners.map(implementingPartner => (
                <Option key={implementingPartner.id} value={implementingPartner.id}>
                  {implementingPartner.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className="filter">
          <p className="filterText">Location</p>
          <Select
           onChange={handleLocation}
           value={selectedCountry}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select the state"
          >
            {states &&
                states.map((state) => (
                  <Option key={state.id} value={state.stateName}>
                    {state.stateName}
                  </Option>
                ))}
          </Select>
        </div>
        <div className="filter">
            <p className="filterText">Month</p>
            <MonthPicker
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select month"
              allowClear
              value={month ? dayjs(month, "MM") : null}
              onChange={handleMonth}
              format="MM"
            />
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <YearPicker
              onChange={handleYear}
              allowClear
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select year"
              value={year ? dayjs(year, "YYYY") : null}
            />
          </div>
      </div>
    )}
  </div>
  )
}

export default Filters