import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import React, { useState } from 'react'
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";

const { Option } = Select;
const EditProjectDetailsModal = ({visible}) => {
    const [form] = Form.useForm();
    const [pageLoading, setPageLoading] = useState(false);
  return (
<Modal
      visible={visible}
      closable={false}
    //   onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        //   onClick={handleCancel}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Edit Member</h2>
      <Spin spinning={pageLoading}>
      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Personal Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
            style={{ width: "48%" }}
          >
            <Input disabled placeholder="Enter Email" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="position"
            label="Position"
            rules={[{ required: true, message: "Please select position" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Position" autoComplete="off" />
          </Form.Item>
        </div>
        <p className="modalsHeader1">Location Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Please select country" }]}
            style={{ width: "48%" }}
          >
            <Select placeholder="Select Country" >
              {/* {allCountries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.stateName}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: "Please select a city" }]}
            style={{ width: "48%" }}
          >
            <Select 
            allowClear placeholder="Select City" >
              {/* {cities.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
        </div>
        <p className="modalsHeader1">Roles and Permissions</p>
        
        <div
          className="addRolesAndPremissins"
        //   onClick={handleAddRolePermissions}
        >
          Enroll to another workspace
        </div>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            // onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            // onClick={handleOk}
          >
            Edit Member
          </Button>
        </div>
      </Form>
      </Spin>
    </Modal>
  )
}

export default EditProjectDetailsModal