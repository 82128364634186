
import React, { useState,useEffect } from "react";
import "../Reporting.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import TableViewIpDonor from "./TableView/TableView";
import KanbanViewIpDonor from "./KanbanView/KanbanView";
import CalendarViewIpDonor from "./CalendarView/CalendarView";
import { get } from "../../../../services/authService";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";

function IpDonorStatusBoard() {
  const [data, setData] = useState([]);
  const [dataForExcel, setDataForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [IPPlans, setIPPlans] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [filters, setFilters] = useState({
    ImplementingPartnerId: '',
    IPStatus: null,
    AssignedTo: "",
    Month: '',
    Year: '',
    Search: ''
  });

  const formatDate = (isoString) => {
    const date = new Date(isoString);
  
    const formattedDate = date.toLocaleDateString("en-CA");
    const formattedTime = date.toLocaleTimeString("en-GB", { hour12: false });
  
    return `${formattedDate}/Time: ${formattedTime}`;
  };

  const fetchIpPlan = async () => {
    setLoading(true);
    try {
      const response = await get(`/api/IPPlan/getAllIPPlans?ProjectId=${projectID}&pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`, { 
        ImplementingPartnerId: filters.ImplementingPartnerId,
        IPStatus: filters.IPStatus,
        AssignedTo: filters.AssignedTo,
        Month: filters.Month,
        Year: filters.Year,
        Search: filters.Search,
       });
      setData(response.data);
      const ipPlansData = response.data.map((ipPlan) => ({
        id: ipPlan?.id,
        trackingId: ipPlan?.id,
        content: {
          title: ipPlan?.identifier,
          statusUpdated: formatDate(ipPlan?.updatedDate) || "Never updated",
          ip: ipPlan?.implementingPartner?.name,
          state: ipPlan?.office?.country,
          municipality: ipPlan?.office?.city,
        },
        column: ipPlan?.ipDonorReportingEnum,
      }));

      setIPPlans(ipPlansData);
      setPagination({
        ...pagination,
        total: response.metaData.totalItemCount
      });
    } catch (error) {
      console.error("Error fetching site visits:", error);
    }
    setLoading(false);
  };


  useEffect(() => {
    fetchIpPlan();
  }, [filters,pagination.pageSize, pagination.current]);

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  
  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: <TableViewIpDonor
        data={data}
        fetchIpPlan={fetchIpPlan}
        handleFilterChange={handleFilterChange}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        setDataForExcel={setDataForExcel}
        onPageChange={onPageChange}
        pagination={pagination}
        setPagination={setPagination}
      />,
    },
    {
      key: "2",
      label: "Kanban view",
      children: <KanbanViewIpDonor
        handleFilterChange={handleFilterChange}
        setFilters={setFilters}
        fetchIpPlan={fetchIpPlan}
        IPPlans={IPPlans}
        onPageChange={onPageChange}
        pagination={pagination}
        setPagination={setPagination}
      />,
    },
    {
      key: "3",
      label: "Calendar view",
      children: <CalendarViewIpDonor
        handleFilterChange={handleFilterChange}
        setFilters={setFilters}
        data={data}
        filters={filters}
        onPageChange={onPageChange}
        pagination={pagination}
        setPagination={setPagination}
      />,
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">IP/Donor Status Board</span>
        <div className="tableViewHeaderButtons">
          <Button
            className="exportButtonTPM addButtonTPM"
            onClick={() => {
              if (dataForExcel.length !== 0) {
                exportToExcel(dataForExcel,"IP-planning")
              }
            }}
          >
            <img src={ExportButton} alt=""/>
            Export selected visits
          </Button>
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default  IpDonorStatusBoard;
