import React from "react";
import "./styles.scss";
import WorkspaceBody from "./WorkspaceBody";
const Workspaces = () => {
  return (
    <div className="workspace-wrapper">
      <div className="wrapper-header">
        <h3>Contract Management</h3>
      </div>
      <WorkspaceBody />
    </div>
  );
};

export default Workspaces;
