import React, { useState, useEffect } from "react";
import { message, Spin, Input, Button, Form } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { post } from "../../../services/authService";
import "./styles.scss";

const NewPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  const email = location.state?.email;

  useEffect(() => {
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);

  const handleForgotPassword = async (values) => {
    const { newPassword, confirmNewPassword } = values;
    if (newPassword !== confirmNewPassword) {
      message.error("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      const response = await post("/api/account/forgotPassword", {
        email,
        newPassword,
        confirmNewPassword,
      });

      if (response.success) {
        message.success("Password changed successfully");
        navigate("/login");
      } else {
        message.error(response.message || "Failed to change password");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <Form
              form={form}
              onFinish={handleForgotPassword}
              layout="vertical"
              initialValues={{ email }}
            >
              <h2>Reset Password</h2>
              <Form.Item
                label="Email address"
                name="email"
                rules={[{ required: true, message: "Please input your email!" }]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[{ required: true, message: "Please input your new password!" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmNewPassword"
                rules={[
                  { required: true, message: "Please confirm your new password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="addButtonTPM"
                  disabled={loading}
                >
                  {loading ? <Spin /> : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
