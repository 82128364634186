import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, Form, Input, Button, Checkbox, message } from "antd";
import { isTokenExpired } from "../../../services/auth";
import "./styles.scss";
import MessagePopUp from "../../components/Messages/MessagePopUp";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('email');
    const token = localStorage.getItem("token");
    if (token && !isTokenExpired(token)) {
      navigate("/dashboard/contracts");
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      localStorage.removeItem("role");
      localStorage.removeItem("userID");
    }
  }, [navigate]);

  const handleLogin = async (values) => {
    const { email, password } = values;

    setLoading(true);

    const loginData = { email, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/Account/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify(loginData),
        }
      );

      const result = await response.json();

      if (response.ok && result.success) {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("userEmail", result.data.email);
        localStorage.setItem('username', result.data.fullName)
        localStorage.setItem("role", result.data.roles);
        localStorage.setItem("userID", result.data.id);

        if (!result.data.confirmationCodeSent) {
          navigate("/dashboard/contracts");
        } else {
          navigate("/confirmationCode", { state: { email } });
        }
      } else {
        const errorText =
          result.errors && result.errors.length > 0
            ? result.errors[0].message
            : result.message || "An error occurred during login.";

        if (errorText === "User already has a pending confirmation code. Please check your email or resend code") {
          navigate("/confirmationCode", { state: { email } });
        } else {
          message.error(errorText);
        }
      }
    } catch (error) {
      message.error("Error during login: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };

  return (
    <div className="wrapper">
      <MessagePopUp
        trigger={popupMessage.trigger}
        type={popupMessage.type}
        icon={popupMessage.icon}
        messageTitle={popupMessage.title}
        messageText={popupMessage.text}
      />
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <p>Welcome back!</p>
            <h2>TPMready Management System</h2>
            <Form
              onFinish={handleLogin}
              layout="vertical"
              style={{ width: '100%' }}
            >
              <Form.Item
                label="Email address"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item name="rememberMe" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <p
                className="forgotPassword"
                onClick={handleForgotPassword}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Forgot your password?
              </p>
              <Form.Item style={{ width: "100%" }}>
                <Button className="addButtonTPM" htmlType="submit" loading={loading} block>
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
