import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
  Space,
  Upload,
  Spin,
} from "antd";
import moment from "moment";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { get, put } from "../../../../../services/authService";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import dayjs from "dayjs";

const { Option } = Select;

const flagStatuses = [
  {
    id: 1,
    status: "Assigned",
  },
  {
    id: 2,
    status: "In progress",
  },
  {
    id: 3,
    status: "On hold",
  },
  {
    id: 4,
    status: "Resolved",
  },
  {
    id: 5,
    status: "Archived",
  },
];

const Edit = ({
  visible,
  onClose,
  record,
  setMessage,
  fetchRedFlags,
  pagination,
}) => {
  const [allCountries, setAllContries] = useState([]);
  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);
  const [newOffice, setNewOffice] = useState("");
  const [addingOffice, setAddingOffice] = useState(false);
  const [implementingPartners, setImplementingPartners] = useState();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState();
  const [users, setUsers] = useState();
  const [form] = Form.useForm();
  const [selectedCityIds, setselectedCityIdss] = useState();
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [redFlagData, setRedFlagData] = useState([]);
  const [initialName, setInitialName] = useState("");

  const addOffice = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (record?.key) {
      const redFlagId = record?.key;
      setPageLoading(true);

      get(`/api/RedFlag/getRedFlag?id=${redFlagId}`)
        .then((res) => {
          const redFlag = res.data;

          if (redFlag?.office?.cities) {
            fetchCityData(redFlag?.office?.cities[0].country?.id);
          }
          if (redFlag?.office?.cities) {
            const cityIds = redFlag?.office?.cities?.map(city => city?.id)
            fetchOfficeData(cityIds);
          }

          const filesUploaded =
            redFlag?.redFlagFiles?.map((file) => file) || [];

          setRedFlagData(filesUploaded);
          setInitialName(redFlag?.name);
          form.setFieldsValue({
            name: redFlag?.name,
            ImplementingPartner: redFlag?.implementingPartnerId,
            description: redFlag?.description,
            siteVisitCard: redFlag?.siteVisitId,
            dateOfSiteVisit: dayjs(redFlag?.dateOfSiteVisit),
            country: redFlag?.office?.cities[0].country?.id,
            city: redFlag?.office?.cities.map(city => city.id),
            office: redFlag?.office?.id,
            dueDate: dayjs(redFlag?.dueDate),
            assignedTo: redFlag?.assignedTo?.id,
            flagStatus: redFlag?.flagStatus,
            followUpNotes: redFlag?.followUpNotes,
          });
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the red flag data!",
            error
          );
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  }, [record, form]);

  const handleOfficeSelectChange = () => {
    setAddingOffice(false);
  };

  const handleCountryChange = (countryId) => {
    form.resetFields(["city", "office"]);
    setCities([]);
    setOffices([]);
    setSelectedCountryId(countryId);
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => setCities(res.data))
        .catch((error) => console.log(error));
    }
  };

  const handleCityChange = (cityIds) => {
    form.resetFields(["office"]);
    setOffices([]);
    setselectedCityIdss(cityIds);
    if (cityIds && cityIds.length > 0) {
      fetchOfficeData(cityIds);
    }
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    console.log("projectid:", projectId);
    get(`/api/Site/getAllSiteVisits?ProjectId=${projectId}`)
      .then((res) => setSiteVisits(res.data))
      .catch((error) => console.log(error));

    get("/api/User/getAllusers")
      .then((res) => setUsers(res.data))
      .catch((error) => console.log(error));

    get("/api/GeoData/countries")
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    get(apiIps)
      .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
  }, []);

  const fetchCityData = (countryId) => {
    get(`/api/GeoData/cities?countryId=${countryId}`)
      .then((res) => setCities(res.data))
      .catch((error) => console.log(error));
  };

  const fetchOfficeData = (cityIds) => {
    const params = new URLSearchParams();
    cityIds.forEach((id) => params.append("CityIds", id));

    get(`/api/Office/getAllByCity?${params}`)
      .then((res) => setOffices(res.data))
      .catch((error) => console.log(error));
  };

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";

    return date.format("YYYY-MM-DD");
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
      const dueDate = formatDate(values.dueDate);
      const formData = new FormData();
      formData.append("Id", record?.key);
      if (values.name !== initialName) {
        formData.append("Name", values.name);
      }
      formData.append("Description", values.description);
      formData.append("DateOfSiteVisit", dateOfSiteVisit);
      formData.append("OfficeId", values.office);
      formData.append("DueDate", dueDate);
      formData.append("AssignedTo", values.assignedTo);
      formData.append("FlagStatus", values.flagStatus);
      formData.append("FollowUpNotes", values.followUpNotes);
      fileList.forEach((file) => {
        formData.append("UpdateFiles.Files", file.originFileObj);
      });
      redFlagData.forEach((file) => {
        const blob = new Blob([JSON.stringify(file)], {
          type: "application/json",
        });
        formData.append("UpdateFiles.Files", blob, file.fileName);
      });
      const response = await put("/api/RedFlag", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const message = response.message || `A new red flag has been created and added to your database!`
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: `Red flag added successfully!`,
        text: message,
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
      form.resetFields();
      onClose();
      setRedFlagData([]);
      setFileList([]);
      fetchRedFlags(pagination.current, pagination.pageSize);
      setLoading(false);
    } catch (error) {
      const messageError = error?.response?.data?.errors[0]?.message;
      setMessage({
        trigger: true,
        type: "danger",
        icon: AddMemberIcon,
        title: "Oops! A problem has occurred!",
        text: messageError,
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
    } finally {
      setLoading(false);
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: `Red flag added successfully!`,
        text: `A new red flag has been created and added to your database!`,
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    setRedFlagData([]);
    onClose();
  };

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleResourceRedFlagRemove = (fileToRemove) => {
    setRedFlagData((prevFiles) =>
      prevFiles.filter((file) => file.fileName !== fileToRemove.fileName)
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <span>Last update:  2024-04-23 12:23:01</span>
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit Red flag card</h2>
      <Spin spinning={pageLoading}>
        <Form form={form} layout="vertical">
          {" "}
          <Form.Item
            className="fullWidthInput"
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Enter the name of the red flag" },
            ]}
          >
            <Input
              placeholder="Enter the name of the red flag"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="ImplementingPartner"
            label="Implementing partner"
            rules={[{ required: true, message: "Select an IP" }]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select an IP">
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Enter the description of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <TextArea
              rows={4}
              placeholder="Enter the description of the flag"
              maxLength={6}
            />
          </Form.Item>
          <Form.Item
            name="siteVisitCard"
            label="Select the site visit card"
            rules={[{ required: true, message: "Select the site visit card" }]}
            style={{ width: "100%" }}
          >
            <Select placeholder="<###> - SV - <Sitename> - <Location 2> - <date created>">
              {siteVisits &&
                siteVisits.map((siteVisit) => (
                  <Option key={siteVisit.id} value={siteVisit.id}>
                    {siteVisit.identifier}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dateOfSiteVisit"
              label="Date of site visit"
              rules={[{ required: true, message: "Pick date of site visit" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Enter text here" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dueDate"
              label="Due date"
              rules={[{ required: true, message: "Pick due date" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Select date"></DatePicker>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Please select country" }]}
              style={{ width: "48%" }}
            >
              <Select
                placeholder="Select Country"
                onChange={handleCountryChange}
              >
                {allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
          name="city"
          label="State/Governorate"
              rules={[{ required: true, message: "Select a state or governorate" }]}
              style={{ width: "48%" }}
            >
              <Select
                mode="multiple"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) ===
                  0
                }
                placeholder="Select a state or governorate"
                onChange={handleCityChange}
                disabled={cities?.length < 1}
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            style={{ width: "48%" }}
            label="Office"
            name="office"
            required
          >
            <Select
              allowClear
              placeholder="Select an item"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  {addingOffice ? (
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Please enter office name"
                        ref={inputRef}
                        value={newOffice}
                        onChange={(e) => setNewOffice(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        loading={loading}
                        icon={<PlusOutlined />}
                        onClick={addOffice}
                      >
                        Add office
                      </Button>
                    </Space>
                  ) : (
                    selectedCityIds && (
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          cursor: "pointer",
                          color: "var(--2, #AA1A5F)",
                        }}
                        onClick={() => setAddingOffice(true)}
                      >
                        <PlusOutlined /> Other
                      </Space>
                    )
                  )}
                </>
              )}
              onChange={handleOfficeSelectChange}
              onDropdownVisibleChange={(open) => {
                !open && setAddingOffice(false);
                setNewOffice();
              }}
              options={offices.map((office) => ({
                label: office.name,
                value: office.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="assignedTo"
            label="Select a person to assign the flag"
            rules={[
              { required: true, message: "Select a person to assign the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select a person to assign the flag">
              {users &&
                users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.userName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="flagStatus"
            label="Flag status"
            rules={[
              { required: true, message: "Select the status of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select the status of the flag">
              {flagStatuses &&
                flagStatuses.map((flagStatus) => (
                  <Option key={flagStatus.id} value={flagStatus.id}>
                    {flagStatus.status}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label="Follow-up notes"
            rules={[{ required: true, message: "Enter your follow up notes" }]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter your follow up notes" />
          </Form.Item>
          <div className="resourcesElement">
            <p className="modalsHeader1">Resources</p>
            <div className="resourcesLabel">
              <p>No resource files found for this IP plan card</p>
              <div className="resourcesUploadElement">
                <Form.Item name="files" label="Upload files">
                  <Upload
                    multiple
                    listType="text"
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={() => false}
                  >
                    <div className="d-flex justify-content-center pb-3">
                      <img src={uploadIcon} alt="" />
                    </div>
                    <Button className="uploadButton">
                      Drag and drop files here, or click to select files
                    </Button>
                    <div className="d-flex justify-content-center bottomText ">
                      <span className="span3">
                        (file formats that are allowed)
                      </span>
                      <span className="span4">Max file size: 5MB</span>
                    </div>
                  </Upload>
                  <div className="uploadsContainer">
                    {fileList.map((file, index) => (
                      <div className="uploadContent" key={file.uid}>
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.name}
                        />
                        <div>
                          <h1>{file.name}</h1>
                          <p>
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="uploadsContainer">
                    {redFlagData.map((file, index) => (
                      <div className="uploadContent" key={index}>
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.fileName}
                        />
                        <div>
                          <h1>{file.fileName}</h1>
                        </div>
                        <img
                          onClick={() => handleResourceRedFlagRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading}
            >
              Save changes
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Edit;
