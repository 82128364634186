import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, message } from "antd";
import { put, get } from "../../../services/authService";
import "./styles.scss";

const FirstTimeSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("1");
  const [skypeId, setSkypeId] = useState("");
  const [languageId, setLanguageId] = useState("");
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await get("/api/GeoData/languages");
        setLanguages(response.data);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "Please input your first name.";
    if (!lastName) newErrors.lastName = "Please input your last name.";
    if (!gender) newErrors.gender = "Please select your gender.";
    return newErrors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      const formData = {
        firstName,
        lastName,
        gender: parseInt(gender),
        socialDetails: {
          skypeId,
        },
        languages: [
          {
            languageId,
          },
        ],
      };

      await put("/api/User/updateUserDetails", formData);

      navigate("/dashboard/contracts");
      message.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Profile update failed!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <form onSubmit={handleLogin}>
              <h2>You have been added to TPMready!</h2>
              <p>Please fill out the following form to complete your profile and set up your account:</p>
            
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">* First Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.firstName ? "input-error" : ""}`}
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && <div className="error-text">{errors.firstName}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">* Last Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.lastName ? "input-error" : ""}`}
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <div className="error-text">{errors.lastName}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="gender" className="form-label">* Gender</label>
                <select
                  className={`form-control ${errors.gender ? "input-error" : ""}`}
                  id="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Other</option>
                </select>
                {errors.gender && <div className="error-text">{errors.gender}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="skypeId" className="form-label">Skype ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="skypeId"
                  value={skypeId}
                  onChange={(e) => setSkypeId(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="languageId" className="form-label">Language</label>
                <select
                  className="form-control"
                  id="languageId"
                  value={languageId}
                  onChange={(e) => setLanguageId(e.target.value)}
                >
                  <option value="">Select a language</option>
                  {languages.map((language) => (
                    <option key={language.id} value={language.id}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember me
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? <Spin /> : "Continue"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeSignIn;
