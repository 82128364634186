import React, { useState, useEffect } from "react";
import { Table, message, Input, Button, Select } from "antd";
import TableViewComponent from "../../components/TableView/TableViewComponent";
import editIcon from "../../../Assets/svg/table-action-edit.svg";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import previewIcon from "../../../Assets/svg/table-action-preview.svg";
import FilterComponent from "../../components/TableView/FilterComponent";
import EditModal from "./modals/EditModal";
import PreviewModal from "./modals/PreviewModal";
import DeleteModal from "./modals/DeleteModal";
import { get, del } from "../../../services/authService";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import SearchIcon from "../../../Assets/svg/searchIcon.svg";
import MessagePopUp from "../../components/Messages/MessagePopUp";
const { Option } = Select;

const PlanningTableView = ({ ipPlansData, onDataLoaded }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isPreviewModalVisible, setPreviewModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 11 }, (_, i) => currentYear + i);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [loading, setLoading] = useState(true);
  const projectID = localStorage.getItem("currentProjectID");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    ImplementingPartnerId: undefined,
    month: undefined,
    year: undefined,
    IPStatus: undefined,
    search: "",
  });
  const [implementingPartners, setImplementingPartners] = useState([]);

  useEffect(() => {
    const fetchImplementingPartners = async () => {
      const workspaceId = localStorage.getItem("currentContractId");
      const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}&IgnorePagination=true`;

      try {
        const res = await get(apiIps);
        setImplementingPartners(res.data);
      } catch (error) {
        console.error("Error fetching Implementing Partners:", error);
      }
    };

    fetchImplementingPartners();

    get("/api/User/getAllusers?IgnorePagination=true").then((res) =>
      setUsers(res.data)
    );
  }, []);

  const handleClearFilters = () => {
    setFilters({
      ImplementingPartnerId: undefined,
      month: undefined,
      year: undefined,
      IPStatus: undefined,
      AssignedTo: undefined,
      search: "",
    });
  };
  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleSearchChange = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, search: e.target.value }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    fetchData();
  }, [filters, pagination.current, pagination.pageSize]);

  const fetchData = async () => {
    try {
      let apiUrl = `/api/IPPlan/getAllIPPlans?ProjectId=${projectID}`;
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          apiUrl += `&${key}=${value}`;
        }
      }

      apiUrl += `&pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`;

      setLoading(true);
      const response = await get(apiUrl);

      const formattedData = response.data.map((item) => ({
        key: item.id,
        ipplan: item?.identifier,
        ipplanid: item?.id,
        ip: item.implementingPartner?.name || "N/A",
        assignedTo: item.assignee?.userName || "N/A",
        clusters:
          item.clusters?.map((cluster) => cluster.name).join(", ") || "N/A",
        sitesRequested: item.sitesRequested || "N/A",
        sitesProvided: item.sitesProvided || "N/A",
        sitesProposed: item.sitesProposed || "N/A",
        initialMeeting: item.initialMeeting || "N/A",
        implementingPartner: item.implementingPartner?.id || "N/A",
        office: item.office?.name || "N/A",
        generalInfo: item.generalInfo || "N/A",
        assignedRoundToMonth: item.assignedRoundToMonth || "N/A",
        assignedRoundToYear: item.assignedRoundToYear || "N/A",
        programDescriptions: item.programDescriptions || "N/A",
        pointOfContact: item.pointOfContact?.name || "N/A",
        securityPlan: item.securityPlan || "N/A",
        postponed: item.isPostponed ? "Yes" : "No",
        reasonForPostponement: item.reasonOfPostponement || "N/A",
        resources: item.resources || "N/A",
        currentIpStatus: item?.currentIPStatus || "N/A",
        ipDonorReportingStatus: item?.ipDonorReportingEnum || "N/A",
      }));
      setData(formattedData);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: response.metaData.pageNumber,
        pageSize: response.metaData.pageSize,
        total: response.metaData.totalItemCount,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
      // message.error("Error fetching data");
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  useEffect(() => {
    fetchData(ipPlansData, pagination.current, pagination.pageSize);
  }, [filters, ipPlansData]);

  const handleDelete = (record) => {
    console.log("Deleting record with ID:", record.ipplan); // Log the ID
    setCurrentRecord(record.key); // Set the current record to be deleted
    setShowDeleteModal(true); // Show the delete modal
  };

  const del = async (url, body, token) => {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json-patch+json",
      },
      body: JSON.stringify(body),
    });
    return response;
  };

  const handleConfirmDelete = async () => {
    if (currentRecord) {
      console.log(currentRecord);
      try {
        const requestBody = [
          {
            id: currentRecord,
          },
        ];

        const url = `https://stagingapi.tpmready.com/api/IPPlan/deleteIPPlans`;
        const token = localStorage.getItem("token");

        const response = await del(url, requestBody, token);
        const message =
          response.message ||
          "IP plan was deleted successfully from your database!";
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "IP plan deleted successfully!",
          text: message,
        });
      } catch (error) {
        const errorMessage =
          error.response?.data?.errors[0]?.message ||
          "We had a problem deleting this ip plan, please try again.";

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      } finally {
        setShowDeleteModal(false);
        setCurrentRecord(null);
        fetchData();
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    }
  };

  const handlePreview = (record) => {
    setCurrentRecord(record?.key);
    setPreviewModalVisible(true);
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    setEditModalVisible(true);
  };

  const handlePreviewOk = () => {
    setPreviewModalVisible(false);
  };

  const handleEditOk = () => {
    setEditModalVisible(false);
  };

  const handleCancel = () => {
    setPreviewModalVisible(false);
    setEditModalVisible(false);
  };

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    onDataLoaded(selectedRows);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  const columns = [
    {
      title: <span className="styledTitle">IP Plan Identifier</span>,
      dataIndex: "ipplan",
      key: "ipplan",
      sorter: (a, b) => a.ipplan.localeCompare(b.ipplan),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },

    {
      title: <span className="styledTitle">IP</span>,
      dataIndex: "ip",
      key: "ip",
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Assigned To</span>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Clusters</span>,
      dataIndex: "clusters",
      key: "clusters",
      sorter: (a, b) => a.clusters.localeCompare(b.clusters),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Current IP status</span>,
      dataIndex: "currentIpStatus",
      key: "currentIpStatus",
      sorter: (a, b) => a.currentIpStatus.localeCompare(b.currentIpStatus),
      render: (text) => {
        const statusMap = {
          1: "Discussion",
          2: "Document Review",
          3: "Pre-phase",
          4: "Data Collection",
          5: "Closed",
        };
        const statusText = statusMap[text] || "Unknown Status";
        let className;
        let fill;

        if (statusText === "Discussion") {
          className = "discussion";
          fill = "#AA1A5F";
        } else if (statusText === "Document Review") {
          className = "reviewPlanning";
          fill = "#FC9403";
        } else if (statusText === "Pre-phase") {
          className = "prePhase";
          fill = "#325899";
        } else if (statusText === "Data Collection") {
          className = "dataCollection";
          fill = "#3B81FE";
        } else if (statusText === "Closed") {
          className = "closed";
          fill = "#555";
        }

        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{statusText}</span>
          </div>
        );
      },
    },
    // {
    //   title: <span className="styledTitle">IP Donor reporting status</span>,
    //   dataIndex: "ipDonorReportingStatus",
    //   key: "ipDonorReportingStatus",
    //   render: (text) => {
    //     const statusMap = {
    //       1: "Awaiting Field",
    //       2: "Data cleaning",
    //       3: "Data analysis",
    //       4: "Data translation",
    //       5: "IP Report Development",
    //       6: "Donor Report",
    //       7: "Completed",
    //     };
    //     const statusText = statusMap[text] || "Unknown Status";
    //     let className;
    //     let fill;

    //     if (statusText === "Awaiting Field") {
    //       className = "discussion";
    //       fill = "#AA1A5F";
    //     } else if (statusText === "Data cleaning") {
    //       className = "reviewPlanning";
    //       fill = "#FC9403";
    //     } else if (statusText === "Data analysis") {
    //       className = "prePhase";
    //       fill = "#325899";
    //     } else if (statusText === "Data translation") {
    //       className = "dataCollection";
    //       fill = "#3B81FE";
    //     } else if (statusText === "IP Report Development") {
    //       className = "closed";
    //       fill = "#555";
    //     } else if (statusText === "Donor Report") {
    //       className = "closed";
    //       fill = "#555";
    //     } else if (statusText === "Completed") {
    //       className = "closed";
    //       fill = "#555";
    //     }

    //     return (
    //       <div className={className}>
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="8px"
    //           height="8px"
    //           viewBox="0 0 9 9"
    //         >
    //           <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
    //         </svg>
    //         <span>{statusText}</span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: <span className="styledTitle"></span>,
      key: "action",

      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={previewIcon}
            onClick={() => handlePreview(record)}
            style={{ cursor: "pointer" }}
          ></img>
          <img
            src={editIcon}
            onClick={() => handleEdit(record)}
            style={{ cursor: "pointer" }}
          />
          <img
            src={deleteIcon}
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(record)}
          ></img>
        </div>
      ),
    },
  ];

  return (
    <>
      {" "}
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="page-content">
        <div className="filtersWrapper my-5">
          <div className="containerFilters">
            <Input
              placeholder="Search..."
              name="search"
              value={filters.search}
              suffix={<img src={SearchIcon} alt="Search" />}
              onChange={handleSearchChange}
            />
            <Button className="clearButton" onClick={handleClearFilters}>
              Clear All
            </Button>
            <Button onClick={handleToggleFilters} className="toggleButton">
              {!showFilters ? "Show Filters" : "Hide Filters"}
            </Button>
          </div>
          {showFilters && (
            <div className="filtersContainer">
              <div className="filter">
                <p className="filterText">Implementing Partner</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select an IP"
                  onChange={(value) =>
                    handleFilterChange("ImplementingPartnerId", value)
                  }
                  value={filters.ImplementingPartnerId}
                  allowClear
                >
                  {implementingPartners.map((implementingPartner) => (
                    <Option
                      key={implementingPartner.id}
                      value={implementingPartner.id}
                    >
                      {implementingPartner.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Current IP status</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select an IP status"
                  onChange={(value) => handleFilterChange("IPStatus", value)}
                  value={filters.IPStatus}
                  allowClear
                >
                  <Option key={1} value={1}>
                    Discussion
                  </Option>
                  <Option key={2} value={2}>
                    Document Review
                  </Option>
                  <Option key={3} value={3}>
                    Pre-phase
                  </Option>
                  <Option key={4} value={4}>
                    Data Collection
                  </Option>
                  <Option key={5} value={5}>
                    Closed
                  </Option>
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Assigned to:</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a user"
                  onChange={(value) => handleFilterChange("AssignedTo", value)}
                  value={filters.AssignedTo}
                  allowClear
                >
                  {users &&
                    users.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.userDetails.firstName
                          ? user.userDetails.firstName
                          : "N/A"}{" "}
                        {user.userDetails.lastName
                          ? user.userDetails.lastName
                          : "N/A"}{" "}
                        - ({user.userName})
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Month</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a month"
                  onChange={(value) => handleFilterChange("month", value)}
                  value={filters.month}
                  allowClear
                >
                  <Option value="1">January</Option>
                  <Option value="2">February</Option>
                  <Option value="3">March</Option>
                  <Option value="4">April</Option>
                  <Option value="5">May</Option>
                  <Option value="6">June</Option>
                  <Option value="7">July</Option>
                  <Option value="8">August</Option>
                  <Option value="9">September</Option>
                  <Option value="10">October</Option>
                  <Option value="11">November</Option>
                  <Option value="12">December</Option>
                </Select>
              </div>

              <div className="filter">
                <p className="filterText">Year</p>

                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a year"
                  onChange={(value) => handleFilterChange("year", value)}
                  value={filters.year}
                  allowClear
                >
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={loading}
          className="tableTPM"
          style={{ marginTop: "20px" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
        <PreviewModal
          visible={isPreviewModalVisible}
          onOk={handlePreviewOk}
          onCancel={handleCancel}
          record={currentRecord}
        />
        <EditModal
          visible={isEditModalVisible}
          onOk={handleEditOk}
          onClose={handleCancel}
          setMessage={setMessage}
          record={currentRecord?.key}
          onEdit={fetchData}
        />
        <DeleteComponent
          visible={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          text="You're about to delete this project. This action can’t be undone."
          header="Delete Project"
          onConfirm={handleConfirmDelete}
        />
      </div>
    </>
  );
};

export default PlanningTableView;
