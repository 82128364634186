import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const Preview = ({ visible, onClose, record}) => {
  const [form] = Form.useForm();


  if (!record) {
    return null; // or you can render a message indicating that no record is available
  }


  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Form form={form} layout="vertical">
        {" "}
        <p className="modalsHeader1">Personal Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="driverName"
            label={<span style={{ fontWeight: "bold" }}>	
            Driver Name</span>}
          >
            <p className="previewText">{record.driverName}</p>
          </Form.Item>
          <Form.Item
            name="driverPhone"
            label={<span style={{ fontWeight: "bold" }}>Driver Phone</span>}
            style={{ width: "48%" }}
          >
             <p className="previewText">{record.driverPhone}</p>
          </Form.Item>
        </div>
        <p className="modalsHeader1">Location Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="mainLocation"
            label={<span style={{ fontWeight: "bold" }}>Main Location</span>}
            style={{ width: "48%" }}
          >
     <p className="previewText">{record.mainLocation}</p>
          </Form.Item>
        </div>

    
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Preview;
