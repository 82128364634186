import { Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import { useState } from "react";

const { Option } = Select;

  //months
  const months = [
    {
      id: "01",
      name: "January",
    },
    {
      id: "02",
      name: "February",
    },
    {
      id: "03",
      name: "March",
    },
    {
      id: "04",
      name: "April",
    },
    {
      id: "05",
      name: "May",
    },
    {
      id: "06",
      name: "June",
    },
    {
      id: "07",
      name: "July",
    },
    {
      id: "08",
      name: "August",
    },
    {
      id: "09",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];

  //years
  const years = [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ];
const TrackingDetails = () => {
    const [isPostponed, setIsPostponed] = useState(false);
  return (
    <div>
      <Row gutter={24}>
        <div className="d-flex  align-items-center">
          <Col span={8}>
            <Form.Item name="reportingYear" label="Reporting year" required>
              <Select placeholder="Select reporting year">
              {years.map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}

              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="reportingMonth" label="Reporting month" required>
              <Select placeholder="Select reporting month">
              {months.map((month) => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="trainingIsCompleted">
              <Switch /> Training Completed
            </Form.Item>
          </Col>
        </div>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="trainingDate" label="Training date" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name="trainingScoreInfo"  label="Training score info">
            <Input placeholder="example" />
          </Form.Item>
        </Col>
        <Col span={8} />
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="actualDateOfVisit" label="Actual date of visit" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dateOfReport" label="Date of report">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dateOfDebrief" label="Date of debrief">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="percentageOfDataCollectionCompleted" label="% of data collection completed" required>
            <Select placeholder="Select an option from 0 to 100">
              {Array.from({ length: 101 }, (_, i) => (
                <Option value={i}>{i}%</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="siteVisitStatus" label="Site visit status" required>
            <Select placeholder="Select status">
              <Option value="1">Selected for monitoring</Option>
              <Option value="2">Data collection in progress</Option>
              <Option value="3">Review</Option>
              <Option value="4">Delivered</Option>
              <Option value="5">Debrief</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Col span={8}>
        <Form.Item name="fieldTeamReportingStatus" label="Field Team Reporting Status" required>
          <Select placeholder="Select a status">
              <Option value="dataCollection">Data collection</Option>
              <Option value="postDataCollection">Post-data collection</Option>
              <Option value="finalization">Finalization</Option>
              <Option value="delivered">Delivered</Option>
              <Option value="ipFeedback">IP Feedback</Option>
              <Option value="closed">Closed</Option>
          </Select>
        </Form.Item>
      </Col>
    </div>
  );
};
export default TrackingDetails;
