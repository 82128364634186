import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Button,
  Select,
  Form,
  message,
  DatePicker,
  Card,
  Divider,
} from "antd";
import { Formik, Form as FormikForm, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import "../styles.scss";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../services/httpService.service";
import { RemoveButton, PlusIcon } from "../../../../Assets/svg/index";
import { get, post } from "../../../../services/authService";
import AddMemberIcon from "../../../../Assets/svg/addMemberIcon.svg";

const { Option } = Select;
const { RangePicker } = DatePicker;

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  projectCode: Yup.string().required("Please enter a project code"),
  clients: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Please enter a client name"),
      })
    )
    .min(1, "At least one client must be present")
    .required(),
  contractNumber: Yup.string().required("Please enter a contract number"),
  contractDates: Yup.array()
    .of(Yup.date().nullable())
    .required("Please select contract dates")
    .test(
      "required",
      "Please select contract dates",
      (value) => value && value.length === 2
    ),
  referenceDocuments: Yup.string(),
  linkToSharedDrive: Yup.string().required(
    "Please enter a link to shared drive"
  ),
  countryId: Yup.array()
    .of(Yup.string().required("Country is required"))
    .min(1, "Please select at least one country"),
});

const AddWorkspaceModal = ({ visible, onClose, onSubmit, setMessage }) => {
  const [country, setCountry] = useState([]);
  const initialValues = {
    description: "",
    projectCode: "",
    clients: [{ name: "" }],
    contractNumber: "",
    contractDates: [],
    referenceDocuments: "",
    linkToSharedDrive: "",
    countryId: [],
  };

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    get(apiCountries)
      .then((res) => setCountry(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    const transformedValues = {
      ...values,
      clientName: values.clients.map((client) => client.name),
      contractStartDate: values.contractDates[0],
      contractEndDate: values.contractDates[1],
    };
    delete transformedValues.contractDates;
  
    try {
      await post("/api/Contract", transformedValues);
      message.success("Contract added successfully!");
      onClose();
      onSubmit();
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: `Contract added successfully!`,
        text: `A new contract has been created and added to your database!`,
      });
    } catch (error) {
      if (error.response) {
        const backendError = error.response.data.errors?.[0] || {};
        onClose();
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
  
        if (error.response.status === 400) {
          setMessage({
            trigger: true,
            type: "error",
            icon: cancelX,
            title: `Error`,
            text:
              backendError.message ||
              `Bad request. Please check your input and try again.`,
          });
        } else {
          setMessage({
            trigger: true,
            type: "error",
            icon: cancelX,
            title: `Error`,
            text: `Failed to add contract. Please try again later.`,
          });
        }
      } else {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
        setMessage({
          trigger: true,
          type: "error",
          icon: cancelX,
          title: `Error`,
          text: `Network error. Please check your connection and try again.`,
        });
      }
    } finally {
      // Reset submission state
      setSubmitting(false);
      // Clean up message after 3 seconds
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000);
    }
  };
  

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      closable={false}
      className="modalAddWorkspaceStyle"
    >
      <img
        src={cancelX}
        alt="BlackXIcon"
        onClick={onClose}
        style={{ float: "right", cursor: "pointer" }}
      />
      <h1 className="title-assessment-modal">New contract</h1>
      <div className="admin-workspace">
        <div className="top-form">
          <div className="login-form login-signin" style={{ display: "block" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
            >
              {({ isSubmitting, setFieldValue, values, handleChange }) => (
                <FormikForm>
                  <div
                    style={{
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#AA1A5F",
                    }}
                  >
                    Contract Details
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>
                        <span>*</span>Project Code
                      </label>
                    </div>
                    <Form.Item>
                      <Input
                        name="projectCode"
                        onChange={handleChange}
                        value={values.projectCode}
                        autoComplete="off"
                        placeholder="Enter project code"
                        style={{ height: "37px", borderRadius: "4px" }}
                      />
                      <ErrorMessage
                        name="projectCode"
                        component="div"
                        className="error-message"
                      />
                    </Form.Item>
                  </div>

                  <div>
                    <div className="label-name-assessment-modal">
                      <label><span>*</span>Contract Number</label>
                    </div>
                    <Form.Item>
                      <Input
                        name="contractNumber"
                        onChange={handleChange}
                        value={values.contractNumber}
                        autoComplete="off"
                        placeholder="Enter contract number"
                        style={{ height: "37px", borderRadius: "4px" }}
                      />
                      <ErrorMessage
                        name="contractNumber"
                        component="div"
                        className="error-message"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label style={{ width: "120px" }}>
                        <span>*</span>Country
                      </label>
                    </div>
                    <Select
                      name="countryId"
                      showSearch
                      allowClear
                      mode="multiple"
                      placeholder="Select a country"
                      style={{ height: "38%" }}
                      onChange={(value) => setFieldValue("countryId", value)}
                      value={values.countryId}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .startsWith(input.toLowerCase())
                      }
                    >
                      {country.map((c) => (
                        <Option key={c.id} value={c.id}>
                          {c.stateName}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="countryId"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <div>
                    <div className="label-name-assessment-modal">
                      <label><span>*</span>Location</label>
                    </div>
                    <Form.Item>
                      <Input
                        onChange={handleChange}
                        placeholder="Enter the location"
                        style={{ height: "37px", borderRadius: "4px" }}
                      />
                      <ErrorMessage name="contractNumber" component="div" className="error-message" />
                    </Form.Item>
                    </div> */}
                    <div>
                      <div className="label-name-assessment-modal">
                        <label>
                          <span>*</span>Contract Dates
                        </label>
                      </div>
                      <Form.Item>
                        <RangePicker
                          name="contractDates"
                          value={values.contractDates}
                          style={{ width: "100%" }}
                          showTime
                          format="YYYY-MM-DD HH:mm:ss"
                          onChange={(value) =>
                            setFieldValue("contractDates", value)
                          }
                        />
                        <ErrorMessage
                          name="contractDates"
                          component="div"
                          className="error-message"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <FieldArray name="clients">
                    {({ remove, push }) => (
                      <Card
                        style={{
                          marginBottom: "10px",
                          background: "#FFF5FA",
                          borderRadius: "8px",
                        }}
                      >
                        {values.clients.map((client, index) => (
                          <div key={index} style={{ marginBottom: "10px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "#AA1A5F",
                                }}
                              >{`Client ${index + 1}`}</label>
                              {values.clients.length > 1 && (
                                <img
                                  src={RemoveButton}
                                  style={{ cursor: "pointer" }}
                                  alt="RemoveButton"
                                  onClick={() => remove(index)}
                                />
                              )}
                            </div>
                            <Form.Item>
                              <div className="label-name-assessment-modal">
                                <label>
                                  <span>*</span>Enter client name
                                </label>
                              </div>
                              <Input
                                name={`clients[${index}].name`}
                                value={client.name}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="Enter client name"
                                style={{ height: "37px", borderRadius: "4px" }}
                              />
                              <ErrorMessage
                                name={`clients[${index}].name`}
                                component="div"
                                className="error-message"
                              />
                            </Form.Item>
                            {index < values.clients.length - 1 && <Divider />}
                          </div>
                        ))}
                        <Button
                          onClick={() => push({ name: "" })}
                          type="dashed"
                          style={{
                            border: "1px dashed #AA1A5F",
                            borderRadius: "7px",
                            height: "60px",
                            width: "100%",
                            background: "#FADCEA",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                              justifyContent: "center",
                            }}
                          >
                            <img src={PlusIcon} alt="PlusIcon" />
                            <h1
                              style={{
                                margin: 0,
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#AA1A5F",
                              }}
                            >
                              Add another client
                            </h1>
                          </div>
                        </Button>
                      </Card>
                    )}
                  </FieldArray>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>Contract description</label>
                    </div>
                    <Form.Item>
                      <Input.TextArea
                        value={values.description}
                        onChange={handleChange}
                        name="description"
                        placeholder="Enter contract description"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>Links to reference documents</label>
                    </div>
                    <Form.Item>
                      <Input.TextArea
                        name="referenceDocuments"
                        onChange={handleChange}
                        value={values.referenceDocuments}
                        autoComplete="off"
                        placeholder="Enter links to reference documents"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>
                        <span>*</span>Link to Shared Drive
                      </label>
                    </div>
                    <Form.Item>
                      <Input
                        name="linkToSharedDrive"
                        value={values.linkToSharedDrive}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder="Enter link to shared drive"
                        style={{ height: "37px", borderRadius: "4px" }}
                      />
                      <ErrorMessage
                        name="linkToSharedDrive"
                        component="div"
                        className="error-message"
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-around">
                    <div className="footer-buttons">
                      <Button
                        className="cancel-workspace-button"
                        key="cancel"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="add-workspace-button"
                        key="success"
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                      >
                        Add new Contract
                      </Button>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddWorkspaceModal;
