import React, { useContext, useState } from "react";
import { Button, Tabs } from "antd";
import TableViewComponent from "./TableView/TableView";
import KanbanView from "./KanbanView/KanbanView";
import AddNewTaskModal from "./Modals/AddNewTaskModal";
import DeleteButton from "../../../../Assets/svg/table-action-delete.svg";
import { DataContext, DataProvider } from "./DataContext";
import "./style.scss";
import { PlusOutlined } from "@ant-design/icons";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import DeletedModal from "./Modals/DeletedModal";

const TasksAssignments = ({ loadTasks }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDeletedModal, setShowDeletedModal] = useState(false)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleDeletedTasks = () =>{
    setShowDeletedModal(true)
  }
  const updateData = () => {
    setDataUpdated(true)
  }


  return (
    <DataProvider>
      <div className="ip-planning-wrapper">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
        <div className="tab-container d-flex flex-column">
          <div className="text-title">
            <h3>Task allocation</h3>
            <div className="ip-planning-buttons">
              <Button className="inviteButtonTPM" onClick={handleDeletedTasks}>
                <img src={DeleteButton} alt="See deleted cards" />
                See deleted cards
              </Button>
              <Button className="archived-selectedBtn" disabled>
                Archive selected task
              </Button>
              <Button className="add-new-ipBtn" onClick={showModal}>
                <PlusOutlined />
                Assign new task
              </Button>
            </div>
          </div>
          <Tabs
            className="tableviewTabs"
            defaultActiveKey="1"
            items={[
              {
                label: "Table view",
                key: "1",
                children: <TableViewComponent dataUpdated={dataUpdated} setDataUpdated={setDataUpdated}/>,
              },
              {
                label: "Kanban view",
                key: "2",
                children: <KanbanView  dataUpdated={dataUpdated} setDataUpdated={setDataUpdated}/>,
              },
            ]}
          />
        </div>
        <AddNewTaskModal
          visible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setMessage={setMessage}
          updateData={updateData}
        />
        <DeletedModal
        visible={showDeletedModal} onOk={handleDeletedTasks} onCancel={()=>setShowDeletedModal(false)}
        />
      </div>
    </DataProvider>
  );
};

export default TasksAssignments;
