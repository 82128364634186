import React, { useState,useEffect } from "react";
import "./VisitBoard.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import DeleteButton from "../../../../Assets/svg/table-action-delete.svg";
import AddButton from "../../../../Assets/svg/addButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import TableView from "./TableView/TableView";
import AddSiteVisitCardModal from "./Modals/AddSiteVisitCardModal";
import DeletedModal from "./TableView/modals/DeletedModal";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import { get } from "../../../../services/authService";

function VisitBoard() {

  // Handle show/hide Modal for "Add new site visit card" button
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [siteVisitCardsForExcel, setSiteVisitCardsForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");


  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    fetchSiteVisitCards(projectID);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeletedModal = () => {
    setIsDeletedModalVisible(true)
  }
  
  const onChange = (key) => {
    console.log(key);
  };

  const fetchSiteVisitCards = async (projectId, search, implementingPartnerId, country, siteVisitStatus, clsuterId, month, year, IgnorePagination) => {
    try {
      setLoading(true)
      const response = await get(`/api/Site/getAllSiteVisits?pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`,
        {
          ProjectId: projectId,
          Search: search,
          ImplementingPartnerId: implementingPartnerId,
          State: country,
          SiteVisitStatus: siteVisitStatus,
          ClusterId: clsuterId,
          Month: month,
          Year: year,
          IgnorePagination: IgnorePagination
        }
      );
  
      const sitevisitcardsData = response?.data
      setPagination({
        ...pagination,
        total: response.metaData.totalItemCount
      });
      if (sitevisitcardsData.length > 0) {
        setSiteVisitCards(sitevisitcardsData);
      } else {
        setSiteVisitCards([]); 
      }
  
    } catch (error) {
      console.error('Error fetching site visit cards:', error);
    }
    setLoading(false)
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ pageSize, current: pageNumber });
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: <TableView onOk={handleOk} siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards} loading={loading} setLoading={setLoading} setSiteVisitCardsForExcel={setSiteVisitCardsForExcel} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination} />,
    },
    {
      key: "2",
      label: "Kanban view",
      children: <KanbanView siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards} loading={loading} setLoading={setLoading} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination}/>,
    },
    {
      key: "3",
      label: "Calendar view",
      children: <CalendarView  siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards}  loading={loading} setLoading={setLoading}/>,
    },
  ];

  useEffect(() => {
    fetchSiteVisitCards(projectID);
  }, [items.key, pagination.pageSize, pagination.current])
  
  return (
    <div className="ip-planning-wrapper">
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Visits board</span>
        <div className="tableViewHeaderButtons">
          <Button
            className="inviteButtonTPM"
            onClick={showDeletedModal}
          >
            <img src={DeleteButton} alt="DeleteButton"/>
            See deleted cards
          </Button>
          <Button
            className="exportButtonTPM addButtonTPM"
            onClick={() => {
              if (siteVisitCardsForExcel.length !== 0) {
                exportToExcel(siteVisitCardsForExcel, "SiteVisitCards")
              }
            }}
          >
            <img src={ExportButton} alt="ExportButton"/>
            Export selected visits
          </Button>
          
          <Button
            className="addButtonTPM" onClick={showModal}
          >
            <img src={AddButton} alt="AddButton"/>
            Add new site visit card
          </Button>
          <AddSiteVisitCardModal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          />
          <DeletedModal
            visible={isDeletedModalVisible}
            onOk={showDeletedModal}
              onCancel={() => { setIsDeletedModalVisible(false) }}
              fetchSiteVisitCards={fetchSiteVisitCards}
          />
        </div>
      </div>
      <div className="tab-container">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="tableviewTabs"
        />
      </div>
    </div>
    </div>
  );
}

export default VisitBoard;
