import React, { useState } from "react";
import Banner from "../../components/Banner/Banner";
import FieldTeamStatusBoard from "./FieldTeamStatusBoard/FieldTeamStatusBoard";
import IpDonorStatusBoard from "./IpDonorStatusBoard/IpDonorStatusBoard";
import ReportingList from "./ReportingList/ReportingList";
import MessagePopUp from "../../components/Messages/MessagePopUp"

function Reporting() {
  const [activeComponent, setActiveComponent] = useState(
    "FieldTeamStatusBoard"
  );
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  
  const openActiveComponent = (collector) => {
    setActiveComponent(collector);
  };
  const renderComponent = () => {
    if (activeComponent === "FieldTeamStatusBoard") {
      return <div><FieldTeamStatusBoard/></div>;
    } else if (activeComponent === "IpDonorStatusBoard") {
      return <div><IpDonorStatusBoard/></div>;
    } else if (activeComponent === "ReportingList") {
      return <div><ReportingList></ReportingList></div>;
    }
  };

  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner text="Reporting"></Banner>
      <div style={{ background: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          <div>
            <label
              className={
                activeComponent === "FieldTeamStatusBoard"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="FieldTeamStatusBoard"
                checked={activeComponent === "FieldTeamStatusBoard"}
                onChange={() => openActiveComponent("FieldTeamStatusBoard")}
              />
              Field Team Status Board
            </label>
          </div>

          <div>
            <label
              className={
                activeComponent === "IpDonorStatusBoard"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="IpDonorStatusBoard"
                checked={activeComponent === "IpDonorStatusBoard"}
                onChange={() => openActiveComponent("IpDonorStatusBoard")}
              />
              IP/Donor Status Board
            </label>
          </div>
          <div>
            <label
              className={
                activeComponent === "ReportingList"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="ReportingList"
                checked={activeComponent === "ReportingList"}
                onChange={() => openActiveComponent("ReportingList")}
              />
              Reporting List
            </label>
          </div>
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default Reporting;
