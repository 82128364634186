import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Select,Checkbox } from "antd";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../../UserManagement/UserManagement.scss";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { get, post } from "../../../../../services/authService";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg"

const { Option } = Select;

const AddTeamCardModal = ({ visible, onClose, fetchTeams,setMessage }) => {
  const [enumerators, setEnumerators] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [selectedEnumerator, setSelectedEnumerator] = useState(null);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountryForCity, setSelectedCountryForCity] = useState(null);
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedFieldCoordinator, setSelectedFieldCoordinator] = useState(null);
  const [fieldCoordinators, setFieldCoordinators] = useState([]);
  const currentContractId = localStorage.getItem("currentContractId");

  const initialValues = {
    teamCardName: "",
    enumeratorId: null,
    teamLeaderId: null,
    fieldCoordinatorId: null,
    isActive: true,
    countryId: null,
    cityId: null
  };

  const validationSchema = Yup.object().shape({
    teamCardName: Yup.string().required("Please enter team card name"),
    enumeratorId: Yup.string().required("Please select an enumerator"),
    teamLeaderId: Yup.string().required("Please select a team leader"),
    fieldCoordinatorId:Yup.string().required("Please select a field coordinator"),
    countryId: Yup.string().required("Please select a country"),
    cityId: Yup.string().required("Please select a city"),
  });
  

  const resetForm = () => {
    setSelectedEnumerator(null);
    setSelectedTeamLeader(null);
    setSelectedFieldCoordinator(null);
  };

  useEffect(() => {
    const fetchEnumerators = async () => {
      try {
        const res = await get(`/api/User/getAllEnumerators?ContractId=${currentContractId}`);
        setEnumerators(res.data);
      } catch (error) {
        console.error("Error fetching enumerators:", error);
      }
    };

    const fetchTeamLeaders = async () => {
      try {
        const res = await get(`/api/User/getAllTeamLeadersByContract?contractId=${currentContractId}`);
        setTeamLeaders(res.data);
      } catch (error) {
        console.error("Error fetching team leaders:", error);
      }
    };


    const fetchFieldCoordinators = async () => {
      try {
        const res = await get(`/api/User/getAllusers?contractIds=${currentContractId}`);
        const filteredFieldCoordinators = res.data.filter(user =>
          user.userContractRoles.some(role => role.role.roleName === "Field Coordinator")
        );
        setFieldCoordinators(filteredFieldCoordinators);
      } catch (error) {
        console.error("Error fetching field coordinators:", error);
      }
    };
    fetchFieldCoordinators();
    fetchEnumerators();
    fetchTeamLeaders();

  }, [currentContractId]);

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllCountries(res.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedCountryForCity) {
      get(`/api/GeoData/cities?countryId=${selectedCountryForCity}`)
        .then((res) => {
          setCity(res.data);
        })
        .catch((error) => console.log(error));
    } else {
      setCity([]);
      setSelectedCity(null);
    }
  }, [selectedCountryForCity]);

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      name: values.teamCardName,
      contractId: currentContractId,
      memberIds: [values.enumeratorId, values.teamLeaderId,values.fieldCoordinatorId],
      isActive: values.isActive,
      countryId: values.countryId,
      cityId: values.cityId
    };

    post("/api/User/createTeam", payload)
      .then((response) => {
        setSubmitting(false);
        onClose();
        fetchTeams(currentContractId);
        resetForm(); 
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Team card added successfully!",
          text: "The Team card has been added to your database!",
        });
        setTimeout(() => {
          setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
        }, 3000); 
      })
      .catch((error) => {
        setMessage({
          trigger: true,
          type: "error",
          icon: AddMemberIcon,
          title: "Failed to add the team card",
          text: error.message,
        });
        setTimeout(() => {
          setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
        }, 3000); 
        setSubmitting(false);
      });
  };

  const CustomCheckboxGroup = ({ options, onChange, selectedValue, id }) => {
    return (
      <div>
        {options.map((option) => (
          <div key={option.id} style={{ marginBottom: "14px" }}>
            <Checkbox
              className="custom-checkbox"
              checked={selectedValue === option.id}
              onChange={() => onChange(option.id)}
            >
              <div style={{
                fontFamily: 'Lato', fontWeight: 400, fontSize: '14px', lineHeight: '22px',
                 color: selectedValue === option.id ? '#AA1A5F' : '#777777'
              }}>
              {option.firstName && option.lastName
                ? `${option.firstName} ${option.lastName}`
                : option.userName || option.email} ({id})
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={() => {
        onClose();
        resetForm(); 
      }}
      footer={false}
      width={800}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => {
            onClose();
            resetForm(); 
          }}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Enumerator Team Card</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, handleChange, values }) => (
          <FormikForm layout="vertical">
            <p className="modalsHeader1">General details</p>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Team card name
              </label>
              <Input
                name="teamCardName"
                onChange={handleChange}
                placeholder="Enter team card name"
                autoComplete="off"
              />
              <ErrorMessage
                name="teamCardName"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Country
              </label>
              <Select
                name="countryId"
                showSearch
                allowClear
                placeholder="Select a country"
                onChange={(value) => {
                  setFieldValue("countryId", value);
                  setSelectedCountryForCity(value);
                }}
                value={values.countryId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {allCountries.map((c) => (
                  <Option key={c.id} value={c.id}>{c.stateName}</Option>
                ))}
              </Select>
              <ErrorMessage name="countryId" component="div" className="error-message" />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>City
              </label>
              <Select
                disabled={!selectedCountryForCity}
                placeholder="Select a city"
                showSearch
                allowClear
                onChange={(value) => {
                  setFieldValue("cityId", value);
                  setSelectedCity(value);
                }}
                value={values.cityId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {city.map((c) => (
                  <Option key={c.id} value={c.id}>{c.name}</Option>
                ))}
              </Select>
              <ErrorMessage name="cityId" component="div" className="error-message" />
            </div>
            <div style={{ paddingBottom: "20px" }}>
              <label style={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '16px', lineHeight: '22px' }}>
                Select your team members
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <CustomCheckboxGroup
        name="fieldCoordinatorId"
        id="Field Coordinator"
          options={fieldCoordinators}
          selectedValue={selectedFieldCoordinator?.id}
          onChange={(value) => {
            const selected = fieldCoordinators.find(fc => fc.id === value);
            setSelectedFieldCoordinator(selected);
            setFieldValue("fieldCoordinatorId", value);
          }}
        />
        <ErrorMessage name="fieldCoordinatorId" component="div" style={{ color: "red" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
             <CustomCheckboxGroup
              name="enumeratorId"
             id="Enumerator"
               options={enumerators}
               selectedValue={selectedEnumerator?.id}
               onChange={(value) => {
                 const selected = enumerators.find(e => e.id === value);
                 setSelectedEnumerator(selected);
                 setFieldValue("enumeratorId", value);
               }}
             />
        <ErrorMessage name="enumeratorId" component="div" style={{ color: "red" }} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px",paddingBottom:"20px"}}>
        <CustomCheckboxGroup
        name="teamLeaderId"
        id="Team Leader"
          options={teamLeaders}
          selectedValue={selectedTeamLeader?.id}
          onChange={(value) => {
            const selected = teamLeaders.find(tl => tl.id === value);
            setSelectedTeamLeader(selected);
            setFieldValue("teamLeaderId", value);
          }}
        />
        <ErrorMessage name="teamLeaderId" component="div" style={{ color: "red" }} />
      </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <div
                style={{ borderRadius: '6px', padding: '1px 0px 1px 10px', gap: '10px', background: "#FADCEA" }}>
              <h1 style={{ fontFamily: 'Lato', fontWeight: 400, fontSize: '14px', lineHeight: '22px',margin:0 }}>
               Note: You can only choose  
            <span style={{ fontWeight: 900 }}> one </span>field coordinator.
             </h1>
          </div>
            </div>
            <div className="buttonsModal">
              <Button
                className="inviteButtonTPM"
                key="cancel"
                onClick={() => {
                  onClose();
                  resetForm(); 
                }}
              >
                Cancel
              </Button>
              <Button
                className="addButtonTPM"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTeamCardModal;
