import React, { useState,useEffect } from "react";
import "../Reporting.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import TableViewReporting from "./TableView/TableView";
import KanbanViewReporting from "./KanbanView/KanbanView";
import CalendarViewReporting from "./CalendarView/CalendarView";
import { get, put } from "../../../../services/authService";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";

function FieldTeamStatusBoard() {
  const [data, setData] = useState([]);
  const [dataForExcel, setDataForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [filters, setFilters] = useState({
    ImplementingPartnerId: '',
    State: '',
    SiteVisitStatus: null,
    FieldTeamReportingStatus:null,
    ClusterId: '',
    Month: '',
    Year: '',
    Search: ''
  });

  const fetchSiteVisits = async () => {
    setLoading(true);
    try {
      const response = await get(`/api/Site/getAllSiteVisits?ProjectId=${projectID}&pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`, { 
        ImplementingPartnerId: filters.ImplementingPartnerId,
        State: filters.State,
        SiteVisitStatus: filters.SiteVisitStatus,
        FieldTeamReportingStatus: filters.FieldTeamReportingStatus,
        ClusterId: filters.ClusterId,
        Month: filters.Month,
        Year: filters.Year,
        Search: filters.Search,
       });
      setData(response.data);
      setPagination({
        ...pagination,
        total: response.metaData.totalItemCount
      });
    } catch (error) {
      console.error("Error fetching site visits:", error);
    }
    setLoading(false);
  };


  useEffect(() => {
    fetchSiteVisits();
  }, [filters,pagination.pageSize, pagination.current]);

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };


  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const changeSiteVisitCardStatus = (draggedTask) => {
    const trackingId = draggedTask.id
    const siteVisitStatus = Number(draggedTask.column)

    setLoading(true);

    put(`/api/Site/changeSiteVisitStatus?TrackingId=${trackingId}&SiteVisitStatus=${siteVisitStatus}`)
      .then((response) => {
        console.log("Response:", response);
        fetchSiteVisits(
          projectID,
          filters?.pageSize,
          filters?.current,
          filters.Search,
          filters.ImplementingPartnerId,
          filters.State,
          filters.SiteVisitStatus,
          filters.ClusterId,
          filters.Month,
          filters.Year
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });

    setTimeout(() => {
    }, 3000);
  };

  const columns = [
    { id: 1, title: "Data Collection", color: "#AA1A5F" },
    { id: 2, title: "Data Check", color: "#FC9403" },
    { id: 3, title: "Translation", color: "#325899" },
    { id: 4, title: "Field Reporting Completed", color: "#3B81FE" },
  ];

  // format data for Kanban view
    const formatData = (siteVisitCard) => {
      return {
        id: siteVisitCard?.id,
        content: {
          title: siteVisitCard?.identifier,
          statusUpdated: siteVisitCard?.createdDate,
          ip: siteVisitCard?.implementingPartner?.name,
          state: siteVisitCard?.locationDetails?.country,
          municipality: siteVisitCard?.locationDetails?.municipality,
        },
        column: siteVisitCard?.tracking?.siteVisitStatus,
      };
    };
    
    const formattedData = data.map(formatData);


  
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: <TableViewReporting data={data} handleFilterChange={handleFilterChange} loading={loading} filters={filters} setFilters={setFilters} setDataForExcel={setDataForExcel} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination} />,
    },
    {
      key: "2",
      label: "Kanban view",
      children: <KanbanViewReporting tasks={formattedData} columns={columns} changeStatus={changeSiteVisitCardStatus} handleFilterChange={handleFilterChange} setFilters={setFilters} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination}/>,
    },
    {
      key: "3",
      label: "Calendar view",
      children: <CalendarViewReporting data={data}  filters={filters} handleFilterChange={handleFilterChange} setFilters={setFilters} onPageChange={onPageChange} pagination={pagination} setPagination={setPagination}/>,
    },
  ];

  return (
    

    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Field Team Status Board</span>
        <div className="tableViewHeaderButtons">
          <Button
            className="exportButtonTPM addButtonTPM"
            onClick={() => {
              if (dataForExcel.length !== 0) {
                exportToExcel(dataForExcel, "ReportingSiteVisits")
              }
}}
          >
            <img src={ExportButton} alt=""/>
            Export selected visits
          </Button>
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default  FieldTeamStatusBoard;
