import React, { useState } from "react";
import CalendarViewComponent from "../../../../components/CalendarView/CalendarViewComponent";
import Filters from "../TableView/Filters";
import { Pagination } from "antd";
import PreviewModal from "../../../planning/modals/PreviewModal";

const CalendarViewIpDonor = ({ handleFilterChange, setFilters, data, filters, onPageChange, pagination,setPagination }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState()


  const handleEventClicked = (eventId) => {
    setClickedEventId(eventId);
    if (eventId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  const events = data.reduce((acc, ipPlan) => {
    if (!ipPlan) return acc;

    const dateKey = ipPlan.initialMeeting?.split("T")[0];
    if (dateKey) {
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push({
        type: "success",
        status: "test",
        content: ipPlan.identifier,
        id: ipPlan.id
      });
    }

    return acc;
  }, {});

  const getListData = (value) => {
    const dateKey = value.format('YYYY-MM-DD');
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter(event => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (filters.search && !event.content.toLowerCase().includes(filters.search.toLowerCase())) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  return (
    <>
    <PreviewModal
          visible={showPreviewModal}
          record={clickedEventId}
          onOk={handlePreviewOk}
          onCancel={handleCancel}
        />
      <Filters handleFilterChange={handleFilterChange} setFilters={setFilters} />
      <CalendarViewComponent getListData={getListData} setClickedEventId={handleEventClicked} />
      <div className="d-flex justify-content-end my-5">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
              />
          </div>
    </>
  );
};

export default CalendarViewIpDonor;
