import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Switch, Select } from "antd";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import { AimOutlined, SearchOutlined, MapMarker } from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { OpenStreetMapAutocomplete } from "@amraneze/osm-autocomplete";
import "./LocationDetails.scss";

import { Option } from "antd/es/mentions";

const LocationMarker = ({ position }) => {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  React.useEffect(() => {
    if (position) {
      map.setView(position, 13);
    }
  }, [position, map]);

  return position === null ? null : (
    <Marker
      position={position}
      icon={
        new Icon({
          iconUrl: markerIconPng,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })
      }
    />
  );
};

const LocationDetails = ({ form }) => {
  const [isPostponed, setIsPostponed] = useState(false);
  // const [form] = Form.useForm();
  const [position, setPosition] = React.useState([0, 0]);
  const [geoLocation, setGeoLocation] = useState(null);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, accuracy } = position.coords;
          form.setFieldsValue({
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
            accuracy: accuracy.toFixed(2),
          });
          setPosition([latitude, longitude]);
          setGeoLocation({
            lat: "0",
            lon: "0",
            type: "0",
            class: "0",
            osm_id: 0,
            licence: "0",
            osm_type: "0",
            place_id: 0,
            importance: 0,
            display_name: "",
            boundingbox: [],
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const selectLocation = (data) => {
    const { lat, lon } = data;
    if (data) {
      form.setFieldsValue({
        latitude: parseFloat(lat).toFixed(6),
        longitude: parseFloat(lon).toFixed(6),
        accuracy: 0,
      });
      setPosition([parseFloat(lat), parseFloat(lon)]);
    }
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Latitude (x°)" name="latitude">
                <Input type="number" placeholder="Enter latitude value" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Longitude (y°)" name="longitude">
                <Input type="number" placeholder="Enter longitude value" />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item label="Altitude (m)" name="altitude">
                <Input type="number" placeholder="Enter altitude value" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Accuracy (m)" name="accuracy">
                <Input type="number" placeholder="Enter accuracy value" />
              </Form.Item>
            </Col> */}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item label="Search" name="search">
                {/* <Input.Search placeholder="Search something here" /> */}
                {/* <Search
                    inputPlaceholder="Search something here"
                    zoom={7}
                    closeResultsOnClick={true}
                    openSearchOnLoad={false}
                  /> */}
                <div
                  style={{ position: "relative", zIndex: 2 }}
                  className="osm-search"
                >
                  {/* <GooglePlacesAutocomplete
                      selectProps={{
                        value,
                        onChange: setValue,
                      }}
                    /> */}
                  <OpenStreetMapAutocomplete
                    value={geoLocation}
                    onChange={(val) => selectLocation(val)}
                  />
                  ,
                </div>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button
                  onClick={handleGetCurrentLocation}
                  style={{ marginTop: 30 }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <AimOutlined style={{ color: "#999" }} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div
                style={{
                  height: "400px",
                  width: "100%",
                  position: "relative",
                  zIndex: 0,
                }}
              >
                <MapContainer
                  center={position}
                  zoom={13}
                  style={{ height: 400, width: "100%" }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <LocationMarker position={position} />
                </MapContainer>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col
          span={12}
          style={{
            marginTop: "-110px",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {/* <Button
              className="clearFieldsBtn"
              onClick={() => form.resetFields()}
              style={{ marginBottom: 16 }}
            >
              Clear all fields
            </Button> */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Form.Item
              name="isVirtual"
              style={{ marginBottom: "0" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <span>This visit will be virtual</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LocationDetails;
