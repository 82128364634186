import React, { useState, useEffect } from 'react'
import { Select, Input, Button,DatePicker } from "antd";
import { get } from '../../../../../services/authService';
import dayjs from "dayjs"

const { Option } = Select;

const Filters = ({handleFilterChange, setFilters}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [clearButtonClick, setClearButtonClick] = useState(0);
   
  
  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const contractId = localStorage.getItem("currentContractId")
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${contractId}`;
    get(apiIps)
    .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
    
      const apiCountries = "/api/GeoData/countries";

      get(apiCountries)
        .then((res) => setAllCountries(res.data))
        .catch((error) => console.log(error));
  },[])



  const handleClearAll = () => {
    setClearButtonClick((prevClick) => prevClick + 1);
    setFilters({
      search: "",
      implementingPartner: [],
      currentIpStatus: "",
      ipDonorReportingStatus: [],
      month: [],
      year: [],
    });
  };
  return (
    <div className="filtersWrapper">
    <div className="containerFilters">
        <Input placeholder="Search a visit by its name" onKeyUp={(e) => handleFilterChange("Search", e.target.value.toLowerCase())}
              key={`search-input-${clearButtonClick}`}
        />
      <Button className="clearButton" onClick={handleClearAll}>Clear All</Button>
      <Button onClick={handleToggleFilters} className="toggleButton">
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
    </div>
    {showFilters && (
      <div className="filtersContainer">
        <div className="filter">
          <p className="filterText">Implementing Partner</p>
            <Select
              key={`select-input-${clearButtonClick}`}
            filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select the IP"
            allowClear
            onChange={(value) => handleFilterChange("ImplementingPartnerId", value)}
          >
            {implementingPartners.map((partner) => (
              <Option key={partner.id} value={partner.id}>{partner.name}</Option>
            ))}
          </Select>
        </div>
        {/* <div className="filter">
          <p className="filterText">Current IP status</p>
          <Select
            filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select an IP status"
            allowClear
            onChange={(value) => handleFilterChange("IPStatus", value)}
          >
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </div>
        <div className="filter">
          <p className="filterText">IP/donor reporting status</p>
          <Select
            filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select an IP/Donor reporting status"
            allowClear
            mode="multiple"
            onChange={(value) => handleFilterChange("ipDonorReportingStatus", value)}
          >
            <Option value="inProgress">In Progress</Option>
            <Option value="completed">Completed</Option>
            <Option value="pending">Pending</Option>
          </Select>
        </div> */}
        {/* <div className="filter">
          <p className="filterText">Location</p>
          <Select
            filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select a country"
            allowClear
            onChange={(value) => handleFilterChange("location", value)}
            mode="multiple"
          >
            {allCountries.map((country) => (
              <Option key={country.id} value={country.id}>{country.name}</Option>
            ))}
          </Select>
        </div> */}
        <div className="filter">
          <p className="filterText">Month</p>
            <DatePicker
              key={`date-input-${clearButtonClick}`}
            picker="month"
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select a month"
            onChange={(date, dateString) => handleFilterChange("Month", dateString ? dayjs(date).month() + 1 : "")}
            format="MMMM"
          />
        </div>
        <div className="filter">
          <p className="filterText">Year</p>
            <DatePicker
              key={`date-input-${clearButtonClick}`}
            picker="year"
            style={{ width: 200, marginBottom: 20 }}
            placeholder="Select a year"
            onChange={(date, dateString) => handleFilterChange("Year", dateString ? dayjs(date).year() : [])}
          />
        </div>
      </div>
    )}
  </div>
  )
}

export default Filters