import { Button, Modal, Table, notification } from "antd";
import { useState, useEffect } from "react";
import { get, del } from "../../../../../services/authService";
import { taskTypes } from "../taskTypes";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import { RevertButton } from "../../../../../Assets/svg";
import { httpService } from "../../../../../services/httpService.service";
import { useTaskStore } from "../store/taskStore";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";

const DeletedModal = ({ visible, onOk, onCancel }) => {
  const { fetchTaskss, fetchAllTasks } = useTaskStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (pagination) => {
    setLoading(true);
    try {
      const projectId = localStorage.getItem("currentProjectID");
      if (!projectId) {
        throw new Error("Project ID is not available");
      }

      const url = `/api/Tasks/getAllTasks?ProjectId=${projectId}&GetDeletedOnly=true&page=${pagination.current}&pageSize=${pagination.pageSize}`;
      const result = await get(url);
      if (!result || !result.data || !result.metaData) {
        throw new Error("API response is missing expected fields.");
      }
      const tasksWithKeys = result.data.map((task) => ({
        ...task,
        key: task.id,
        assignedTo: task.taskUsers?.length > 0 ? task.taskUsers[0] : null,
      }));
      setData(tasksWithKeys);
      setPagination({
        ...pagination,
        total: result.metaData.totalItemCount,
      });
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchData(pagination);
    }
  }, [visible]);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const projectId = localStorage.getItem("currentProjectID");
      const params = {
        ProjectId: projectId,
      };
      await httpService.get("/api/Tasks/getAllTasks", { ...params });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const deleteTask = async (taskId) => {
    setLoading(true);
    try {
      const url = `/api/Tasks/deleteTasks?includeIsDeleted=true`;
      const data = JSON.stringify([{ id: taskId, isDeleted: true }]);
      await del(url, data);
      fetchData(pagination);
      fetchTasks();
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete task:", error);
      setLoading(false);
    }
  };

  const recoverData = async (id) => {
    setLoading(true);
    await httpService.put(
      `/api/Site/revertDelete`,
      {
        entityType: 12,
        ids: [id],
      },
      (res) => {
        const message =
          res.message || "Tasks are successfully reverted from soft deletion";
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Trip logistic reverted successfully!",
          text: message,
        });
        fetchData(pagination);
        fetchTasks();
        fetchAllTasks(true);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      },
      (error) => {
        const errorMessage =
          error.response?.data?.errors[0]?.message ||
          "Failed to recover visit Tasks.";

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination);
  };

  const columns = [
    {
      title: "Task Identifier",
      dataIndex: ["taskSite", "identifier"],
      key: "identifier",
      render: (text, record) =>
        `${record.name} - ${record.type} - ${record.deadline}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const statusMap = {
          1: "Assigned",
          2: "Accepted",
          3: "Completed",
          4: "On hold",
          5: "Archived",
        };
        const statusText = statusMap[text] || "Unknown Status";
        let className;
        let fill;

        if (statusText === "Archived") {
          className = "archived";
          fill = "#555555";
        } else if (statusText === "Completed") {
          className = "completed";
          fill = "#0E9349";
        } else if (statusText === "On hold") {
          className = "onHold";
          fill = "#D46600";
        } else if (statusText === "Accepted") {
          className = "accepted";
          fill = "#3B81FE";
        } else if (statusText === "Assigned") {
          className = "assigned";
          fill = "#FC9403";
        }

        return (
          <div
            className={className}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{statusText}</span>
          </div>
        );
      },
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      width: 250,
    },
    {
      title: "Assigned to",
      dataIndex: "taskUsers",
      key: "taskUsers",
      render: (taskUsers) => {
        if (taskUsers && taskUsers.length > 0) {
          return taskUsers.map((user) => user.userName).join(", ");
        }
        return "Unassigned";
      },
    },
    {
      title: "Location",
      dataIndex: "taskLocation",
      key: "location",
      render: (location) =>
        `${location?.city?.name}, ${location?.country?.stateName}`,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (typeId) => {
        const taskType = taskTypes.find((task) => task.id === typeId);
        return taskType ? taskType.name : "Unknown Type";
      },
    },
    {
      title: "",
      key: "actions",
      width: 50,
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            onClick={() => deleteTask(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={deleteIcon} alt="delete" />
          </Button>
          <Button
            onClick={() => recoverData(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={RevertButton} alt="RevertButton" />
          </Button>
        </span>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Modal
        title="Deleted task allocation"
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={[]}
        width={"70%"}
      >
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey="key"
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </div>
  );
};

export default DeletedModal;
