import React from "react";
import "./IpSummary.scss";
import { Button } from "antd";

function ResourceSection({ projectData }) {
  const reports = projectData?.data?.resourceSection?.uploadedReports || [];
  const documents = projectData?.data?.resourceSection?.uploadedDocuments || [];

  return (
    <div style={{ width: "100%" }}>
      <div className="resourceSection">
        <h2 className="ipCardName">Resource section</h2>
        <div className="resourceReport">
          <div className="resourceReportCard">
            <h2 className="ipCardName">Last 6 reports uploaded</h2>
            <div className="resourceReportList">
              {reports.slice(0, 6).map((report, index) => (
                <div key={index} className="reportItem">
                  <div className="ipCardTextName">
                    <span className="boldText">{report.name}</span>
                  </div>
                  <div className="ipCardTextName">
                    Status: <span className="boldText">{report.reportStatus}</span>
                  </div>
                  <div className="ipCardTextName">
                    Location: <span className="boldText">{report.location}</span>
                  </div>
                  <div className="ipCardTextName">
                    Date: <span className="boldText">{new Date(report.createdDate).toLocaleDateString()}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="resourceReportCard">
            <h2 className="ipCardName">Last 6 documents uploaded</h2>
            <div className="resourceReportList">
              {documents.slice(0, 6).map((document, index) => (
                <div key={index}>
                  <span className="ipCardTextName">
                    <span className="boldText">{document.fileName}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceSection;
