import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  if (!token) return true;

  if (token) {
    const { exp } = jwtDecode(token);
    if (!exp) return true;
    const currentTime = Math.floor(Date.now() / 1000);
    return exp < currentTime;
  } else {
    return false;
  }
};
