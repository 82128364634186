import { Button, Select } from "antd";
import React, { useState,useEffect } from "react";
import LogButton from "../../../../Assets/svg/logButton.svg";
import TeamButton from "../../../../Assets/svg/teamButton.svg";
import AddButton from "../../../../Assets/svg/addButton.svg";
import "./FieldLogisticsBoard.scss";
import VisitCards from "./header/VisitCards";
import { VisitCardFilters } from "./header/VisitCardFilters";
import TripLogistics from "./TripLogistics/TripLogistics";
import TeamCards from "./TeamCards/TeamCards";
import DriverCards from "./DriverCards/DriverCards";
import AddTeamCardModal from "./modals/AddTeamCardModal";
import AddDriverCardModal from "./modals/AddDriverCardModal";
import TripLogCardModal from "./modals/TripLogCardModal";
import { get } from "../../../../services/authService";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

function FieldLogisticsBoard() {
  const { Option } = Select;

  const [page, setPage] = useState("Trip logistics cards");
  const pageOptions = [
    "Trip logistics cards",
     "Team cards", 
    //  "Driver cards"
    ];
  const [showAddTeamCardModal, setShowAddTeamCardModal] = useState(false);
  const [showAddDriverCardModal, setShowAddDriverCardModal] = useState(false)
  const [showTripLogCardModal, setShowTripLogCardModal] = useState(false);
  const [showAllTripData, setShowAllTripData] = useState([]);
  const [siteVisits, setSiteVisits] = useState([]); 
  const [teams,setTeams] = useState([])
  const [cards, setCards] = useState([]);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    status: null,
    country: "",
    city:"",
    search: ''
  });
  const contractId = localStorage.getItem("currentContractId");
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [loadingTripLogistic, setLoadingTripLogistic] = useState(false);



  const fetchTripLogistics = async () => {
    setLoadingTripLogistic(true);
    try {
      const response = await get(`/api/TripLogistic/getAllTripLogistics?ProjectId=${projectID}&pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`, {
          StartDate: filters.startDate,
          EndDate: filters.endDate,
          Status: filters.status,
          Search: filters.search,
          Country: filters.country,
          City: filters.city
        },
      );
  
      const tripLogistics = response?.data || [];
      setShowAllTripData(tripLogistics);
      setPagination({
        ...pagination,
        total: response.metaData.totalItemCount
      });
    setLoadingTripLogistic(false);
    } catch (error) {
      console.error('Error fetching trip logistics:', error);
    }
    finally{
    setLoadingTripLogistic(false);
    }
  };


  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };
  
  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const fetchTeams = (contractId) => {
    httpService.get(
      `/api/User/getAllTeams?contractId=${contractId}`,
      (data) => {
        const teamsData = data?.data;
        setTeams(teamsData);
      },
      (error) => {
        console.error('Error fetching teams:', error);
      }
    );
  };
  
  
  useEffect(() => {
    fetchTeams(contractId); 
  },[page])

  const fetchSiteVisits = async (filters) => {
    try {
      const params = {
        ProjectId: projectID,
      };
  
      if (filters.monthYear && filters.monthYear.isValid && filters.monthYear.isValid()) {
        params.Month = filters.monthYear.format('MM');
        params.Year = filters.monthYear.format('YYYY');
      }
  
      if (filters.state) {
        params.State = filters.state;
      }
  
      const queryParams = new URLSearchParams(params).toString();
  
      const response = await get(`/api/Site/getAllSiteVisits?${queryParams}`);
      const siteVisitsData = response?.data || [];
      
      const filteredSiteVisits = filters.state
        ? siteVisitsData.filter(visit => visit.locationDetails.country === filters.state)
        : siteVisitsData;
  
      setSiteVisits(filteredSiteVisits);
      const transformedCards = filteredSiteVisits.map((visit) => ({
        id: visit?.id,
        title: visit?.identifier,
        statusUpdated: new Date(visit?.createdDate).toLocaleDateString(),
        ip: visit?.ipPlan?.implementingPartner?.name,
        state: visit?.locationDetails?.country,
        municipality: visit?.locationDetails?.municipality,
      }));
      setCards(transformedCards);
    } catch (error) {
      console.error('Error fetching site visits:', error);
    }
  };
  
  useEffect(() => {
    fetchSiteVisits(filters);
  }, [filters]);


  const handleCloseTripLogCardModalModal = () =>{
    setShowTripLogCardModal(!showTripLogCardModal)
  }

  const handleCloseAddTeamCardModal = () =>{
    setShowAddTeamCardModal(!showAddTeamCardModal)
  }

  const handleCloseAddDriverCardModal = () =>{
    setShowAddDriverCardModal(!showAddDriverCardModal)
  }

  

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  return (
    <div className="FieldLogisticsBoard">
       <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="headerContainer">
        <div className="tableHeader">
          <span className="tableViewHeaderText">Field Logistics Board</span>
          <div className="tableViewHeaderButtons">
            <Button
              className="inviteButtonTPM"
            onClick={() => {
              setShowTripLogCardModal(true);
            }}
          >
              <img src={LogButton} alt="LogButton"/>
              Create Trip Log Card
            </Button>
            <Button
              className="exportButtonTPM addButtonTPM"
              onClick={() => {
                setShowAddTeamCardModal(true);
              }}
            >
              <img src={TeamButton} alt="TeamButton"/>
              Create Team Card
            </Button>
            <Button
            disabled
              style={{ pointerEvents:"none" }}
              className="addButtonTPM"
              onClick={() => {
                setShowAddDriverCardModal(true);
              }}
            >
              <img src={AddButton} alt="AddButton"/>
              Create Driver Card
            </Button>
            <TripLogCardModal
              fetchTripLogistics={fetchTripLogistics}
              visible={showTripLogCardModal}
              onClose={handleCloseTripLogCardModalModal}
              siteVisits={siteVisits}
              setMessage={setMessage}
            />
            <AddTeamCardModal
              visible={showAddTeamCardModal}
              onClose={handleCloseAddTeamCardModal}
              fetchTeams={fetchTeams}
              setMessage={setMessage}
            />
            <AddDriverCardModal
            visible={showAddDriverCardModal}
            onClose={handleCloseAddDriverCardModal}
          />
          </div>
        </div>
        <div>
          <div className="headerSubContainer">
            <span className="tableViewHeaderText">
              Most recent site visit cards
            </span>
            <VisitCardFilters onFilterChange={ handleFilterChange} />
            <VisitCards cards={cards} />
          </div>
        </div>
      </div>
      <div className="logisticsController">
        <h1>Select the logistic cards you want to review</h1>
        <Select
          style={{ width: 200, marginBottom: 20 }}
          placeholder="Select logistic cards"
          onChange={handlePageChange}
          value={page}
        >
          {pageOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>
      {page === "Trip logistics cards" ? <TripLogistics showAllTripData={showAllTripData} fetchTripLogistics={fetchTripLogistics} siteVisits={siteVisits} page={page} pagination={pagination} onPageChange={onPageChange} setPagination={setPagination} loadingTripLogistic={loadingTripLogistic} handleFilterChange={handleFilterChange} filters={filters} setFilters={setFilters} /> : null}
      {page === "Team cards" ? <TeamCards teams={teams} fetchTeams={fetchTeams} /> : null}
      {/* {page === "Driver cards" ? <DriverCards/> : null} */}
    </div>
  );
}

export default FieldLogisticsBoard;
