import React from "react";
import TableView from "./TableView/TableView";

function DriverCards() {




  return (
    

    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Driver Cards</span>
      </div>
      <TableView/>
    </div>
  );
}

export default DriverCards;
