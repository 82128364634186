import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Button, Flex, Spin, Tabs, Tooltip } from "antd";
import edit from "../../../Assets/svg/table-action-edit.svg";
import preview from "../../../Assets/svg/table-action-preview.svg";
import icon1 from "../../../Assets/svg/projectDashboardIcon1.svg";
import icon2 from "../../../Assets/svg/projectDashboardIcon2.svg";
import icon3 from "../../../Assets/svg/projectDashboardIcon3.svg";
import action1 from "../../../Assets/Images/quickAction1.png";
import action2 from "../../../Assets/Images/quickAction2.png";
import action3 from "../../../Assets/Images/quickAction3.png";
import action4 from "../../../Assets/Images/quickAction4.png";
import TpmSummary from "./TPMsummary/TpmSummary";
import IpSummary from "./IPsummary/IpSummary";
import EditProject from "../projects/EditProject";
import PreviewProject from "../projects/PreviewProject";
import { get } from "../../../services/authService";
import AddSiteVisitCardModal from "../../pages/planning/VisitBoard/Modals/AddSiteVisitCardModal";
import AddNewTaskModal from "../planning/TasksAssignments/Modals/AddNewTaskModal";
import AddIpPlan from "../planning/modals/AddModal";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import AddRedFlagCardModal from "../Flags/Modals/AddRedFlagCardModal";

const { TabsProps } = Tabs;

function Dashboard() {
  const [activeComponent, setActiveComponent] = useState("TPMsummary");
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showPreviewProjectModal, setShowPreviewProjectModal] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [reloadKey, setReloadKey] = useState(0); // State variable to trigger re-render
  const [isModalVisibleSiteVisit, setIsModalVisibleSiteVisit] = useState(false);
  const [isModalVisibleAddTask, setIsModalVisibleAddTask] = useState(false);
  const [isModalVisibleAddFlag, setIsModalVisibleAddFlag] = useState(false);
  const [isModalVisibleAddModal, setIsModalVisibleAddModal] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const showModalSiteVist = () => {
    setIsModalVisibleSiteVisit(true);
  };

  const showModalAddFlag = () => {
    setIsModalVisibleAddFlag(true);
  };
  const showModalEditModal = () => {
    setIsModalVisibleAddModal(true);
  };

  const showModalAddTask = () => {
    setIsModalVisibleAddTask(true);
  };

  const handleOk = () => {
    setIsModalVisibleSiteVisit(false);
  };

  const handleCancel = () => {
    setIsModalVisibleSiteVisit(false);
    setIsModalVisibleAddTask(false);
    setIsModalVisibleAddFlag(false);
    setIsModalVisibleAddModal(false);
  };

  const openActiveComponent = (collector) => {
    setActiveComponent(collector);
  };

  const renderComponent = () => {
    if (activeComponent === "TPMsummary") {
      return <TpmSummary></TpmSummary>;
    } else if (activeComponent === "IPsummary") {
      return <IpSummary></IpSummary>;
    }
  };

  const handleAddedIpPlan = () => {
    //reload the dashboard
  };

  const handleSubmit = () => {
    setReloadKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectID = localStorage.getItem("currentProjectID");
        if (projectID) {
          const apiProject = `/api/Projects?id=${projectID}`;
          const results = await get(apiProject);
          setProjectData(results.data);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };
    fetchProjectData();
  }, [reloadKey]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract parts of the date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return formatted date string
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const maxDescriptionLength = 100;
  const truncatedDescription =
    projectData?.description?.length > maxDescriptionLength
      ? `${projectData?.description.slice(0, maxDescriptionLength)}...`
      : projectData?.description;

  return (
    <div>
      {!projectData ? (
        <div
          style={{
            height: "calc(100vh - 130px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <MessagePopUp
            trigger={message.trigger}
            type={message.type}
            icon={message.icon}
            messageTitle={message.title}
            messageText={message.text}
          />
          <div className="projectName">
            <div className="projectContent">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip title={`Project Name: ${projectData.projectName}`}>
                  <h2 className="projectNameText">{projectData.projectName}</h2>
                </Tooltip>
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    className="projectDashboardButton"
                    onClick={() => {
                      setShowPreviewProjectModal(true);
                    }}
                  >
                    <img src={preview}></img>
                  </Button>
                  <Button
                    className="projectDashboardButton"
                    onClick={() => {
                      setShowEditProjectModal(true);
                    }}
                  >
                    <img src={edit}></img>
                  </Button>
                </div>
              </div>

              <Tooltip title="Project Description">
                <p className="projectNameDescription">{truncatedDescription}</p>
              </Tooltip>
            </div>
            <div className="projectTags">
              <div className="projectTagDesign">
                <Tooltip
                  title={`Clients: ${projectData?.clients
                    .map((client) => client.name)
                    .join(", ")}`}
                >
                  {projectData?.clients.map((client, index) => (
                    <React.Fragment key={client.id}>
                      <img
                        src={icon1}
                        alt={`Icon ${index + 1}`}
                        style={{ marginRight: "8px" }}
                      />
                      {client.name}
                      {index !== projectData.clients.length - 1 && (
                        <span>, </span>
                      )}
                    </React.Fragment>
                  ))}
                </Tooltip>
              </div>
              <div className="projectTagDesign">
                <Tooltip title={`Project Name: ${projectData.projectName}`}>
                  <img
                    src={icon2}
                    alt="Icon 2"
                    style={{ marginRight: "8px" }}
                  />
                  {projectData.projectName}
                </Tooltip>
              </div>
              <div className="projectTagDesign">
                <Tooltip
                  title={`Updated Date: ${
                    projectData.updatedDate
                      ? formatDate(projectData.updatedDate)
                      : formatDate(projectData.createdDate)
                  }`}
                >
                  <img
                    src={icon3}
                    alt="Icon 3"
                    style={{ marginRight: "8px" }}
                  />
                  {projectData.updatedDate
                    ? formatDate(projectData.updatedDate)
                    : formatDate(projectData.createdDate)}
                </Tooltip>
              </div>
            </div>
          </div>
          <div style={{ padding: "24px", background: "#F6F6F6" }}>
            <div className="actionsDiv">
              <h2>Quick Actions</h2>
              <div className="quickActions">
                <Button
                  className="quickActionsButton"
                  onClick={showModalSiteVist}
                >
                  <img src={action4}></img> Create site visit card
                </Button>
                <Button
                  className="quickActionsButton"
                  onClick={showModalAddTask}
                >
                  <img src={action3}></img> Create task card
                </Button>
                <Button
                  className="quickActionsButton"
                  onClick={showModalEditModal}
                >
                  <img src={action2}></img> Create an IP Planning card
                </Button>
                <Button
                  className="quickActionsButton"
                  onClick={showModalAddFlag}
                >
                  <img src={action1}></img>Create a red flag card
                </Button>

                <AddSiteVisitCardModal
                  visible={isModalVisibleSiteVisit}
                  onOk={handleOk}
                  onCancel={handleCancel}
                />
                
                <AddNewTaskModal
                  setMessage={setMessage}
                  visible={isModalVisibleAddTask}
                  setIsModalVisible={handleCancel}
                />
                <AddRedFlagCardModal
                  visible={isModalVisibleAddFlag}
                  onClose={handleCancel}
                />
                <AddIpPlan
                  visible={isModalVisibleAddModal}
                  onClose={() => setIsModalVisibleAddModal(false)}
                  setMessage={setMessage}
                  onAdd={handleAddedIpPlan}
                />
              </div>
            </div>
          </div>{" "}
          <div style={{ background: "#F6F6F6" }}>
            <div
              className="tab-container"
              style={{
                borderTop: "2px solid #DFE6FF",
                borderBottom: "none",
                background: "white",
              }}
            >
              <div>
                <label
                  className={
                    activeComponent === "TPMsummary"
                      ? "tab-nav-active"
                      : "tab-nav-not-active"
                  }
                >
                  <input
                    type="radio"
                    value="TPMsummary"
                    checked={activeComponent === "TPMsummary"}
                    onChange={() => openActiveComponent("TPMsummary")}
                  />
                  TPM summary
                </label>
              </div>
              <div>
                <label
                  className={
                    activeComponent === "IPsummary"
                      ? "tab-nav-active"
                      : "tab-nav-not-active"
                  }
                >
                  <input
                    type="radio"
                    value="IPsummary"
                    checked={activeComponent === "IPsummary"}
                    onChange={() => openActiveComponent("IPsummary")}
                  />
                  IP summary
                </label>
              </div>
            </div>
          </div>
          {activeComponent === "TPMsummary" && <TpmSummary />}
          {activeComponent === "IPsummary" && <IpSummary />}
          <EditProject
            visible={showEditProjectModal}
            onClose={() => setShowEditProjectModal(false)}
            project={projectData}
            onSubmit={handleSubmit}
            setMessage={setMessage}
          />
          <PreviewProject
            visible={showPreviewProjectModal}
            onClose={() => setShowPreviewProjectModal(false)}
            project={projectData}
            onSubmit={handleSubmit}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
