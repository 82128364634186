import { Table } from "antd";
import React, { useEffect, useState } from "react";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import Filters from "./Filters";
import Preview from "../../../implementation/VisitBoard/TableView/modals/Preview";
import Edit from "../../../implementation/VisitBoard/TableView/modals/Edit";

function TableViewReporting({
  data,
  handleFilterChange,
  loading,
  filters,
  setFilters,
  setDataForExcel,
  onPageChange,
  pagination,
  setPagination,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const statusMappingFieldTeamReportingStatus = (status) => {
    switch (status) {
      case 1:
        return {
          text: "Data collection",
          className: "dataCollection",
          fill: "#3B81FE",
        };
      case 2:
        return {
          text: "Data check",
          className: "postDataCollection",
          fill: "#325899",
        };
      case 3:
        return {
          text: "Translation",
          className: "finalization",
          fill: "#045B46",
        };
      case 4:
        return {
          text: "Field Reporting Completed",
          className: "delivered",
          fill: "#0F7507",
        };
      default:
        return { text: "Unknown", className: "noStatus", fill: "red" };
    }
  };

  const statusMappingSiteVisitStatus = (status) => {
    switch (status) {
      case 1:
        return {
          text: "Data collection in progress",
          className: "progress",
          fill: "#3B81FE",
        };
      case 2:
        return {
          text: "Selected for monitoring",
          className: "monitoring",
          fill: "#FC9403",
        };
      case 3:
        return { text: "Review", className: "review", fill: "#9670B8" };
      case 4:
        return { text: "Delivered", className: "delivered", fill: "#045B46" };
      case 5:
        return { text: "Debrief", className: "debrief", fill: "#0F7507" };
      default:
        return { text: "Unknown", className: "noStatus", fill: "red" };
    }
  };
  const columns = [
    {
      title: <span className="styledTitle">Field Team Reporting Status</span>,
      dataIndex: "tracking.fieldTeamReportingStatus",
      key: "fieldTeamReportingStatus",
      width: 250,
      sorter: (a, b) => (a.tracking?.fieldTeamReportingStatus || "").localeCompare(b.tracking?.fieldTeamReportingStatus || ""),
      render: (value, record) => {
        const { text, className, fill } = statusMappingFieldTeamReportingStatus(record?.tracking?.fieldTeamReportingStatus);
        return (
          <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8px" height="8px" viewBox="0 0 9 9">
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span className="styledDataIndex3">{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle">Site visit card identifier</span>,
      dataIndex: "identifier",
      key: "identifier",
      ellipsis: true,
      width: 250,
      sorter: (a, b) => (a.identifier || "").localeCompare(b.identifier || ""),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      dataIndex: "ipPlan.implementingPartner.name",
      key: "implementingPartner",
      sorter: (a, b) => (a.ipPlan?.implementingPartner?.name || "").localeCompare(b.ipPlan?.implementingPartner?.name || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.ipPlan?.implementingPartner?.name}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Location</span>,
      dataIndex: "locationDetails.country",
      key: "locationDetails",
      sorter: (a, b) => (a.locationDetails?.country || "").localeCompare(b.locationDetails?.country || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.locationDetails?.country}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Site name</span>,
      dataIndex: "identifier",
      key: "identifier",
      ellipsis: true,
      width: 250,
      sorter: (a, b) => (a.identifier || "").localeCompare(b.identifier || ""),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      dataIndex: "cluster.name",
      key: "cluster",
      sorter: (a, b) => (a.cluster?.name || "").localeCompare(b.cluster?.name || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">{record.cluster?.name}</span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">TPM Coordinator</span>,
      dataIndex: "logistic.tpmCoordinator",
      key: "tpmCoordinator",
      sorter: (a, b) => (a?.logistic?.tpmCoordinator?.firstName || "").localeCompare(b?.logistic?.tpmCoordinator?.firstName || ""),
      render: (text, record) => record?.logistic?.tpmCoordinator?.firstName,
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Team name</span>,
      dataIndex: "logistic.team.name",
      key: "name",
      sorter: (a, b) => (a?.logistic?.team?.name || "").localeCompare(b?.logistic?.team?.name || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">{record?.logistic?.team?.name}</span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Reporting Month-Year</span>,
      dataIndex: "tracking.reportingMonthYear",
      key: "reportingMonthYear",
      sorter: (a, b) => ((a.tracking?.reportingYear + "-" + a.tracking?.reportingMonth) || "").localeCompare(b.tracking?.reportingYear + "-" + b.tracking?.reportingMonth || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.tracking?.reportingYear}-{record.tracking?.reportingMonth}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Date of planned visit</span>,
      dataIndex: "visit.plannedVisit",
      key: "plannedVisit",
      sorter: (a, b) => (a.visit?.plannedVisit || "").localeCompare(b.visit?.plannedVisit || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">{record.visit?.plannedVisit}</span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Site visit status</span>,
      dataIndex: "tracking.siteVisitStatus",
      key: "siteVisitStatus",
      width: 250,
      sorter: (a, b) => (a.tracking?.siteVisitStatus || "").localeCompare(b.tracking?.siteVisitStatus || ""),
      render: (record) => {
        const { text, className, fill } = statusMappingSiteVisitStatus(record?.tracking?.siteVisitStatus);
        return (
          <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8px" height="8px" viewBox="0 0 9 9">
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle">Training completed</span>,
      dataIndex: "tracking.trainingIsCompleted",
      key: "trainingIsCompleted",
      sorter: (a, b) => (a.tracking?.trainingIsCompleted || "").localeCompare(b.tracking?.trainingIsCompleted || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.tracking?.trainingIsCompleted ? "Yes" : "No"}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Postponed</span>,
      dataIndex: ["logistic", "isPostponed"],
      key: "isPostponed",
      sorter: (a, b) => {
        const aValue = a.logistic?.isPostponed || "";
        const bValue = b.logistic?.isPostponed || "";
        return aValue.toString().localeCompare(bValue.toString());
      },
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.logistic?.isPostponed ? "Yes" : "No"}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      key: "actions",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      width: 120,
      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={previewIcon}
            style={{  cursor: "pointer" }}
            alt="previewicon"
            onClick={() => handleTogglePreviewModal(record.id)}
          />
          <img
            src={editIcon}
            style={{  cursor: "pointer" }}
            alt="editicon"
            onClick={() => handleToggleEditModal(record.id)}
          />
        </div>
      ),
    },
  ];
  

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    const formattedData = selectedRows.map((item) => ({
      "Field Team Reporting Status":
        item?.ipPlan?.ipDonorReportingEnum || "N/A",
      "Site visit card identifier": item?.identifier || "N/A",
      IP: item?.ipPlan?.implementingPartner?.name || "N/A",
      Location: item?.locationDetails?.country || "N/A",
      "Site name": item?.identifier || "N/A",
      Cluster: item?.cluster?.name || "N/A",
      "TPM Coordinator": item?.logistic?.tpmCoordinator?.name || "N/A",
      "Team name": item?.logistic?.team?.name || "N/A",
      "Reporting Month-Year":
        item?.tracking?.reportingMonth - item?.tracking?.reportingYear || "N/A",
      "Date of planned visit": item?.visit?.plannedVisit || "N/A",
      "Site visit status": item?.siteVisitStatus || "N/A",
      "Training completed":
        (item?.tracking?.trainingIsCompleted ? "YES" : "NO") || "N/A",
      "Postponed": (item.logistic?.isPostponed ? "YES" : "NO") || "N/A",
    }));
    setDataForExcel(formattedData);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  return (
    <>
      <Filters onChange={handleFilterChange} setFilters={setFilters} />
      <div>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={loading}
          className="tableTPM"
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
        />
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
      <Edit
        visible={showEditModal}
        onClose={handleToggleEditModal}
        siteVisitCardId={selectedSiteVisit}
      />
    </>
  );
}

export default TableViewReporting;
