import React, { useState } from "react";
import { Button, Col, Row, Spin,Table } from "antd";
import { CreditCardOutlined, EditOutlined } from "@ant-design/icons";
import "./index.scss";
import CurrentSubscription from "./CurrentSubscription";
import BasicModal from "./BasicModal";
import CancelSubscription from "./CancelSubscription";
import dayjs from "dayjs";
import BillingHistory from "./BillingHistory";

const PaymentInformation = React.memo(() => {

    const [openModal, setOpenModal] = useState({
        editPayment: false,
        cancelSubscription: false
    });
    const [isLoading, setIsLoading] = useState(false); // Set to false for design purposes
    const [stripeSubscriptionData] = useState({
        stripeSubscriptionId: null,
        subscriptionStartsAt: null,
        subscriptionEndsAt: "2024-12-31", // Example date
        subscriptionStatus: "active", // Example status
        planId: null,
        planName: "Pro Plan", // Example plan name
        clientSecret: "example-client-secret", // Example client secret
        price: "20.00", // Example price
        subscriptionId: "sub_example123", // Example subscription ID
        paymentMethodId: "pm_example456", // Example payment method ID
        last4CardDigits: "4242", // Example last 4 card digits
        cancelAt: null
    });

    const appearance = {
        theme: "stripe"
    };

    const options = {
        clientSecret: stripeSubscriptionData.clientSecret,
        appearance
    };

    return (
        <>
            {isLoading ? (
                <Spin
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        width: "100%"
                    }}
                />
            ) : (
                <div className="paymentInformation accountSettings">
                    {stripeSubscriptionData.subscriptionStatus !== "canceled" && (
                        <Row gutter={[16, 16]} justify="space-between" align="middle">
                            <Col xs={24} sm={18} md={12} lg={12} xl={12}>
                                <span className="title">Payment information</span>
                            </Col>
                            <Col xs={24} sm={6} md={12} lg={12} xl={12} className="cancelSubscription">
                                <Button
                                    type="default"
                                    className="inviteButtonTPM"
                                    onClick={() => setOpenModal({ ...openModal, cancelSubscription: true })}
                                >
                                    Cancel my subscription
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <CurrentSubscription
                        planName={stripeSubscriptionData.planName}
                        subscriptionStatus={stripeSubscriptionData.subscriptionStatus}
                        subscriptionId={stripeSubscriptionData.subscriptionId}
                        price={stripeSubscriptionData.price}
                        isMonthly={true} // Example billing frequency
                    ></CurrentSubscription>
                    <div className="paymentMethod">
                        <div className="nextPayment">
                            <p>Next payment due</p>
                            <h3>
                                {dayjs(stripeSubscriptionData.subscriptionEndsAt).format("MMM D, YYYY")}
                            </h3>
                        </div>
                        <div className="editPaymentMethod">
                            <p>Payment method</p>
                            <div className="cardInformation">
                                <div className="infoDetails">
                                    <div className="creditCardIcon">
                                        <CreditCardOutlined
                                            style={{
                                                strokeWidth: "35",
                                                stroke: "white"
                                            }}
                                        />
                                    </div>
                                    <div className="visaEnding">
                                        {stripeSubscriptionData.paymentMethodId ? (
                                            <>
                                                <span>Credit card</span>
                                                <span>
                                                    Visa ending in ****{stripeSubscriptionData.last4CardDigits}
                                                </span>
                                            </>
                                        ) : (
                                            <span>No card details added</span>
                                        )}
                                    </div>
                                </div>
                                <Button
                                    type="default"
                                    className="inviteButtonTPM"
                                    icon={<EditOutlined />}
                                    onClick={() => setOpenModal({ ...openModal, editPayment: true })}
                                >
                                    {stripeSubscriptionData.paymentMethodId ? (
                                        <span>Edit payment method</span>
                                    ) : (
                                        <span>Add payment method</span>
                                    )}
                                </Button>
                            </div>
                        </div>
                       
                    </div> 
                    {openModal.cancelSubscription && (
                        <CancelSubscription
                            isModalOpen={openModal.cancelSubscription}
                            handleAccountModal={() => setOpenModal({ ...openModal, cancelSubscription: false })}
                            subscriptionId={stripeSubscriptionData.subscriptionId}
                            handleCancelPlan={() => {}}
                        ></CancelSubscription>
                    )}
                </div>


            )}
            <BillingHistory></BillingHistory>
        </>
    );
});

export default PaymentInformation;
