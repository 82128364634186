import React,{useState,useEffect} from "react";
import { Modal, Form, Button } from "antd";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import { get } from "../../../../../../../services/authService";

const Preview = ({ visible, onClose, record }) => {
  
  console.log(record,"record.id")
  const [previewData, setPreviewData] = useState([])

  const [form] = Form.useForm();

  const fetchTripLogisticById = async (id) => {
    try {
      const response = await get('/api/TripLogistic/getTripLogistic', {
          id
      });
      setPreviewData(response.data)
    } catch (error) {
      console.error('Error fetching trip logistic by ID:', error);
    }
  };

  useEffect(() => {
    fetchTripLogisticById(record)
  },[record])

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Personal Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="siteVisitId"
            label={<span style={{ fontWeight: "bold" }}>Site Visit ID</span>}
          >
            <p className="previewText">{previewData.siteVisitId}</p>
          </Form.Item>
          <Form.Item
            name="tripDate"
            label={<span style={{ fontWeight: "bold" }}>Date of Planned Visit</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{new Date(previewData?.enumeratorInformation?.tripDate).toLocaleDateString()}</p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="pickupTime"
            label={<span style={{ fontWeight: "bold" }}>Pickup Time</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{previewData?.enumeratorInformation?.pickupTime}</p>
          </Form.Item>
          <Form.Item
            name="meetingTime"
            label={<span style={{ fontWeight: "bold" }}>Meeting Time</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{previewData?.enumeratorInformation?.meetingTime}</p>
          </Form.Item>
        </div>
        <Form.Item
          name="pickupPlace"
          label={<span style={{ fontWeight: "bold" }}>Pickup Place</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.pickupPlace}</p>
        </Form.Item>
        <Form.Item
          name="meetingPlace"
          label={<span style={{ fontWeight: "bold" }}>Meeting Place</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.meetingPlace}</p>
        </Form.Item>
        <Form.Item
          name="mainRoadsToTravel"
          label={<span style={{ fontWeight: "bold" }}>Main Roads to Travel</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.mainRoadsToTravel}</p>
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="distanceToSite"
            label={<span style={{ fontWeight: "bold" }}>Distance to Site</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{previewData?.enumeratorInformation?.distanceToSite}</p>
          </Form.Item>
          <Form.Item
            name="timeToSite"
            label={<span style={{ fontWeight: "bold" }}>Time to Site</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{previewData?.enumeratorInformation?.timeToSite}</p>
          </Form.Item>
        </div>
        <Form.Item
          name="numberOfDays"
          label={<span style={{ fontWeight: "bold" }}>Number of Days</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.numberOfDays}</p>
        </Form.Item>
        <Form.Item
          name="enumeratorTeamDistance"
          label={<span style={{ fontWeight: "bold" }}>Enumerator Team Distance</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.enumeratorTeamDistance}</p>
        </Form.Item>
        <Form.Item
          name="isOverNight"
          label={<span style={{ fontWeight: "bold" }}>Is Over Night</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.isOverNight ? "Yes" : "No"}</p>
        </Form.Item>
        <Form.Item
          name="overNightNoOfPeople"
          label={<span style={{ fontWeight: "bold" }}>Over Night Number of People</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.overNightNoOfPeople}</p>
        </Form.Item>
        <Form.Item
          name="overNightNoOfNights"
          label={<span style={{ fontWeight: "bold" }}>Over Night Number of Nights</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.overNightNoOfNights}</p>
        </Form.Item>
        <Form.Item
          name="overNightLodging"
          label={<span style={{ fontWeight: "bold" }}>Over Night Lodging</span>}
        >
          <p className="previewText">{previewData?.enumeratorInformation?.overNightLodging}</p>
        </Form.Item>
        <p className="modalsHeader1">Additional Comments</p>
        <Form.Item
          name="otherComments"
          label={<span style={{ fontWeight: "bold" }}>Other Comments</span>}
        >
          <p className="previewText">{previewData?.otherComments}</p>
        </Form.Item>
        <p className="modalsHeader1">Logistics Coordinator Information</p>
        <Form.Item
          name="logiCoordNameAndOtherInfo"
          label={<span style={{ fontWeight: "bold" }}>Logistics Coordinator Name and Other Info</span>}
        >
          <p className="previewText">{previewData?.logiCoordNameAndOtherInfo}</p>
        </Form.Item>
        <p className="modalsHeader1">Site Visit Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="siteVisitCountry"
            label={<span style={{ fontWeight: "bold" }}>Site Visit Country</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{previewData?.siteVisitCountry}</p>
          </Form.Item>
          <Form.Item
            name="siteVisitCity"
            label={<span style={{ fontWeight: "bold" }}>Site Visit City</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{previewData?.siteVisitCity}</p>
          </Form.Item>
        </div>
        <div className="buttonsModal">
          <Button className="inviteButtonTPM" key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Preview;
