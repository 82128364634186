import React, { useState } from "react";
import { message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { post, get} from "../../../services/authService";
import "./styles.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleForgotPassword = async (event) => {
    event.preventDefault();
    
    if (!email) {
      message.error("Please enter your email address.");
      return;
    }

    setLoading(true);

    try {
      const response = await get("/api/account/resendCode", { email });

      if (response.success) {
        message.success("Verification code sent to your email.");
        navigate("/forgotPassword/code", { state: { email } }) ;
      } else {
        message.error("Failed to send verification code: " + response.message);
      }
    } catch (error) {
      message.error("Error sending verification code: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <form onSubmit={handleForgotPassword}>
              <h2>Forgot Password?</h2>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  * Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? <Spin /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
