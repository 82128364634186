import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Input } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { get, put } from "../../../services/authService";

const { Option } = Select;

const EditIp = ({ visible, onClose, record, onSubmit, setMessage }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [implementingPartnerData, setImplementingPartnerData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && record) {
      setLoading(true)
      get(`/api/ImplementingPartner/getImplementingPartner?implementingPartnerId=${record.id}`)
        .then((res) => {
          setImplementingPartnerData(res.data);
          if (res.data.cities.length > 0) {
            const countryId = res.data.cities[0].country.id;
            setSelectedCountries({ 0: countryId });
            handleCountryChange(0, countryId, res.data.cities);
          }
          setLoading(false)
        })
        .catch((error) => console.log(error));
    }
  }, [visible, record]);

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllCountries(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleCountryChange = (groupId, countryId, initialCities = []) => {
    const updatedCountries = { ...selectedCountries, [groupId]: countryId };
    setSelectedCountries(updatedCountries);
    setCities((prev) => ({ ...prev, [groupId]: [] }));
  
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => {
          const citiesData = res.data;
          setCities((prev) => ({ ...prev, [groupId]: citiesData }));
        })
        .catch((error) => console.log(error));
    }
  };
  

  const fetchOfficeData = (cityIds) => {
    const cityIdsParams = cityIds.map((id) => `CityIds=${id}`).join("&");
    get(`/api/Office/getAllByCity?${cityIdsParams}`).catch((error) => console.log(error));
  };

  const handleCityChange = (groupId, cityIds) => {
    if (cityIds && cityIds.length > 0) {
      fetchOfficeData(cityIds);
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    put("/api/ImplementingPartner/updateImplementingPartner", {
      id: record.id,
      name: values.ipName,
      cityIds: values.city,
    })
      .then(() => {
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `IP updated successfully!`,
          text: `Ip has been updated to your database!`,
        });
        onSubmit();
        onClose();
        setCities([]);
      })
      .catch((error) => {
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: `We had a problem updating this ip to your database, please try again.`,
        });
        console.log("Update error:", error);
      })
      .finally(() => {
        setSubmitting(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      });
  };

  const validationSchema = Yup.object().shape({
    ipName: Yup.string().required("Please enter IP name"),
    country: Yup.string().required("Please select a country"),
    city: Yup.array().min(1, "Please select at least one governorate or state"),
  });

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={400}
      loading={loading}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Edit IP</h2>
      {implementingPartnerData && (
        <Formik
        enableReinitialize
          initialValues={{
            ipName: implementingPartnerData?.name || "",
            country: implementingPartnerData?.cities[0]?.country?.id || "",
            city: implementingPartnerData?.cities.map((city) => city?.id) || [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, values, isSubmitting,handleChange }) => (
            <Form layout="vertical">
               <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>IP name
              </label>
                <Input name="ipName" placeholder="Enter IP name"
                  value={values.ipName}
                  onChange={handleChange}
                  autoComplete="off" as="input" />
                {errors.ipName && touched.ipName ? (
                  <div className="error">{errors.ipName}</div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Country
              </label>
                <Field name="country">
                  {({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select Country"
                      onChange={(value) => {
                        setFieldValue("country", value);
                        handleCountryChange(0, value);
                      }}
                      value={values.country}
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                    >
                      {allCountries.map((country) => (
                        <Option key={country.id} value={country.id}>
                          {country.stateName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Field>
                {errors.country && touched.country ? (
                  <div className="error">{errors.country}</div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Governorate/State
              </label>
                <Field name="city">
                  {({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select a governorate or state"
                      mode="multiple"
                      onChange={(value) => {
                        setFieldValue("city", value);
                        handleCityChange(0, value);
                      }}
                      value={values.city}
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                    >
                      {cities[0]?.map((city) => (
                        <Option key={city.id} value={city.id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Field>
                {errors.city && touched.city ? (
                  <div className="error">{errors.city}</div>
                ) : null}
              </div>
              <div className="buttonsModal" style={{ marginTop: "20px" }}>
                <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="addButtonTPM"
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default EditIp;
