import React, { useState } from "react";
import { Radio, Space, Input, Button, Row, Col, Form, Modal } from "antd";
const { TextArea } = Input;

const DeactivateAccountModal = ({ isModalOpen, handleAccountModal }) => {
    const [value, setValue] = useState(null);
  
    const onFinish = (values) => {
        console.log("Received values:", values);
        // Handle form submission here, you can pass the values to a parent component function
        // and perform further actions.
    };

   return (
      <Modal
         visible={isModalOpen}
         onCancel={handleAccountModal}
         footer={null}
         width={800}
      >
         <Form onFinish={onFinish}>
            <div className="mt-5 mb-8">
               <h4 className="headingDeactivate">Deactivate your account</h4>
            </div>

            <p className="label-text mt-8 mb-5">
               Thinking of taking a break? Deactivating your account is always an option. We'd
               appreciate your feedback if you're willing to share why you're considering this.
            </p>
            <Form.Item
               name="reason"
               rules={[{ required: true, message: "Please select an option" }]}
            >
               <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                  <Space direction="vertical">
                     <Radio value={1}>I'm just taking a break from this platform.</Radio>
                     <Radio value={2}>I'm not using this account anymore.</Radio>
                     <Radio value={3}>Other (please specify)</Radio>
                  </Space>
               </Radio.Group>
            </Form.Item>
            {value === 3 ? (
               <Form.Item name="otherReason">
                  <TextArea
                     rows={2}
                     placeholder="Type something here"
                     rules={[{ required: true, message: "Please type something here" }]}
                  />
               </Form.Item>
            ) : null}
            <p className="label-text mt-8">
               Is there anything we could have done to improve your experience? Please tell us
               more about why you're deleting your account.
            </p>
            <Form.Item
               name="improveExperience"
               rules={[{ required: true, message: "Please type something here" }]}
            >
               <TextArea rows={2} placeholder="Type something here" />
            </Form.Item>
            <Row gutter={16} className="mt-8" justify="center" align="middle">
               <Col>
                  <Button className="inviteButtonTPM" onClick={handleAccountModal}>
                     Cancel
                  </Button>
               </Col>
               <Col>
                  <Button htmlType="submit" className="addButtonTPM">
                     Continue
                  </Button>
               </Col>
            </Row>
         </Form>
      </Modal>
   );
};

export default DeactivateAccountModal;
