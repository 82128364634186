import React,{useState} from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../TableView/Filters";
import { put } from "../../../../../services/authService";
import PreviewModal from "../../../planning/modals/PreviewModal";
import { Pagination } from "antd";

const KanbanViewIpDonor = ({ handleFilterChange, setFilters, fetchIpPlan, IPPlans, onPageChange,pagination,setPagination }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();


  const changeIPPlanStatus = (draggedTask) => {
    const requestBody = {
      ipPlanId: draggedTask.id,
      ipDonorReporting: draggedTask.column,
    };

    put("/api/IPPlan/changeIPPlanDonorReportingStatus", requestBody)
      .then((values) => {
        fetchIpPlan();
      })
  };

  const columns = [
    { id: 1, title: "Awaiting Field", color: "#325899" },
    { id: 2, title: "Data cleaning", color: "#3B81FE" },
    { id: 3, title: "Data analysis", color: "#9670B8" },
    { id: 4, title: "Data translation", color: "#C4648C" },
    { id: 5, title: "IP Report Development", color: "#C77A43" },
    { id: 6, title: "Donor Report", color: "#0F7507" },
    { id: 7, title: "Completed", color: "#045B46" },
  ];

  const handleTaskClick = (taskId) => {
    setSelectedTaskId(taskId);
    if (taskId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };


  return (
    <>
      <PreviewModal
          visible={showPreviewModal}
          record={selectedTaskId}
          onOk={handlePreviewOk}
          onCancel={handleCancel}
        />
      <Filters handleFilterChange={handleFilterChange} setFilters={ setFilters}/>
      <div id="excludedStrictMode"><KanbanBoardComponent tasks={IPPlans} columns={columns} changeStatus={changeIPPlanStatus} setClickedTaskId={handleTaskClick} /></div>
      <div className="d-flex justify-content-end my-5">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
              />
          </div>
    </>
  );
};

export default KanbanViewIpDonor;
