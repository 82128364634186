import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import "./styles.scss";
import { Button, Tabs } from "antd";
import PlanningTableView from "./PlanningTableView";
import AddModal from "./modals/AddModal";
import { useState } from "react";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import KanbanView from "./KanbanView/KanbanView";
import CalendarViewPlanning from "./CalendarView/CalendarView";
import AddSiteVisitCardModal from "./VisitBoard/Modals/AddSiteVisitCardModal"
import {exportToExcel} from "../../components/ExportToExcel/exportToExcel"

const IpPlanningBoard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSiteVisitModal, setShowSiteVisitModal] = useState(false)


  const handleAddSiteVisitModal = () => {
    setShowSiteVisitModal(true)
  }
  
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [ipPlansData, setIpPlansData] = useState([]); 
  const [dataFromChild, setDataFromChild] = useState([]);

  const handleDataLoaded = (data) => {
    setDataFromChild(data);
  };


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddIpPlan = (newPlan) => {
    setIpPlansData((prevPlans) => [...prevPlans, newPlan]); 
  };



  return (
    <div className="ip-planning-wrapper">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="text-title">
        <h3>IP planning board</h3>
        <div className="ip-planning-buttons">
          <Button className="export-selectedBtn"
            onClick={() => {
              if (dataFromChild.length !== 0) {
                exportToExcel(dataFromChild,"IP-planning")
              }
            }}
          >
            <UploadOutlined />
            Export selected cards
          </Button>
          <Button className="add-new-ipBtn" onClick={showModal}>
            <PlusOutlined />
            Add new IP plan card
          </Button>
          {/* <Button
            className="addButtonTPM" onClick={handleAddSiteVisitModal}
          >
            <PlusOutlined />
            Add new site visit card
          </Button> */}
          <AddModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            setMessage={setMessage}
            onAdd={handleAddIpPlan}
          />
          <AddSiteVisitCardModal
            visible={showSiteVisitModal}
            onCancel={() => setShowSiteVisitModal(false)}
          />
        </div>
      </div>
      <div className="tab-container">
        <Tabs
          className="tableviewTabs"
          defaultActiveKey="1"
          onChange={handleAddIpPlan}
          items={[
            {
              label: "Table view",
              key: "1",
              children: <PlanningTableView ipPlansData={ipPlansData} onDataLoaded={handleDataLoaded}/>,
            },
            {
              label: "Kanban view",
              key: "2",
              children: (
                <KanbanView ipPlansData={ipPlansData}></KanbanView>
              ),
            },
            {
              label: "Calendar view",
              key: "3",
              children: (
                <CalendarViewPlanning ipPlansData={ipPlansData} />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
export default IpPlanningBoard;
