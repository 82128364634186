import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message, Spin } from "antd";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { get } from "../../../../../services/authService";

const { Option } = Select;

const Preview = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  const [redFlag, setRedFlag] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if (record?.key) {
      fetchRedFlag(record.key);
    }
  },[form, visible, record])
  
  async function fetchRedFlag(redFlagId) {
    setLoading(true)
    try {
      const res = await get(`/api/RedFlag/getRedFlag?id=${redFlagId}`);
      setRedFlag(res.data);
    } catch (error) {
      console.error('Error fetching the red flag:', error);
      
    }
    setLoading(false)
  }

  if (!record) {
    return null; 
  }

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const formatDate = (date) => {
    if (!date || typeof date !== 'string') {
      console.error('Invalid date:', date);
      return '';
    }
    const formattedDate = date.split("T")[0];
    return formattedDate;
  }

  const formatStatus = (status) =>{
    if (status === 1) {
      return "Assigned";
    } else if (status === 2) {
      return "In progress";
    } else if (status === 3) {
      return "On Hold";
    } else if (status === 4) {
      return "Resolved";
    } else if (status === 5) {
      return "Archived";
    }
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      {
        loading ? <div style={{display: "flex", justifyContent: "center", height: "50vh"}}><Spin spinning={loading}> </Spin> </div>:
        <Form form={form} layout="vertical">
        {" "}
        <p className="modalsHeader1">General Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>Name</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.name}</p>
          </Form.Item>
          <Form.Item
            name="ip"
            label={
              <span style={{ fontWeight: "bold" }}>Implementing Partner</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.implementingPartnerName}</p>
          </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="status"
            label={
              <span style={{ fontWeight: "bold" }}>Status</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{formatStatus(redFlag?.flagStatus)}</p>
          </Form.Item>
          <Form.Item
            name="dateOfSiteVisit"
            label={
              <span style={{ fontWeight: "bold" }}>Date Of Site Visit</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{formatDate(redFlag?.dateOfSiteVisit)}</p>
          </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="dueDate"
            label={
              <span style={{ fontWeight: "bold" }}>Due Date</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{formatDate(redFlag?.dueDate)}</p>
          </Form.Item>
          
          </div>
          <Form.Item
            name="description"
            label={
              <span style={{ fontWeight: "bold" }}>Description</span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">{redFlag?.description}</p>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label={
              <span style={{ fontWeight: "bold" }}>Follow Up Notes</span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">{redFlag?.followUpNotes}</p>
          </Form.Item>
          <p className="modalsHeader1">Assigned To</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          
          <Form.Item
            name="fullName"
            label={
              <span style={{ fontWeight: "bold" }}>Full Name</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.assignedTo?.firstName} {redFlag?.assignedTo?.lastName}</p>
          </Form.Item>
          <Form.Item
            name="position"
            label={<span style={{ fontWeight: "bold" }}>Position</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.assignedTo?.position}</p>
          </Form.Item>
        </div>
        <p className="modalsHeader1">Location Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          
          <Form.Item
            name="country"
            label={
              <span style={{ fontWeight: "bold" }}>Country</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.office?.country}</p>
          </Form.Item>
          <Form.Item
            name="city"
            label={<span style={{ fontWeight: "bold" }}>City</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.office?.city}</p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="office"
            label={<span style={{ fontWeight: "bold" }}>Office</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.office?.name}</p>
          </Form.Item>
        </div>
        <p className="modalsHeader1">Resources</p>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
      }
    </Modal>
  );
};

export default Preview;
