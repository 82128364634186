// src/WorldMap.js
import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Modal } from 'antd';
import Preview from './Preview';

const markers = [
  { position: [51.505, -0.09], color: 'rgba(212, 33, 119, 0.50)', number: 1, location: 'London' },
  { position: [48.8566, 2.3522], color: 'rgba(212, 33, 119, 0.50)', number: 2, location: 'Paris' },
  { position: [40.7128, -74.0060], color: 'rgba(212, 33, 119, 0.50)', number: 3, location: 'New York' },
  { position: [2.505, -20.09], color: 'rgba(212, 33, 119, 0.50)', number: 1, location: 'Unknown 1' },
  { position: [43.8566, 32.3522], color: 'rgba(212, 33, 119, 0.50)', number: 2, location: 'Unknown 2' },
  { position: [41.7128, -2.0060], color: 'rgba(212, 33, 119, 0.50)', number: 3, location: 'Unknown 3' },
];
const data1 = {
  location: "123 Main St",
  position: [37.7749, -122.4194],
  pdfs: [
    "https://example.com/file1.pdf",
    "https://example.com/file2.pdf",
    "https://example.com/file3.pdf",
  ],
  excels: [
    "https://example.com/file1.xlsx",
    "https://example.com/file2.xlsx",
    "https://example.com/file3.xlsx",
  ],
  videos: [
    "https://example.com/video1.mp4",
    "https://example.com/video2.mp4",
    "https://example.com/video3.mp4",
  ],
};


const createIcon = (color, number) => {
  return L.divIcon({
    className: "custom-icon",
    html: `<div style="background-color: ${color}; border-radius: 50%; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; color: white; font-weight: bold; font-size:18px">${number}</div>`,
    iconSize: [40, 40]
  });
};

const CompletedMap = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedMarker(null);
  };

  return (
    <>
      <MapContainer center={[20, 0]} zoom={2} style={{ height: "450px", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, idx) => (
          <Marker
            key={idx}
            position={marker.position}
            icon={createIcon(marker.color, marker.number)}
            eventHandlers={{
              click: () => handleMarkerClick(marker)
            }}
          />
        ))}
      </MapContainer>
      {selectedMarker && (
        <Preview
          visible={modalVisible}
          onClose={closeModal}
          data={selectedMarker}
          data1 = {data1}
        />
      )}
    </>
  );
};

export default CompletedMap;
