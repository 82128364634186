import { create } from "zustand";

export const useTaskStore = create((set) => ({
  fetchTaskss: false,

  fetchAllTasks: (data) => {
    set({
      fetchTaskss: data,
    });
  },
}));
