import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  message,
} from "antd";
import { get, post } from "../../../../../services/authService";
import moment from "moment";

import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";

const { Option } = Select;

const AddNewTaskModal = ({ visible, setIsModalVisible, setMessage, updateData }) => {
  console.log(visible)
  const [form] = Form.useForm();
  const context = useContext(DataContext);
  const [taskMembers, settaskMembers] = useState([]);
  const [taskMembersLoading, settaskMembersLoading] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false)

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  useEffect(() => {
    get("/api/GeoData/countries").then((res) => setLocations(res.data));
  }, []);

  const handleLocationChange = async (country) => {
    setCities([]);
    setSiteVisits([]);
    settaskMembers([]);

    form.setFieldsValue({
      city: undefined,
      siteIds: undefined,
      assignedTo: undefined,
    });

    get(`/api/GeoData/cities?countryId=${country}`)
      .then((res) => setCities(res.data))
      .catch((error) => console.log(error));

    const fetchSiteVisitsByCountry = async () => {
      const projectId = localStorage.getItem("currentProjectID");
      if (!projectId) {
        console.error("No project ID found in local storage");
        return;
      }
      console.log(country);
      const countryData = locations.find((location) => location.id == country);
      const url = `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}&IgnorePagination=true`;
      try {
        const response = await get(url);
        if (response && Array.isArray(response.data)) {
          setSiteVisits(response.data);
        } else {
          console.error(
            "API response does not contain a data array:",
            response
          );
          setSiteVisits([]);
        }
      } catch (error) {
        console.error("Failed to fetch site visits by country:", error);
        setSiteVisits([]);
      }
    };

    fetchSiteVisitsByCountry();
  };

  const handleSubmit = async () => {
    const projectId = localStorage.getItem("currentProjectID");
  
    try {
      setButtonLoading(true)
      const values = await form.validateFields();
  
      const formattedDeadline = values.deadline
        ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;
  
      const payload = {
        projectId,
        name: values.taskName,
        deadline: formattedDeadline,
        taskLocation: {
          countryId: values.location,
          municipality: values.location2,
          cityId: values.city,
          district: values.district,
        },
        collectionMethod: values.collectionMethod,
        type: values.taskType,
        otherType: values.otherType,
        description: values.description,
        numberOfInterviews: values.numberOfInterviews,
        contactInformation: values.contactInformation,
        additionalNotes: values.additionalNotes,
        status: values.taskStatus,
        siteIds: values.siteIds,
        assignedTo: values.assignedTo,
      };
  
      const response = await post("/api/Tasks", payload);
      console.log("API Response:", response);
  
      const message =
        response.data?.message ||
        "The task was successfully added to your database.";
  
      setMessage({
        trigger: true,
        type: "success",
        icon: "",
        title: "Task added successfully!",
        text: message,
      });
  
      form.resetFields(); // Reset form fields
      setIsModalVisible(false); // Close modal
      updateData()
    } catch (error) {
      console.error("Failed to submit task:", error);
  
      const errorMessage =
      error.response?.data?.errors[0]?.message ||
        "We had a problem adding this task, please try again.";
  
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: errorMessage,
      });
  
    } finally {
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
      setButtonLoading(false)
    }
  };
  
  
  
  
  

  const handleSiteVisit = async (siteVisitIds) => {
    settaskMembersLoading(true);
    try {
      const queryString = siteVisitIds
        .map((id, index) => `siteVisitIds[${index}]=${encodeURIComponent(id)}`)
        .join("&");

      const response = await get(
        `/api/Site/getTeamBySiteVisits?${queryString}`
      );
      settaskMembers(response.data || []);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch team by site visit:", error);
    } finally {
      settaskMembersLoading(false);
    }
  };

  // if (!context) {
  //   return <></>;
  // }
  // const { fetchData } = context;

  return (
    <Modal
      title="Add new task card"
      open={visible}
      onCancel={handleCancel}
      width={800}
      destroyOnClose
      footer={[
        <Button className="inviteButtonTPM" key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={buttonLoading}
          className="addButtonTPM"
        >
          Add new task
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Task card identifier" name="taskIdentifier">
          <Input
            placeholder="xxxx - Task Name - Task Type - date created"
            disabled
          />
        </Form.Item>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Task name"
              name="taskName"
              rules={[
                { required: true, message: "Please enter the task name" },
              ]}
            >
              <Input placeholder="Enter text here" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Deadline"
              name="deadline"
              rules={[
                { required: true, message: "Please select the deadline date" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="location"
              rules={[{ required: true, message: "Please select Country" }]}
            >
              <Select
                placeholder="Select an option"
                onChange={handleLocationChange}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {locations.map((location) => (
                  <Option key={location?.id} value={location?.id}>
                    {location?.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please select City" }]}
            >
              <Select
                placeholder="Select a city"
                showSearch
                disabled={cities?.length < 1}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Col span={20}>
          <Form.Item
            label="Pick the site visit card"
            name="siteIds"
            rules={[{ required: true, message: "Please input your site ID!" }]}
          >
            <Select
              placeholder="Select a site visit"
              onChange={handleSiteVisit}
              showSearch
              allowClear
              mode="multiple"
              disabled={siteVisits?.length < 1 ? true : false}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {siteVisits.map((visit) => (
                <Option key={visit.id} value={visit.id}>
                  {visit.identifier}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Assigned to"
            name="assignedTo"
            rules={[{ required: false, message: "Please select a person" }]}
          >
            <Select
              placeholder="Select an option"
              disabled={taskMembers?.length < 1 ? true : false}
            >
              {taskMembersLoading ? (
                <Option disabled>
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 0",
                    }}
                  />
                </Option>
              ) : (
                taskMembers.map((team) =>
                  team?.members?.map((member) => (
                    <Option key={member?.id} value={member?.id}>
                      {member?.firstName ?? ""} {member?.username ?? ""} (
                      {member?.roleName ?? ""})
                    </Option>
                  ))
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Collection method" name="collectionMethod">
            <Input placeholder="Enter the collection method" />
          </Form.Item>
        </Col>

        <Col span={16}>
          <Form.Item
            label="Task Type"
            name="taskType"
            rules={[{ required: true, message: "Please select a task type" }]}
          >
            <Select placeholder="Select an option">
              {taskTypes.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Form.Item label="Task description" name="description">
          <Input.TextArea rows={3} placeholder="Enter the task description" />
        </Form.Item>
        <Col span={6}>
          <Form.Item label="Number of interviews" name="numberOfInterviews">
            <InputNumber min={1} style={{ width: "100%" }} defaultValue={1} />
          </Form.Item>
        </Col>
        <Form.Item label="Contact information" name="contactInformation">
          <Input placeholder="Enter the contact information" />
        </Form.Item>
        <Form.Item label="Any additional notes" name="additionalNotes">
          <Input.TextArea rows={3} placeholder="Enter additional notes" />
        </Form.Item>
        <Form.Item
          label="Task status"
          name="taskStatus"
          rules={[{ required: true, message: "Please select the task status" }]}
        >
          <Select placeholder="Select the task status">
            <Option value={1}>Assigned</Option>
            <Option value={2}>Accepted</Option>
            <Option value={3}>Completed</Option>
            <Option value={4}>OnHold</Option>
            <Option value={5}>Archived</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewTaskModal;
