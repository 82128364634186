import React, { useEffect, useState } from "react";
import { Spin, Pagination } from "antd";
import CalendarViewComponent from "../../../components/CalendarView/CalendarViewComponent";
import Filters from "../TableView/Filters";
import Preview from "../KanbanView/modals/Preview";

const CalendarView = (
  { redFlags,
    fetchRedFlags,
    loading,
    pagination,
    setPagination,
    onPageChange,
    filters,
    setFilters,
    handleFilterChange
  }) => {
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [clickedEventId, setClickedEventId] = useState()
  
  
    const handleEventClicked = (eventId) => {
      setClickedEventId(eventId);
      if (eventId) {
        setShowPreviewModal(!showPreviewModal);
      }
    };
  
    const handlePreviewOk = () => {
      setShowPreviewModal(false);
    };
  
    const handleCancel = () => {
      setShowPreviewModal(false);
    };

  useEffect(() => {
    const projectID = localStorage.getItem("currentProjectID");
    fetchRedFlags(
      projectID,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.Month,
      filters.Year
    );
  }, [filters,pagination.current, pagination.pageSize]);

  const events = redFlags.reduce((acc, redFlag) => {
    const dateKey = redFlag.dateOfSiteVisit.split('T')[0]; 
    
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push({
      type: "",
      status: redFlag.flagStatus,
      content: redFlag.name,
      id: redFlag.id
    });
    return acc;
  }, {});


  const getListData = (value) => {
    const dateKey = value.format('YYYY-MM-DD');
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter(event => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (filters.search && !event.content.toLowerCase().includes(filters.search.toLowerCase())) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  return (
    <>
      <Spin spinning={loading}>
      <Preview
        visible={showPreviewModal}
        onClose={handleCancel}
        record={clickedEventId}
      />
      <Filters onChange={handleFilterChange} filters={filters} setFilters={setFilters} pagination={pagination} setPagination={setPagination} fetchRedFlags={fetchRedFlags} />
        <CalendarViewComponent getListData={getListData}  setClickedEventId={handleEventClicked}/>
        <div className="d-flex justify-content-end my-5">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
              />
          </div>
      </Spin>
    </>
  );
};

export default CalendarView;
