import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, message, Spin } from "antd";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { put, get } from "../../../../services/authService";

const { Option } = Select;

const PersonalInformation = React.memo(() => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    socialDetails: {
      skypeId: ''
    },
    languages: []
  });

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await get("/api/GeoData/languages");
        setLanguages(response.data); 
      } catch (error) {
        console.error("Error fetching languages:", error);
        message.error("Failed to fetch languages.");
      }
    };

    fetchLanguages();
  }, []);

  const fetchUserData = async () => {
    const userId = localStorage.getItem('userID'); 
    try {
      const response = await get(`/api/User?userId=${userId}`);
        const userData = response.data;

        setInitialValues({
          firstName: userData.userDetails.firstName || '',
          lastName: userData.userDetails.lastName || '',
          gender: userData.userDetails.gender || '',
          socialDetails: {
            skypeId: userData.userDetails.socialDetails.skypeId || ''
          },
          languages: userData.languages.map(lang => lang.id) || [] 
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    
    useEffect(() => {
    fetchUserData();
  }, []);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    gender: Yup.string().required("Please select your gender"),
    socialDetails: Yup.object({
      skypeId: Yup.string().required("Please enter your Skype ID")
    }),
    languages: Yup.array().min(1, "Please select at least one language")
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formattedValues = {
      ...values,
      languages: values.languages.map(languageId => ({ languageId }))
    };
    setLoading(true);
    try {
      await put("/api/User/updateUserDetails", formattedValues);
      message.success("User updated successfully.");
      resetForm();
      fetchUserData();
    } catch (error) {
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    enableReinitialize
    >
      {({ setFieldValue, values,handleChange }) => (
        <Form className="personalInformation" autoComplete="off" layout="vertical">
          <div className="mt-8 mb-2">
            <h4>Personal information</h4>
          </div>
          <Row gutter={24} style={{ paddingBottom:"20px" }}>
            <Col xs={24} sm={12}  style={{ paddingBottom:"10px" }}>
              <div className="form-item">
                <label htmlFor="firstName">First Name</label>
                <Field name="firstName">
                  {({ field }) => (
                    <Input
                      {...field}
                      value={values.firstName}
                      placeholder="First Name"
                    />
                  )}
                </Field>
                <ErrorMessage name="firstName" component="div" style={{ color: "red" }} />
              </div>
            </Col>
            <Col xs={24} sm={12} style={{ paddingBottom:"10px" }}>
              <div className="form-item">
                <label htmlFor="lastName">Last Name</label>
                <Field name="lastName">
                  {({ field }) => (
                    <Input
                      {...field}
                      value={values.lastName}
                      placeholder="Last Name"
                    />
                  )}
                </Field>
                <ErrorMessage name="lastName" component="div" style={{ color: "red" }} />
              </div>
            </Col>
            <Col xs={24} sm={12} style={{ paddingBottom:"10px" }}>
              <div className="form-item">
                <label htmlFor="gender">Gender</label>
                <Field name="gender">
                  {({ field }) => (
                    <Select
                      {...field}
                      value={values.gender}
                      placeholder="Select an option"
                      onChange={value => setFieldValue('gender', value)}
                    >
                      <Option value={1}>Male</Option>
                      <Option value={2}>Female</Option>
                      <Option value={3}>Other</Option>
                    </Select>
                  )}
                </Field>
                <ErrorMessage name="gender" component="div" style={{ color: "red" }} />
              </div>
            </Col>
            <Col xs={24} sm={12} style={{ paddingBottom:"10px" }}>
              <div className="form-item">
                <label htmlFor="socialDetails.skypeId">Skype ID</label>
                <Field name="socialDetails.skypeId">
                  {({ field }) => (
                    <Input
                      {...field}
                      value={values.socialDetails.skypeId}
                      placeholder="Skype ID"
                      onChange={handleChange}
                    />
                  )}
                </Field>
                <ErrorMessage name="socialDetails.skypeId" component="div" style={{ color: "red" }} />
              </div>
            </Col>
            <Col xs={24} sm={12} style={{ paddingBottom:"10px" }}>
              <div className="form-item">
                <label htmlFor="languages">Languages</label>
                <Field name="languages">
                  {({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      allowClear
                      placeholder="Select languages"
                      onChange={value => setFieldValue('languages', value)}
                      value={values.languages}
                    >
                      {languages.map((language) => (
                        <Option key={language.id} value={language.id}>
                          {language.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Field>
                <ErrorMessage name="languages" component="div" style={{ color: "red" }} />
              </div>
            </Col>
            </Row>
          <Row gutter={16} className="mt-8" align="middle">
            <Col>
            <Button htmlType="submit" className="addButtonTPM">
               {loading ? <Spin /> : <div>Save changes</div>}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
});

export default PersonalInformation;
