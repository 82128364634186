import React, { useState } from "react";
import { Modal, Form, Button } from "antd";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";

const Preview = ({ visible, onClose, data, data1 }) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const openVideoModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setVideoVisible(true);
  };

  const closeVideoModal = () => {
    setVideoVisible(false);
    setCurrentVideo("");
  };

  return (
    <>
      <Modal
        visible={visible}
        closable={false}
        onCancel={onClose}
        footer={false}
        width={800}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={cancelX}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
        <h2 className="modalHeader">Site visit card</h2>
        <Form layout="vertical">
          <Form.Item
            name="name"
            label={<span style={{ fontWeight: "bold" }}>Site visit identifier</span>}
          >
            <p className="previewText">test</p>
          </Form.Item>
          <p className="modalsHeader1">General details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="implementingPartner"
              label={<span style={{ fontWeight: "bold" }}>Implementing Partner</span>}
            >
              <p className="previewText">test</p>
            </Form.Item>
            <Form.Item
              name="cluster"
              label={<span style={{ fontWeight: "bold" }}>Cluster</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="sector"
              label={<span style={{ fontWeight: "bold" }}>Sector</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
            <Form.Item
              name="subSectors"
              label={<span style={{ fontWeight: "bold" }}>Subsectors</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
          </div>
          <p className="modalsHeader1">Location details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="state"
              label={<span style={{ fontWeight: "bold" }}>State</span>}
            >
              <p className="previewText">test</p>
            </Form.Item>
            <Form.Item
              name="Municipality"
              label={<span style={{ fontWeight: "bold" }}>Municipality</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="Locality"
              label={<span style={{ fontWeight: "bold" }}>Locality</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
            <Form.Item
              name="Site name"
              label={<span style={{ fontWeight: "bold" }}>Site name</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="Address or specific location"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Address or specific location
                </span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{data.location}</p>
            </Form.Item>
            <Form.Item
              name="Location in the map"
              label={<span style={{ fontWeight: "bold" }}>Location in the map</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{data.position.join(", ")}</p>
            </Form.Item>
          </div>
          <p className="modalsHeader1">General details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="implementingPartner"
              label={<span style={{ fontWeight: "bold" }}>Implementing Partner</span>}
            >
              <p className="previewText">test</p>
            </Form.Item>
            <Form.Item
              name="cluster"
              label={<span style={{ fontWeight: "bold" }}>Cluster</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="sector"
              label={<span style={{ fontWeight: "bold" }}>Sector</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
            <Form.Item
              name="subSectors"
              label={<span style={{ fontWeight: "bold" }}>Subsectors</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">test</p>
            </Form.Item>
          </div>
          <p className="modalsHeader1">Resources</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{flexDirection:"column", display:"flex"}}>
            <span style={{ fontWeight: "bold" }}>Site visit report documents</span>
            {data1.pdfs.map((pdf, index) => (
              <a key={index} href={pdf} target="_blank" rel="noopener noreferrer" className="resourcesText">
                PDF File {index + 1}
              </a>
            ))}</div>
             <div style={{flexDirection:"column", display:"flex"}}>
             <span style={{ fontWeight: "bold" }}>Other documents</span>
            {data1.excels.map((excel, index) => (
              <a key={index} href={excel} target="_blank" rel="noopener noreferrer" className="resourcesText">
                Excel File {index + 1}
              </a>
            ))}</div>
           
          </div>
          <div style={{marginTop:"20px"}}><span style={{ fontWeight: "bold" }}>Site video file</span> {data1.videos.map((video, index) => (
              <div key={index} onClick={() => openVideoModal(video)} style={{ cursor: "pointer" }} className="resourcesText">
               MP4 File {index + 1}
              </div>
            ))}</div>
          <div className="buttonsModal">
            <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        visible={videoVisible}
        closable={false}
        onCancel={closeVideoModal}
        footer={false}
        width={700}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={cancelX}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={closeVideoModal}
          />
        </div>
        <video width="100%" controls>
          <source src={currentVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
    </>
  );
};

export default Preview;
