import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  message,
} from "antd";
import { get, post, put } from "../../../../../services/authService";
import moment from "moment";

import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import dayjs from "dayjs";

const { Option } = Select;

const EditTaskModal = ({ visible, taskId, setShowEditModal, setMessage }) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);

  const [taskCreators, setTaskCreators] = useState([]);
  const [taskCreatorsLoading, setTaskCreatorsLoading] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false)
  const onClose = () => {
    // form.resetFields()
    setShowEditModal(false);
  };

  const handleCountryChange = (country) => {
    
    setCities([]);
    setSiteVisits([]);
    setTaskCreators([]);
  
    form.setFieldsValue({
      city: undefined,
      siteIds: undefined,
      assignedTo: undefined,
    });
    
    get(`/api/GeoData/cities?countryId=${country}`)
      .then((res) => setCities(res.data))
      .catch((err) => console.log(err));
  
    const projectId = localStorage.getItem("currentProjectID");
    const countryData = locations.find((location) => location.id == country);
  
    
    get(`/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}&IgnorePagination=true`)
      .then((res) => setSiteVisits(res.data))
      .catch((err) => console.log(err));
  };
  

  const handleSubmit = async () => {
    try {
      setButtonLoading(true)
      const values = await form.validateFields();
  
      const payload = {
        id: taskId,
        name: values.taskName,
        deadline: values.deadline,
        taskLocation: {
          countryId: values.country,
          cityId: values.city,
        },
        collectionMethod: values.collectionMethod,
        type: values.taskType,
        otherType: values.otherType,
        description: values.description,
        numberOfInterviews: values.numberOfInterviews,
        contactInformation: values.contactInformation,
        additionalNotes: values.additionalNotes,
        status: values.taskStatus,
        siteVisitId: values.siteIds,
      };
  
      await put("/api/Tasks/updateTask", payload)
        .then(response => {
          const message = response.data.message || "This task was correctly updated to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Task updated successfully!",
            text: message,
          });
          onClose(); // Assuming this closes the form or modal after success
          fetchData(); // Assuming this fetches updated data
        })
        .catch((error) => {
          console.error("Failed to submit task:", error);
          const errorMessage = error.response?.data?.errors[0]?.message || "We had a problem updating this task, please try again.";
          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        })
        .finally(() => {
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          setButtonLoading(false)
        });
  
    } catch (error) {
      console.error("Form validation failed:", error);
      // Handle form validation errors if needed
    }
  };
  

  const fetchTeamBySiteVisit = async (siteVisitId) => {
    setTaskCreatorsLoading(true);
    try {
      const response = await get(
        `/api/Site/getTeamBySiteVisits?siteVisitIds=${siteVisitId}`
      );
      setTaskCreators(response.data || []);
    } catch (error) {
      console.error("Failed to fetch team by site visit:", error);
    } finally {
      setTaskCreatorsLoading(false);
    }
  };

  useEffect(() => {
    if (taskId) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const taskResponse = await get(`/api/Tasks/getTask?id=${taskId}`);
          const task = taskResponse.data;

          form.setFieldsValue({
            taskIdentifier: task?.identifier,
            taskName: task?.name,
            deadline: dayjs(task.deadline),
            country: task?.taskLocation?.country?.id,
            city: task?.taskLocation?.city?.id,
            siteIds: task?.taskSite?.id,
            assignedTo: task?.taskUsers.map((user) => user.userName),
            district: task?.taskLocation?.district,
            collectionMethod: task?.collectionMethod,
            description: task?.description,
            numberOfInterviews: task?.numberOfInterviews,
            contactInformation: task?.contactInformation,
            additionalNotes: task?.additionalNotes,
            taskType: task?.type,
            taskStatus: task?.status,
          });

          const countriesResponse = await get(`/api/GeoData/countries`);
          setLocations(countriesResponse.data);

          const countryId = task?.taskLocation?.country?.id;
          const citiesResponse = await get(
            `/api/GeoData/cities?countryId=${countryId}`
          );
          setCities(citiesResponse.data);

          const projectId = localStorage.getItem("currentProjectID");

          const countryData = locations.find(
            (location) => location.id == countryId
          );
          const siteVisitsResponse = await get(
            `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}`
          );
          setSiteVisits(siteVisitsResponse.data);

          const teamResponse = await get(
            `/api/Site/getTeamBySiteVisits?siteVisitIds=${task?.taskSite?.id}`
          );
          const members = teamResponse?.data;
          setTaskCreators(members);

          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [taskId, form]);

  if (!context) {
    return <></>;
  }
  const { fetchData } = context;

  return (
    <Modal
      title="Edit Task Card"
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button className="inviteButtonTPM" key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={buttonLoading}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Task card identifier" name="taskIdentifier">
            <Input
              placeholder="xxxx - Task Name - Task Type - date created"
              disabled
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Task name"
                name="taskName"
                rules={[
                  { required: true, message: "Please enter the task name" },
                ]}
              >
                <Input placeholder="Enter text here" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                    message: "Please select the deadline date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // onChange={(date) => handleInputChange("deadline", date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Please select Country" }]}
              >
                <Select
                  placeholder="Select an option"
                  onChange={handleCountryChange}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {locations && locations.map((location) => (
                    <Option key={location?.id} value={location?.id}>
                      {location?.stateName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please select City" }]}
              >
                <Select
                  placeholder="Select a city"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={cities?.length < 1 ? true : false}
                >
                  {cities &&
                    cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Col span={20}>
            <Form.Item
              label="Pick the site visit card"
              name="siteIds"
              rules={[
                { required: true, message: "Please input your site ID!" },
              ]}
            >
              <Select
                placeholder="Select a site visit"
                onChange={(value) => {
                  fetchTeamBySiteVisit(value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={siteVisits?.length < 1}
              >
                {siteVisits &&
                  siteVisits.map((visit) => (
                    <Option key={visit.id} value={visit.id}>
                      {visit.identifier}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Assigned to"
              name="assignedTo"
              rules={[{ required: false, message: "Please select a person" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select an option"
                disabled={taskCreators?.length < 1 ? true : false}
              >
                {taskCreatorsLoading ? (
                  <Option>
                    <Spin
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px 0",
                      }}
                    />
                  </Option>
                ) : (
                  taskCreators.map((team) =>
                    team?.members?.map((member) => (
                      <Option key={member?.id} value={member?.id}>
                        {member?.firstName ?? ""} {member?.username ?? ""} (
                        {member?.roleName ?? ""})
                      </Option>
                    ))
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Collection method" name="collectionMethod">
              <Input
                placeholder="Enter the collection method"
                // onChange={(e) =>
                //   handleInputChange("collectionMethod", e.target.value)
                // }
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              label="Task Type"
              name="taskType"
              rules={[{ required: true, message: "Please select a task type" }]}
            >
              <Select placeholder="Select an option">
                {taskTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Form.Item label="Task description" name="description">
            <Input.TextArea
              rows={3}
              placeholder="Enter the task description"
              // onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Number of interviews" name="numberOfInterviews">
            <InputNumber span={6} min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Contact information" name="contactInformation">
            <Input
              placeholder="Enter the contact information"
              // onChange={(e) =>
              //   handleInputChange("contactInformation", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item label="Any additional notes" name="additionalNotes">
            <Input.TextArea
              rows={3}
              placeholder="Enter additional notes"
              // onChange={(e) =>
              //   handleInputChange("additionalNotes", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item
            label="Task status"
            name="taskStatus"
            rules={[
              { required: true, message: "Please select the task status" },
            ]}
          >
            <Select placeholder="Select the task status">
              <Option value={1}>Archived</Option>
              <Option value={2}>Completed</Option>
              <Option value={3}>On hold</Option>
              <Option value={4}>Accepted</Option>
              <Option value={5}>Assigned</Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditTaskModal;
