import { Modal, Form, Input, Button, Divider, Typography, message, Spin } from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import LocationDetails from "./LocationDetails";
import VisitDetails from "./VisitDetails";
import Logistics from "./Logistics";
import ResourcesDetails from "./ResourcesDetails";
import TeamDetails from "./TeamDetails";
import Postponement from "./Postponement";
import TrackingDetails from "./TrackingDetails";
import { useEffect, useState } from "react";
import { post } from "../../../../../services/authService";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";
import { useSiteVisitStore } from "./store/siteVisitStore";

const { TextArea } = Input;

const { Title } = Typography;

const EditSiteVisitCardModal = ({ visible, onCancel, onOk, siteVisitCardId}) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const { setSiteVisitList, setOtherDocuments, setSiteVideos} = useSiteVisitStore();

  const [SiteLoader, setSiteLoader] = useState(false)
  const [siteID, setSiteID] = useState('');

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    if(visible && siteVisitCardId) {
      setSiteLoader(true);
      httpService.get(`/api/Site/getSiteVisit?id=${siteVisitCardId}`, (res) => {
        setSiteLoader(false);
        setSiteID(res?.data?.id);

        const initialData = {
          siteContacts: res?.data?.visit?.contacts?.filter((e) => e?.contactType == 1)?.map((e) => ({...e, contactName: e.name})),
          pointContacts: res?.data?.visit?.contacts?.filter((e) => e?.contactType == 2)?.map((e) => ({...e, contactName: e.name})),
          KIIcontacts: res?.data?.visit?.contacts?.filter((e) => e?.contactType == 3)?.map((e) => ({...e, contactName: e.name})),

          IPlaningCard: res?.data?.ipPlan?.id,
          cluster: res?.data?.cluster?.id,
          sector: res?.data?.sector?.id,
          subSector: res?.data?.subSector?.id,
          projectId: projectId,
          latitude: res?.data?.locationDetails?.latitude,
          longitude: res?.data?.locationDetails?.longitude,
          isVirtual: res?.data?.isVirtual,
          dateOfPlannedVisit: moment(new Date(res?.data?.visit?.plannedVisit), 'YYYY-MM-DD HH:mm:ss'),
    
          observationList: res?.data?.observationList,
          beneficiariesCount: res?.data?.visit?.interviewDetails?.beneficiariesCount,
          kills: res?.data?.visit?.interviewDetails?.clientsCount,
          observationChecklistCount: res?.data?.visit?.interviewDetails?.observationChecklist,
          mediaLimit: res?.data?.visit?.interviewDetails?.mediaLimit,
          samplingInstructions: res?.data?.visit?.interviewDetails?.samplingInstructions, 
          clientInstructions: res?.data?.visit?.interviewDetails?.clientInstructions, 
          otherInstructions: res?.data?.visit?.interviewDetails?.otherInstructions, 
          visitPlanApproval: res?.data?.visit?.interviewDetails?.visitPlanApproval, 
          
          specificsOfOnSiteOperation: res?.data?.specificsOfOnSiteOperation, 
          observationList: res?.data?.observationList,
          
          tpmCoordinator: res?.data?.logistic?.tpmCoordinator?.id,
          coordinationType: res?.data?.logistic?.coordinationType,
          fulcrumAccount: res?.data?.logistic?.fulcrumAccount,
          activationDate: res?.data?.logistic?.activationDate,
          isTeamLocal: res?.data?.logistic?.isTeamLocal,
    
          team: res?.data?.logistic?.team?.id,
          isPostponed: res?.data?.logistic?.isPostponed, 
          postponedReason: res?.data?.logistic?.postponedReason, 
          postponedDetails: res?.data?.logistic?.postponedDetails, 
          notes: res?.data?.logistic?.notes, 

          // report_documents: record?.siteVisitFiles[0],
          // other_files: record?.siteVisitFiles[1],
          // video_files: record?.siteVisitFiles[2],
  
        }

        setSiteVisitList(res?.data?.siteVisitFiles?.find(e => e?.fileCategory == 1))
        setOtherDocuments(res?.data?.siteVisitFiles?.find(e => e?.fileCategory == 2))
        setSiteVideos(res?.data?.siteVisitFiles?.find(e => e?.fileCategory == 3))

        setInitialValues(initialData);
        form.setFieldsValue(initialData);
      }, (err) => {
        setSiteLoader(false);
        console.log(err);
      })
    }
  },[visible])

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return '';
  
    if(type) {
      const newDate = new Date(date);
      const formattedDate = moment(new Date(newDate.setDate(newDate.getDate() - 1)));
      return formattedDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
      const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
      return formattedDate;
    }
  };
  
  const formatHours = (hrs) => {
    if (!hrs?.$isDayjsObject) return '';
  
    const formattedTime = hrs.format('HH:mm:ss');
    return formattedTime;
  };
  

  const transformSiteContacts = (siteContactsObj) => {
    const siteContacts = siteContactsObj?.siteContacts;
    if (!siteContacts || !Array.isArray(siteContacts)) {
      return [];
    }

    return siteContacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 1,
    }));
  };

  const transformPointContacts = (pointContactsObj) => {
    const pointContacts = pointContactsObj?.siteContacts;
    if (!pointContacts || !Array.isArray(pointContacts)) {
      return [];
    }

    return pointContacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 2,
    }));
  };

  const transformKIIcontacts = (KIIcontactsObj) => {
    const KIIcontacts = KIIcontactsObj?.siteContacts;
    if (!KIIcontacts || !Array.isArray(KIIcontacts)) {
      return [];
    }

    return KIIcontacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 3,
    }));
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const plannedVisit = `${formatDate(values.dateOfPlannedVisit)}`;
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const activationDate = formatDate(values.dateOfPlannedVisit, 'activation');
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);

        let siteContacts = values.siteContacts;
        let pointContacts = values.pointContacts;
        let KIIcontacts = values.KIIcontacts;

        siteContacts?.map(contact => contact.contactType = 1)
        pointContacts?.map(contact => contact.contactType = 2)
        KIIcontacts?.map(contact => contact.contactType = 3)

        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];
  
        const formData = new FormData();
        formData.append("Id", siteID);
        formData.append("IPPlanId", values.IPlaningCard);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector);
        formData.append("ProjectId", projectId);
        formData.append("LocationDetails.Latitude", values.latitude );
        formData.append("LocationDetails.Longitude", values.longitude );
        formData.append("isVirtual", values.isVirtual);
        formData.append("Visit.PlannedVisit", plannedVisit);

        createContacts.forEach((contact, index) => {
          if(contact?.id) {
            formData.append(`visit.contacts[${index}].id`, contact.id);
          }
          formData.append(`visit.contacts[${index}].name`, contact.contactName);
          formData.append(`visit.contacts[${index}].email`, contact.email);
          formData.append(`visit.contacts[${index}].role`, contact.role);
          formData.append(`visit.contacts[${index}].phone`, contact.phone || "");
          formData.append(`visit.contacts[${index}].skype`, contact.skype || "");
          formData.append(`visit.contacts[${index}].organization`, contact.organization || "");
          formData.append(`visit.contacts[${index}].contactType`, contact.contactType);
        });
  
        formData.append("Visit.ObservationListCheck", values.observationList || false);
        formData.append("Visit.CreateInterviewDetails.BeneficiariesCount", values.beneficiariesCount);
        formData.append("Visit.CreateInterviewDetails.ClientsCount", values.kills);
        formData.append("Visit.CreateInterviewDetails.ObservationChecklist", values.observationChecklistCount);
        formData.append("Visit.CreateInterviewDetails.MediaLimit", values.mediaLimit);
        formData.append("Visit.CreateInterviewDetails.SamplingInstructions", values.samplingInstructions || '');
        formData.append("Visit.CreateInterviewDetails.ClientInstructions", values.clientInstructions || '');
        formData.append("Visit.CreateInterviewDetails.OtherInstructions", values.otherInstructions || '');
        formData.append("Visit.VisitPlanApproval", values.visitPlanApproval || '');
  
        formData.append("specificsOfOnSiteOperation", values.specificsOfOnSiteOperation);
        formData.append("ObservationList", values.observationList || false);
        
        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);
        formData.append("Logistic.ActivationDate", activationDate);
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed || false);
        formData.append("Logistic.PostponedReason", values.postponedReason || '');
        formData.append("Logistic.PostponedDetails", values.postponedDetails || '');
        formData.append("Logistic.Notes", values.notes || '');
      

        if (values.report_documents?.name) {
          formData.append("UpdateFiles[0].FileCategory", 1);
          formData.append("UpdateFiles[0].File", values.report_documents || undefined);
        } else if (values.report_documents?.fileName) {
        } else {
          formData.append("UpdateFiles[0].FileCategory", 1);
          formData.append("UpdateFiles[0].File", undefined);        
        }


        if (values.other_files?.name) {
          formData.append("UpdateFiles[1].FileCategory", 2);
          formData.append("UpdateFiles[1].File", values.other_files || undefined);
        } else if (values.other_files?.fileName) {
        } else {
          formData.append("UpdateFiles[1].FileCategory", 2);
          formData.append("UpdateFiles[1].File", undefined);        
        }


        if (values.video_files?.name) {
          formData.append("UpdateFiles[2].FileCategory", 3);
          formData.append("UpdateFiles[2].File", values.video_files || undefined);
        } else if (values.video_files?.fileName) {
        } else {
          formData.append("UpdateFiles[2].FileCategory", 3);
          formData.append("UpdateFiles[2].File", undefined);        
        }

        setSubmitLoader(true);
        httpService.put("/api/Site/updateSiteVisit", formData, (res) => {
          message.success("You have successfully modified the site visit");
          setSubmitLoader(false);
          form.resetFields();
          onOk();
        }, (error) => {
          console.error("There was an error adding the project!", error);
          setSubmitLoader(false);
          message.error("Failed to create site visit. Please try again later.");
        }, {
          headers: {
             "Content-Type": "multipart/form-data",
          },
       })
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        message.error("Please fill all the required fields.");
      });
  };
  

  return (
    <Modal
      title="Edit site visit card"
      open={visible}
      onCancel={onCancel}
      width={800}
      footer={[
        <Button key="back" onClick={onCancel} className="closeButton">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="submitButton"
          loading={submitLoader}
        >
        Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={SiteLoader}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="Site visit card identifier" name="identifier">
            <Input placeholder="### - Namegoeshere - Location - date" disabled/>
          </Form.Item>
          <GeneralDetails />
          <Divider />
          <h3>Location details</h3>
          <LocationDetails form={form}/>

          <Divider />
          <h3>Visit details</h3>

          <VisitDetails />

          <Divider />
          <h3>Logistics</h3>

          <Logistics />
          
          <Divider />
          <h3>Team</h3>
          <TeamDetails />
          
          <Divider/>
          <h3>Postponement</h3>
          <Postponement />
          
          <Divider />
          <h3>Resources</h3>

          <ResourcesDetails form={form} />

          <Divider />

          {/* <h3>Tracking</h3>
          <TrackingDetails /> */}

          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default EditSiteVisitCardModal;
