import React from "react";
import TableView from "./TableView/TableView";

function TeamCards({teams,fetchTeams}) {




  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Enumerator team cards</span>
      </div>
      <TableView teams={teams} fetchTeams={ fetchTeams} />
    </div>
  );
}

export default TeamCards;
