export const taskTypes = [
    { id: 1, name: "Ops preparation" },
    { id: 2, name: "Data Collection" },
    { id: 3, name: "Data check" },
    { id: 4, name: "Translation" },
    { id: 5, name: "Field report" },
    { id: 6, name: "Data cleaning" },
    { id: 7, name: "Data analysis" },
    { id: 8, name: "Data translation" },
    { id: 9, name: "IP report" },
    { id: 10, name: "Donor report" },
    { id: 11, name: "Other" },
  ];