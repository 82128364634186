import { PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Row,
  Select,
  Switch,
  Card,
  Divider,
  Button,
  Tooltip,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { get } from "../../../../../services/authService";
import { httpService } from "../../../../../services/httpService.service";
import TeamAddNew from "./TeamAddNew";

const { Option } = Select;

const TeamDetails = ({ teamCreated }) => {
  const [teams, setTeams] = useState([]);

  const [singleTeamLoader, setSingleTeamLoader] = useState(false);
  const [singleTeam, setSingleTeam] = useState({});

  const [teamCardVisible, setTeamCardVisible] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);

  const getAllTeams = () => {
    const contractId = localStorage.getItem("currentContractId");
    const apiTeams = `/api/User/getAllTeams?contractId=${contractId}`;
    setTeamLoading(true);

    get(apiTeams)
      .then((res) => {
        setTeams(res.data?.filter((e) => e?.isActive === true));
        setTeamLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTeamLoading(false);
      });
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  const selectTeam = (teamID) => {
    setSingleTeamLoader(true);
    httpService.get(
      `/api/User/getTeamById?teamId=${teamID}`,
      (res) => {
        setSingleTeam(res?.data);
        setSingleTeamLoader(false);
      },
      (err) => {
        console.log(err);
        setSingleTeamLoader(false);
      }
    );
  };

  const onClose = () => {
    setTeamCardVisible(false);
  };

  return (
    <div>
      <div className="tripLogisticsCard">
        <div className="tripLogicWrapper">
          <h4>
            If you don't see your team on the list, create a new team card.
          </h4>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setTeamCardVisible(true)}
            iconPosition="end"
            style={{ display: "flex", flexDirection: "row" }}
          >
            Create team card
          </Button>
        </div>
      </div>
      {teamCardVisible ? (
        <div className="teamAdd">
          <TeamAddNew onClose={onClose} teamCreated={() => getAllTeams()} />
        </div>
      ) : (
        <></>
      )}
      <Row gutter={16} className="d-flex align-items-center">
        <Col span={12}>
          <Spin spinning={teamLoading}>
            <Form.Item
              label="Select a team name for data collection"
              name="team"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select a team"
                onChange={(team) => selectTeam(team)}
              >
                {teams &&
                  teams.map((team) => (
                    <Option key={team?.id} value={team?.id}>
                      {team?.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Spin>
        </Col>

        <Col span={6}>
          <div className="d-flex align-items-center" style={{ gap: "10px" }}>
            <Form.Item
              name="isTeamLocal"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <span>Team is local</span>
          </div>
        </Col>
      </Row>

      {singleTeam?.members?.length ? (
        <div className="teamDetails">
          <Card title="Team Details">
            <Divider />
            {singleTeam?.members
              ?.filter((el) => el?.roleName === "Field Team Leader")
              ?.map((el) => (
                <p>
                  <span style={{ fontWeight: "700" }}>Team Lead:</span>{" "}
                  {el?.username}
                </p>
              ))}
            {singleTeam?.members
              ?.filter((el) => el?.roleName !== "Field Team Leader")
              ?.map((el, i) => (
                <p>
                  <span style={{ fontWeight: "700" }}>Member {i + 1} :</span>{" "}
                  {el?.username}
                </p>
              ))}
            <div className="seeMoreBtn">
              {/* <Button className="d-flex align-items-center">
                  See more
                  <DownOutlined />
                </Button> */}
            </div>
          </Card>
        </div>
      ) : singleTeamLoader ? (
        <Spin style={{ width: "100%" }} />
      ) : (
        <></>
      )}
    </div>
  );
};
export default TeamDetails;
