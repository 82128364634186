import { Button, Modal } from "antd";
import React from "react";
import downloadList from "../../../../Assets/svg/downloadList.svg";
import { useFilePreview } from "../../completed/ReportingList/Download";

function PreviewModal({ visible, onClose, item }) {

  const { previewFiles } = useFilePreview();

  return (
    <Modal
      title="File information"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" className="mt-4  inviteButtonTPM" onClick={onClose}>
          Close
        </Button>,
        <Button
          onClick={(e) => previewFiles(e, item.filePath, item.id)}
          className="mt-4 addButtonTPM"
        >
         Download <img src={downloadList} alt="Download" />
        </Button>,
      ]}
    >
      {/* <Spin spinning={loading}> */}
      {item ? (
        <>
          <p>
            <strong>File name:</strong> {item?.fileName}
          </p>
          <p>
            <strong>Identifier:</strong> {item.identifier}
          </p>
        </>
      ) : (
        <p>Loading item details...</p>
      )}
      {/* </Spin> */}
    </Modal>
  );
}

export default PreviewModal;
