import { Modal, Form, Input, Button, Divider, Typography, message, Spin } from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import TrackingDetails from "./TrackingDetails";
import { useEffect, useState } from "react";
import { post } from "../../../../../services/authService";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";

const { TextArea } = Input;

const { Title } = Typography;

const TrackingModal = ({ visible, onCancel, onOk, siteVisitCardId, tracked}) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    // console.log()
    if(siteVisitCardId && visible){
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

    httpService.get(apiSiteVisitCards, (res) =>{

      if(tracked) {
        const initialData = {
            identifier: res?.data?.identifier,
            actualDateOfVisit: moment(new Date(res?.data?.tracking?.actualDateOfVisit), 'YYYY-MM-DD HH:mm:ss'),
            dateOfReport: moment(new Date(res?.data?.tracking?.dateOfReport), 'YYYY-MM-DD HH:mm:ss'),
            dateOfDebrief: moment(new Date(res?.data?.tracking?.dateOfDebrief), 'YYYY-MM-DD HH:mm:ss'),
            fieldTeamReportingStatus: res?.data?.tracking?.fieldTeamReportingStatus ,
            percentageOfDataCollectionCompleted: res?.data?.tracking?.percentageOfDataCollectionCompleted ,
            reportingMonth: res?.data?.tracking?.reportingMonth ,
            reportingYear: res?.data?.tracking?.reportingYear ,
            siteVisitStatus: res?.data?.tracking?.siteVisitStatus ,
            trainingDate: moment(new Date(res?.data?.tracking?.trainingDate), 'YYYY-MM-DD HH:mm:ss'),
            trainingIsCompleted: res?.data?.tracking?.trainingIsCompleted ,
            trainingScoreInfo: res?.data?.tracking?.trainingScoreInfo 
        }
        form.setFieldsValue(initialData);
      } else {
        const initialData = {
            identifier: res?.data?.identifier
        }
        form.setFieldsValue(initialData);
      }
      setLoading(false);
    }, (err) => {
      console.log("Error: ",err);
      setLoading(false);
    });
    }
  }, [visible]);

  const closeModal = () => {
    form.resetFields();
    onCancel();
  }

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return '';
  
    if(type) {
      const newDate = new Date(date);
      const formattedDate = moment(new Date(newDate.setDate(newDate.getDate() - 1)));
      return formattedDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
      const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
      return formattedDate;
    }
  };
  
  const formatHours = (hrs) => {
    if (!hrs?.$isDayjsObject) return '';
  
    const formattedTime = hrs.format('HH:mm:ss');
    return formattedTime;
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);
  
        const formData = new FormData();

        if(tracked) {
          formData.append("Id", siteVisitCardId);
          formData.append("Tracking.ReportingYear", values.reportingYear);
          formData.append("Tracking.ReportingMonth", values.reportingMonth);
          formData.append("Tracking.TrainingDate", trainingDate);
          formData.append("Tracking.TrainingScoreInfo", values.trainingScoreInfo);
          formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
          formData.append("Tracking.DateOfReport", dateOfReport);
          formData.append("Tracking.DateOfDebrief", dateOfDebrief);
          formData.append("Tracking.PercentageOfDataCollectionCompleted", values.percentageOfDataCollectionCompleted);
          formData.append("Tracking.SiteVisitStatus", values?.siteVisitStatus);
          formData.append("Tracking.FieldTeamReportingStatus", values?.fieldTeamReportingStatus);
          formData.append("Tracking.TrainingIsCompleted", values.trainingIsCompleted || false);

          httpService.put("/api/Site/updateSiteVisit", formData, (res) => {
            message.success("You have successfully updated tracking in site visit");
            setSubmitLoader(false);
            form.resetFields();
            onOk();
          }, (error) => {
            console.error("There was an error tracking this site visit!", error);
            setSubmitLoader(false);
            message.error("Failed to create tracking for site visit. Please try again later.");
          }, {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         })

        } else {

          formData.append("siteVisitId", siteVisitCardId);
          formData.append("reportingYear", values.reportingYear);
          formData.append("reportingMonth", values.reportingMonth);
          formData.append("trainingDate", trainingDate);
          formData.append("trainingScoreInfo", values.trainingScoreInfo);
          formData.append("actualDateOfVisit", actualDateOfVisit);
          formData.append("dateOfReport", dateOfReport);
          formData.append("dateOfDebrief", dateOfDebrief);
          formData.append("percentageOfDataCollectionCompleted", values.percentageOfDataCollectionCompleted);
          formData.append("siteVisitStatus", values?.siteVisitStatus);
          formData.append("fieldTeamReportingStatus", values?.fieldTeamReportingStatus);
          formData.append("trainingIsCompleted", values.trainingIsCompleted || false);

          httpService.post("/api/Site/createTracking", formData, (res) => {
            message.success("You have successfully tracked site visit");
            setSubmitLoader(false);
            form.resetFields();
            onOk();
          }, (error) => {
            console.error("There was an error tracking this site visit!", error);
            setSubmitLoader(false);
            message.error("Failed to create tracking for site visit. Please try again later.");
          })
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        message.error("Please fill all the required fields.");
      });
  };
  

  return (
    <Modal
      title="Site visit card"
      open={visible}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={onCancel} className="closeButton">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="submitButton"
          loading={submitLoader}
        >
          {
            tracked ?
              `Edit Tracking` :
              `Add Tracking`
          }
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <div className="indentifierTracking">
            <Form.Item label="Site visit card identifier" name="identifier">
              <Input placeholder="### - Namegoeshere - Location - date" disabled/>
            </Form.Item>
          </div>
          <Divider />

          <h3>Tracking</h3>
          <TrackingDetails />

          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default TrackingModal;
