import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Select,
  DatePicker,
  Upload,
  Radio,
  Space,
  Tooltip,
  Slider,
} from "antd";
import "../../../pages/UserManagement/UserManagement.scss";
import "../Flags.scss";
import "./modalStyles.scss";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const PostVisitCardModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log("Form values:", values);
      form.resetFields();
      onClose();
      message.success("Card added successfully!");
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  //Select question type
  const [toogleQuestionType1, setToogleQuestionType1] = useState(1);

  const handleQuestionType = (value) => {
    setToogleQuestionType1(value.target.value);
  };

  //Question type 2 data

  const [value, setValue] = useState(3);

  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <span>Last update:  2024-04-23 12:23:01</span>
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Post-visit card</h2>
      <Form form={form} layout="vertical">
        {" "}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Select a date" }]}
            style={{ width: "48%" }}
          >
            <DatePicker placeholder="Select date" />
          </Form.Item>
          <Form.Item
            name="staffName"
            label="Staff name"
            rules={[{ required: true, message: "Enter the staff name" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter the staff name" autoComplete="off" />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="ip"
            label="IP"
            rules={[{ required: true, message: "Enter the IP" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter the IP" autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="location1"
            label="Location 1"
            rules={[{ required: true, message: "Enter the location" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter the location" autoComplete="off" />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="pickTheSiteVisitCard"
            label="Pick the site visit card"
            rules={[{ required: true, message: "Select the site visit" }]}
            style={{ width: "48%" }}
          >
            <Select placeholder="Select the site visit">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="questionType">
          <div className="questionTypeHeader">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="question1"
                label="Choose the question type:"
                rules={[{ required: true, message: "Select a date" }]}
              >
                <Radio.Group
                  onChange={handleQuestionType}
                  className="custom-radio-group d-flex w-100"
                  defaultValue={toogleQuestionType1}
                >
                  <Radio value={1} className="custom-radio yes-radio">
                    Binary
                  </Radio>
                  <Radio value={2} className="custom-radio no-radio">
                    Likert scale
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div
            className={
              toogleQuestionType1 === 1
                ? "questionTypeBody1"
                : "questionTypeBody1 hidden"
            }
          >
            <Form.Item
              name="question2"
              label="Did you observe any conduct concerns with the IP staff?"
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Radio.Group className="custom-radio-group d-flex w-100">
                <Radio value={1} className="custom-radio yes-radio">
                  Yes
                </Radio>
                <Radio value={2} className="custom-radio no-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="question3"
              label="Did you observe any concerns for the safety of beneficiaries or staff?"
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Radio.Group className="custom-radio-group d-flex w-100">
                <Radio value={1} className="custom-radio yes-radio">
                  Yes
                </Radio>
                <Radio value={2} className="custom-radio no-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>{" "}
            <Form.Item
              name="question4"
              label="Did you observe any concerns of serious inconveniences for beneficiaries or staff?"
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Radio.Group className="custom-radio-group d-flex w-100">
                <Radio value={1} className="custom-radio yes-radio">
                  Yes
                </Radio>
                <Radio value={2} className="custom-radio no-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>{" "}
            <Form.Item
              name="question5"
              label="Did you observe any concerns of corruption or diverted aid?"
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Radio.Group className="custom-radio-group d-flex w-100">
                <Radio value={1} className="custom-radio yes-radio">
                  Yes
                </Radio>
                <Radio value={2} className="custom-radio no-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>{" "}
            <Form.Item
              name="question6"
              label="Did you observe any protection/security/violence concerns?"
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Radio.Group className="custom-radio-group d-flex w-100">
                <Radio value={1} className="custom-radio yes-radio">
                  Yes
                </Radio>
                <Radio value={2} className="custom-radio no-radio">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div
            className={
              toogleQuestionType1 === 2
                ? "questionTypeBody2"
                : "questionTypeBody2 hidden"
            }
          >
            <Form.Item
              name="question7"
              label={
                <div className="questionInfo">
                  The integrity of the information provided by beneficiaries was
                  not affected by IP staff being there.
                  <span>
                    (1-Strongly Disagree, 2-Disagree, 3-Neither disagree nor
                    agree, 4-Agree, 5-Strongly Agree)
                  </span>
                </div>
              }
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Slider
                marks={marks}
                step={1}
                min={1}
                max={5}
                defaultValue={3}
                value={value}
                onChange={setValue}
              />
            </Form.Item>
            <Form.Item
              name="question8"
              label={
                <div className="questionInfo">
                  The observed facilities or space met the needs/expected
                  conditions for the observed activity.
                  <span>
                    (1-Strongly Disagree, 2-Disagree, 3-Neither disagree nor
                    agree, 4-Agree, 5-Strongly Agree)
                  </span>
                </div>
              }
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Slider
                marks={marks}
                step={1}
                min={1}
                max={5}
                defaultValue={3}
                value={value}
                onChange={setValue}
              />
            </Form.Item>
            <Form.Item
              name="question9"
              label={
                <div className="questionInfo">
                  The observed staff treated beneficiaries with respect, with
                  proper conduct.
                  <span>
                    (1-Strongly Disagree, 2-Disagree, 3-Neither disagree nor
                    agree, 4-Agree, 5-Strongly Agree)
                  </span>
                </div>
              }
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Slider
                marks={marks}
                step={1}
                min={1}
                max={5}
                defaultValue={3}
                value={value}
                onChange={setValue}
              />
            </Form.Item>
            <Form.Item
              name="question10"
              label={
                <div className="questionInfo">
                  There were no violations of TPM criteria observed.
                  <span>
                    (1-Strongly Disagree, 2-Disagree, 3-Neither disagree nor
                    agree, 4-Agree, 5-Strongly Agree)
                  </span>
                </div>
              }
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Slider
                marks={marks}
                step={1}
                min={1}
                max={5}
                defaultValue={3}
                value={value}
                onChange={setValue}
              />
            </Form.Item>
            <Form.Item
              name="question11"
              label={
                <div className="questionInfo">
                  We observed awareness or indications of a functioning CFM
                  process.
                  <span>
                    (1-Strongly Disagree, 2-Disagree, 3-Neither disagree nor
                    agree, 4-Agree, 5-Strongly Agree)
                  </span>
                </div>
              }
              rules={[{ required: true, message: "Select a date" }]}
            >
              <Slider
                marks={marks}
                step={1}
                min={1}
                max={5}
                defaultValue={3}
                value={value}
                onChange={setValue}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          name="anyOthersFeedback"
          label={
            <span>
              Any other feedback&nbsp;
              <Tooltip title="Enter any additional feedback here">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[{ required: true, message: "Enter other feedback" }]}
          style={{ width: "100%" }}
        >
          <Input.TextArea
            rows={4}
            placeholder="Enter your follow up notes"
            maxLength={6}
          />
        </Form.Item>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Save changes
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PostVisitCardModal;
