import React, { useState } from "react";
import PersonalInformation from "./PersonalInformation";
import "./index.scss";
import { Button, Col, Row } from "antd";
import DeactivateAccountModal from "./DeactivateAccountModal";

const ProfileInformation = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   

   const handleAccountModal = () => {
      setIsModalOpen((prev) => !prev);
   };

   let userEmail = localStorage.getItem("userEmail");
   let userName = localStorage.getItem('username')

   return (
      <div className="profileInformation accountSettings">
         <div className="userLogo">
            <div className="logo">
               <span>{userName && userName !== undefined ? userName.charAt(0) : userEmail.charAt(0)}</span>
            </div>
         </div>
         <div className="profileContent">
            <Row gutter={16} align="middle">
               <Col xs={24} sm={12} lg={12} xl={12}>
                  <p className="title">Profile information</p>
               </Col>
               <Col xs={24} sm={12} lg={12} xl={12} justify="flex-end">
                  <Button disabled type="default" className="inviteButtonTPM" onClick={handleAccountModal}>
                     Deactivate account
                  </Button>
               </Col>
            </Row>
            <PersonalInformation />
         </div>
         {isModalOpen && (
            <DeactivateAccountModal
               isModalOpen={isModalOpen}
               handleAccountModal={handleAccountModal}
            ></DeactivateAccountModal>
         )}
      </div>
   );
};

export default ProfileInformation;
