import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Select,Checkbox, Spin } from "antd";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { get, post } from "../../../../../services/authService";
import AddMemberIcon from '../../../../../Assets/svg/addMemberIcon.svg'
import '../../../UserManagement/UserManagement.scss'
// import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../../services/httpService.service";

const { Option } = Select;

const TeamAddNew = ({ visible, onClose, fetchTeams,setMessage, teamCreated }) => {
  const [teamCardName, setTeamCardName] = useState('');

  const [enumerators, setEnumerators] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [selectedEnumerator, setSelectedEnumerator] = useState(null);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountryForCity, setSelectedCountryForCity] = useState(null);
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedFieldCoordinator, setSelectedFieldCoordinator] = useState(null);
  const [fieldCoordinators, setFieldCoordinators] = useState([]);
  const currentContractId = localStorage.getItem("currentContractId");

  const [fetchEnumeratorsLoader, setFetchEnumeratorsLoader] = useState(false);
  const [creatingLoader, setCreatingLoader] = useState(false);

  const [roles, setRoles] = useState([]);

  // const initialValues = {
  //   teamCardName: "",
  //   enumeratorId: null,
  //   teamLeaderId: null,
  //   fieldCoordinatorId: null,
  //   isActive: true,
  //   countryId: null,
  //   cityId: null
  // };

  const validationSchema = Yup.object().shape({
    teamCardName: Yup.string().required("Please enter team card name"),
    enumeratorId: Yup.string().required("Please select an enumerator"),
    teamLeaderId: Yup.string().required("Please select a team leader"),
    fieldCoordinatorId:Yup.string().required("Please select a field coordinator"),
    countryId: Yup.string().required("Please select a country"),
    cityId: Yup.string().required("Please select a city"),
  });
  

  const resetForm = () => {
    setSelectedEnumerator(null);
    setSelectedTeamLeader(null);
    setSelectedFieldCoordinator(null);
  };

  const fetchAllUsers = async () => {
    let url = `/api/User/getAllUsers?pageSize=100000&pageNumber=1&ContractIds=${currentContractId}`;
    url = url+'&'+roles?.map((e) => 'RoleIds='+e?.id)?.join('&')
    try {
      await httpService.get(`${url}`,
        (res) => {
          const filteredFieldCoordinators = res?.data?.filter(user =>
            user?.userContractRoles?.some(role => role?.role?.roleName == "Field Coordinator")
          );
          const filteredFieldEnumerators = res?.data?.filter(user =>
            user?.userContractRoles?.some(role => role?.role?.roleName == "Field Enumerator")
          );
          const filteredFieldTeamLeaders = res?.data?.filter(user =>
            user?.userContractRoles?.some(role => role?.role?.roleName == "Field Team Leader")
          );
          setFieldCoordinators(filteredFieldCoordinators);
          setEnumerators(filteredFieldEnumerators);
          setTeamLeaders(filteredFieldTeamLeaders);
          setFetchEnumeratorsLoader(false);
        }, (err) => {
          console.log(err);
          setFetchEnumeratorsLoader(false);
        }
      )
    } catch(err) {
      setFetchEnumeratorsLoader(false);
      console.log("Error fetching users")
    }
  }

  const fetchAllRoles =  async () => {
    setFetchEnumeratorsLoader(true);
    try {
      await httpService.get(`/api/Account/getAllRoles`,
        (res) => {
          const rolet = res?.data;
          setRoles(rolet?.filter((e) => e?.roleName === 'Field Enumerator' || e?.roleName === 'Field Team Leader' || e?.roleName === 'Field Coordinator'))
          fetchAllUsers()
        }, (err) => {
          console.log('err', err);
          setFetchEnumeratorsLoader(false);
        }
      )
    } catch (error) {
      console.log("Error fetching all roles: ", error);
      setFetchEnumeratorsLoader(false);
    }
  }

  useEffect(() => {
    fetchAllRoles()
  }, [currentContractId]);

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllCountries(res?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedCountryForCity) {
      get(`/api/GeoData/cities?countryId=${selectedCountryForCity}`)
        .then((res) => {
          setCity(res?.data);
        })
        .catch((error) => console.log(error));
    } else {
      setCity([]);
      setSelectedCity(null);
    }
  }, [selectedCountryForCity]);

  const handleSubmit = () => {
    const payload = {
      name: teamCardName,
      contractId: currentContractId,
      memberIds: [selectedEnumerator?.id, selectedTeamLeader?.id,selectedFieldCoordinator?.id],
      isActive: true,
      countryId: selectedCountryForCity,
      cityId: selectedCity
    };

    // const isValid = validationSchema.validate(payload, { strict: true })

    // isValid.then((e) => {
    //   console.log(e);
    //   return;
    // }).catch((err) => {
    //   console.log(err);
    //   return;
    // }) 

    setCreatingLoader(true);

    post("/api/User/createTeam", payload)
      .then((response) => {
        // setSubmitting(false);
        teamCreated();
        onClose();
        setCreatingLoader(false)
        fetchTeams(currentContractId);
        // resetForm(); 
        // setMessage({
        //   trigger: true,
        //   type: "success",
        //   icon: AddMemberIcon,
        //   title: "Team card added successfully!",
        //   text: "The Team card has been added to your database!",
        // });
      })
      .catch((error) => {
        setCreatingLoader(false);
        // setMessage({
        //   trigger: true,
        //   type: "error",
        //   icon: AddMemberIcon,
        //   title: "Failed to add the team card",
        //   text: error.message,
        // });
        // setSubmitting(false);
      });
  };

  const CustomCheckboxGroup = ({ options, onChange, selectedValue, id }) => {
    return (
      <div>
        {options?.map((option) => (
          <div key={option?.id} style={{ marginBottom: "14px" }}>
            <Checkbox
              className="custom-checkbox"
              checked={selectedValue === option?.id}
              onChange={() => onChange(option?.id)}
            >
              <div style={{
                fontFamily: 'Lato', fontWeight: 400, fontSize: '14px', lineHeight: '22px',
                 color: selectedValue === option?.id ? '#AA1A5F' : '#777777'
              }}>
              {option?.firstName && option?.lastName
                ? `${option?.firstName} ${option?.lastName}`
                : option?.userName || option?.email} ({id})
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
      </div>
      <h2 className="modalHeader">Enumerator Team Card</h2>
      <div>
            <p className="modalsHeader1">General details</p>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Team card name
              </label>
              <Input
                name="teamCardName"
                value={teamCardName}
                onChange={(e) => setTeamCardName(e.target.value)}
                placeholder="Enter team card name"
                autoComplete="off"
              />
              {/* <ErrorMessage
                name="teamCardName"
                component="div"
                style={{ color: "red" }}
              /> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Country
              </label>
              <Select
                name="countryId"
                showSearch
                allowClear
                placeholder="Select a country"
                onChange={(value) => {
                  setSelectedCountryForCity(value);
                }}
                value={selectedCountryForCity}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {allCountries?.map((c) => (
                  <Option key={c?.id} value={c?.id}>{c?.stateName}</Option>
                ))}
              </Select>
              {/* <ErrorMessage name="countryId" component="div" className="error-message" /> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>City
              </label>
              <Select
                disabled={!selectedCountryForCity}
                placeholder="Select a city"
                onChange={(value) => {
                  setSelectedCity(value);
                }}
                value={selectedCity}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {city?.map((c) => (
                  <Option key={c?.id} value={c?.id}>{c?.name}</Option>
                ))}
              </Select>
              {/* <ErrorMessage name="cityId" component="div" className="error-message" /> */}
            </div>
              <div style={{ paddingBottom: "20px" }}>
                <label style={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '16px', lineHeight: '22px' }}>
                  Select your team members
                </label>
              </div>
              {
                fetchEnumeratorsLoader ? 
                <Spin style={{width: '100%', padding: '15px 0'}} />
                :
              <>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <CustomCheckboxGroup
                  name="fieldCoordinatorId"
                  id="Field Coordinator"
                    options={fieldCoordinators}
                    selectedValue={selectedFieldCoordinator?.id}
                    onChange={(value) => {
                      const selected = fieldCoordinators.find(fc => fc?.id === value);
                      setSelectedFieldCoordinator(selected);
                    }}
                  />
                  {/* <ErrorMessage name="fieldCoordinatorId" component="div" style={{ color: "red" }} /> */}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                      <CustomCheckboxGroup
                        name="enumeratorId"
                      id="Enumerator"
                        options={enumerators}
                        selectedValue={selectedEnumerator?.id}
                        onChange={(value) => {
                          const selected = enumerators.find(e => e?.id === value);
                          setSelectedEnumerator(selected);
                        }}
                      />
                  {/* <ErrorMessage name="enumeratorId" component="div" style={{ color: "red" }} /> */}
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px",paddingBottom:"20px"}}>
                  <CustomCheckboxGroup
                  name="teamLeaderId"
                  id="Team Leader"
                    options={teamLeaders}
                    selectedValue={selectedTeamLeader?.id}
                    onChange={(value) => {
                      const selected = teamLeaders.find(tl => tl?.id === value);
                      setSelectedTeamLeader(selected);
                    }}
                  />
                  {/* <ErrorMessage name="teamLeaderId" component="div" style={{ color: "red" }} /> */}
                </div>
              </>
              }
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "20px" }}>
              <div
                style={{ borderRadius: '6px', padding: '1px 0px 1px 10px', gap: '10px', background: "#FADCEA" }}>
              <h1 style={{ fontFamily: 'Lato', fontWeight: 400, fontSize: '14px', lineHeight: '22px',margin:0 }}>
               Note: You can only choose  
            <span style={{ fontWeight: 900 }}> one </span>field coordinator, field enumerator and field team leader.
             </h1>
          </div>
            </div>
            <div className="buttonsModal">
              <Button
                className="inviteButtonTPM"
                key="cancel"
                onClick={() => {
                  onClose();
                  resetForm(); 
                }}
              >
                Cancel
              </Button>
              <Button
                className="addButtonTPM"
                key="submit"
                type="primary"
                // htmlType="submit"
                onClick={handleSubmit}
                loading={creatingLoader}
              >
                Add Team Card
              </Button>
            </div>
          </div>
    </div>
  );
};

export default TeamAddNew;
