import React, { useState } from 'react';
import { Table, Input, Button, Space, Modal } from 'antd';
import { SearchOutlined, DownloadOutlined} from '@ant-design/icons';
import preview from "../../../../Assets/svg/billingEye.svg"
import download from "../../../../Assets/svg/billingDownload.svg"

// Dummy data
const dataSource = [
  {
    key: '1',
    name: 'Invoice #001',
    date: '2024-06-01',
    amount: '$120.00',
    status: 'Paid',
  },
  {
    key: '2',
    name: 'Invoice #002',
    date: '2024-06-05',
    amount: '$90.00',
    status: 'Unpaid',
  },
  {
    key: '3',
    name: 'Invoice #003',
    date: '2024-06-10',
    amount: '$150.00',
    status: 'Paid',
  },
];

const BillingHistory = () => {
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePreview = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleDownload = (record) => {
    // Implement download logic here
    console.log('Downloading', record);
  };

  const filteredData = dataSource.filter((data) => 
    data.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <div onClick={() => handlePreview(record)} style={{cursor:"pointer"}}><img src={preview}></img></div>
          <div onClick={() => handleDownload(record)} style={{cursor:"pointer"}}><img src={download}></img></div>
        </Space>
      ),
    },
  ];

  return (
    <div className="paymentInformation accountSettings">
      <Space align="baseline" style={{ width: '100%', justifyContent: 'space-between' }}>
        <h3>Billing History</h3>
        <Button type="primary" className="addButtonTPM" icon={<DownloadOutlined />}>Download All</Button>
      </Space>
      <Input
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={handleSearch}
        style={{ margin: '10px 0', width: '300px' }}
      />
      <Table dataSource={filteredData} columns={columns} rowKey="key" />

      <Modal
        title="Preview"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedRecord && (
          <div>
            <p><strong>Name:</strong> {selectedRecord.name}</p>
            <p><strong>Date:</strong> {selectedRecord.date}</p>
            <p><strong>Amount:</strong> {selectedRecord.amount}</p>
            <p><strong>Status:</strong> {selectedRecord.status}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BillingHistory;
