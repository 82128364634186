import React, { useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../Filters";
import { put } from "../../../../../services/authService";
import Preview from "../../VisitBoard/TableView/modals/Preview";
import { Spin } from "antd";

const KanbanView = ({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState();
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const projectID = localStorage.getItem("currentProjectID");

  const changeSiteVisitCardStatus = (draggedTask) => {
    const trackingId = draggedTask.id;
    const siteVisitStatus = Number(draggedTask.column);

    setLoading(true);

    put(
      `/api/Site/changeSiteVisitStatus?TrackingId=${trackingId}&SiteVisitStatus=${siteVisitStatus}`
    )
      .then((response) => {
        console.log("Response:", response);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Status updated successfully!",
          text: "Red flag status was correctly updated to your database.",
        });
        fetchSiteVisitCards(
          projectID,
          filters?.pageSize,
          filters?.current,
          filters.Search,
          filters.ImplementingPartnerId,
          filters.State,
          filters.SiteVisitStatus,
          filters.ClusterId,
          filters.Month,
          filters.Year
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "We had a problem updating red flag status, please try again.",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    setTimeout(() => {
      setMessage({
        trigger: false,
        type: "",
        icon: "",
        title: "",
        text: "",
      });
    }, 3000);
  };

  useEffect(() => {
    fetchSiteVisitCards(
      projectID,
      filters?.pageSize,
      filters?.current,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year
    );
  }, [filters]);

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };


  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal)
  }

  const columns = [
    { id: 1, title: "Data Collection", color: "#AA1A5F" },
    { id: 2, title: "Data Check", color: "#FC9403" },
    { id: 3, title: "Translation", color: "#325899" },
    { id: 4, title: "Field Reporting Completed", color: "#3B81FE" },
  ];

  // format data for Kanban view
  const formatData = (siteVisitCard) => {
    return {
      id: siteVisitCard?.id,
      content: {
        title: siteVisitCard?.identifier,
        statusUpdated: siteVisitCard?.createdDate,
        ip: siteVisitCard?.implementingPartner?.name,
        state: siteVisitCard?.locationDetails?.country,
        municipality: siteVisitCard?.locationDetails?.municipality,
      },
      column: siteVisitCard?.tracking?.fieldTeamReportingStatus,
    };
  };

  const formattedData = siteVisitCards.map(formatData);

  return (
    <>
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />
      <Spin spinning={loading}>
      <Filters />
      <div id="excludedStrictMode">
        <KanbanBoardComponent tasks={formattedData} columns={columns} changeStatus={changeSiteVisitCardStatus} setClickedTaskId={handleTaskClick}/>
      </div>
      </Spin>
    </>
  );
};

export default KanbanView;
