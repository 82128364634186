import { useState } from "react";
import { post } from "../../../../services/authService";
import axios from "axios";
import { notification } from "antd";
import { saveAs } from "file-saver"; // Import the saveAs function

export const useFilePreview = () => {
  const [loadingFiles, setLoadingFiles] = useState({});

  const previewFiles = (e, filePath, id) => {
    e.stopPropagation();
    e.preventDefault(); // Add prevent default to stop default behavior

    setLoadingFiles((prev) => ({ ...prev, [filePath]: true }));

    // Ensure the corrected file path replacement is accurate for your use case
    let correctedFilePath = filePath.replace(/\\202/g, "\\x82");

    const payload = [
      {
        fileKey: correctedFilePath,
        entityId: id,
      },
    ];

    axios
      .post(
        `https://kapfilemanager.kapsurvey.com/FileData/getFileUrlByFileKeyAndEntityId`,
        payload
      )
      .then((response) => {
        console.log("Response:", response);
        setLoadingFiles((prev) => ({ ...prev, [filePath]: false }));
        if (
          response &&
          response.data.data &&
          response.data.data.length > 0 &&
          response.data.data[0].fileUrl
        ) {
          const fileData = response.data.data[0].fileUrl;

          const newWindow = window.open(fileData, "_blank");

          if (newWindow) {
            newWindow.focus();
          } else {
            notification.warning({
              message: "Pop-up Blocked",
              description:
                "Please allow pop-ups for this website to view the file.",
            });
          }
        } else {
          console.log("No data available in the response");
          notification.warning({
            message: "No Data Available",
            description: "No file URL found in the response.",
          });
        }
      })
      .catch((error) => {
        setLoadingFiles((prev) => ({ ...prev, [filePath]: false }));
        notification.error({
          message: "Error",
          description: "Error fetching file URL. Please try again later.",
        });
        console.error("Error fetching file URL:", error);
      });
  };

  return { loadingFiles, previewFiles };
};
