import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Card,
  Spin,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { get } from "../../../../../../services/authService";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";

const { Option } = Select;

const Preview = ({ visible, onClose, siteVisitCardId }) => {
  console.log(siteVisitCardId)

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [siteVisitCard, setSiteVisitCard] = useState({});
  const [filteredContacts, setFilteredContacts] = useState({
    siteContacts: [],
    pointOfContacts: [],
    KIIcontacts: [],
  });

  useEffect(() => {
    if(siteVisitCardId && toBeVisible){
      setLoading(true);
    const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

    get(apiSiteVisitCards)
      .then((res) => setSiteVisitCard(res.data))
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
    }
  }, [siteVisitCardId]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    const contacts = siteVisitCard?.visit?.contacts || [];
    const siteContacts = contacts.filter(
      (contact) => contact.contactType === 1
    );
    const pointOfContacts = contacts.filter(
      (contact) => contact.contactType === 2
    );
    const KIIcontacts = contacts.filter((contact) => contact.contactType === 3);

    setFilteredContacts({
      siteContacts,
      pointOfContacts,
      KIIcontacts,
    });
  }, [siteVisitCard]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    return `Date: ${year}-${month}-${day} Time: ${hours}:${minutes}:${seconds}`;
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Spin spinning={loading}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label={
            <span style={{ fontWeight: "bold" }}>Site visit identifier</span>
          }
        >
          <p className="previewText">{siteVisitCard?.identifier}</p>
        </Form.Item>
        <p className="modalsHeader1">General details</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="ip"
            label={
              <span style={{ fontWeight: "bold" }}>Implementing Partner</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.implementingPartner?.name}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            cluster="cluster"
            label={<span style={{ fontWeight: "bold" }}>Cluster</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{siteVisitCard?.cluster?.name}</p>
          </Form.Item>
          <Form.Item
            name="sector"
            label={<span style={{ fontWeight: "bold" }}>Sector</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{siteVisitCard?.sector?.name}</p>
          </Form.Item>
          {siteVisitCard?.subSector && (
            <Form.Item
              name="subSector"
              label={<span style={{ fontWeight: "bold" }}>Subsector</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.subSector?.name}</p>
            </Form.Item>
          )}
        </div>
        <p className="modalsHeader1">Location Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="latitude"
            label={<span style={{ fontWeight: "bold" }}>Latitude</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.locationDetails?.latitude}
            </p>
          </Form.Item>
          <Form.Item
            name="longitude"
            label={<span style={{ fontWeight: "bold" }}>Longitude</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.locationDetails?.longitude}
            </p>
          </Form.Item>
          <Form.Item
            name="isVirtual"
            label={<span style={{ fontWeight: "bold" }}>Virtual</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.virtual ? "Yes" : "No"}
            </p>
          </Form.Item>
        </div>
        <p className="modalsHeader1">Visit details</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="visitStartTime"
            label={<span style={{ fontWeight: "bold" }}>Visit start time</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.visit?.startTime)}
            </p>
          </Form.Item>
          <Form.Item
            name="dateOfPlannedVisit"
            label={
              <span style={{ fontWeight: "bold" }}>Date of planned visit</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.visit?.plannedVisit)}
            </p>
          </Form.Item>
        </div>
        <p className="modalsHeader1 text-black">Site Contacts</p>
        <div className="container mt-4">
          <div className="row g-4">
            {filteredContacts?.siteContacts &&
              filteredContacts.siteContacts.map((contact) => (
                <div className="col-md-4 mb-4">
                  <div
                    className="card shadow-sm h-100"
                    style={{ borderColor: "#dc3545" }}
                  >
                    <div
                      style={{ background: "#fadcea" }}
                      className="card-header font-bold"
                    >
                      <strong>Name:</strong>{" "}
                      <span className="text-capitalize">{contact.name}</span>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <strong>Email:</strong> {contact.email}
                      </li>
                      <li className="list-group-item">
                        <strong>Organization:</strong> {contact.organization}
                      </li>
                      <li className="list-group-item">
                        <strong>Phone:</strong> {contact.phone}
                      </li>
                      <li className="list-group-item">
                        <strong>Role:</strong> {contact.role}
                      </li>
                      <li className="list-group-item">
                        <strong>Skype:</strong> {contact.skype}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <p className="modalsHeader1 text-black">Point of contacts</p>
        <div className="container mt-4">
          <div className="row g-4">
            {filteredContacts?.pointOfContacts &&
              filteredContacts.pointOfContacts.map((contact) => (
                <div className="col-md-4 mb-4">
                  <div
                    className="card shadow-sm h-100"
                    style={{ borderColor: "#dc3545" }}
                  >
                    <div
                      style={{ background: "#fadcea" }}
                      className="card-header font-bold"
                    >
                      <strong>Name:</strong>{" "}
                      <span className="text-capitalize">{contact.name}</span>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <strong>Email:</strong> {contact.email}
                      </li>
                      <li className="list-group-item">
                        <strong>Organization:</strong> {contact.organization}
                      </li>
                      <li className="list-group-item">
                        <strong>Phone:</strong> {contact.phone}
                      </li>
                      <li className="list-group-item">
                        <strong>Role:</strong> {contact.role}
                      </li>
                      <li className="list-group-item">
                        <strong>Skype:</strong> {contact.skype}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <p className="modalsHeader1 text-black">KII Contacts</p>
        <div className="container mt-4">
          <div className="row g-4">
            {filteredContacts?.KIIcontacts &&
              filteredContacts.KIIcontacts.map((contact) => (
                <div className="col-md-4 mb-4">
                  <div
                    className="card shadow-sm h-100"
                    style={{ borderColor: "#dc3545" }}
                  >
                    <div
                      style={{ background: "#fadcea" }}
                      className="card-header font-bold"
                    >
                      <strong>Name:</strong>{" "}
                      <span className="text-capitalize">{contact.name}</span>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <strong>Email:</strong> {contact.email}
                      </li>
                      <li className="list-group-item">
                        <strong>Organization:</strong> {contact.organization}
                      </li>
                      <li className="list-group-item">
                        <strong>Phone:</strong> {contact.phone}
                      </li>
                      <li className="list-group-item">
                        <strong>Role:</strong> {contact.role}
                      </li>
                      <li className="list-group-item">
                        <strong>Skype:</strong> {contact.skype}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Visit start time</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.observationList ? "Yes" : "No"}
            </p>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="beneficiaries"
            label={<span style={{ fontWeight: "bold" }}>Beneficiaries</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.beneficiariesCount}
            </p>
          </Form.Item>
          <Form.Item
            name="kills"
            label={<span style={{ fontWeight: "bold" }}>Kills</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.clientsCount}
            </p>
          </Form.Item>
          <Form.Item
            name="observation"
            label={<span style={{ fontWeight: "bold" }}>Observation</span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.observationChecklist}
            </p>
          </Form.Item>
          <Form.Item
            name="media"
            label={
              <span style={{ fontWeight: "bold" }}>Media (Video/Photos)</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.mediaLimit}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>
                Sampling instructions (beneficiaries, sample, profile, selection
                method, etc)
              </span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.samplingInstructions}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>
                Instructions for any Kills
              </span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.clientInstructions}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>Any other instructions</span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.otherInstructions}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>
                Instructions for any Kills
              </span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.visit?.interviewDetails?.clientInstructions}
            </p>
          </Form.Item>
        </div>
        <p className="modalsHeader1">Logistics</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>TPM Coordinator</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.tpmCoordinator?.fullName}
            </p>
          </Form.Item>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>Coordination Type</span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.coordinationType}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Fulcrum Account</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.coordinationType}
            </p>
          </Form.Item>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Activation Date</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.logistic?.activationDate)}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>
                Select a team name for data collection
              </span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">{siteVisitCard?.logistic?.team?.name}</p>
          </Form.Item>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Team is local</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.isTeamLocal ? "Yes" : "No"}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Postponed</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.isPostponed ? "Yes" : "No"}
            </p>
          </Form.Item>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Postponed reason</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.postponedReason}
            </p>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={
              <span style={{ fontWeight: "bold" }}>Postponed details</span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.postponedDetails}
            </p>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="observationList"
            label={<span style={{ fontWeight: "bold" }}>Any other notes (Logistics/Coordination)</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.logistic?.notes}
            </p>
          </Form.Item>
        </div>

        <p className="modalsHeader1">Rescources</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="reportingYear"
            label={<span style={{ fontWeight: "bold" }}>Reporting year</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.reportingYear}
            </p>
          </Form.Item>
        </div>

        
        {(siteVisitCard.tracking )&&
        <>
        <p className="modalsHeader1">Tracking</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="reportingYear"
            label={<span style={{ fontWeight: "bold" }}>Reporting year</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.reportingYear}
            </p>
          </Form.Item>
          <Form.Item
            name="reportingMonth"
            label={<span style={{ fontWeight: "bold" }}>Reporting month</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.reportingMonth}
            </p>
          </Form.Item>
          <Form.Item
            name="trainingIsCompleted"
            label={<span style={{ fontWeight: "bold" }}>Training Completed</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.trainingIsCompleted ? "Yes" : "No"}
            </p>
          </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="trainingDate"
            label={<span style={{ fontWeight: "bold" }}>Training date</span>}
            style={{ width: "33%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.tracking?.trainingDate)}
            </p>
          </Form.Item>
          <Form.Item
            name="trainingScoreInfo"
            label={<span style={{ fontWeight: "bold" }}>Training score info</span>}
            style={{ width: "67%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.trainingScoreInfo}
            </p>
          </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="actualDateOfVisit"
            label={<span style={{ fontWeight: "bold" }}>Actual date of visit</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.tracking?.actualDateOfVisit)}
            </p>
          </Form.Item>
          <Form.Item
            name="dateOfReport"
            label={<span style={{ fontWeight: "bold" }}>Date of report</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.tracking?.dateOfReport)}
            </p>
          </Form.Item>
          <Form.Item
            name="dateOfDebrief"
            label={<span style={{ fontWeight: "bold" }}>Date of debrief</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {formatDate(siteVisitCard?.tracking?.dateOfDebrief)}
            </p>
          </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="percentageOfDataCollectionCompleted"
            label={<span style={{ fontWeight: "bold" }}>% of data collection completed</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {`${siteVisitCard?.tracking?.percentageOfDataCollectionCompleted} %`}
            </p>
          </Form.Item>
          <Form.Item
            name="siteVisitStatus"
            label={<span style={{ fontWeight: "bold" }}>Site visit status</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.siteVisitStatus}
            </p>
          </Form.Item>
          <Form.Item
            name="fieldTeamReportingStatus"
            label={<span style={{ fontWeight: "bold" }}>Field Team Reporting Status</span>}
            style={{ width: "100%" }}
          >
            <p className="previewText">
              {siteVisitCard?.tracking?.fieldTeamReportingStatus}
            </p>
          </Form.Item>
          </div></>
          }

        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
      </Spin>
    </Modal>
  );
};

export default Preview;
