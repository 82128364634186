import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Typography,
  message,
  Spin,
} from "antd";
import "./EditParts/style.scss";
import GeneralDetails from "./EditParts/GeneralDetails";
import LocationDetails from "./EditParts/LocationDetails";
import VisitDetails from "./EditParts/VisitDetails";
import Logistics from "./EditParts/Logistics";
import ResourcesDetails from "./EditParts/ResourcesDetails";
import TrackingDetails from "./EditParts/TrackingDetails";
import { useEffect, useState } from "react";
import { get, post, put } from "../../../../../../services/authService";
import dayjs from 'dayjs';


const { TextArea } = Input;
const { Title } = Typography;

// Helper functions

// Function to format date
const formatDate = (date) => {
  if (!dayjs.isDayjs(date)) return '';
  return date.format('YYYY-MM-DD');
};

// Function to format hours
const formatHours = (hrs) => {
  if (!dayjs.isDayjs(hrs)) return '';
  return hrs.format('HH:mm:ss');
};

const transformSiteContacts = (siteContactsObj) => {
  const siteContacts = siteContactsObj?.siteContacts;
  if (!siteContacts || !Array.isArray(siteContacts)) {
  return [];
  }
  return siteContacts.map((contact) => ({
    name: contact.contactName,
    email: contact.email,
    role: contact.role,
    phone: contact.phone || "",
    skype: contact.skype || "",
    organization: contact.organization || "",
    contactType: 1,
  }));
};

const transformPointContacts = (pointContactsObj) => {
  const pointContacts = pointContactsObj?.siteContacts;
  if (!pointContacts || !Array.isArray(pointContacts)) {
  return [];
  }
  return pointContacts.map((contact) => ({
    name: contact.contactName,
    email: contact.email,
    role: contact.role,
    phone: contact.phone || "",
    skype: contact.skype || "",
    organization: contact.organization || "",
    contactType: 2,
  }));
  
};

const transformKIIcontacts = (KIIcontactsObj) => {
  const KIIcontacts = KIIcontactsObj?.siteContacts;
  if (!KIIcontacts || !Array.isArray(KIIcontacts)) {
  return [];
  }
  return KIIcontacts.map((contact) => ({
    name: contact.contactName,
    email: contact.email,
    role: contact.role,
    phone: contact.phone || "",
    skype: contact.skype || "",
    organization: contact.organization || "",
    contactType: 3,
  }));
  
};

const SiteVisitModal = ({ visible, onClose, siteVisitCardId }) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [loading, setLoading] = useState(false);
  const [siteVisitId, setSiteVisitId] = useState("")
  const [siteVisitCard, setSiteVisitCard] = useState(null);

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
    setSiteVisitId(siteVisitCardId && siteVisitCardId)
    setLoading(true);
    const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

    get(apiSiteVisitCards)
      .then((res) => {
        setSiteVisitCard(res.data);
        form.setFieldsValue({
          identifier: res.data.identifier,
          partner: res.data.implementingPartner.id,
          cluster: res.data.cluster.id,
          sector: res.data.sector.id,
          // subSector: res.data.subSector,
          projectId: res.data.isVirtual,
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          isVirtual: res.data.isVirtual,
          // visitStartTime: res.data.visit.startTime,
          // plannedVisit: res.data.visit.plannedVisit,
          observationList: res.data.visit.observationListCheck,
          beneficiariesCount: res.data.visit.interviewDetails.beneficiariesCount,
          kills: res.data.visit.interviewDetails.clientsCount,
          observationChecklistCount: res.data.visit.interviewDetails.observationChecklist,
          mediaLimit: res.data.visit.interviewDetails.mediaLimit,
          samplingInstructions: res.data.visit.interviewDetails.samplingInstructions,
          clientInstructions: res.data.visit.interviewDetails.clientInstructions,
          otherInstructions: res.data.visit.interviewDetails.otherInstructions,
          visitPlanApproval: res.data.visit.visitPlanApproval,
          specificsOfOnSiteOperation: res.data.specificsOfOnSiteOperation,
          tpmCoordinator: res.data.logistic.tpmCoordinator,
          coordinationType: res.data.logistic.coordinationType,
          fulcrumAccount: res.data.logistic.fulcrumAccount,
          // activationDate: res.data.logistic.activationDate,
          isTeamLocal: res.data.logistic.isTeamLocal,
          team: res.data.logistic.teamId,
          isPostponed: res.data.logistic.isPostponed,
          postponedReason: res.data.logistic.postponedReason,
          postponedDetails: res.data.logistic.postponedDetails,
          notes: res.data.logistic.notes,
          // reportingYear: res.data.tracking.reportingYear,
          // reportingMonth: res.data.tracking.reportingMonth,
          // trainingDate: res.data.tracking.trainingDate,
          trainingScoreInfo: res.data.tracking.trainingScoreInfo,
          // actualDateOfVisit: res.data.tracking.actualDateOfVisit,
          // dateOfReport: res.data.tracking.dateOfReport,
          // dateOfDebrief: res.data.tracking.dateOfDebrief,
          percentageOfDataCollectionCompleted: res.data.tracking.percentageOfDataCollectionCompleted,
          trainingIsCompleted: res.data.tracking.trainingIsCompleted,
        });
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [siteVisitCardId, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const visitStartTime = `${formatDate(
          values.visitStartTime
        )}T${formatHours(values.visitStartTime)}.000Z`;
        const plannedVisit = formatDate(values.dateOfPlannedVisit);
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const activationDate = formatDate(values.activationDate);
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);

        const siteContacts = transformSiteContacts(values.siteContacts);
        const pointContacts = transformPointContacts(values.pointContacts);
        const KIIcontacts = transformKIIcontacts(values.KIIcontacts);
        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];

        const formData = new FormData();
        formData.append("ImplementingPartnerId", values.partner);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector);
        formData.append("ProjectId", projectId);
        formData.append(
          "LocationDetails.Latitude",
          values.latitude || "42.877651"
        );
        formData.append(
          "LocationDetails.Longitude",
          values.longitude || "20.857268"
        );
        formData.append("isVirtual", values.isVirtual || false);

        formData.append("Visit.StartTime", visitStartTime);
        formData.append("Visit.PlannedVisit", plannedVisit);

        createContacts.forEach((contact, index) => {
          formData.append(
            `visit.createContacts[${index}].visitId`,
            "3fa85f64-5717-4562-b3fc-2c963f66afa6"
          );
          formData.append(`visit.createContacts[${index}].name`, contact.name);
          formData.append(
            `visit.createContacts[${index}].email`,
            contact.email
          );
          formData.append(`visit.createContacts[${index}].role`, contact.role);
          formData.append(
            `visit.createContacts[${index}].phone`,
            contact.phone || ""
          );
          formData.append(
            `visit.createContacts[${index}].skype`,
            contact.skype || ""
          );
          formData.append(
            `visit.createContacts[${index}].organization`,
            contact.organization || ""
          );
          formData.append(
            `visit.createContacts[${index}].contactType`,
            contact.contactType
          );
        });

        formData.append(
          "Visit.ObservationListCheck",
          values.observationList || false
        );
        formData.append(
          "Visit.CreateInterviewDetails.BeneficiariesCount",
          values.beneficiariesCount
        );
        formData.append(
          "Visit.CreateInterviewDetails.ClientsCount",
          values.kills
        );
        formData.append(
          "Visit.CreateInterviewDetails.ObservationChecklist",
          values.observationChecklistCount
        );
        formData.append(
          "Visit.CreateInterviewDetails.MediaLimit",
          values.mediaLimit
        );
        formData.append(
          "Visit.CreateInterviewDetails.SamplingInstructions",
          values.samplingInstructions
        );
        formData.append(
          "Visit.CreateInterviewDetails.ClientInstructions",
          values.clientInstructions
        );
        formData.append(
          "Visit.CreateInterviewDetails.OtherInstructions",
          values.otherInstructions
        );
        formData.append("Visit.VisitPlanApproval", values.visitPlanApproval);

        formData.append(
          "SpecificsOfOnSiteOperation",
          values.specificsOfOnSiteOperation
        );
        formData.append("ObservationList", values.observationList || false);

        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);
        formData.append("Logistic.ActivationDate", activationDate);
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed);
        formData.append("Logistic.PostponedReason", values.postponedReason);
        formData.append("Logistic.PostponedDetails", values.postponedDetails);
        formData.append("Logistic.Notes", values.notes);

        formData.append("Tracking.ReportingYear", values.reportingYear);
        formData.append("Tracking.ReportingMonth", values.reportingMonth);
        formData.append("Tracking.TrainingDate", trainingDate);
        formData.append("Tracking.TrainingScoreInfo", values.trainingScoreInfo);
        formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
        formData.append("Tracking.DateOfReport", dateOfReport);
        formData.append("Tracking.DateOfDebrief", dateOfDebrief);
        formData.append(
          "Tracking.PercentageOfDataCollectionCompleted",
          values.percentageOfDataCollectionCompleted
        );
        formData.append("Tracking.SiteVisitStatus", 1);
        formData.append("Tracking.FieldTeamReportingStatus", 1);
        formData.append(
          "Tracking.TrainingIsCompleted",
          values.trainingIsCompleted || false
        );
        if (siteVisitId) {
          put(`/api/Site/updateSiteVisit?id=${siteVisitId}`, formData)
          .then(() => {
            message.success("Successfully submitted the site visit");
            onClose();
          })
          .catch(() => {
            message.error("Error in submitting the site visit");
          });

        }
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  return (
    <Modal
      title="Edit Site Visit"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <GeneralDetails form={form} />
          <LocationDetails form={form} />
          <VisitDetails form={form} />
          <Logistics form={form} />
          <ResourcesDetails form={form} />
          <TrackingDetails form={form} />
          <Divider />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SiteVisitModal;
