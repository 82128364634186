import React, { useState } from "react";
// import "../Implementation.scss";
import { Button, Tabs } from "antd";
import AddButton from "../../../../Assets/svg/addButton.svg";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import AddModal from "../../planning/modals/AddModal";
import axios from "axios";

function MonitoringRoundOverview() {


  // Handle show/hide Modal for "Add new site visit card" button
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [loading, setLoading] = useState(false)


  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const fetchSiteVisitCards = async (projectId, pageSize, pageNumber, search, implementingPartnerId, country, siteVisitStatus, clsuterId, month, year) => {
    try {
      const token = localStorage.getItem("token");
      setLoading(true)
      const response = await axios.get(`/api/Site/getAllSiteVisits`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          ProjectId: projectId,
          pageNumber: pageNumber,
          pageSize: pageSize,
          Search: search,
          ImplementingPartnerId: implementingPartnerId,
          State: country,
          SiteVisitStatus: siteVisitStatus,
          ClusterId: clsuterId,
          Month: month,
          Year: year,
        }
      });
  
      const sitevisitcardsData = response?.data?.data;
  
      if (sitevisitcardsData.length > 0) {
        setSiteVisitCards(sitevisitcardsData);
      } else {
        setSiteVisitCards([]); 
      }
  
    } catch (error) {
      console.error('Error fetching site visit cards:', error);
    }
    setLoading(false)
  };


  // This will return which page is going to be rendered
  const items = [
    {
      key: "1",
      label: "Kanban view",
      children: <KanbanView  siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards} loading={loading} setLoading={setLoading}/>,
    },
    {
      key: "2",
      label: "Calendar view",
      children: <CalendarView  siteVisitCards={siteVisitCards} fetchSiteVisitCards={fetchSiteVisitCards} loading={loading} setLoading={setLoading}/>,
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Monitoring round overview</span>
        <div className="tableViewHeaderButtons">
          <Button
            className="exportButtonTPM addButtonTPM"
            // onClick={handleToggleInviteMemberModal}
          >
            <img src={ExportButton} />
            Export selected visits
          </Button>
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default MonitoringRoundOverview;
