import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message, DatePicker } from "antd";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const Edit = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        driverName: record.driverName,
        driverPhone: record.driverPhone,
        mainLocation: record.mainLocation,
      });
    }
  }, [record, form]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log("Form values:", values);
      form.resetFields();
      onClose();
      message.success("Edited successfully!");
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit</h2>
      <Form form={form} layout="vertical">
        {" "}
        <p className="modalsHeader1">Personal Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            driverName="driverName"
            label="Driver name"
            rules={[
              { required: true, message: "Please enter driver name" },
            ]}
            style={{ width: "48%" }}
          >
            <Input
              placeholder="Enter Driver name"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            driverPhone="driverPhone"
            label="Date of planned visit"
            rules={[
              { required: true, message: "Please enter driver phone" },
              { type: "date", message: "Please enter driver phone" },
            ]}
            style={{ width: "48%" }}
          >
            
            <Input
              placeholder="Enter driver phone"
              autoComplete="off"
            />
          </Form.Item>
        </div>
        <p className="modalsHeader1">Location Information</p>
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Form.Item
            mainLocation="mainLocation"
            label="Main Location"
            rules={[{ required: true, message: "Please enter main location" }]}
            style={{ width: "48%" }}
          >
            
            <Input
              placeholder="Enter main location"
              autoComplete="off"
            />
          </Form.Item>
        </div>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Save Progress
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Edit;
