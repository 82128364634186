import React from "react";
import { Modal } from "antd";
import "./index.scss";

const BasicModal = ({
   isModalOpen,
   handleOk,
   handleCancel,
   children,
   footerButtonCustomStyle = false
}) => {
   return (
      <Modal
         open={isModalOpen}
         // open
         onOk={handleOk}
         onCancel={handleCancel}
         className={`basicModal ${footerButtonCustomStyle ? "footerButtonCustomStyle" : ""}`}
         width={600}
         centered
         footer={false}
      >
         {children}
      </Modal>
   );
};

export default BasicModal;
