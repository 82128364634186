import React, { useState } from "react";
import "./IpSummary.scss";
import arrow1 from "../../../../Assets/Images/arrowRight.png";

const getIpDonorReportingDescription = (enumValue) => {
  const descriptions = {
    1: "Awaiting Field",
    2: "Data cleaning",
    3: "Data analysis",
    4: "Data translation",
    5: "IP Report Development",
    6: "Donor Report",
    7: "Completed"
  };
  return descriptions[enumValue] || "Unknown";
};

// Utility function to get IP Status description
const getIpStatusDescription = (enumValue) => {
  const descriptions = {
    1: "Discussion",
    2: "Document Review",
    3: "Pre-phase",
    4: "Data Collection",
    5: "Closed"
  };
  return descriptions[enumValue] || "Unknown";
};

// Utility function to convert true/false to "Yes"/"No"
const getPosponedStatus = (value) => (value ? "Yes" : "No");

// Utility function to format date by removing 'T'
const formatDate = (dateString) => dateString.split('T')[0];

function IpCards({ projectData }) {
  const [selectedCardKeyInfo, setSelectedCardKeyInfo] = useState(null);

  const handleCardClick = (keyInfo) => {
    setSelectedCardKeyInfo(keyInfo);
  };

  const cardsData = Array.isArray(projectData?.data?.ipSummaryIPPlanningCardResponse)
    ? projectData.data.ipSummaryIPPlanningCardResponse
    : [];

  return (
    <div style={{ width: "100%" }}>
      <div className="ipCardDiv">
        <h2 className="ipCardName">IP Planning cards</h2>
        <div className="ipCards">
          {cardsData.map((card, index) => (
            <React.Fragment key={card.ipPlanIdentifier}>
              <div className="ipCardCarousel">
                <div
                  className="cardIp"
                  onClick={() => handleCardClick(card.ipPlanKeyInfo)}
                >
                  <h6 className="ipCardHeadingName">{card?.ipPlanIdentifier}</h6>
                  <span className="ipCardTextName">{`Main Sector: ${card?.mainSector}`}</span>
                  <span className="ipCardTextName">{`IP/Donor reporting status: ${getIpDonorReportingDescription(card?.ipDonorReportingEnum)}`}</span>
                </div>
                {index === 1 && <img src={arrow1} alt="arrow1" />}
              </div>
            </React.Fragment>
          ))}

          <div className="ipCardList">
            {selectedCardKeyInfo ? (
              <>
                <span className="ipCardTextName">
                  {`Status: `} <span>{getIpStatusDescription(selectedCardKeyInfo.ipStatusEnum)}</span>
                </span>
                <span className="ipCardTextName">
                  {`Planning Round: `} <span>{selectedCardKeyInfo.planningRound}</span>
                </span>
                <span className="ipCardTextName">
                  {`Site Proposed: `} <span>{formatDate(selectedCardKeyInfo.siteProposed)}</span>
                </span>
                <span className="ipCardTextName">
                  {`Postponed: `} <span>{getPosponedStatus(selectedCardKeyInfo?.posponed)}</span>
                </span>
                <span className="ipCardTextName">
                  {`Data Collection Completed: `} <span>{selectedCardKeyInfo.dataCollectionCompleted}</span>
                </span>
              </>
            ) : (
              <span className="ipCardTextName">Select a card to see key info</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IpCards;
