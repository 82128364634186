import { Select, DatePicker, Input, Button, List, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "../Reporting.scss";
import previewList from "../../../../Assets/svg/previewList.svg";
import downloadList from "../../../../Assets/svg/downloadList.svg";
import { get } from "../../../../services/authService";
import { useFilePreview } from "../../completed/ReportingList/Download";
import PreviewModal from "./PreviewModal";

function ReportingList() {
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = useState("");
  const [implementingPartners, setImplementingPartners] = useState("");
  const [pagination, setPagination] = useState({ pageSize: 10, pageNumber: 1 });
  const [data, setData] = useState([]);
  const itemsPerPage = 10;
  const { previewFiles } = useFilePreview();
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSearch = () => {
    // setShowFilters(!showFilters);
  };

  const handlePreview = (item) => {
    setSelectedItem(item);
    setPreviewFileModal(true);
  };

  const handleModalClose = () => {
    setPreviewFileModal(false);
    setSelectedItem(null);
  };

  const { Option } = Select;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    get("/api/GeoData/countries")
      .then((res) => setAllCountries(res.data))
      .catch((error) => console.log(error));

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    get(apiIps)
      .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    const apiUrl = `/api/Site/getResourcesForSiteVisitsAndIPPlans?projectId=${projectId}&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`;
    setLoading(true);
    get(apiUrl)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination.pageNumber]);

  return (
    <Spin spinning={loading}>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">Reporting List</span>

        <div className="filtersWrapper">
          <div className="containerFilters">
            <Input onChange={handleSearch} disabled placeholder="Search..." />
          </div>
        </div>
        <div className="listDesign">
          <List
            itemLayout="horizontal"
            dataSource={paginatedData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => handlePreview(item)}
                    className="buttonsList"
                  >
                    <img src={previewList} alt="Preview" />
                  </Button>,

                  <Button
                    onClick={(e) => previewFiles(e, item.filePath, item.id)}
                    className="buttonsList"
                  >
                    <img src={downloadList} alt="Download" />
                  </Button>,
                ]}
                style={{ width: "100%" }}
              >
                <List.Item.Meta
                  title={
                    <span className="titleText">{`IP/Donor Report - ${item.identifier}`}</span>
                  }
                />
              </List.Item>
            )}
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: data.length,
              onChange: handlePageChange,
            }}
            style={{ width: "100%" }}
          />
        </div>
        <PreviewModal
          onClose={handleModalClose}
          visible={previewFileModal}
          item={selectedItem}
        />
      </div>
    </Spin>
  );
}

export default ReportingList;
