import React, { useEffect, useState } from "react";
import { Select, Input, Button, DatePicker,Spin } from "antd";
import SearchIcon from "../../../../../../Assets/svg/searchIcon.svg";
import { get } from "../../../../../../services/authService";
import dayjs from "dayjs";

const { Option } = Select;

const Filters = ({ onChange,setFilters,setPagination,pagination,fetchTripLogistics,projectID }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState([]);
  const [selectedCountryForCity,setSelectedCountryForCity] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [startDate, setStartDate] = useState(null); 
  const [endDate, setEndDate] = useState(null);
  const [clearButtonClick, setClearButtonClick] = useState(0);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setPagination({ ...pagination, current: 1 });
    onChange(key, value);
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setClearButtonClick((prevClick) => prevClick + 1);
    setSelectedCountry(null);
    setSelectedCity(null);
    setStartDate(null);
    setEndDate(null);
    setFilters({}); 
    setPagination({ pageSize: 10, current: 1 }); 
    fetchTripLogistics({ projectID, pageNumber: 1, pageSize: 10 });
  };

  useEffect(() => {
    setLoading(true);
    get("/api/GeoData/countries")
      .then((res) => {
        setCountry(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    if (selectedCountryForCity) {
      get(`/api/GeoData/cities?countryId=${selectedCountryForCity}`)
        .then((res) => {
          setCity(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [selectedCountryForCity]);

  const handleTripStatus = (value) => {
    const tripStatus = value ? parseInt(value) : null;
    handleFilterChange("status", tripStatus);
  };

  const handleLocation = (value, option) => {
    const countryId = option ? option.key : null;
    setSelectedCountry(value);
    setSelectedCountryForCity(countryId);
    handleFilterChange("country", value);
  };

  const handleSecondLocation = (value) => {
    setSelectedCity(value);
    handleFilterChange("city", value);
  };

  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString);
    handleFilterChange("startDate", dateString);
  };

  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString);
    handleFilterChange("endDate", dateString);
  };

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        <Input
              key={`search-input-${clearButtonClick}`}
          placeholder="Type something here" suffix={<img src={SearchIcon} alt="searchIcon" />}
          onChange={(e) => handleFilterChange("search", e.target.value)}
        />
        <Button className="clearButton" onClick={handleClearAll}>Clear All</Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Start Date</p>
            <DatePicker
              style={{ width: "100%", marginBottom: 20 }}
              format="YYYY-MM-DD"
              onChange={handleStartDateChange}
              value={startDate ? dayjs(startDate) : null}
            />
          </div>
          <div className="filter">
            <p className="filterText">End Date</p>
            <DatePicker
              style={{ width: "100%", marginBottom: 20 }}
              format="YYYY-MM-DD"
              onChange={handleEndDateChange}
              value={endDate ? dayjs(endDate) : null}
            />
          </div>
          <div className="filter">
            <p className="filterText">Location 1</p>
            <Select
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a location"
              onChange={handleLocation}
              value={selectedCountry} 
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {country.map((c) => (
                <Option key={c.id} value={c.stateName}>{c.stateName}</Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Location 2</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              allowClear
              showSearch
              disabled={!selectedCountryForCity}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a location"
              onChange={handleSecondLocation}
              value={selectedCity}
              suffixIcon={loading ? <Spin size="small" /> : null}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {city.map((c) => (
                <Option key={c.id} value={c.name}>{c.name}</Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Driver</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a driver"
              onChange={handleLocation}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Team Lead</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a team lead"
              onChange={handleLocation}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Trip status</p>
            <Select
              key={`trip-status-select-${clearButtonClick}`}
              allowClear
              showSearch
              filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
               }
             style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a trip status"
              onChange={(value) => handleTripStatus(value)} 
          >
            <Option value={1}>Planned</Option>
            <Option value={2}>In progress</Option>
            <Option value={3}>Completed</Option>
            <Option value={4}>Canceled</Option>
          </Select>

          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
