import React, { useEffect, useState } from "react";
import { Modal, Button, Spin } from "antd";
import { get } from "../../../../../services/authService";

const PreviewTaskModal = ({ isVisible, onClose, taskId }) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTaskData = (taskId) => {
    setLoading(true);
    get(`/api/Tasks/getTask?id=${taskId}`)
      .then(res => {
        setTask(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (taskId) {
      fetchTaskData(taskId);
    }
  }, [taskId]);

  const formatType = (type) => {
    switch (type) {
      case 1: return "Ops preparation";
      case 2: return "Data Collection";
      case 3: return "Data check";
      case 4: return "Translation";
      case 5: return "Field report";
      case 6: return "Data cleaning";
      case 7: return "Data analysis";
      case 8: return "Data translation";
      case 9: return "IP report";
      case 10: return "Donor report";
      case 11: return "Other";
      default: return "Unknown Type";
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case 1: return "Assigned";
      case 2: return "Accepted";
      case 3: return "Completed";
      case 4: return "On hold";
      case 5: return "Archived";
      default: return "Unknown Status";
    }
  };

  const type = task?.type ? Number(task?.type) : null;
  const formattedType = type !== null ? formatType(type) : "Unknown Type";

  const status = task?.status ? Number(task?.status) : null;
  const formattedStatus = status !== null ? formatStatus(status) : "Unknown Status";

  return (
    <Modal
      title="Task Preview"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="back" className="inviteButtonTPM" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        {task ? (
          <>
            <p>
              <strong>Name:</strong> {task?.name}
            </p>
            <p>
              <strong>Type:</strong> {formattedType}
            </p>
            <p>
              <strong>Deadline:</strong> {task?.deadline}
            </p>
            <p>
              <strong>Status:</strong> {formattedStatus}
            </p>
            <p>
              <strong>Assigned to:</strong>{" "}
              {task?.taskUsers
                ? task?.taskUsers.map(user => user?.userName).join(", ")
                : "Unassigned"}
            </p>
            <p>
              <strong>Location:</strong>{" "}
              {`${task?.taskLocation?.city?.name}, ${task?.taskLocation?.country?.stateName}`}
            </p>
          </>
        ) : (
          <p>Loading task details...</p>
        )}
      </Spin>
    </Modal>
  );
};

export default PreviewTaskModal;
