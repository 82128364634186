import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
  Upload,
  Space,
  Divider,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { get, post } from "../../../../services/authService";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import AddMemberIcon from "../../../../Assets/svg/addMemberIcon.svg";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Assigned" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const AddRedFlagCardModal = ({
  visible,
  onClose,
  setMessage,
  fetchRedFlags,
}) => {
  const [allCountries, setAllContries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCityIds, setselectedCityIds] = useState();
  const [offices, setOffices] = useState([]);
  const [newOffice, setNewOffice] = useState("");
  const [addingOffice, setAddingOffice] = useState(false);
  const [implementingPartners, setImplementingPartners] = useState();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState();
  const [users, setUsers] = useState();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [disableCity, setDisableCity] = useState(true);
  const [disableOffice, setDisableOffice] = useState(true);

  const [form] = Form.useForm();

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";
    return date.format("YYYY-MM-DD");
  };

  const handleOk = async () => {
    setLoadingData(true);
    try {
      const values = await form.validateFields();
      const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
      const dueDate = formatDate(values.dueDate);
      const projectId = localStorage.getItem("currentProjectID");

      const formData = new FormData();
      formData.append("Name", values.name);
      formData.append("Description", values.description);
      formData.append("SiteVisitId", values.siteVisitCard);
      formData.append("ProjectId", projectId);
      formData.append("DateOfSiteVisit", dateOfSiteVisit);
      formData.append("OfficeId", values.office);
      formData.append("DueDate", dueDate);
      formData.append("AssignedTo", values.assignedTo);
      formData.append("FlagStatus", values.flagStatus);
      formData.append("FollowUpNotes", values.followUpNotes);

      fileList.forEach((file) => {
        formData.append("Files.Files", file.originFileObj);
      });
      formData.append("Files.EntityId", projectId);

      const response = await post("/api/RedFlag", formData);
      const message = response.message ||"A new red flag has been created and added to your database!"
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: "Red flag added successfully!",
        text: message,
      });
      form.resetFields();
      fetchRedFlags(projectId);
      onClose();
      setFileList([]);
      setLoadingData(false);
    } catch (error) {
      const messageError = error?.response?.data?.errors[0]?.message;
      setMessage({
        trigger: true,
        type: "danger",
        icon: AddMemberIcon,
        title: "Oops! A problem has occurred!",
        text:
          messageError ||
          "We had a problem adding this red flag to your database, please try again.",
      });
    } finally {
      setLoadingData(false);
      setFileList([]);
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setCities([]);
      setOffices([]);
      setDisableCity(true);
      setDisableOffice(true);
    }
  }, [visible]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const fetchOfficeData = (cityIds) => {
    const params = new URLSearchParams();
    cityIds.forEach(id => params.append('CityIds', id));
    
    get(`/api/Office/getAllByCity?${params}`)
      .then((res) => setOffices(res.data))
      .catch((error) => console.log(error));
  };

  const addOffice = async (e) => {
    e.preventDefault();
    if (newOffice) {
      try {
        setLoading(true);
        console.log(selectedCityIds);
  
        const requestBody = {
          cityIds: selectedCityIds,
          name: newOffice,
        };
  
        const response = await post("/api/Office", requestBody);
        const message = response.message || 'Office added successfully!';
        fetchOfficeData(selectedCityIds);
        setNewOffice("");
        setAddingOffice(false);
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Office added successfully!`,
          text: message,
        });
      } catch (error) {
        setLoading(false);
        console.error(`Error adding new item:`, error);
        const errorMessage = error.response?.data?.errors[0]?.message
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
      } finally {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }
    }
  };

  const handleOfficeSelectChange = () => {
    setAddingOffice(false);
  };

  const handleCountryChange = (countryId) => {
    form.resetFields(["city", "office"]);
    setCities([]);
    setOffices([]);
    setDisableCity(!countryId);
    setDisableOffice(true);
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => setCities(res.data))
        .catch((error) => console.log(error));
    }
  };

  const handleCityChange = (cityIds) => {
    form.resetFields(["office"]);
    setOffices([]);
    setselectedCityIds(cityIds);
    setDisableOffice(!cityIds);
    if (cityIds && cityIds.length > 0) {
      fetchOfficeData(cityIds);
    }
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    get(`/api/Site/getAllSiteVisits?ProjectId=${projectId}`)
      .then((res) => setSiteVisits(res.data))
      .catch((error) => console.log(error));

    get("/api/User/getAllusers")
      .then((res) => setUsers(res.data))
      .catch((error) => console.log(error));

    get("/api/GeoData/countries")
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));

    const workspaceId = localStorage.getItem("currentContractId");
    get(
      `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`
    )
      .then((res) => setImplementingPartners(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="cancelX"
        />
      </div>
      <h2 className="modalHeader">Add Red flag card</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          className="fullWidthInput"
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Enter the name of the red flag" },
          ]}
        >
          <Input
            placeholder="Enter the name of the red flag"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="ImplementingPartner"
          label="Implementing partner"
          rules={[{ required: true, message: "Select an IP" }]}
        >
          <Select placeholder="Select an IP">
            {implementingPartners &&
              implementingPartners.map((implementingPartner) => (
                <Option
                  key={implementingPartner.id}
                  value={implementingPartner.id}
                >
                  {implementingPartner.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Enter the description of the flag" },
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Enter the description of the flag"
            maxLength={6}
          />
        </Form.Item>
        <Form.Item
          name="siteVisitCard"
          label="Select the site visit card"
          rules={[{ required: true, message: "Select the site visit card" }]}
        >
          <Select placeholder="<###> - SV - <Sitename> - <Location 2> - <Location 3>">
            {siteVisits &&
              siteVisits.map((siteVisit) => (
                <Option key={siteVisit.id} value={siteVisit.id}>
                  {siteVisit.identifier}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dateOfSiteVisit"
          label="Date of site visit"
          rules={[{ required: true, message: "Enter the date of site visit" }]}
        >
          <DatePicker placeholder="Date of site visit" format="YYYY-MM-DD" />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

        <Form.Item
          name="country"
          label="Country"
          style={{ width: "48%" }}
          mode="multiple"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
          }
          rules={[{ required: true, message: "Select the country" }]}
        >
          <Select
            placeholder="Select the country"
            onChange={handleCountryChange}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
            }
          >
            {allCountries.map((country) => (
              <Option key={country.id} value={country.id}>
                {country.stateName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="city"
          style={{ width: "48%" }}
          label="State/Governorate"
          rules={[{ required: true, message: "Select a state or governorate" }]}
        >
          <Select
            mode="multiple"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
            }
            placeholder="Select a state or governorate"
            onChange={handleCityChange}
            disabled={cities?.length < 1}
          >
            {cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        </div>
        <Form.Item
          name="office"
          label="Office"
          style={{ width: "48%" }}
          rules={[{ required: true, message: "Select the office" }]}
        >
          <Select
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
            }
            showSearch
            placeholder="Select the office"
            disabled={disableOffice}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={newOffice}
                    onChange={(e) => setNewOffice(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addOffice}
                  >
                    Add office
                  </Button>
                </Space>
              </>
            )}
            onSelect={handleOfficeSelectChange}
          >
            {offices.map((office) => (
              <Option key={office.id} value={office.id}>
                {office.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dueDate"
          label="Due date"
          rules={[{ required: true, message: "Enter the due date" }]}
        >
          <DatePicker placeholder="Due date" format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="assignedTo"
          label="Assigned to"
          rules={[{ required: true, message: "Select the assigned user" }]}
        >
          <Select placeholder="Select the assigned user">
            {users &&
              users.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.userName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="flagStatus"
          label="Flag status"
          rules={[{ required: true, message: "Select the flag status" }]}
        >
          <Select placeholder="Select the flag status">
            {flagStatuses.map((status) => (
              <Option key={status.id} value={status.id}>
                {status.status}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="followUpNotes"
          label="Follow-up notes"
          rules={[{ required: true, message: "Enter the follow-up notes" }]}
        >
          <TextArea rows={4} placeholder="Enter the follow-up notes" />
        </Form.Item>
        <div className="resourcesElement">
          <p className="modalsHeader1">Resources</p>
          <div className="resourcesLabel">
            <p>No resource files found for this IP plan card</p>
            <div className="resourcesUploadElement">
              <Form.Item name="files" label="Upload files">
                <Upload
                  multiple
                  listType="text"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={() => false}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">
                      (file formats that are allowed)
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload>
                <div className="uploadsContainer">
                  {fileList &&
                    fileList.map((file) => (
                      <div className="uploadContent">
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.name}
                        />
                        <div>
                          <h1>{file.name}</h1>
                          <p>
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item>
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading}
            >
              {loadingData ? <Spin /> : "Save changes"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRedFlagCardModal;
