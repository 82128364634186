import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Row,
  Select,
  Switch,
  DatePicker,
  Input,
  Card,
  Divider,
  Button,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { get } from "../../../../../../../services/authService";

const { Option } = Select;

const Logistics = () => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const workspaceId = localStorage.getItem("currentContractId");
    const apiTeams = `/api/User/getAllTeams?workspaceId=${workspaceId}`;

    get(apiTeams)
      .then((res) => setTeams(res.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            label="TPM Coordinator"
            name="tpmCoordinator"
            rules={[
              { required: true, message: "Please select a TPM coordinator" },
            ]}
          >
            <Select placeholder="Enter TPM coordinator">
              <Option value="317D2A0B-6415-469B-B968-13714969E97D">
                Coordinator 1
              </Option>
              {/* More options can be added here */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Coordination Type"
            name="coordinationType"
            rules={[
              {
                required: true,
                message: "Please select a coordination type",
              },
            ]}
          >
            <Select placeholder="Select coordination type">
              <Option value="remote">Remote</Option>
              <Option value="notRemote">Not-remote</Option>
              {/* More options can be added here */}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Fulcrum Account"
            name="fulcrumAccount"
            rules={[
              { required: true, message: "Please enter Fulcrum account" },
            ]}
          >
            <Input placeholder="Enter Fulcrum account" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Activation Date"
            name="activationDate"
            rules={[
              { required: true, message: "Please select an activation date" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="d-flex align-items-center">
        <Col span={12}>
          <Form.Item
            label="Select a team name for data collection"
            name="team"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select a team">
              {teams &&
                teams.map((team) => (
                  <Option key={team.id} value={team.id}>
                    {team.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="isTeamLocal">
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Switch />
              <span>Team is local</span>
            </div>
          </Form.Item>
        </Col>
      </Row>

      <div className="teamDetails">
        <Card title="Team Details">
          <Divider />
          <p>
            <span style={{ fontWeight: "700" }}>Team Lead:</span> Name goes here
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Enumerator 1:</span> Name goes
            here
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Enumerator 2:</span> Name goes
            here
          </p>
          <div className="seeMoreBtn">
            <Button className="d-flex align-items-center">
              See more
              <DownOutlined />
            </Button>
          </div>
        </Card>
      </div>
      <div className="tripLogisticsCard">
        <Form.Item>
          <Tooltip title="More information about the trip logistics card">
            <Button
              icon={<InfoCircleOutlined />}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              See trip logistics card
            </Button>
          </Tooltip>
        </Form.Item>
      </div>

      <Row gutter={18} align="middle">
        <Col span={18}>
          <Form.Item name="postponedReason" label="Postponed reason">
            <Select placeholder="Select a reason">
              <Option value="reason1">Reason 1</Option>
              <Option value="reason2">Reason 2</Option>
              <Option value="reason3">Reason 3</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="isPostponed"
            label="Postponed"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Col span={18}>
        <Form.Item name="postponedDetails" label="Postponed Details">
          <TextArea rows={2} placeholder="Enter details of the postpone" />
        </Form.Item>
        <Form.Item
          name="notes"
          label="Any other notes (Logistics/Coordination)"
        >
          <TextArea rows={2} placeholder="Enter details of the postpone" />
        </Form.Item>
      </Col>
    </div>
  );
};
export default Logistics;
