import React from "react";
import { Radar } from "react-chartjs-2";
import { Chart, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import "./IpSummary.scss";
import WorldMap from "../TPMsummary/WorldMap";

// Register the necessary Chart.js components
Chart.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

function LocationRatings() {
  const data = {
    labels: ["Metric 1", "Metric 2", "Metric 3", "Metric 4", "Metric 5"],
    datasets: [
      {
        label: "Location Ratings",
        data: [2, 3, 4, 5, 4],
        backgroundColor: "rgba(34, 202, 236, 0.2)",
        borderColor: "rgba(34, 202, 236, 1)",
        pointBackgroundColor: "rgba(34, 202, 236, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(34, 202, 236, 1)",
      },
    ],
  };

  const options = {
    scales: {
      r: { // Updated from 'scale' to 'scales' and 'r' for radial scale in Chart.js v3
        ticks: { beginAtZero: true, min: 0, max: 5, stepSize: 1 },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="locationRatings">
        <h2 className="ipCardName">Location Ratings</h2>
        <div className="mapAndGraphic">
          <div className="graphic">
            <Radar data={data} options={options} />
          </div>
          <div className="map">
            <WorldMap/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationRatings;
