import React from "react";
import "./AdminHeader.css";
import { Button, Dropdown, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import AuthService from "../../../services/authentication.service";

const AdminHeader = () => {
  let userRoles = localStorage.getItem("role");
  let userEmail = localStorage.getItem("userEmail");
  let userName = localStorage.getItem('username');

  const backLogout = () => {
    AuthService.logout();
  };

  const menu = (
    <Menu>
      <Menu.Item key="2">
        <Link to="/settings/profile-information" style={{ textDecoration: 'none' }}>
          Profile information
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/settings/login-information" style={{ textDecoration: 'none' }}>
          Login information
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="5">
        <Button
          type="link"
          style={{ color: "red", textAlign: "center", width: "100%" }}
          onClick={backLogout}
        >
          Log Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: "flex" }}>
      <div className="adminHeaderName">
        <div className="adminHeaderNameText">
          {userName && userName.trim() !== "" ? userName : userEmail}
          <br></br>
          <span className="adminHeaderNameTextPosition">
            {userRoles && userRoles}
          </span>
        </div>
      </div>
      <div className="adminHeaderLogo">
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className="adminHeaderCircle">
            <span className="adminHeaderCircleText" style={{ textTransform: "capitalize" }}>
              {userName && userName.trim() !== "" ? userName.charAt(0) : userEmail.charAt(0)}
            </span>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default AdminHeader;
