import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, message, DatePicker } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { post, get } from "../../../services/authService";
import "react-phone-input-2/lib/style.css";
import IconStrategy from "../../../Assets/svg/iconStrategy.svg";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const NewProject = ({ visible, onClose, onSubmit, setMessage }) => {
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllContries] = useState();
  const [clients, setClients] = useState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const contractId = localStorage.getItem("currentContractId");
    const apiCountries = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;

    get(apiCountries)
      .then((res) => setAllContries(res.data))
      .catch((error) => console.log(error));


    const apiContracts = `/api/Contract/getClients?contractId=${contractId}`;

    get(apiContracts)
      .then((res) => setClients(res.data))
      .catch((error) => console.log(error));
  }, []);

  const [form] = Form.useForm();
  const id = localStorage.getItem("currentContractId");

  const handleCountryChange = (countryId) => {
    if (!countryId){
      form.setFieldsValue({ cityId: [] });
      setCities([]);
    }
    if (countryId) {
      get(`/api/GeoData/cities?countryId=${countryId}`)
        .then((res) => setCities(res.data))
        .catch((error) => console.log(error));
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        // const [contractStartDate, contractEndDate] = values.contractDates;
        const requestBody = {
          projectName: values.projectName,
          projectNumber: values.projectNumber,
          description: values.projectDescription || "",
          countryId: values.countryId,
          contractId: id,
          clients: values.clients,
          cityIds: values.cityId,
          clientFocalPoint: values.clientFocalPoint,
          clientFocalPointEmail: values.clientFocalPointEmail,
          referenceDocuments: values.referenceDocuments || "",
          linkToSharedDrive: values.linkToSharedDrive,
          // contractName: values.contractName,
          //   contractNumber: values.contractNumber,
          //   conctractStartDate: contractStartDate.toISOString(),
          // conctractEndDate: contractEndDate.toISOString()
        };

        post("/api/projects", requestBody)
          .then((response) => {
            const message = response.message || "A new project has been created and added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: IconStrategy,
              title: "Project added successfully!",
              text: message,
            });
            form.resetFields();
            onSubmit();
            onClose();
          })
          .catch((error) => {
            console.error("There was an error adding the project!", error);
            const errorMessage = error.response?.data?.errors[0]?.message || "We had a problem adding this project from your database, please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              icon: IconStrategy,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        const errorMessage = errorInfo.response?.data?.errors[0]?.message || "Please fill all required fields correctly.";
        setMessage({
          trigger: true,
          type: "danger",
          icon: IconStrategy,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        // message.error("");
      });

    setMessage({
      trigger: false,
      type: "",
      icon: "",
      title: "",
      text: "",
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">New project round</h2>
      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Project Details</p>
        <Form.Item
          name="projectName"
          label="Project Name"
          rules={[{ required: true, message: "Please enter project name" }]}
        >
          <Input placeholder="Enter project name" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="projectNumber"
          label="Project Code"
          rules={[{ required: true, message: "Please enter project code" }]}
        >
          <Input placeholder="Enter project number" autoComplete="off" />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Form.Item
            name="contractNumber"
            label="Contract Number"
            rules={[
              { required: true, message: "Please enter contract number" },
            ]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Contract Number" autoComplete="off" />
          </Form.Item>*/}
          <Form.Item
            name="countryId"
            label="Country"
            rules={[{ required: true, message: "Please select country" }]}
            style={{ width: "48%" }}
          >
            <Select
              placeholder="Select Country"
              showSearch
              allowClear
              onChange={handleCountryChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allCountries &&
                allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="cityId"
            label="Governorate/State"
            rules={[{ required: true, message: "Please select governorate or state" }]}
            style={{ width: "48%" }}
          >
            <Select
              mode="multiple"
              placeholder={cities?.length < 1 ? "Select a country first!" : "Select governorate or state"}
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="clients"
            label="Clients"
            rules={[{ required: true, message: "Please select a client" }]}
            style={{ width: "48%" }}
          >
            <Select
              placeholder="Select Clients"
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {clients &&
                clients.map((client) => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        {/* <Form.Item
          name="contractDates"
          label="Contract Dates"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <RangePicker />
        </Form.Item> */}
        <Form.Item
          name="clientFocalPoint"
          label="Client Focal Point"
          rules={[
            { required: true, message: "Please enter a client focal point" },
          ]}
        >
          <Input placeholder="Enter client focal point" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="clientFocalPointEmail"
          label="Client Focal Point Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            placeholder="Enter client focal point email"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item name="projectDescription" label="Project Description">
          <TextArea placeholder="Enter project description" rows={4} />
        </Form.Item>
        <Form.Item
          name="referenceDocuments"
          label="Links to reference documents"
          rules={[{ required: true, message: "Please enter links to reference documents" }]}
        >
          <TextArea placeholder="Enter links to reference documents" rows={4} />
        </Form.Item>
        <Form.Item
          name="linkToSharedDrive"
          label="Link to Shared Drive"
          rules={[
            { required: true, message: "Please enter a link to shared drive" },
          ]}
        >
          <Input placeholder="Enter link to shared drive" autoComplete="off" />
        </Form.Item>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
            disabled={loading}
          >
            Create project round
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NewProject;
