import { Table,notification } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../../../components/DeleteComponent/DeleteComponent";
import Edit from "./modals/Edit";
import Filters from "./Filters";
import Preview from "./modals/Preview";
import { get } from "../../../../../../services/authService";
import { httpService } from "../../../../../../services/httpService.service";
import MessagePopUp from "../../../../../components/Messages/MessagePopUp";
import AddMemberIcon from "../../../../../../Assets/svg/addMemberIcon.svg"


function TableView(
  {
    showAllTripData,
    fetchTripLogistics,
    siteVisits,
    pagination,
    onPageChange,
    setPagination,
    loadingTripLogistic,
    handleFilterChange,
    filters,
    setFilters
  }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [previewData, setPreviewData] = useState([])
  const projectID = localStorage.getItem("currentProjectID");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [message, setMessage] = useState({
  trigger: false,
  type: "",
  icon: "",
  title: "",
  text: "",
  });

  const handleShowEditModal = (record) => {
    setSelectedRowData(record);
    setShowEditModal(true);
  };

  const handlePreviewModal = async (record) => {
    setSelectedRowData(record);
    setShowPreviewModal(true);
  };

  const handleDeleteModal = (record) => {
    setSelectedRowData(record);
    setShowDeleteModal(true)
  }


  useEffect(() => {
    fetchTripLogistics(projectID, filters.startDate, filters.endDate, filters.status, filters.country, filters.city, filters.search);
  }, [filters, pagination.current, pagination.pageSize]);
  

  const handleDelete = async () => {
    try {
      const payload = [
        {
          id: selectedRowData.id,
          isDeleted: false,
        }
      ];
      await httpService.delete('/api/TripLogistic/deleteTripLogistics', payload);
      setShowDeleteModal(false);
      fetchTripLogistics(projectID);
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: "Trip Logistic deleted successfully!",
        text: "This Trip Logistic has been deleted from your database!",
      });
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000); 
    } catch (error) {
      setMessage({
        trigger: true,
        type: "error",
        icon: AddMemberIcon,
        title: "Failed to delete this trip logistic",
        text: error.message,
      });
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000); 
      console.error('Error deleting trip logistics:', error);
    }
  };
  
  
  

const columns = [
  {
    title: (
      <span className="styledTitle">
        Trip status
      </span>
    ),
    dataIndex: "tripStatus",
    key: "tripStatus",
    sorter: (a, b) => a.tripStatus.localeCompare(b.tripStatus),
    render: (tripStatus,text) => {
      let className;
      let fill;
      if (tripStatus === 1) {
        className = "progress";
        text="Planned"
        fill = "#3B81FE";
      } else if (tripStatus === 2) {
        className = "monitoring";
        text="In Progress"
        fill = "#FC9403";
      } else if (tripStatus === 3) {
        className = "review";
        fill = "#9670B8";
        text="Completed"
      } else if (tripStatus === 4) {
        className = "delivered";
        fill = "#045B46";
        text="Cancelled"
      }
      return (
        <div className={className} >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8px"
            height="8px"
            viewBox="0 0 9 9"
          >
            <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
          </svg>
          <span>{text}</span>
        </div>
      );
    },
  },
 
  {
    title: (
      <span className="styledTitle">
        Date for trip
      </span>
    ),
    sorter: (a, b) => a.enumeratorInformation.tripDate.localeCompare(b.enumeratorInformation.tripDate),
    dataIndex: ["enumeratorInformation", "tripDate"], 
    key: "tripDate",
    render: (tripDate) => <span className="styledDataIndex3">{new Date(tripDate).toLocaleDateString()}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Site visit card id name
      </span>
    ),
    sorter: (a, b) => a.siteVisitId.localeCompare(b.siteVisitId),
    dataIndex: "siteVisitId",
    key: "distanceToSite",
    render: (siteVisitId) => <span className="styledDataIndex3">{siteVisitId}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Driver Name
      </span>
    ),
    sorter: (a, b) => a.driverName.localeCompare(b.driverName),
    dataIndex: "driverName",
    key: "driverName",
    render: (text) => <span className="styledDataIndex3">{text}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Team lead name
      </span>
    ),
    sorter: (a, b) => a.teamLeadName.localeCompare(b.teamLeadName),
    dataIndex: "teamLeadName",
    key: "teamLeadName",
    render: (text) => <span className="styledDataIndex3">{text}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Pickup Time
      </span>
    ),
    dataIndex: ["enumeratorInformation","pickupTime"],
    key: "pickupTime",
    sorter: (a, b) => a.enumeratorInformation?.pickupTime.localeCompare(b.enumeratorInformation?.pickupTime),
    render: (pickupTime) => <span className="styledDataIndex3">{pickupTime}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Pickup Place
      </span>
    ),
    dataIndex: ["enumeratorInformation","pickupPlace"],
    key: "pickupPlace",
    sorter: (a, b) => a.enumeratorInformation?.pickupPlace.localeCompare(b.enumeratorInformation?.pickupPlace),
    render: (pickupPlace) => <span className="styledDataIndex3">{pickupPlace}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Meeting Time
      </span>
    ),
    dataIndex: ["enumeratorInformation","meetingTime"],
    key: "meetingTime",
    sorter: (a, b) => a.enumeratorInformation?.meetingTime.localeCompare(b.enumeratorInformation?.meetingTime),
    render: (meetingTime) => <span className="styledDataIndex3">{meetingTime}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Location 2
      </span>
    ),
    dataIndex: ["enumeratorInformation","meetingPlace"],
    key: "meetingPlace",
    sorter: (a, b) => a.enumeratorInformation?.meetingPlace.localeCompare(b.enumeratorInformation?.meetingPlace),
    render: (meetingPlace) => <span className="styledDataIndex3">{meetingPlace}</span>,
  },
  {
    title: (
      <span className="styledTitle">
        Overnight
      </span>
    ),
    dataIndex: ["enumeratorInformation","isOverNight"],
    key: "isOverNight",
    sorter: (a, b) => a.enumeratorInformation?.isOverNight.localeCompare(b.enumeratorInformation?.isOverNight),
    render: (isOverNight) => <span className="styledDataIndex3">{ isOverNight ? "Yes" : "No"}</span>,
  },
  {
    title: <span className="styledTitle"></span>,
    key: "action",
    fixed: isLargeScreen && showAllTripData.length > 0 ? "right" : undefined,
    render: (text, record) => (
      <div className="actionDiv">
        <img
          src={previewIcon}
          onClick={() => handlePreviewModal(record)}
          style={{ cursor: "pointer" }}
          alt="previewIcon"
        ></img>
        <img
          src={editIcon}
          onClick={() => handleShowEditModal(record)}
          style={{ cursor: "pointer" }}
          alt="editIcon"
        />
        <img
          src={deleteIcon}
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteModal(record)}
          alt="deleteIcon"
        ></img>
      </div>
    ),
  },
];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  return (
    <>
       <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Filters onChange={handleFilterChange} setFilters={setFilters} setPagination={setPagination} pagination={pagination} fetchTripLogistics={fetchTripLogistics} projectID={projectID} />
      <div>
        <Table rowKey="id"
          loading={loadingTripLogistic}
           pagination={{
            ...pagination,
            onChange: onPageChange
           }}
          dataSource={showAllTripData} rowSelection={{ ...rowSelection }} columns={columns} className="tableTPM" />
      </div>
      <Edit
        visible={showEditModal}
        onClose={() => {
          setShowEditModal(false)
        }}
        record={selectedRowData}
        fetchTripLogistics={fetchTripLogistics}
        previewData={previewData}
        siteVisits={siteVisits}
        setMessage={setMessage}
      />
     <Preview
        visible={showPreviewModal}
        onClose={() => {
          setShowPreviewModal(false);
        }}
        record={selectedRowData?.id}
        previewData={previewData}
      />
       <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete} 
        text={"You're about to delete this. This action can’t be undone."}
        header={"Delete"}
      />
    </>
  );
}

export default TableView;
