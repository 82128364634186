import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import "../../../UserManagement/UserManagement.scss";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddDriverCardModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log("Form values:", values);
      form.resetFields();
      onClose();
      message.success('Driver added successfully!');
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Driver card</h2>
      <Form form={form} layout="vertical">
        {" "}
        <p className="modalsHeader1">General details</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="driverName"
            label="Driver Name"
            rules={[{ required: true, message: "Please enter name" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="driverPhoneNumber"
            label="Driver phone number"
            rules={[{ required: true, message: "Enter the phone number" }]}
            style={{ width: "48%" }}
          >
            <PhoneInput
              country={"us"}
              
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
            name="driverVehicleInfo"
            label="Driver Vehicle Info"
            rules={[{ required: true, message: "Enter the vehicle info" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="driverBaseLocation"
            label="Driver Base Location"
            rules={[{ required: true, message: "Enter the location here" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Name" autoComplete="off" />
          </Form.Item>
        </div>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Save changes
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddDriverCardModal;
