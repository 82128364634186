import { Form, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import uploadIcon from "../../../../../../../Assets/svg/uploadIcon.svg";

const fileProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76", // Change this to your upload URL
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  beforeUpload(file) {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("File must smaller than 5MB!");
    }
    return isLt5M;
  },
};

const ResourcesDetails = () => {
  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <Form.Item
        label="Upload site visit report document(s)"
        style={{ fontWeight: "500" }}
      >
        <Dragger {...fileProps}>
          <p
            className="ant-upload-drag-icon d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "80px",
              background: "#fff",
              width: "44px",
              height: "44px",
            }}
          >
            <img src={uploadIcon} alt="" />
          </p>
          <p className="ant-upload-text">
            Drag and drop site visit report document(s) here, or click to select
            files
          </p>
          <p className="ant-upload-hint">
            File formats that are allowed
            <br />
            Max file size: 5MB
          </p>
        </Dragger>
      </Form.Item>

      <Form.Item label="Upload other document(s)" style={{ fontWeight: "500" }}>
        <Dragger {...fileProps} style={{ marginTop: "20px" }}>
          <p
            className="ant-upload-drag-icon d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "80px",
              background: "#fff",
              width: "44px",
              height: "44px",
            }}
          >
            <img src={uploadIcon} alt="" />
          </p>
          <p className="ant-upload-text">
            Drag and drop other document(s) here, or click to select files
          </p>
          <p className="ant-upload-hint">
            File formats that are allowed
            <br />
            Max file size: 5MB
          </p>
        </Dragger>
      </Form.Item>

      <Form.Item
        label="Upload site video file(s)"
        style={{ fontWeight: "500" }}
      >
        <Dragger {...fileProps} style={{ marginTop: "20px" }}>
          <p
            className="ant-upload-drag-icon d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "80px",
              background: "#fff",
              width: "44px",
              height: "44px",
            }}
          >
            <img src={uploadIcon} alt="" />
          </p>
          <p className="ant-upload-text">
            Drag and drop site video file(s) here, or click to select files
          </p>
          <p className="ant-upload-hint">
            File formats that are allowed
            <br />
            Max file size: 5MB
          </p>
        </Dragger>
      </Form.Item>
    </div>
  );
};
export default ResourcesDetails;
