import { Button, Col, Radio, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import PlanDetail from "./PlanDetail";
import BasicModal from "./BasicModal";
import starBadge from "../../../../Assets/svg/starBadge.svg";
import starRate from "../../../../Assets/svg/starRate.svg";

const SubscriptionPlanBoxes = ({
  planName,
  subscriptionStatus,
  subscriptionId,
  price,
  isMonthly,
}) => {
  const [planOptions, setPlanOptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({
    name: planName || "tt",
    price: price || 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openPackageModal, setOpenPackageModal] = useState(false);
  const [selectedPlanToChange, setSelectedPlanToChange] = useState({
    plan: null,
    planPeriodEnd: false,
  });
  const [isYearly, setIsYearly] = useState(!isMonthly);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      const dummyData = [
        {
          id: 3,
          name: "Enterprise",
          monthlyPrice: 50,
          annuallyPrice: 500,
          workspaceLimit: 0, // Unlimited workspaces
          membersLimit: 0, // Unlimited users
          databaseLimit: 0, // Unlimited storage
        },
        {
          id: 2,
          name: "Premium",
          monthlyPrice: 20,
          annuallyPrice: 200,
          workspaceLimit: 10,
          membersLimit: 20,
          databaseLimit: 100,
        },
        {
          id: 1,
          name: "Standard",
          monthlyPrice: 10,
          annuallyPrice: 100,
          workspaceLimit: 5,
          membersLimit: 10,
          databaseLimit: 50,
        },
      ];
      setPlanOptions(dummyData);
      setIsLoading(false);
    }, 1000);
  }, []);

  const handlePlanSelection = (plan) => {
    if (!plan || subscriptionStatus === "canceled") return;

    setSelectedPlan({
      name: plan.name,
      price: isYearly ? plan.annuallyPrice : plan.monthlyPrice,
    });
    setSelectedPlanToChange({ planPeriodEnd: null, plan: plan });
    setOpenPackageModal(true);
  };

  const handleSelectedPackage = (e) => {
    setSelectedPlanToChange((prevState) => ({
      ...prevState,
      planPeriodEnd: e.target.value,
    }));
  };

  const handlePricingToggle = (e) => {
    setIsYearly(e.target.value === "yearly");
    localStorage.setItem("isYearly", e.target.value === "yearly");
  };

  return (
    <>
      {isLoading ? (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        />
      ) : (
        <div className="subscriptionPlanBoxes">
          <Row>
            <Col xs={24} sm={18} md={16} lg={16} xl={12}>
              <h6>Current Subscription</h6>
            </Col>
            <Col
              className="columnPosition"
              xs={24}
              sm={18}
              md={16}
              lg={16}
              xl={12}
            >
              <Radio.Group
                onChange={handlePricingToggle}
                value={isYearly ? "yearly" : "monthly"}
              >
                <Radio value="yearly">Billed annually</Radio>
                <Radio value="monthly">Billed monthly</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row className="mt-8 mb-8" justify="space-between" gutter={[24, 24]}>
            {planOptions.map((plan) => {
              const isSelected =
                selectedPlan?.name === plan?.name &&
                (isYearly
                  ? selectedPlan.price === plan.annuallyPrice
                  : selectedPlan.price === plan.monthlyPrice);
              return (
                <Col xs={24} sm={12} md={8} lg={8} xl={8} key={plan.id}>
                  <div
                    className={`subscriptionPlans ${
                      isSelected ? "selected" : ""
                    }`}
                  >
                    <div className="planTitle">
                      <span className="months">
                        {isYearly ? "6 months free" : "3 months free"}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <img
                          src={plan?.name === "Standard" ? starBadge : starRate}
                          alt="badge"
                          style={{ marginRight: "8px" }}
                        />
                        <b>
                          {plan?.name === "Standard"
                            ? "Starter"
                            : plan?.name === "Premium"
                            ? "Premium"
                            : "Enterprise"}{" "}
                        </b>
                        &nbsp; package
                      </span>
                    </div>
                    <div className="payment">
                      <span>USD</span>{" "}
                      <span className="annuallyPrice">
                        ${isYearly ? plan.annuallyPrice : plan.monthlyPrice}
                      </span>
                      <span style={{ color: "#AA1A5F" }}> per month</span>
                    </div>
                    <div className="content">
                      <p
                        style={{
                          color: isSelected ? "#AA1A5F" : "#AA1A5F",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        This plan includes:
                      </p>
                      <p>
                        <AiOutlineCheckCircle color="#AA1A5F" />
                        &nbsp;
                        <b>
                          {plan.workspaceLimit !== 0
                            ? plan.workspaceLimit
                            : "Unlimited"}{" "}
                        </b>
                        workspaces
                      </p>
                      <p>
                        <AiOutlineCheckCircle color="#AA1A5F" />
                        &nbsp;
                        <b>
                          {plan.membersLimit !== 0
                            ? plan.membersLimit
                            : "Unlimited"}{" "}
                        </b>
                        users/workspace
                      </p>
                      <p>
                        <AiOutlineCheckCircle color="#AA1A5F" />
                        &nbsp;
                        {plan.databaseLimit !== 0 ? (
                          <>
                            <b>{plan.databaseLimit} GB</b> data storage limit
                          </>
                        ) : (
                          <>
                            <b>Unlimited</b> data storage
                          </>
                        )}
                      </p>
                    </div>
                    <PlanDetail planData={plan} />
                    <Button
                      type="default"
                      className={`defaultButton ${
                        isSelected ? "currentPlan" : "choosePlan"
                      }`}
                      disabled={isSelected}
                      onClick={() => handlePlanSelection(plan)}
                    >
                      {isSelected ? "Current plan" : "Choose this plan"}
                    </Button>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </>
  );
};

export default SubscriptionPlanBoxes;
