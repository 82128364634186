import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spin } from "antd";
import "./style.scss";
import { get } from "../../../../services/authService";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";

const PreviewModal = ({ visible, record, onCancel }) => {
  console.log(record);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ipPlanCard, setIpPlanCard] = useState({});

  useEffect(() => {
    setLoading(true);
    if (record) {
      const apiIpPlan = `/api/IPPlan/getIPPlan?id=${record}`;

      get(apiIpPlan)
        .then((res) => setIpPlanCard(res.data))
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [record]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  function getPhaseDescription(value) {
    let description;
  
    switch (value) {
      case 1:
        description = "Discussion";
        break;
      case 2:
        description = "Document Review";
        break;
      case 3:
        description = "Pre Phase";
        break;
      case 4:
        description = "Data Collection";
        break;
      case 5:
        description = "Closed";
        break;
      default:
        description = "Unknown";
    }
  
    return description;
  }
  
  function getMonthName(monthNumber) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    if (monthNumber < 1 || monthNumber > 12) {
      throw new Error('Invalid month number');
    }
    
    return monthNames[monthNumber - 1];
  }

  return (
    <Modal
      title="Preview IP plan card"
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      {
        <Spin spinning={loading}>
        <div>
          <Form form={form} layout="vertical">
            <p className="modalsHeader1">General details</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="ip"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Implementing partner:
                  </span>
                }
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.implementingPartner?.name}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Office:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{ipPlanCard?.office?.name}</p>
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="ip"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    IP Program Activities Description:
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.planningRound?.programDescription || "N/A"}
                </p>
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Assigned to:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{ipPlanCard?.assignee?.email}</p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Clusters:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{ipPlanCard?.clusters?.map(cluster => cluster?.name)}</p>
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="ip"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    General information about on-site operations:
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">{ipPlanCard?.generalInfo}</p>
              </Form.Item>
            </div>
            <p className="modalsHeader1">Assigned to round</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Month:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {getMonthName(ipPlanCard?.planningRound?.month)}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Year:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{ipPlanCard?.planningRound?.year}</p>
              </Form.Item>
            </div>
            <p className="modalsHeader1">Point of contact</p>
            <div className="contactInfoWrapper">
              <Form.Item
                name="ip"
                label={
                  <span style={{ fontWeight: "bold" }}>Contact name:</span>
                }
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.pointOfContact?.name}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Email:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.pointOfContact?.email}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Role:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.pointOfContact?.role}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={
                  <span style={{ fontWeight: "bold" }}>Organization:</span>
                }
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.pointOfContact?.organization}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Phone:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.pointOfContact?.phone}
                </p>
              </Form.Item>
              <Form.Item
                name="ip"
                label={<span style={{ fontWeight: "bold" }}>Skype:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {ipPlanCard?.pointOfContact?.skype}
                </p>
              </Form.Item>
            </div>
            <div className="my-3"><span  style={{ fontWeight: "bold" }}>Security plan:</span></div>
            <div className="filesWrapper">
            {ipPlanCard?.ipPlanFiles?.map((file) => (
              <div className="fileDetails">
                <div className="fileIcon"><img src={uploadIcon} alt="uploaded" /></div>
                <div className="fileInfo">
                  <span>{file?.fileName}</span>
                  <p className="mb">{file?.fileSize}</p>
                </div>
              </div>
            ))}
            </div>

            <div className="my-3" style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="isPostponed"
                label={<span style={{ fontWeight: "bold" }}>Current IP status:</span>}
                style={{ width: "100%" }}
              >
                <p className="previewText ">
                  {getPhaseDescription(ipPlanCard?.currentIPStatus)}
                </p>
              </Form.Item>
            </div>
            <div className="button-container">
              <Button
                className="inviteButtonTPM"
                key="cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
        </Spin>
      }
    </Modal>
  );
};

export default PreviewModal;
