import { Spin, Pagination } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WorkspaceBox from "./WorkspaceBoxMenu";
import EditWorkspaceModal from "./EditWorkspace/EditWorkspaceModal";
import { get,put } from "../../../services/authService";
import "./styles.scss";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import AddWorkspaceModal from "./AddWorkspace/AddWorkspaceModal"
import { httpService } from "../../../services/httpService.service";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg"

const WorkspaceBody = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 12,
    total: 0,
    pageCount: 0,
  });
  const [modals, setModals] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const fetchWorkspaces = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get("/api/Contract/getAll", {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
      });
      const { data = [], metaData = {} } = response;
      setWorkspaces(data);
      setPagination((prev) => ({
        ...prev,
        total: metaData.totalItemCount || 1,
        pageCount: metaData.pageCount || 1,
      }));
    } catch (error) {
      console.error("Error fetching workspaces", error);
      setWorkspaces([]);
      setPagination((prev) => ({ ...prev, total: 1, pageCount: 1 }));
    } finally {
      setLoading(false);
    }
  }, [pagination.currentPage, pagination.pageSize]);

  useEffect(() => {
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  const toggleModal = (modal) =>
    setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

  const handleWorkspaceChange = (workspace, action) => {
    setCurrentWorkspace(workspace);
    toggleModal(action);
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({ ...prev, currentPage: page, pageSize }));
  };

  const handleWorkspaceClick = useCallback(
    (workspaceId) => {
      localStorage.setItem("currentContractId", workspaceId);
      navigate("/projects/");
    },
    [navigate]
  );

  const handleEditSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await put("/api/Workspace", values);
        fetchWorkspaces();
        toggleModal("edit");
      } catch (error) {
        console.error("Failed to edit contract:", error);
      } finally {
        setLoading(false);
      }
    },
    [fetchWorkspaces]
  );

  const handleDeleteSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await httpService.delete(`/api/Contract?Id=${currentWorkspace.id}`);
      fetchWorkspaces();
      toggleModal("delete");
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: "Contract deleted successfully!",
        text: "This contract has been deleted from your database!",
      });
      setPagination((prev) => ({ ...prev, currentPage:1 }));
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000); 
    } catch (error) {
      setMessage({
        trigger: true,
        type: "error",
        icon: AddMemberIcon,
        title: "Failed to delete contract",
        text: error.message,
      });
      setTimeout(() => {
        setMessage({ trigger: false, type: "", icon: "", title: "", text: "" });
      }, 3000); 
      console.error("Failed to delete contract:", error);
    } finally {
      setLoading(false);
    }
  }, [currentWorkspace, fetchWorkspaces]);
  
  
  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="top-content">
        <h4>My Contracts</h4>
        <button className="my-2" onClick={() => toggleModal("add")}>
          + New Contract
        </button>
      </div>
      <span className="d-flex" style={{ padding: "0 40px" }}>
      Below are the contracts you are part of. Select the one you want to work on
      </span>
      <div style={{ padding: "0 40px" }}>
        <div className="workspace-container">
          {loading ? (
            <Spin spinning={loading} />
          ) : (
            <>
              {workspaces.length > 0 ? (
                workspaces.map((workspace) => (
                  <WorkspaceBox
                    key={workspace.id}
                    id={workspace.id}
                    title={workspace.name}
                    onEdit={() => handleWorkspaceChange(workspace, "edit")}
                    onDelete={() => handleWorkspaceChange(workspace, "delete")}
                    onClick={handleWorkspaceClick}
                  />
                ))
              ) : (
                <p>No contracts available</p>
              )}
            </>
          )}
        </div>
        <div className="d-flex justify-content-end me-5 my-5">
        <Pagination
          current={pagination.currentPage}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={handlePageChange}
        />
        </div>
      </div>
      <AddWorkspaceModal
        visible={modals.add}
        onClose={() => toggleModal("add")}
        onSubmit={() => {
          fetchWorkspaces();
        }}
        setMessage={setMessage}
      />
      <EditWorkspaceModal
        visible={modals.edit}
        onClose={() => toggleModal("edit")}
        onSubmit={() => {
          fetchWorkspaces();
        }}
        currentContractId={currentWorkspace}
        setMessage={setMessage}
      />
      <DeleteComponent
        header="Delete Contract"
        text={
          "You're about to delete this contract. This action can’t be undone."
        }
        visible={modals.delete}
        onClose={() => toggleModal("delete")}
        onConfirm={handleDeleteSubmit}
      />
    </>
  );
};

export default WorkspaceBody;
