import { Modal, Form, Input, Button, Divider, Typography, message, Spin } from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import LocationDetails from "./LocationDetails";
import VisitDetails from "./VisitDetails";
import Logistics from "./Logistics";
import ResourcesDetails from "./ResourcesDetails";
import TeamDetails from "./TeamDetails";
import Postponement from "./Postponement";
import TrackingDetails from "./TrackingDetails";
import { useEffect, useState } from "react";
import { post } from "../../../../../services/authService";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";

const { TextArea } = Input;

const { Title } = Typography;

const SiteVisitModal = ({ visible, onCancel, onOk }) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return '';
  
    if(type) {
      const newDate = new Date(date);
      const formattedDate = moment(new Date(newDate.setDate(newDate.getDate() - 1)));
      return formattedDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
      const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
      return formattedDate;
    }
  };
  
  const formatHours = (hrs) => {
    if (!hrs?.$isDayjsObject) return '';
  
    const formattedTime = hrs.format('HH:mm:ss');
    return formattedTime;
  };
  

  const transformSiteContacts = (siteContactsObj) => {
    const siteContacts = siteContactsObj?.siteContacts;
    if (!siteContacts || !Array.isArray(siteContacts)) {
      return [];
    }

    return siteContacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 1,
    }));
  };

  const transformPointContacts = (pointContactsObj) => {
    const pointContacts = pointContactsObj?.siteContacts;
    if (!pointContacts || !Array.isArray(pointContacts)) {
      return [];
    }

    return pointContacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 2,
    }));
  };

  const transformKIIcontacts = (KIIcontactsObj) => {
    const KIIcontacts = KIIcontactsObj?.siteContacts;
    if (!KIIcontacts || !Array.isArray(KIIcontacts)) {
      return [];
    }

    return KIIcontacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 3,
    }));
  };

  const initialValues = {
    observationList: false,
    isVirtual: false,
    trainingIsCompleted: false,
    latitude: "",
    longitude: "",
    beneficiariesCount: 3,
    kills: 3,
    observationChecklistCount: 3,
    mediaLimit: 3
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const plannedVisit = `${formatDate(values.dateOfPlannedVisit)}`;
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const activationDate = formatDate(values.dateOfPlannedVisit, 'activation');
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);
        
        // const siteContacts = transformSiteContacts(values.siteContacts);
        // const pointContacts = transformPointContacts(values.pointContacts);
        // const KIIcontacts = transformKIIcontacts(values.KIIcontacts);

        let siteContacts = values.siteContacts;
        let pointContacts = values.pointContacts;
        let KIIcontacts = values.KIIcontacts;

        siteContacts?.map(contact => contact.contactType = 1)
        pointContacts?.map(contact => contact.contactType = 2)
        KIIcontacts?.map(contact => contact.contactType = 3)

        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];
  
        const formData = new FormData();
        formData.append("IPPlanId", values.IPlaningCard);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector);
        formData.append("ProjectId", projectId);
        formData.append("LocationDetails.Latitude", values.latitude );
        formData.append("LocationDetails.Longitude", values.longitude );
        formData.append("isVirtual", values.isVirtual || false);
        formData.append("Visit.PlannedVisit", plannedVisit);
        
        createContacts.forEach((contact, index) => {
          formData.append(`visit.createContacts[${index}].name`, contact.contactName);
          formData.append(`visit.createContacts[${index}].email`, contact.email);
          formData.append(`visit.createContacts[${index}].role`, contact.role);
          formData.append(`visit.createContacts[${index}].phone`, contact.phone || "");
          formData.append(`visit.createContacts[${index}].skype`, contact.skype || "");
          formData.append(`visit.createContacts[${index}].organization`, contact.organization || "");
          formData.append(`visit.createContacts[${index}].contactType`, contact.contactType);
        });
  
        formData.append("Visit.ObservationListCheck", values.observationList || false);
        formData.append("Visit.CreateInterviewDetails.BeneficiariesCount", values.beneficiariesCount);
        formData.append("Visit.CreateInterviewDetails.ClientsCount", values.kills);
        formData.append("Visit.CreateInterviewDetails.ObservationChecklist", values.observationChecklistCount);
        formData.append("Visit.CreateInterviewDetails.MediaLimit", values.mediaLimit);
        formData.append("Visit.CreateInterviewDetails.SamplingInstructions", values.samplingInstructions || '');
        formData.append("Visit.CreateInterviewDetails.ClientInstructions", values.clientInstructions || '');
        formData.append("Visit.CreateInterviewDetails.OtherInstructions", values.otherInstructions || '');
        formData.append("Visit.VisitPlanApproval", values.visitPlanApproval || '');
  
        formData.append("SpecificsOfOnSiteOperation", values.specificsOfOnSiteOperation);
        formData.append("ObservationList", values.observationList || false);
        
        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);
        formData.append("Logistic.ActivationDate", activationDate);
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed || false);
        formData.append("Logistic.PostponedReason", values.postponedReason || '');
        formData.append("Logistic.PostponedDetails", values.postponedDetails || '');
        formData.append("Logistic.Notes", values.notes || '');
        
        formData.append("Tracking.ReportingYear", values.reportingYear);
        formData.append("Tracking.ReportingMonth", values.reportingMonth);
        formData.append("Tracking.TrainingDate", trainingDate);
        formData.append("Tracking.TrainingScoreInfo", values.trainingScoreInfo);
        formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
        formData.append("Tracking.DateOfReport", dateOfReport);
        formData.append("Tracking.DateOfDebrief", dateOfDebrief);
        formData.append("Tracking.PercentageOfDataCollectionCompleted", values.percentageOfDataCollectionCompleted);
        formData.append("Tracking.SiteVisitStatus", 1);
        formData.append("Tracking.FieldTeamReportingStatus", 1);
        formData.append("Tracking.TrainingIsCompleted", values.trainingIsCompleted || false);

        formData.append("Files[0].FileCategory", 1);
        formData.append("Files[0].File", values.report_documents || undefined);
        
        formData.append("Files[1].FileCategory", 2);
        formData.append("Files[1].File", values.other_files || undefined);
        
        formData.append("Files[2].FileCategory", 3);
        formData.append("Files[2].File", values.video_files || undefined);

        httpService.post("/api/Site/createSiteVisitCard", formData, (res) => {
          message.success("You have successfully created");
          setSubmitLoader(false);
          form.resetFields();
          onOk();
        }, (error) => {
          console.error("There was an error adding the project!", error);
          setSubmitLoader(false);
          message.error("Failed to create site visit. Please try again later.");
        }, {
          headers: {
             "Content-Type": "multipart/form-data",
          },
       })
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        message.error("Please fill all the required fields.");
      });
  };
  

  return (
    <Modal
      title="Site visit card"
      open={visible}
      onCancel={onCancel}
      width={800}
      footer={[
        <Button key="back" onClick={onCancel} className="closeButton">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="submitButton"
          loading={submitLoader}
        >
          Add Site Visit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Site visit card identifier" name="identifier">
          <Input placeholder="### - Namegoeshere - Location - date" disabled/>
        </Form.Item>
        <GeneralDetails />
        <Divider />
        <h3>Location details</h3>
        <LocationDetails form={form}/>

        <Divider />
        <h3>Visit details</h3>

        <VisitDetails />

        <Divider />
        <h3>Logistics</h3>

        <Logistics />
        
        <Divider />
        <h3>Team</h3>
        <TeamDetails />
        
        <Divider/>
        <h3>Postponement</h3>
        <Postponement />
        
        <Divider />
        <h3>Resources</h3>

        <ResourcesDetails form={form} />

        <Divider />

        {/* <h3>Tracking</h3>
        <TrackingDetails /> */}

        <Divider />
      </Form>
    </Modal>
  );
};
export default SiteVisitModal;
