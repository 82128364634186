import React, { useState } from "react";
import { Modal } from "antd";

function PlanDetail({ planData }) {
  const [open, setOpen] = useState(false);
  const getIfIsYearly = localStorage.getItem("isYearly");
  return (
    <div className="plan-details-modal">
      <a onClick={() => setOpen(true)}>
        <span
          style={{
            color: "#F58F45",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          See full plan details
        </span>
      </a>
      <Modal
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={900}
      >
        <h2>{planData?.name}</h2>
        <p>This plan includes:</p>
        <ul>
          {/* <li>
                  Access our data collection services, including unlimited survey design, project
                  organization, and data export in Excel and PDF formats
               </li>
               <li>
                  Share key insights with stakeholders and manage your team and permissions through
                  our platform, creating and assigning roles to users, tracking progress, and
                  measuring performance
               </li>
               <li>
                  Manage calls with ease, uploading unlimited datasets of cases, integrating with
                  WhatsApp, and automatically assigning calls to users
               </li>
               <li>
                  Effectively manage beneficiary feedback with our platform, creating public
                  feedback pages in just three clicks, collecting and analyzing feedback data, and
                  assigning feedback collectors
               </li> */}
          <li>
            With{" "}
            {planData?.workspaceLimit != 0
              ? planData?.workspaceLimit
              : "Unlimited"}{" "}
            workspaces and{" "}
            {planData?.membersLimit != 0 ? planData?.membersLimit : "Unlimited"}{" "}
            users per workspace, enjoy{" "}
            {planData?.databaseLimit != 0 ? (
              <>a data storage limit of {planData?.databaseLimit}GB</>
            ) : (
              <>an unlimited storage</>
            )}
          </li>
        </ul>
        <p>{planData?.description}</p>
        <div className="plan-price">
          <p>Plan's Price</p>
          <h4>
            <span>USD </span>$
            {getIfIsYearly === "true"
              ? planData.annuallyPrice + "/yr."
              : planData.monthlyPrice + "/mo."}
          </h4>
        </div>
      </Modal>
    </div>
  );
}

export default PlanDetail;
