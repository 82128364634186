import { Modal, Form, Input, Button, Divider, Typography, message } from "antd";
import "./style.scss";
import GeneralDetails from "./addSiteVisitModalParts/GeneralDetails";
import LocationDetails from "./addSiteVisitModalParts/LocationDetails";
import VisitDetails from "./addSiteVisitModalParts/VisitDetails";
import Logistics from "./addSiteVisitModalParts/Logistics";
import ResourcesDetails from "./addSiteVisitModalParts/ResourcesDetails";
import TrackingDetails from "./addSiteVisitModalParts/TrackingDetails";
import { useEffect, useState } from "react";
import { post } from "../../../../../services/authService";

const { TextArea } = Input;

const { Title } = Typography;

const SiteVisitModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  const formatDate = (date) => {
    if (!date?.$isDayjsObject) return '';
  
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  };
  
  const formatHours = (hrs) => {
    if (!hrs?.$isDayjsObject) return '';
  
    const formattedTime = hrs.format('HH:mm:ss');
    return formattedTime;
  };
  

  const transformSiteContacts = (siteContactsObj) => {
    const siteContacts = siteContactsObj?.siteContacts;
    if (!siteContacts || !Array.isArray(siteContacts)) {
      return [];
    }

    return siteContacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 1,
    }));
  };

  const transformPointContacts = (pointContactsObj) => {
    const pointContacts = pointContactsObj?.siteContacts;
    if (!pointContacts || !Array.isArray(pointContacts)) {
      return [];
    }

    return pointContacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 2,
    }));
  };

  const transformKIIcontacts = (KIIcontactsObj) => {
    const KIIcontacts = KIIcontactsObj?.siteContacts;
    if (!KIIcontacts || !Array.isArray(KIIcontacts)) {
      return [];
    }

    return KIIcontacts.map((contact) => ({
      name: contact.contactName,
      email: contact.email,
      role: contact.role,
      phone: contact.phone || "",
      skype: contact.skype || "",
      organization: contact.organization || "",
      contactType: 3,
    }));
  };

  const initialValues = {
    observationList: false,
    isVirtual: false,
    trainingIsCompleted: false,
    latitude: "",
    longitude: "",
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const visitStartTime = `${formatDate(values.visitStartTime)}T${formatHours(values.visitStartTime)}.000Z`;
        const plannedVisit = formatDate(values.dateOfPlannedVisit);
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const activationDate = formatDate(values.activationDate);
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);
        
        const siteContacts = transformSiteContacts(values.siteContacts);
        const pointContacts = transformPointContacts(values.pointContacts);
        const KIIcontacts = transformKIIcontacts(values.KIIcontacts);
        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];
  
        const formData = new FormData();
        formData.append("ImplementingPartnerId", values.partner);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector);
        formData.append("ProjectId", projectId);
        formData.append("LocationDetails.Latitude", values.latitude || "42.877651");
        formData.append("LocationDetails.Longitude", values.longitude || "20.857268");
        formData.append("isVirtual", values.isVirtual || false);
  
        formData.append("Visit.StartTime", visitStartTime);
        formData.append("Visit.PlannedVisit", plannedVisit);
        
        createContacts.forEach((contact, index) => {
          // formData.append(`visit.createContacts[${index}].visitId`, "3fa85f64-5717-4562-b3fc-2c963f66afa6");
          formData.append(`visit.createContacts[${index}].name`, contact.name);
          formData.append(`visit.createContacts[${index}].email`, contact.email);
          formData.append(`visit.createContacts[${index}].role`, contact.role);
          formData.append(`visit.createContacts[${index}].phone`, contact.phone || "");
          formData.append(`visit.createContacts[${index}].skype`, contact.skype || "");
          formData.append(`visit.createContacts[${index}].organization`, contact.organization || "");
          formData.append(`visit.createContacts[${index}].contactType`, contact.contactType);
        });
  
        formData.append("Visit.ObservationListCheck", values.observationList || false);
        formData.append("Visit.CreateInterviewDetails.BeneficiariesCount", values.beneficiariesCount);
        formData.append("Visit.CreateInterviewDetails.ClientsCount", values.kills);
        formData.append("Visit.CreateInterviewDetails.ObservationChecklist", values.observationChecklistCount);
        formData.append("Visit.CreateInterviewDetails.MediaLimit", values.mediaLimit);
        formData.append("Visit.CreateInterviewDetails.SamplingInstructions", values.samplingInstructions);
        formData.append("Visit.CreateInterviewDetails.ClientInstructions", values.clientInstructions);
        formData.append("Visit.CreateInterviewDetails.OtherInstructions", values.otherInstructions);
        formData.append("Visit.VisitPlanApproval", values.visitPlanApproval);
  
        formData.append("SpecificsOfOnSiteOperation", values.specificsOfOnSiteOperation);
        formData.append("ObservationList", values.observationList || false);
        
        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);
        formData.append("Logistic.ActivationDate", activationDate);
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed);
        formData.append("Logistic.PostponedReason", values.postponedReason);
        formData.append("Logistic.PostponedDetails", values.postponedDetails);
        formData.append("Logistic.Notes", values.notes);
        
        formData.append("Tracking.ReportingYear", values.reportingYear);
        formData.append("Tracking.ReportingMonth", values.reportingMonth);
        formData.append("Tracking.TrainingDate", trainingDate);
        formData.append("Tracking.TrainingScoreInfo", values.trainingScoreInfo);
        formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
        formData.append("Tracking.DateOfReport", dateOfReport);
        formData.append("Tracking.DateOfDebrief", dateOfDebrief);
        formData.append("Tracking.PercentageOfDataCollectionCompleted", values.percentageOfDataCollectionCompleted);
        formData.append("Tracking.SiteVisitStatus", 1);
        formData.append("Tracking.FieldTeamReportingStatus", 1);
        formData.append("Tracking.TrainingIsCompleted", values.trainingIsCompleted || false);
  
        post("/api/Site/createSiteVisitCard", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(() => {
            message.success("You have successfully created");
            form.resetFields();
            onClose();
          })
          .catch((error) => {
            console.error("There was an error adding the project!", error);
            message.error("Failed to create site visit. Please try again later.");
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };
  

  return (
    <Modal
      title="Site visit card"
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button key="back" onClick={onClose} className="closeButton">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="submitButton"
        >
          Save Changes
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Site visit card identifier" name="identifier">
          <Input placeholder="### - Namegoeshere - Location - date" />
        </Form.Item>
        <GeneralDetails />
        <Divider />
        <h3>Location details</h3>
        <LocationDetails />

        <Divider />
        <h3>Visit details</h3>

        <VisitDetails />

        <Divider />
        <h3>Logistics</h3>

        <Logistics />

        <Divider />

        <h3>Resources</h3>

        <ResourcesDetails />

        <Divider />

        <h3>Tracking</h3>
        <TrackingDetails />

        <Divider />
      </Form>
    </Modal>
  );
};
export default SiteVisitModal;
