import { Tabs } from "antd";
import Banner from "../../components/Banner/Banner";
import TabPane from "antd/es/tabs/TabPane";
import IpPlanningBoard from "./IpPlanningBoard";
import TasksAssignments from "./TasksAssignments/TasksAssignments";
import "./styles.scss";
import VisitsBoard from "./VisitBoard/VisitBoard";

function Planning() {
  return (
    <>
      <div className="planning-banner">
        <Banner text="Planning" />
      </div>
      <div className="planning-page">
        <Tabs
          type="card"
          className="card-tabs"
          // onChange={}
          destroyInactiveTabPane={true}
        >
          <TabPane className="tabpane" tab="IP Planning board" key="1">
            <IpPlanningBoard />
          </TabPane>
          <TabPane className="tabpane" tab="Visits board" key="2">
            <VisitsBoard />
          </TabPane>
          <TabPane className="tabpane" tab="Tasks assignments" key="3">
            <TasksAssignments />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default Planning;
