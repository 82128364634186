import React, { useEffect, useState } from "react";
import {  Select, Input, Button } from "antd";
import { get } from "../../../../../../services/authService";
import SearchIcon from "../../../../../../Assets/svg/searchIcon.svg"

const { Option } = Select;

const Filters = ({ data, setTableData }) => {
  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [filters, setFilters] = useState({
    country: [],
    dateRange: [],
    tripStatus: [],
  });

  const applyFilters = () => {
    let filteredData = data;

    if (filters.country.length > 0) {
      filteredData = filteredData.filter((item) => {
        return filters.country.some((selectedcountry) =>
          item.pickupPlace.toLowerCase().includes(selectedcountry.toLowerCase())
        );
      });
    }

    setTableData(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const handleClearAll = () => {
    setFilters({
      country: [],
      dateRange: [],
      tripStatus: [],
    });
  };

  const handleLocation = (country) => {
    setFilters((prevFilters) => ({ ...prevFilters, country }));
  };

  //get data from API for filters
  const [allCountries, setAllCountries] = useState();

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    get(apiCountries)
      .then((res) => setAllCountries(res.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        {" "}
        <Input placeholder="Type something here" suffix={<img src={SearchIcon}/>}/>
        <Button className="clearButton" onClick={handleClearAll}>Clear All</Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Location 1</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a location"
              allowClear
              mode="multiple"
              onChange={handleLocation}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries &&
                allCountries.map(country => (
                  <Option key={country.id} value={country.stateName}>
                    {country.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Location 2</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a location"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries &&
                allCountries.map(country => (
                  <Option key={country.id} value={country.stateName}>
                    {country.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Team lead</p>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a team lead"
            >
              <Option value="role1">Role 1</Option>
              <Option value="role2">Role 2</Option>
              <Option value="role3">Role 3</Option>
              {/* Add more role options as needed */}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
